

import React from 'react';
import Button from "../button.js";
import InputField from "../forms/inputField";
import { ReactComponent as LinkIcon } from "../../icons/link.svg";
import { ReactComponent as SortAscendingIcon } from "../../icons/sort-ascending.svg";
import { ReactComponent as SortDescendingIcon } from "../../icons/sort-descending.svg";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";

const Z_LIMIT = 30;

const ImageSublayerForm = ({
  z,
  src: url,
  width,
  height,
  id,
  updateLayer,
  removeLayer,
}) => {
  const handleBringForward = () => {
    // don't go over Z_LIMIT
    updateLayer({
      id,
      data: { z: z < Z_LIMIT ? z + 1 : z },
    });
  };

  const handleSendBack = () => {
    // don't go negative
    updateLayer({
      id,
      data: { z: z > 0 ? z - 1 : z },
    });
  };

  const handleDimensionUpdate = ({
    width: givenWidth,
    height: givenHeight,
  }) => {
    updateLayer({
      id,
      data: {
        width: parseInt(givenWidth ? givenWidth : width),
        height: parseInt(givenHeight ? givenHeight : height),
      },
    });
  };

  return (
    <div>
      <div className="w-full border-b border-gray-100 mb-4">
        <h3 className="text-gray-100 font-bold text-lg leading-relaxed">
          Image
        </h3>
      </div>
      <div className="h-72 bg-cPurple-light rounded-lg p-1 mb-4 flex flex-col justify-between">
        <div>
          <h4 className="text-gray-100 font-bold text-lg mb-2">Location</h4>
          <a href={url} target="_new">
            <div className="flex flex-row items-center hover:text-yellow-400">
              <LinkIcon className="flex-shrink-0 w-3 h-3 mr-1" />
              <p className="truncate">{url}</p>
            </div>
          </a>
        </div>
        <div>
          <h4 className="text-gray-100 font-bold text-lg mb-2">Dimensions</h4>
          <div className="flex gap-2">
            <div>
              <InputField
								className="w-20 bg-cPurple-light text-white"
                label="Width"
                labelStyle="text-gray-100"
                id="width"
                value={Math.floor(width * 1000) / 1000}
                type="number"
                onChange={(event) =>
                  handleDimensionUpdate({ width: event.target.value })
                }
              />
            </div>
            <div>
              <InputField
								className="w-20 bg-cPurple-light text-white"
                label="Height"
                labelStyle="text-gray-100"
                id="height"
                value={Math.floor(height * 1000) / 1000}
                type="number"
                onChange={(event) =>
                  handleDimensionUpdate({ height: event.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-gray-100 font-bold text-lg mb-2">Stacking</h3>
          <div className="flex gap-1 mb-2">
            <Button onClick={handleBringForward} className="w-1/2 text-xs px-1 bg-purple-700 text-white hover:text-gray-700">
              <SortAscendingIcon className="w-4 h-4" />
              Bring Forward
            </Button>
            <Button className="w-1/2 text-xs px-1 bg-purple-700 text-white hover:text-gray-700" onClick={handleSendBack}>
              <SortDescendingIcon className="w-4 h-4" /> Send Back
            </Button>
          </div>
          <span className="italic text-gray-100">Layer Level: {z}</span>
        </div>
      </div>
      <div>
        <Button danger className="w-full" onClick={() => removeLayer(id)}>
          <TrashIcon className="w-4 h-4 mr-px" />
          Remove
        </Button>
      </div>
    </div>
  );
};

export default ImageSublayerForm;
