import React, { useContext, useState, useMemo } from "react";
import InviteLink from "./inviteLink";
import AudioChannel from "./audioChannel";
import Scenes from "./scenes";
// import Broadcast from './broadcast';
import Button from "../button";
import VirtualBackgroundModal from "../../features/virtualBackgroundModal";
import { FirebaseContext } from "../../helpers/contexts/firebaseContext";
import { useCollectionQuery } from "../../helpers/hooks/useFirebaseCollection";
import { deleteFile, firebaseUpload } from "../../helpers/firebaseUpload";
import { nanoid } from "nanoid";
import { AuthContext } from "../../helpers/contexts/userSessionContext";
import { LocalTracksContext } from "../../helpers/contexts/localTracksContext";
import { getFirestore, collection, doc, deleteDoc, setDoc } from "@firebase/firestore";
import { ReactComponent as ArrowDown } from "../../icons/chevron-down.svg";
import { StreamActions } from "../Header/StreamActions";
import { ISOProvider } from "../../helpers/contexts/isoContext";
import {
  selectPeers,
	useHMSStore
} from "@100mslive/react-sdk";
import { PlayerList } from "../../features/playerList";

const HeaderQuickControl = ({
	isHost,
	conferenceId
}) => {
	const [isVirtualBackgroundModal, setIsVirtualBackgroundModal] = useState();

	const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);

  const docRef = useMemo(() => doc(collection(db, "conferences"), conferenceId ?? "defaultConference"), [db, conferenceId]);
	const virtualBackgroundRef = useMemo(() => collection(db, 'virtualBackgrounds'), [db]);

	const [virtualBackgrounds, virtualBackgroundsLoading] = useCollectionQuery(virtualBackgroundRef, {
    idField: "id",
  });
  
	const { user } = useContext(AuthContext);

  const peers = useHMSStore(selectPeers);

	const {
    virtualBackground,
  } = useContext(LocalTracksContext);

	const addNewVirtualBackground = async ({ img, isCustomRatio }) => {
    const file = img?.[0];
		if (!file) return;

    const url = await firebaseUpload(firebaseApp)({
      name: file.name,
      file,
    });

		const id = nanoid();

    setDoc(doc(virtualBackgroundRef, id), {
      id,
      userId: user?.uid,
			url,
    });
  };

	const deleteVirtualBackground = async  (id) => {
		deleteDoc(doc(virtualBackgroundRef, id));
	};

	const handleVirtualBackground = (url) => {
		setIsVirtualBackgroundModal(false);
	};
	return (
		<div className="flex w-full justify-between">
			<VirtualBackgroundModal
				isOpen={isVirtualBackgroundModal}
				onOk={handleVirtualBackground}
				onCancel={() => setIsVirtualBackgroundModal(false)}
				addBackground={addNewVirtualBackground}
				deleteBackground={deleteVirtualBackground}
				virtualBackgrounds={virtualBackgrounds}
			/>
			<div className="flex items-center ">
				<InviteLink className="text-gray-100"/>
				{/* <AudioChannel className="text-gray-100" /> */}
				<button
					className="pl-2 pr-2 ml-2 border-none custom-bg-1 text-gray-100 flex items-center hover:custom-bg-1"
					onClick={() => setIsVirtualBackgroundModal(true)}
				>
					Virtual Background
					<ArrowDown className="text-gray-100 w-4 h-4 -mb-1"/>
				</button>
				{/* <PlayerList /> */}
			</div>
			
			{/* <Broadcast conferenceId={conferenceId} /> */}
			
			{isHost && 
				<div className="flex">

					<ISOProvider users={peers}>
						<StreamActions />
					</ISOProvider>
					
					<Scenes conferenceId={conferenceId}/>
				</div>
			}
		</div>
	)
};

export default React.memo(HeaderQuickControl);
