import React from "react";
import {useSliderState} from 'react-stately';

import {mergeProps, useFocusRing, useNumberFormatter, useSlider, useSliderThumb, VisuallyHidden} from 'react-aria';
import { timeFormat } from '../../../utils/timeFormat';

const Thumb = (props) => {
	let { state, trackRef, index } = props;
	let inputRef = React.useRef(null);
	let { thumbProps, inputProps, isDragging } = useSliderThumb({
		index,
		trackRef,
		inputRef
	}, state);

	let { focusProps, isFocusVisible } = useFocusRing();

	const [isThumbLavelShow, setIsThumbLavelShow] = React.useState(false);

	const handleMouseEnter = (e) => {
		setIsThumbLavelShow(true);
	}

	const handleMouseLeave = (e) => {
		setIsThumbLavelShow(false);
	}

	return (
		<div
			{...thumbProps}
			className={`thumb ${isFocusVisible ? 'focus' : ''} ${
				isDragging ? 'dragging' : ''
			} w-4 h-4 rounded-full bg-blue-500`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{isThumbLavelShow &&
				<div
					className="absolute w-48 -top-5 text-white -left-10"
				>
					{timeFormat(state?.values?.[index] / 100 * (props?.videoDuration ?? 0))}
				</div>
			}
			<VisuallyHidden>
				<input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
			</VisuallyHidden>
		</div>
	);
}

export default Thumb;
