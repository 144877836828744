import React, { useEffect } from 'react';
import { useState, useMemo, useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { useFirebaseContext } from '../helpers/hooks/useFirebaseContext';
import { FirebaseContext } from '../helpers/contexts/firebaseContext';
import { getFirestore, collection, doc, getDoc, setDoc, onSnapshot } from "firebase/firestore";
// import ccLogo from "../images/CC_logo.png";
import ccLogo from "../image/Voodoo Cast_logo_dark mode.svg";

// import PaymentForm from '../components/forms/payment';

// TODO
// - Register users / user sign up

const Login = ({ redirectToHome = false }) => {
  const storedEmail = window.localStorage.getItem("email");
	const { user, loginUser, error } = useFirebaseContext();
  const { firebaseApp } = useContext(FirebaseContext);
  const db = getFirestore(firebaseApp);

	const { register, handleSubmit } = useForm({
    defaultValues: {
      rememberMe: !!storedEmail,
      email: storedEmail,
			password: ''
    },
  });

	// useEffect(() => {
		const updateUserDoc = async (_user) => {
			const usersRef = collection(db, 'profiles');
			const docRef = doc(db, "profiles", _user?.uid ?? "defaultLayer");
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				// console.log("user data:", docSnap.data());
			} else {
				// doc.data() will be undefined in this case
				// console.log("No such document!");
				await setDoc(doc(usersRef, _user?.uid), { 
					displayName: _user?.displayName,
					email: _user?.email,
					firstName: _user?.displayName.split(' ')[0],
					lastName: _user?.displayName.split(' ')[1],
				});
			}

			// window.location.href = '/';
		}

  const onSubmit = async ({ email, password, rememberMe }) => {
		try {
			const _user = await loginUser({ email, password });
			
			if (rememberMe) {
				window.localStorage.setItem('email', email);
			}
			await updateUserDoc(_user);
			window.location.href = '/';
		} catch (err) {
			console.log(err)
		}
  };

  return (
    <div className="w-full min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 shape-bg">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-32 w-auto"
          src={ccLogo}
          alt="Conference Cloud Logo"
        ></img>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-lg rounded-xl sm:rounded-xg sm:px-10">
          <h2 className="mb-6 text-center text-3xl font-paytone-one text-gray-900">
            Sign-in to your account
          </h2>
          {error && (
            <div className="bg-red-200 border border-red-400 text-red-700 rounded px-2 m-3">
              {error}
            </div>
          )}
					<form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
						<div>
							<label
								htmlFor="email"
								className="block text-sm font-medium text-gray-700"
							>
								Email address
							</label>
							<div className="mt-1">
								<input
									{...register('email', { required: true })}
									id="email"
									name="email"
									type="email"
									autoComplete="email"
									required
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm"
								/>
							</div>
						</div>

						<div>
							<label
								htmlFor="password"
								className="block text-sm font-medium text-gray-700"
							>
								Password
							</label>
							<div className="mt-1">
								<input
									{...register('password', { required: true })}
									id="password"
									name="password"
									type="password"
									autoComplete="current-password"
									required
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm"
								/>
							</div>
						</div>

						<div className="flex items-center justify-between">
							<div className="flex items-center">
								<input
									{...register('rememberMe')}
									id="rememberMe"
									name="rememberMe"
									type="checkbox"
									className="h-4 w-4 text-yellow-500 focus:ring-yellow-400 border-gray-300 rounded"
								/>
								<label
									htmlFor="rememberMe"
									className="ml-2 block text-sm text-gray-900"
								>
									Remember me
								</label>
							</div>

							<div className="text-sm">
								<Link
									to="/forgot-password"
									className="font-medium text-gray-500 hover:text-gray-600"
								>
									Forgot your password?
								</Link>
							</div>
						</div>

						<div>
							Have no account?
							<Link
								to="/register"
								className="font-medium blue pl-2"
							>
								Create a new account
							</Link>
						</div>

						<div>
							<button
								type="submit"
								className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gellow hover:bg-gellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gellow-500"
							>
								Sign in
							</button>
						</div>
					</form>
				
          {/* {user && user?.uid && !user?.isSubscribed && user?.email &&
            <PaymentForm user={user} auth={auth}/>
          }
          {user && user?.isSubscribed && !user?.isAttached && 
            <>
              <div className={`block loader`} />
              <div className="text-center">Your payment is being in proceed</div>
            </>
          } */}
        </div>
      </div>
    </div>
  );
};

export default Login;
