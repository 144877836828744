
import Button from "./button";
import { ReactComponent as Plus } from "../icons/plus.svg";

export const AddAction = (props) => (
  <Button
    className="custom-bg-3 border-none text-gray-200"
    // className="flex gap-px font-bold text-gray-500 hover:text-gray-700 border-2 border-gray-400 hover:border-gray-700 px-4 py-px rounded-lg items-center"
    {...props}
  >
    <Plus className="w-5 h-5 cursor-pointer border border-gray-200 rounded-3xl mr-1" /> Add New
  </Button>
);