import { Button } from "@100mslive/react-ui";
import React from "react";
import { InteractionContext } from "../../helpers/contexts/interactionContext";
import { AuthContext } from "../../helpers/contexts/userSessionContext";
import { ReactComponent as DropDownIcon } from "../../icons/chevron-down.svg";
import QuestionInteraction from "./chatInteractionBody/questionInteraction";
import PollInteraction from "./chatInteractionBody/pollInteraction";
import RateInteraction from "./chatInteractionBody/rateInteraction";
import EmbedInteraction from "./chatInteractionBody/embedInteraction";
import { TrashIcon } from "@100mslive/react-icons"
import { FirebaseContext } from "../../helpers/contexts/firebaseContext";
import { getFirestore, doc, collection, deleteDoc } from "@firebase/firestore";

const ChatInteractions = () => {
  const { 
    interactionHistory,
    checkInteraction,
    interact,
    deny
  } = React.useContext(InteractionContext);
  const interactions = React.useMemo(() => (interactionHistory ?? [])?.filter(
    interaction => interaction?.type?.parentType === "chatWindow" && checkInteraction(interaction)) ?? []
  , [interactionHistory, checkInteraction]);

  const [isJustTriggered, setIsJustTriggered] = React.useState({});

  React.useEffect(() => {
    const curTime = new Date().getTime();
    let flag = {};
    for (let interaction of interactions) {
      if (curTime - interaction?.triggeredAt < 10000) {
        flag = { ...flag, [interaction?.id]: true }
        setTimeout(() => {
          setIsJustTriggered(previous => {
            return {
              ...previous,
              [interaction?.id]: false
            }
          })
        }, [interaction?.triggeredAt + 5000 - curTime])
      } else {
        flag = { ...flag, [interaction?.id]: false }
      }
    }
    setIsJustTriggered(flag);
  }, [interactions]);

  const holdRef = React.useRef();
  const { user } = React.useContext(AuthContext);

  const { firebaseApp } = React.useContext(FirebaseContext);
  const db = getFirestore(firebaseApp);

  const curInteractionCount = React.useRef(0);

  React.useEffect(() => {
    if (interactions?.length !== curInteractionCount.current) {
      curInteractionCount.current = interactions?.length;
      holdRef.current.scrollTo({
        top: holdRef.current.scrollHeight,
        behavior: "smooth"
      })
    }
  }, [interactions]);

  const isAdmin = (interaction) => {
    return interaction?.userId === user?.uid;
  }

  const isResponded = (interaction) => {
    return (interaction?.responded?.length ? interaction?.responded : []).some(_user => _user?.interactedUser?.userId === user?.uid)
  }

  const isDenied = (interaction) => {
    return (interaction?.denied?.length ? interaction?.denied : []).some(_user => _user?.deniedUser?.userId === user?.uid)
  }

  return (
    <div 
      ref={holdRef}
      className="interaction h-full overflow-y-scroll text-white"
    >
      {(interactions ?? [])?.map((interaction, idx) => (
        <div
          key={idx}
          className={`flex flex-col ${idx > 0 ? "border-t" : ""}`}
        >
          <div className="mt-2 text-lg underline flex justify-between">
            <span
              style={{ width: `calc(100% - 18px)`}}
              className={`${isJustTriggered?.[interaction?.id] && !isAdmin(interaction) ? 'animate-toggle' : ''}`}
            >
              {interaction?.content?.body}
            </span>
            {isAdmin(interaction) && <TrashIcon 
              className="w-6 h-6 text-green-500 cursor-pointer" 
              onClick={() => {
                deleteDoc(doc(collection(db, "interactionHistory"), interaction?.id ?? 'default'));
              }}
            />}
          </div>
          <QuestionInteraction
            user={user}
            interaction={interaction}
            isShow={interaction?.type?.type === 'question'}
            isAdmin={isAdmin(interaction)}
            isResponded={isResponded(interaction)}
            isDenied={isDenied(interaction)}
            interact={interact}
            deny={deny}
          />
          <PollInteraction
            user={user}
            interaction={interaction}
            isShow={interaction?.type?.type === 'poll'}
            isAdmin={isAdmin(interaction)}
            isResponded={isResponded(interaction)}
            isDenied={isDenied(interaction)}
            interact={interact}
            deny={deny}
          />
          <RateInteraction
            user={user}
            interaction={interaction}
            isShow={interaction?.type?.type === 'rating'}
            isAdmin={isAdmin(interaction)}
            isResponded={isResponded(interaction)}
            isDenied={isDenied(interaction)}
            interact={interact}
            deny={deny}
          />
          <EmbedInteraction
            user={user}
            interaction={interaction}
            isShow={interaction?.type?.type === 'embed'}
            isAdmin={isAdmin(interaction)}
            isResponded={isResponded(interaction)}
            isDenied={isDenied(interaction)}
            interact={interact}
            deny={deny}
          />
        </div>
      ))}
    </div>
  )
};

export default ChatInteractions;
