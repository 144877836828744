import React from "react";
import {
  CrossIcon,
  TrashIcon,
} from "@100mslive/react-icons";

const ImageList = ({
  medias,
  curTab,
  handleAddToScene,
  deleting,
  handleDelete,
  updateTitle
}) => {
  return (
    <>
      {medias && medias.filter(media => media.type === curTab)?.map((media, idx) => (
        <div
          key={idx}
          className={`relative w-24 rounded-lg border-2 border-purple-500 mt-1 mr-1`}
        > 
          <img
            id={'a76' + media.id}
            src={media.url}
            className="rounded-lg w-full h-32"
            alt={media.title} />
          <input
            id={'a76' + media.id}
            type='text'
            value={media.title}
            onChange={(e) => {updateTitle(e.target.id.split('a76')[1], e.target.value)}}
            className="absolute left-0 top-0 bg-black w-full h-6 text-white border-none"
          />
          <div className="absolute bottom-0 w-full h-6 rounded-b-lg bg-purple-800 pl-4 flex justify-end">
            <button
              id={'a76' + media.id}
              className="bg-none text-white"
              onClick={handleAddToScene}
            >
              <CrossIcon className="rotate-45" id={'a76' + media.id} />
            </button>
            <button
              id={'a76' + media.id}
              className="bg-none text-white"
              onClick={handleDelete}
            >
              {deleting === media.id && 
                <div className="w-5 h-5">
                  <div className="uploading-spinner"></div>
                </div>
              }
              {deleting !== media.id && <TrashIcon id={'a76' + media.id} />}
            </button>
          </div>
        </div>
      ))}
    </>
  )
};

export default ImageList;
