import React, { useState, useEffect } from 'react';
import { createContext } from "react";
import { useFirebaseInit } from "../hooks/useFirebaseInit";
import { getAuth, signInAnonymously, signInWithEmailAndPassword, sendPasswordResetEmail, 
	createUserWithEmailAndPassword, updateProfile, onAuthStateChanged } from "firebase/auth";

export const FirebaseContext = createContext({ fireInstance: undefined });
FirebaseContext.displayName = "Firebase Context";

const FirebaseProvider = ({ children }) => {
	const config = {
		apiKey: process.env.REACT_APP_FB_API_KEY,
		authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
		databaseUrl: process.env.REACT_APP_FB_DB_URL,
		projectId: process.env.REACT_APP_FB_PROJ_ID,
		storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_FB_MSG_SENDER_ID,
		appId: process.env.REACT_APP_FB_APP_ID,
		measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
	}

  const [fireInstance, loading] = useFirebaseInit(config);
  const [user, setUser] = useState();
  const [error, setError] = useState();
	const [_loading, setLoading] = useState(false);

  useEffect(() => {
    if (fireInstance && !loading) {
      const auth = getAuth();
			
      onAuthStateChanged(auth, (_user) => {
        if (_user) {
          setUser(_user);
        } else {
          setUser(null);
        }
      });
    }
  }, [fireInstance, loading]);

	const registerUser = async ({ email, password, firstName, lastName }) => {
		const auth = getAuth();
		const registerPromise = new Promise((resolve, reject) => {
			createUserWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				resolve(userCredential.user);
			})
			.catch((error) => {
				reject(error);
			});
		});

		const updatePromise = (user) => new Promise((resolve, reject) => {
			updateProfile(user, {
				displayName: `${firstName} ${lastName}`, 
				username: `${firstName.toLowerCase()}_${lastName.toLowerCase()}`,
				photoURL: ""
			}).then(() => {
				resolve(true);
			}).catch((error) => {
				reject(false);
			});
		})

		let registered;
		setLoading(true);
		await registerPromise.then(user => {
			registered = user;
		}).catch(err => {
			setError(err.message);
			registered = false;
		})

		if (registered) {
			await updatePromise(registered).then(() => {
				registered = true;
			}).catch(err => {
				setError('Profile Update Failed.');
			})
		}
		setLoading(false);
		return !!registered;
	}
	
	const loginUser = async ({ email, password }) => {
		const auth = getAuth();
		const promise = new Promise((resolve, reject) => {
			signInWithEmailAndPassword(auth, email, password)
				.then((userCredential) => {	
					resolve(userCredential.user);
				})
				.catch((error) => {
					reject(error);
					// const errorCode = error.code;
					// const errorMessage = error.message;
				});
		});
		setLoading(true);
		let _user;
		await promise.then(user => {
			setUser(user);
			_user = user;
		}).catch(err => {
			setError(err.message);
		})
		setLoading(false);
		return _user;
	}

	const forgotPassword = async ({ email }) => {
		const auth = getAuth();
		const promise = new Promise((resolve, reject) => {
			sendPasswordResetEmail(auth, email)
				.then(() => {
					resolve(true);
				})
				.catch((error) => {
					reject(error);
				});
			});
		
		let emailSent;
		setLoading(true);
		await promise.then(() => {
			emailSent = true;
		}).catch(err => {
			emailSent = false;
			setError(err.message);
		})
		setLoading(false);
		return !!emailSent;
	}

  return (
    <FirebaseContext.Provider
			value={{
				firebaseApp: fireInstance,
				user,
				loginUser,
				registerUser,
				forgotPassword,
				loading: loading || _loading,
				error 
			}}
		>
      {!loading && children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
