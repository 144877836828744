import React from "react";
import { FirebaseContext } from "./firebaseContext";
import { useConferenceCall } from '../hooks/useConferenceCall';
import { AuthContext } from './userSessionContext';
import { useParams } from "react-router-dom";
import { doc, collection, getFirestore, setDoc, onSnapshot } from "@firebase/firestore";
import { getDatabase, onValue, ref as rRef, set, get} from "@firebase/database";
import {
  HMSNotificationTypes,
  useHMSNotifications,
} from "@100mslive/react-sdk";
import { Transcriber } from "../../common/transcriber";
import {
  useRecordingStreaming,
} from "@100mslive/react-sdk";

const TranscriptionContext = React.createContext({});

const TranscriptionProvider = ({
  children,
  roomId,
}) => {
  const { firebaseApp } = React.useContext(FirebaseContext);
  const { user } = React.useContext(AuthContext);
  const db = React.useMemo(() => getFirestore(firebaseApp), [firebaseApp]);
  const rdb = React.useMemo(() => getDatabase(firebaseApp), [firebaseApp]);
  const transcriptsRef = React.useMemo(() => doc(collection(db, 'transcripts'), `${roomId ?? 'default'}`), [roomId, db]);
  
  const [isTranscriptionStarted, setIsTranscriptionStarted] = React.useState(false);
  const [isShowTranscript, setIsShowTranscript] = React.useState(false);
  const [isISO, setIsISO] = React.useState();
  const [startedTime, setStartedTime] = React.useState();
  const [isLocalTranscriptEnabled, setIsLocalTranscriptFlag] = React.useState(false);
  const [isConnecting, setIsConnecting] = React.useState(false);
  
  const {
    isBrowserRecordingOn,
    isStreamingOn,
    isHLSRunning
  } = useRecordingStreaming();

  const { role } = useParams();

  const curTranscript = React.useRef([]);

  const [roomTranscriptionEnabled, setRoomTranscriptionEnabled] = React.useState(false);

  const [transcript, _setTranscript] = React.useState([]);

  const transcriber = React.useRef();

  const { localParticipant, participants, isJoined } = useConferenceCall();

  const notification = useHMSNotifications(HMSNotificationTypes.PEER_LEFT);

  const hearingStartFlag = (snapshot) => {
    const flag = snapshot.val();
    setRoomTranscriptionEnabled(!!flag?.enabled);
  }

  const showTranscriptRef = React.useRef();

  React.useEffect(() => {
    showTranscriptRef.current = isShowTranscript;
  }, [isShowTranscript]);

  const clearTranscripts = React.useCallback(async () => {
    let isAnyoneTranscripts = false;
    for (let peer of [...(participants ?? [])]) {
      try {
        const snapshot = await get(rRef(rdb, `/transcripts_peer/${roomId}/${peer?.identity}/`));
        if (!!snapshot.val()?.enabled) {
          isAnyoneTranscripts = true;
        }
      } catch (err) {
        // console.log(err)
      }
    }
    set(rRef(rdb, `/transcripts_peer/${roomId}/${user?.uid}/`), {
      enabled: showTranscriptRef.current
    })
    set(rRef(rdb, `/transcripts_room/${roomId}/`), {
      enabled: isAnyoneTranscripts || showTranscriptRef.current
    })
    setDoc(transcriptsRef, {
      transcripts: []
    })
  }, [transcriptsRef, rdb, roomId, user, participants]);

  const roomTranscriptStatus = React.useRef();

  React.useEffect(() => {
    roomTranscriptStatus.current = roomTranscriptionEnabled;
  }, [roomTranscriptionEnabled]);

  const setIsLocalTranscriptEnabled = React.useCallback(async (flag) => {
    setIsLocalTranscriptFlag(flag);
    await set(rRef(rdb, `/transcripts_peer/${roomId}/${user?.uid}/`), {
      enabled: flag || !!isISO
    })
    if (!roomTranscriptStatus.current && flag) {
      await set(rRef(rdb, `/transcripts_room/${roomId}/`), {
        enabled: flag
      })
    } else if (roomTranscriptStatus.current && !flag) {
      if (!isISO && !isBrowserRecordingOn && !isStreamingOn && !isHLSRunning) {
        setTimeout(() => clearTranscripts());
      }
    }
  }, [roomId, user, isISO, rdb, isBrowserRecordingOn, isStreamingOn, isHLSRunning, clearTranscripts]);

  const setTranscript = async (data = []) => {
    await setDoc(transcriptsRef, {
      transcripts: data
    })
  };

  // when a peer left room
  React.useEffect(() => {
    if (notification && roomId) {
      const leftPeerId = notification?.data?.name?.split('*_*')?.[0];
      set(rRef(rdb, `/transcripts_peer/${roomId}/${leftPeerId}`), {
        enabled: false
      })
    }
  }, [notification, rdb, roomId]);

  React.useEffect(() => {
    return onValue(rRef(rdb, `/transcripts_room/${roomId}/`), hearingStartFlag);
  }, [rdb, roomId]);

  // when room transcription enabled
  React.useEffect(() => {
    transcriber.current?.enableTranscription(roomTranscriptionEnabled);
  }, [roomTranscriptionEnabled]);

  // reading transcripts
  const readTranscripts = React.useCallback((snapshot) => {
    const data = snapshot.data();
    _setTranscript(data?.transcripts);
  }, []);

  React.useEffect(() => {
    return onSnapshot(transcriptsRef, readTranscripts);
  }, [transcriptsRef, readTranscripts]);

  React.useEffect(() => {
    curTranscript.current = transcript;
  }, [transcript])

  // initialize Transcriber
  const addTranscript = React.useCallback(({ speaker, message, audio_start, created }) => {
    if (!curTranscript.current) {
      curTranscript.current = [];
    }
    let temp = [...curTranscript.current.map(transcription => {
      if (transcription.speaker === speaker && transcription.audio_start === audio_start)
        return { speaker, message, audio_start, created: transcription.created }
      else return transcription
    })];
    if (!temp.some(t => t.speaker === speaker && t.audio_start === audio_start)) {
      temp.push({ speaker, message, audio_start, created });
    }
    setDoc(transcriptsRef, {
      transcripts: temp
    });
  }, [transcriptsRef]);

  React.useEffect(() => {
    if (!transcriber.current && localParticipant?.id && isJoined && roomTranscriptionEnabled) {
      transcriber.current = new Transcriber(addTranscript);
      transcriber.current.enabled = false;
      transcriber.current.username = localParticipant?.displayName;
      transcriber.current.setIsTranscriptionStarted = setIsTranscriptionStarted;
      transcriber.current.setIsInitializing = setIsConnecting;
      const localPeer = window.__hms.sdk.getLocalPeer();
      transcriber.current.sttTuningConfig.desiredSampRate
        = localPeer?.audioTrack?.nativeTrack.getSettings().sampleRate ?? 48000;   
      
      transcriber.current.addTranscript = addTranscript;
      transcriber.current.enableTranscription(roomTranscriptionEnabled);
    }
  }, [roomId, localParticipant, isJoined, roomTranscriptionEnabled, addTranscript]);

  // React.useEffect(() => {
  //   if (transcriber.current) {
  //     transcriber.current.addTranscript = addTranscript
  //   }
  // }, [addTranscript]);

  return (
    <TranscriptionContext.Provider
      value={{
        transcript,
        setTranscript,
        isShowTranscript,
        setIsShowTranscript,
        isLocalTranscriptEnabled,
        setIsLocalTranscriptEnabled,
        isISO,
        startedTime,
        setStartedTime,
        isTranscriptionStarted,
        setIsISO,
        isConnecting,
        clearTranscripts,
      }}
    >
      {children}
    </TranscriptionContext.Provider>
  )
};

export {
  TranscriptionContext,
  TranscriptionProvider,
};
