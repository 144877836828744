import React, { useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import { getFirestore, collection, query, where, updateDoc,
  doc, getDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { ReactComponent as CheckCircleIcon } from "../icons/check-circle.svg";
import { ReactComponent as XCircleIcon } from "../icons/x-circle.svg";
import { FirebaseContext } from "../helpers/contexts/firebaseContext";
import { getDatabase, update, ref as rRef } from "firebase/database";

const EntryQueue = ({ roomType, className, children }) => {
  const { conferenceId } = useParams();
  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);
  const rdb = useMemo(() => getDatabase(firebaseApp), [firebaseApp]);

  const queueRef = useMemo(() => 
    query(collection(doc(collection(db, "conferences"), conferenceId ?? "defaultConference"), "participants"), where("canJoin", "==", false), where("deny", "==", false))
  , [db, conferenceId]);

  const [_data, loading] = useCollectionQuery(queueRef, { idField: "id" });
  const data = _data?.filter(_ => _.actionTime > 0);

  const handleApprove = (id) => {
    const offset = new Date().getTimezoneOffset() * 60 * 1000;
    updateDoc(doc(collection(doc(collection(db, "conferences"), conferenceId), "participants"), id), {
      canJoin: true,
      actionTime: new Date().getTime() + offset,
    });
    if (roomType === 'discussion') {
      update(rRef(rdb, `${conferenceId}/status/${id}`), {
        live: true,
      })
    }
  };

  const handleDeny = (id) => {
    const offset = new Date().getTimezoneOffset() * 60 * 1000;
    updateDoc(doc(collection(doc(collection(db, "conferences"), conferenceId), "participants"), id), {
      canJoin: false,
      deny: true,
      actionTime: new Date().getTime() + offset,
    });
  };

  if (loading) return <div className="loader">&nbsp;</div>;

  if ((!data || data.length <= 0) && children) {
    return children;
  } else if (!data && !children) {
    return (<></>);
  }

  return data?.map((req) => (
    <div
      className={`${
        className ? className : ""
      } bg-white hover:bg-gray-50 rounded-md border border-gray-200 p-2 m-1 flex flex-col justify-center items-center`}
    >
      <div className="flex flex-col items-center text-gray-700">
        <h5 className="font-bold text-xl">{req.name},</h5>
        <p>would like to join.</p>
      </div>
      <div className="flex gap-2">
        <button
          onClick={() => handleApprove(req.id)}
          className="text-gray-700 hover:text-green-500"
        >
          <CheckCircleIcon className="h-7 w-7" />
        </button>
        <button
          onClick={() => handleDeny(req.id)}
          className="text-gray-700 hover:text-red-500"
        >
          <XCircleIcon className="h-7 w-7" />
        </button>
      </div>
    </div>
  ));
};

export default EntryQueue;
