import React from 'react';
import { createContext, useState, useEffect, useRef, useCallback, useContext } from "react";
import { FirebaseContext } from './firebaseContext';
import { firebaseUpload } from "../firebaseUpload";
import { audioFormats, videoFormats } from "../../utils/utils";
import { usePlaylist } from "../../components/hooks/usePlaylist";
import { HMSPlaylistType } from "@100mslive/react-sdk";
import { compareObject } from "../../utils/compareObject";
import { useParams } from "react-router-dom";
import { collection, doc, getFirestore, updateDoc } from '@firebase/firestore';
import { useDocumentQuery } from '../hooks/useFirebaseDocument';
import {
  useHMSStore,
  HMSNotificationTypes,
  useHMSNotifications,
  selectVideoPlaylist,
  selectAudioPlaylist
} from "@100mslive/react-sdk";
import { getDoc } from 'firebase/firestore';

export const PlaylistContext = createContext({});

export const PlaylistProvider = ({ children, users = [] }) => {
  const freeze = m => new Promise(resolve => setTimeout(resolve, m * 1000));
  const { active: activeVideo, list: videoPlaylist, actions: videoActions } = usePlaylist(HMSPlaylistType.video);
  const { active: activeAudio, list: audioPlaylist, actions: audioActions } = usePlaylist(HMSPlaylistType.audio);

  const audioProgress = useHMSStore(selectAudioPlaylist.progress);
  const videoProgress = useHMSStore(selectVideoPlaylist.progress);
  const audioProgressRef = useRef();
  const videoProgressRef = useRef();

  const [isAutoplayOn, setIsAutoplayOn] = useState(false);
  const [activeMedia, setActiveMedia] = useState();
  const [autoLoadingId, setAutoLoadingId] = useState(false);

  const endedNotification = useHMSNotifications(HMSNotificationTypes.PLAYLIST_TRACK_ENDED);

  const [loadedFromDB, setLoadedFromDB] = useState(false);

  const [playlist, setPlaylist] = useState([]);
  const playlistRef = useRef([]);
  const [uploadingList, setUploadingList] = useState([]);
  const [activeType, setActiveType] = useState('video');

  const { firebaseApp } = useContext(FirebaseContext);
  const { conferenceId } = useParams();
  const db = getFirestore(firebaseApp);
  const confRef = doc(collection(db, 'conferences'), conferenceId ?? 'defaultConference');
  const [conference] = useDocumentQuery(confRef);

  useEffect(() => {
    audioProgressRef.current = audioProgress;
  }, [audioProgress])

  useEffect(() => {
    videoProgressRef.current = videoProgress;
  }, [videoProgress])

  /* E1, Update playlist ref */
  useEffect(() => {
    playlistRef.current = playlist;
  }, [playlist]);

  const stopMedia = useCallback(() => {
    try {
      videoActions?.stop();
    } catch (err) {
      // console.log(err);
    }
    try {
      audioActions?.stop();
    } catch (err) {
      // console.log(err);
    }
  }, [videoActions, audioActions]);

  /* E2, When playlist stopped */
  const handleAutoPlay = useCallback(async () => {
    if (endedNotification) {
      if (
        (audioProgressRef.current > 10 && audioProgressRef.current < 90) 
        || (videoProgressRef.current > 10 && videoProgressRef.current < 90)
      ) {
        return;
      }

      const curId = endedNotification?.data?.id;

      if (!playlistRef.current?.find(media => media.id === curId)) {
        return;
      }
      if (isAutoplayOn) {
        let nextId;
        for (let i = 0; i < playlistRef.current?.length - 1; i++) {
          if (playlistRef.current?.[i].id === curId) {
            nextId = i + 1;
            break;
          }
        }
        while (nextId && nextId < playlistRef.current?.length && playlistRef.current?.[nextId]?.url?.slice(0, 4) !== 'http') {
          nextId++;
        }
        if (nextId && nextId < playlistRef.current?.length) {
          if (playlistRef.current?.[nextId]?.type === HMSPlaylistType.video) {
            try {
              audioActions.stop();
              await freeze(1);
              videoActions?.play(playlistRef.current?.[nextId]?.id);
            } catch (err) {
              // console.log('playError', err)
            }
          } else {
            try {
              videoActions.stop();
              await freeze(1);
              audioActions?.play(playlistRef.current?.[nextId]?.id);
            } catch (err) {
              // console.log('playError', err)
            }
          }
          setActiveMedia(playlistRef.current?.[nextId])
          setActiveType(playlistRef.current?.[nextId]?.type)
          setAutoLoadingId(playlistRef.current?.[nextId]?.id);
        } else {
          setActiveMedia();
        }
      } else {
        setActiveMedia();
      }
    }
  }, [endedNotification, videoActions, audioActions, isAutoplayOn]);

  useEffect(() => {
    handleAutoPlay();
  }, [handleAutoPlay])

  /* E3, Read playlist only once when first load */
  useEffect(() => {
    if (videoActions?.playlistManager?.state?.video?.isAutoplayOn) {
      videoActions?.setIsAutoplayOn(false);
      audioActions?.setIsAutoplayOn(false);
    }
    if (!loadedFromDB && conference && videoActions && audioActions) {
      setPlaylist(conference?.playlist);
      videoActions?.setList(conference?.playlist?.filter(media => media.type === HMSPlaylistType.video));
      audioActions?.setList(conference?.playlist?.filter(media => media.type === HMSPlaylistType.audio));
      setLoadedFromDB(true);
    }
  }, [conference, loadedFromDB, videoActions, audioActions]);

  /* Refresh Playlist */
  const refresh = () => {
    setLoadedFromDB(false);
    videoActions?.stop();
    audioActions?.stop();
    videoActions?.clearList();
    audioActions?.clearList();
    setActiveType('video');
    setAutoLoadingId(false);
  }

  /* E4, Update playlist based on the information of files uploaded from file browser */
  const applyToPlaylist = useCallback(async (_uploadingList=[]) => {
    if (_uploadingList?.length === 0) return;
    let copiedPlaylist = JSON.parse(JSON.stringify(playlistRef.current ?? []));
    let updatingList = _uploadingList?.filter(media => copiedPlaylist?.some(_media => _media.id === media.id));
    let addingList = _uploadingList?.filter(media => !copiedPlaylist?.some(_media => _media.id === media.id));
    let applyingList = [...updatingList, ...addingList];
    for (let media of updatingList) {
      if (media?.type === 'video') {
        await videoActions?.removeItem(media.id).then(data => console.log('removed item', data));
      } else {
        await audioActions?.removeItem(media.id).then(data => console.log('removed item', data));
      }
    }
    videoActions.setList(applyingList.filter(media => media.type === HMSPlaylistType.video));
    audioActions.setList(applyingList.filter(media => media.type === HMSPlaylistType.audio));

    if (_uploadingList?.every(media => media.url?.slice(0, 4) === 'http')) {
      setUploadingList([]);
    }
  }, [videoActions, audioActions]);
  
  useEffect(() => {
    applyToPlaylist(uploadingList);
  }, [uploadingList, applyToPlaylist])

  /* E5, Update Playlist based on 100ms playlist */
  useEffect(() => {
    if (audioPlaylist && videoPlaylist) {
      let updatingList = [...audioPlaylist, ...videoPlaylist].sort((a, b) => {
        if (a.created > b.created) return 1;
        if (a.created < b.created) return -1;
        return 0;
      });
      let filteredList = playlistRef.current?.filter(media => updatingList?.some(_media => _media.id === media.id)) ?? [];
      filteredList = filteredList.map(media => updatingList?.find(_media => _media.id === media.id));
      let addingList = updatingList.filter(media => !filteredList.some(_media => _media.id === media.id)) ?? [];
      setPlaylist([...filteredList, ...addingList]);
    }
  }, [videoPlaylist, audioPlaylist]);
  
  /* E6, Update Database based on playlist */
  useEffect(() => {
    const checkIfExist = async (data) => {
      const snapshot = await getDoc(confRef);
      
      if (JSON.stringify(snapshot?.playlist ?? []) !== JSON.stringify(data ?? [])) {
        updateDoc(confRef, {
          playlist
        })
      }
      // return compareObject(snapshot?.playlist, data);
    } 
    if (loadedFromDB && playlist) {
      checkIfExist(playlist);
    }
  }, [playlist, confRef, loadedFromDB]);

  const handleUploadNewFiles = async (files) => {
    let audioList = [];
    let videoList = [];
    for (let i = 0; i < files?.length; i++) {
      let extension = files[i].name?.split('.');
      extension = extension[extension?.length - 1];
      if (videoFormats.find(ext => ext === extension || ext.toLowerCase() === extension)) {
        videoList.push({
          type: HMSPlaylistType.video,
          id: files[i].lastModified,
          name: files[i].name,
          url: URL.createObjectURL(files[i]),
          playing: false,
          selected: false,
          created: new Date().getTime(),
          fileId: i,
        })
      } else if (audioFormats.find(ext => ext === extension || ext.toLowerCase() === extension)) {
        audioList.push({
          type: HMSPlaylistType.audio,
          id: files[i].lastModified,
          name: files[i].name,
          url: URL.createObjectURL(files[i]),
          playing: false,
          selected: false,
          created: new Date().getTime(),
          fileId: i,
        })
      }
    }
    const filteredVideoList = videoList.filter(video => !playlist?.some(_list => _list.id === video.id));
    const filteredAudioList = audioList.filter(audio => !playlist?.some(_list => _list.id === audio.id));
    const newList = [...filteredAudioList, ...filteredVideoList];

    newList.forEach(media => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        setUploadingList(prevValue => {
          const newUploadingList = prevValue.map(media => {
            if (media.url === video.src) {
              return {
                ...media,
                duration
              }
            } else {
              return media
            }
          })
          return newUploadingList;
        })
      }

      video.src = media?.url;
    })
    setUploadingList(newList);

    for (let i = 0; i < newList?.length; i++) {
      const file = files?.[newList[i].fileId];
      const url = await firebaseUpload(firebaseApp)({
        name: file.name,
        file,
      });
      setUploadingList(prevValue => {
        const newUploadingList = prevValue?.map(media => {
          if (media.id === newList[i].id) {
            return {
              ...media,
              url
            }
          } else {
            return media
          }
        })
        return newUploadingList;
      })
    }
  };

  return (
    <PlaylistContext.Provider
      value={{
        playlist,
        setPlaylist,
        handleUploadNewFiles,
        activeType,
        setActiveType,
        isAutoplayOn,
        setIsAutoplayOn,
        activeMedia,
        setActiveMedia,
        autoLoadingId,
        setAutoLoadingId,
        refresh,
      }}
    >
      {children}
    </PlaylistContext.Provider>
  );
};
