import React, { useContext, useMemo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../helpers/contexts/userSessionContext";
import { FirebaseContext } from "../helpers/contexts/firebaseContext";
import { getFirestore, collection, query, where, updateDoc,
  doc, getDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore";
import { getDatabase, ref as rRef, update } from "@firebase/database";
import EntryQueue from "./entryQueue";
import LocalVideoFeed from "./localVideoFeed";
import ParticipantVideoFeed from "./participantVideoFeed";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
// import { ReactComponent as Microphone } from "../icons/microphone2.svg";
import { ReactComponent as Microphone } from "../icons/microphone.svg";
import { ReactComponent as ScreenShare } from "../icons/screen-share.svg";
import { ReactComponent as CogIcon } from "../icons/cog.svg";
import ParticipantControl from "./participantControl";
import { LocalTracksContext } from "../helpers/contexts/localTracksContext";
import { ReactComponent as DeviceRotate } from "../icons/device_rotate.svg";
import { 
	useHMSActions,
  useHMSStore,
  selectIsLocalScreenShared,
} from "@100mslive/react-sdk";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";

const AttendeesRoom = ({
  roomType,
  sessions,
  localParticipant,
  participants,
  streamParticipants,
  height,
  userIsLive = false,
  producer = false,
  isBeamUser = false,
  joining = false,
  ...props
}) => {

  const hmsActions = useHMSActions();
  const amIScreenSharing = useHMSStore(selectIsLocalScreenShared);
  const [loading, setLoading] = useState(false);
  const { user, hostStatus, toggleHost } = useContext(AuthContext);
  const { conferenceId: confId } = useParams();
  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);
  const rdb = useMemo(() => getDatabase(firebaseApp), [firebaseApp]);
  const [editName, setEditName] = useState(-1);
  const [currentName, setCurrentName] = useState('');
  const [userLavel, setUserLavel] = useState({});

  const mySession = sessions?.[user?.uid];
  const { currentState, setEdited } = useLayerEditorContext();

  const layersRef = collection(doc(collection(doc(collection(db, 'conferences'), props?.room?.id ?? 'defaultConference'), 'scenes'), props?.activeScene?.id ?? 'defaultScene'), 'layers');
  const [layers, layersLoading] = useCollectionQuery(layersRef, {
    idField: "id"
  });

  const editable = useMemo(() => {
    if (hostStatus) {
      if (user)
        return hostStatus[user?.uid];
      else return false;
    } return false;
  }, [hostStatus, user]);

  useEffect(() => {
    if (editName !== -1) {
      const inputs = document.querySelectorAll(".customer-name");
      
      for (let input of inputs) {
        input.focus();
      }
    }
  }, [editName]);

  const toggleLive = (uid) => {

    const ref = rRef(rdb, `${confId}/status/${uid}`);
    if (currentState) {
      update(ref, {
        editingLive: true,
      });
      setEdited(true);
    } else {
      update(ref, {
        live: true,
      });
    }

  }

  const goLive = (uid) => {
    toggleLive(uid);
  };

  const offLive = (uid) => {
    const ref = rRef(rdb, `${confId}/status/${uid}`);
    if (currentState) {
      update(ref, {
        editingLive: false,
      });
      setEdited(true);
    } else {
      update(ref, {
        live: false,
      });
    }
  };

  const kick = (participant) => {
    if (!producer) return;

    fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/removeParticipant`, {
      method: "POST",
      body: JSON.stringify({
        participant: participant.sid,
        room: confId,
        identity: participant.identity,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const mute = (participant, currentMute) => {
    if (!producer && !editable && participant.identity !== localParticipant.identity) return;
    // if (!editable) return;
    // const isYouInPrivate = currentAudioChannel.members ? (currentAudioChannel.members.some(m => m.id == user?.uid)) : false;
    // const isPInPrivate = currentAudioChannel.members ? (currentAudioChannel.members.some(m => m.id == participant.identity)) : false;

    // if (isPInPrivate && !isYouInPrivate) return;
    update(rRef(rdb, `${confId}/status/${participant.identity}`), {
      muted: !currentMute,
    });
  };

  const customForamt = (str) => {
    if (str?.length > 15) {
      return str.substring(0, 15) + '...';
    }
    return str;
  };

  const changeRatio = (id, ratio) => {
		const targetLayer = layers?.find(layer => layer.id === id);
    
		if (props?.currentViewType !== 'mobile') {
			updateDoc(doc(layersRef, id), {
				ratio,
				width: targetLayer?.height,
				height: targetLayer?.width
			});
		} else {
			updateDoc(doc(layersRef, id), { 
				mobile_ratio: ratio,
				mobile_width: targetLayer?.mobile_height,
				mobile_height: targetLayer?.mobile_width
			})
		}
	}

  const handleNameChange = (id, name) => {
    update(rRef(rdb, `${confId}/status/${id}`), {
      name
    });
  };

  const { openDeviceSelection } = useContext(LocalTracksContext);

  useEffect(() => {
    hmsActions.setLocalAudioEnabled(!mySession?.muted);
  }, [mySession?.muted, hmsActions]);

  return (
    joining ? <div className={`${loading ? "block" : "hidden"} loader`} /> :
    <div className="flex w-auto h-full">
      {(!userIsLive || props?.activeScene?.isSingleView || mySession?.isOverloaded) && (
        <div style={{width: '180px', height: '105.25px'}}>
          <LocalVideoFeed
            localParticipant={localParticipant}
            className="w-full flex flex-wrap"
            onActionText={mySession?.isOverloaded ? 'Send To Waiting' : 'Go Live'}
            onAction={() => {
              if (mySession?.isOverloaded) {
                offLive(user?.uid)
              } else {
                goLive(user?.uid)
              }
            }}
            session={mySession}
            // onAction={() => goLive(user?.uid)}
            showAction={producer || editable}
            width={180}
            height={180 / 1920 * 1080}
            muted={mySession?.muted}
            onMute={() => {
              mute(localParticipant, mySession?.muted)
            }}
            isLive={false}
            setRatio={(ratio) => changeRatio(localParticipant?.identity, ratio)}
            ratio={layers?.find(layer => layer.id === localParticipant?.identity)?.ratio}
          />
        </div>
      )}
      {(userIsLive && !props?.activeScene?.isSingleView && !mySession?.isOverloaded) && (
        <div
          onClick={() => {
            if (editable || producer) offLive(user?.uid);
          }}
          className="p-1 cursor-pointer relative" style={{width: '180px', height: '105.25px'}}
        >
          <button
            className="absolute top-1 right-1 text-yellow-400 hover:text-yellow-200 cursor-pointer z-30"
            onClick={(e) => {
              e.stopPropagation();
              changeRatio(localParticipant?.identity, layers?.find(layer => layer.id === localParticipant?.identity)?.ratio < 1 ? 1920 / 1080 : 1080 / 1920)
            }}
          >
            <DeviceRotate className={`${layers?.find(layer => layer.id === localParticipant?.identity)?.ratio < 1 ? "rotate-90 -scale-y-100" : ""} w-8 h-8`} />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              mute(localParticipant, sessions[localParticipant.identity]?.muted);
            }}
            className={`absolute bottom-0 right-0 ${
              sessions[localParticipant.identity]?.muted
                ? "text-red-600 hover:text-red-400"
                : "text-gray-300 hover:text-gray-400"
            } cursor-pointer z-30`}
          >
            <Microphone className="w-6 h-6 mr-2 mb-2" />
          </button>
        
          <div className="absolute left-0 top-0 z-30">
            <button
              onClick={(e) => {
                e.stopPropagation();
                openDeviceSelection();
              }}
              className="text-gray-300 hover:text-gray-400"
            >
              <CogIcon className="w-6 h-6 ml-1 mt-1 text-gray-400" />
            </button>
          </div>

          <button
            onClick={async (e) => {
              e.stopPropagation();
              setLoading(true);
              await hmsActions.setScreenShareEnabled(!amIScreenSharing);
              setLoading(false);
            }}
            className={`absolute bottom-0 left-0 ${
              amIScreenSharing
                ? "text-red-600 hover:text-red-400"
                : "text-gray-300 hover:text-gray-400"
            } cursor-pointer z-30`}
          >
            <ScreenShare className="w-6 h-6 ml-2 mb-2"/>
          </button>
          <div className="h-full border-solid border-2 border-black rounded flex justify-center items-center bg-gray white">
            {editName !== localParticipant.identity && <>
              <button
                className="rounded-md pl-2 pr-2 bg-gray-500 text-gray-100 hover:bg-blue-200"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditName(localParticipant.identity);
                  setCurrentName(customForamt(sessions[localParticipant.identity]?.name));
                }}
              >
                {customForamt(sessions[localParticipant.identity]?.name)}
              </button>
            </>}
            {editName === localParticipant.identity && <>
              <input
                className="w-32 customer-name ml-2 mr-2"
                value={currentName}
                onChange={(e) => setCurrentName(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    if (e.target.value === '') return;
                    handleNameChange(localParticipant.identity, e.target.value);
                    setEditName(-1);
                  }
                }}
                onBlur={(e) => {
                  setEditName(-1);
                  setCurrentName(customForamt(sessions[localParticipant.identity]?.name));
                }}
              />
            </>}
          </div>
        </div>
      )}
      {streamParticipants?.map((participant, idx) => (
        <>
        {!props?.activeScene?.isSingleView && !sessions[participant?.identity]?.isOverloaded &&
          <ParticipantControl
            idx={idx}
            editable={editable}
            producer={producer}
            offLive={offLive}
            participant={participant}
            kick={kick}
            mute={mute}
            sessions={sessions}
            hostStatus={hostStatus}
            userLavel={userLavel}
            toggleHost={toggleHost} 
            confId={confId}
            editName={editName}
            setEditName={setEditName}
            setCurrentName={setCurrentName}
            customForamt={customForamt} 
            currentName={currentName} 
            handleNameChange={handleNameChange}
            setRatio={(ratio) => changeRatio(participant?.identity, ratio)}
            ratio={layers?.find(layer => layer.id === participant?.identity)?.ratio}
            peerId={participant?.peerId}
          />
        }
        </>
      ))}
      {streamParticipants?.map((participant, idx) => (
        <>
        {(props?.activeScene?.isSingleView || sessions[participant?.identity]?.isOverloaded) &&  
          <div key={idx} style={{width: '180px'}}>
            <ParticipantVideoFeed
              className="w-full flex flex-wrap"
              width={180}
              height={180 / 1920 * 1080}
              keyIdx={`key-${idx}`}
              participant={participant}
              onActionText={sessions[participant?.identity]?.isOverloaded ? 'Send To Waiting' : 'Go Live'}
              onAction={() => {
                if (sessions[participant?.identity]?.isOverloaded) {
                  offLive(participant.identity)
                } else {
                  goLive(participant.identity)
                }
              }}
              disableAudio={sessions[participant.identity]?.live}
              session={sessions[participant.identity]}
              showAction={producer || editable}
              onClose={(e) => {
                e.stopPropagation();
                kick(participant);
              }}
              onMute={(e) => {
                e.stopPropagation();
                mute(participant, sessions[participant.identity]?.muted)
              }}
              isLive={false}
            />
          </div>
        }
        </>
      ))}
      {participants?.map((participant, idx) => (
        <div key={idx} style={{width: '180px'}}>
          <ParticipantVideoFeed
            className="w-full flex flex-wrap"
            width={180}
            height={180 / 1920 * 1080}
            // key={participant.sid}
            keyIdx={`key-${idx}`}
            participant={participant}
            onAction={() => goLive(participant.identity)}
            // disableAudio={userIsLive}
            disableAudio={sessions[participant.identity]?.live}
            session={sessions[participant.identity]}
            showAction={producer || editable}
            onClose={(e) => {
              e.stopPropagation();
              kick(participant);
            }}
            onMute={(e) => {
              e.stopPropagation();
              mute(participant, sessions[participant.identity]?.muted)
            }}
            isLive={false}
            setRatio={(ratio) => changeRatio(participant?.identity, ratio)}
            ratio={layers?.find(layer => layer.id === participant?.identity)?.ratio}
          />
        </div>
      ))}
      {producer && <EntryQueue className="w-2/12" roomType={roomType} />}
    </div>
  );
};

export default AttendeesRoom;
