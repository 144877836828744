export const keyToNum = (keyCode) => {
  let result = -1;
  switch (keyCode) {
    case 'Digit0':
      result = 10;
      break;
    case 'Digit1':
      result = 1;
      break;
    case 'Digit2':
      result = 2;
      break;
    case 'Digit3':
      result = 3;
      break;
    case 'Digit4':
      result = 4;
      break;
    case 'Digit5':
      result = 5;
      break;
    case 'Digit6':
      result = 6;
      break;
    case 'Digit7':
      result = 7;
      break;
    case 'Digit8':
      result = 8;
      break;
    case 'Digit9':
      result = 9;
      break;
    case 'KeyQ':
      result = 11;
      break;
    case 'KeyW':
      result = 12;
      break;
    default:
      result = -1;
  }
  return result;
};
