import React from "react";
import "../styles/spinner.css";

export default function LoadingSpinner({ className="w-10 h-10" }) {
  return (
    <div className={`${className} spinner-container`}>
      <div className="loading-spinner">
      </div>
    </div>
  );
}