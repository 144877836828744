import React from "react";
import { install } from "resize-observer";
import { default as LocalDraggable } from "../components/draggable";
// TODO
// - Ratio resize?
install();

const Draggable = ({
  id,
  children,
  onDrag,
  onStop,
  x: givenX = 0,
  y: givenY = 0,
  isText = false,
  disabled,
  viewHeight,
  viewWidth,
  isTimer,
  setIsBeingReposition,
  isVisible,
  parentRef
}) => {
  const handleDrag = (...vals) => {
    onDrag(...vals);
  };

  const handleStart = () => {
    
  };

  return (
    <LocalDraggable
      id={id}
      disabled={disabled}
      onStop={() => {
        handleDrag();
        onStop();
      }}
      onDrag={onDrag}
      onStart={handleStart}
      isText={isText}
      bounds="parent"
      position={{ x: givenX ? givenX : 0, y: givenY ? givenY : 0 }}
      viewHeight={viewHeight}
      viewWidth={viewWidth}
      isTimer={isTimer}
      setIsBeingReposition={setIsBeingReposition}
      parentRef={parentRef}
      isVisible={isVisible}
    >
      {children}
    </LocalDraggable>
  );
};

export default Draggable;