import React from "react";
import Modal from "../../components/modal";
import { Button } from "@100mslive/react-ui";
import { FirebaseContext } from "../../helpers/contexts/firebaseContext";
import RatingEle from "./ratingEle";

const BroadcastInteractionBody = ({
  isOpen,
  onClose,
  interaction,
  interact,
  deny,
  rate,
  setRate,
  user,
  className=''
}) => {
  return (
    <div className={`rounded-t-lg w-400 ${className}`}>
      <div className="custom-bg-1 w-full p-2 rounded-t-md flex items-center">
        <h2 className="font-medium text-xl text-gray-100">
          Admin User Requests An Interaction.</h2>
      </div>
      <div 
        className="p-2 flex flex-col justify-center items-center w-full"
      >
        <p className="break-all">{interaction?.content?.body}</p>
        <RatingEle
          isOpen={isOpen}
          value={rate}
          setValue={setRate}
        />
      </div>
      <div className="flex justify-around mb-3">
        <Button
          css={{ h: "$11" }}
          onClick={async () => {
            await interact({
              ...interaction,
              interactedUser: { displayName: user?.displayName, email: user?.email },
              rate
            });
            setRate(0);
            onClose();
          }}
        >Interact</Button>
        <Button
          variant="standard"
          css={{ h: "$11" }}
          onClick={async () => {
            await deny({
              ...interaction,
              denied: true,
              deniedUser: { displayName: user?.displayName, email: user?.email },
            })
            setRate(0);
            onClose();
          }}
        >Deny</Button>
      </div>
    </div>
  )
}

const InteractionRequest = ({
  isOpen,
  onClose,
  interaction,
  interact=()=>{},
  deny=()=>{},
  parentWidth=0,
  parentHeight=0
}) => {
  const [rate, setRate] = React.useState(0);
  
  React.useEffect(() => {
    setRate(0);
  }, [isOpen])
  const { user, firebaseApp } = React.useContext(FirebaseContext);
  const [additionalStyle, setAdditionalStyle] = React.useState({});

  React.useEffect(() => {
    if (interaction?.type?.type === 'emoji') {
      setTimeout(() => setAdditionalStyle({
        transform: 'scale(1, 1)',
        transition: 'transform 0.5s',
      }))
    } else {
      setAdditionalStyle({})
    }
  }, [interaction?.type?.type])

  const interactionStyle = (isOpen, type) => {
    if (type === 'bottom_to_top') {
      return !isOpen ? {
          transition: 'all 0.5s',
          left: `calc(50% - 200px)`,
          top: `${parentHeight}px`
        } : {
          left: `calc(50% - 200px)`,
          top: `${parentHeight}px`,
          zIndex: 100000,
          transform: `translate(0, -${parentHeight / 2 + 100}px)`,
          transition: 'transform 0.5s',
        }
    } else if (type === 'top_to_bottom') {
      return !isOpen ? {
          transition: 'all 0.5s',
          left: `calc(50% - 200px)`,
          top: '-100px',
        } : {
          left: `calc(50% - 200px)`,
          top: '-100px',
          zIndex: 100000,
          transform: `translate(0, ${parentHeight / 2}px)`,
          transition: 'transform 0.5s',
        }
    } else if (type === 'left_to_right') {
      return !isOpen ? {
          transition: 'all 0.5s',
          top: `calc(50% - 100px)`,
          left: '-200px',
        } : {
          top: `calc(50% - 100px)`,
          left: '-200px',
          zIndex: 100000,
          transform: `translate(${parentWidth / 2}px, 0)`,
          transition: 'transform 0.5s',
        }
    } else if (type === 'right_to_left') {
      return !isOpen ? {
          transition: 'all 0.5s',
          top: `calc(50% - 100px)`,
          right: '-200px',
        } : {
          top: `calc(50% - 100px)`,
          right: '-200px',
          zIndex: 100000,
          transform: `translate(-${parentWidth / 2}px, 0)`,
          transition: 'transform 0.5s',
        }
    } else if (type === 'interactive_notification') {
      return !isOpen ? {
        left: '-400px',
        top: '-400px'
      } : {
        zIndex: 100000,
        top: `calc(50% - 100px)`,
        left: `${parentWidth / 2 - 200}px`,
        transform: "rotate(360deg)",
        transition: 'transform 0.5s',
      }
    } else if (type === 'emoji') {
      return !isOpen ? {
        left: '-400px',
        top: '-400px',
      } : {
        zIndex: 100000,
        top: `calc(50% - 100px)`,
        left: `${parentWidth / 2 - 200}px`,
        width: '400px',
        height: 'auto',
        transform: 'scale(0.1, 0.1)',
      }
    } else if (type !== 'modal' && type !== 'rating' && type !== 'question' && type !== 'poll' && type !== 'embed') {
      return !isOpen ? {
        left: '-400px',
        top: '-400px'
      } : {
        zIndex: 100000,
      }
    } else if (type === 'rating' || type === 'question' || type === 'poll' || type === 'embed') {
      return {
        left: '-400px',
        top: '-400px'
      } 
    }
  }
  
  return (
    <>
      {interaction?.type?.type === 'modal' &&
        <Modal
          isOpen={isOpen}
          className="w-full left-0"
          isHeader={false}
          isFooter={false}
        >
          <BroadcastInteractionBody
            isOpen={isOpen}
            interaction={interaction}
            interact={interact}
            deny={deny}
            rate={rate}
            setRate={setRate}
            user={user}
          />
        </Modal>
      }
      {isOpen && interaction?.type?.parentType !== 'chatWindow' && 
        <div className="fixed left-0 top-0 bg-gray-500 w-full h-full z-5000 opacity-20"></div>
      }
      <div 
        id="interaction"
        className="absolute shadow bg-white rounded-lg"
        style={{...interactionStyle(isOpen, interaction?.type?.type), ...additionalStyle}}
      >
        <BroadcastInteractionBody
          interaction={interaction}
          interact={interact}
          deny={deny}
          rate={rate}
          setRate={setRate}
          user={user}
          onClose={onClose}
        />
      </div>
    </>
  )
};

export default InteractionRequest;
