import React, { useContext, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import ParticipantView from "../features/participantView";
import RequestToJoin from "../features/requestToJoin";
import { AuthContext } from "../helpers/contexts/userSessionContext";
import { useCanJoinRoom } from "../helpers/hooks/requests/useCanJoinRoom";
import { usePresence, useSelfPresence } from "../helpers/hooks/usePresence";
import { FirebaseContext } from "../helpers/contexts/firebaseContext";
import { signInAnonymously } from "@firebase/auth";
import ConferenceCallProvider from "../helpers/contexts/conferenceCallContext";
import { LocalTracksProvider } from "../helpers/contexts/localTracksContext";
import { AudioChannelProvider } from "../helpers/contexts/audioChannelContext";
import { ChatProvider } from "../helpers/contexts/chatContext";
import { LayerEditorProvider } from "../helpers/contexts/layerEditorContext";
import { TranscriptionProvider } from "../helpers/contexts/transcriptionContext";

const ConferenceView = ({ firebase, conferenceId, user, roomType }) => {
  useSelfPresence({ firebase, confId: conferenceId, user, roomType });

  // Create a presence with the real-time datastore. This is important and will be used to manage
  // join requests later on.
  const userSessions = usePresence({ firebase, user, confId: conferenceId });

  return (
    <AudioChannelProvider>
      <ConferenceCallProvider roomType={roomType}>
        <LocalTracksProvider>
          <TranscriptionProvider roomId={conferenceId}>
            <ChatProvider>
              <ParticipantView
                userSessions={userSessions}
                conferenceId={conferenceId}
                user={user}
                roomType={roomType}
              />
            </ChatProvider>
          </TranscriptionProvider>
        </LocalTracksProvider>
      </ConferenceCallProvider>
    </AudioChannelProvider>
  );
};

/**
 * Conference is the chief component responsible for rendering the Conference view. There
 * are two primary end-users for this view. Viewers, as in anonymous users, and Producers,
 * the people running the conference.
 */
const Conference = ({
  roomType
}) => {
  const { firebaseApp } = useContext(FirebaseContext);

  const { conferenceId } = useParams();
  const { 
    user, 
    auth,
    setConferenceId 
  } = useContext(AuthContext);
  // const { user } = useContext(FirebaseContext);
  const [anonymUser, setAnonymUser] = useState();

  useEffect(() => {
    setConferenceId(conferenceId);
  }, [conferenceId, setConferenceId])

  useEffect(() => {
    if (!user && auth) {
      signInAnonymously(auth).then((data) => {
        setAnonymUser(data?.user);
      })
    }
  }, [user, auth]);

  const guestUser = useMemo(() => user ? user : anonymUser, [user, anonymUser]);

  const [canJoin] = useCanJoinRoom({ conferenceId, userId: guestUser?.uid });

  if (!guestUser) {
    return <div className="loader">&nsbsp;</div>;
  }

  // If they aren't explicitly allowed to enter yet, show them the request page.
  if (guestUser?.isAnonymous && !canJoin) {
    return <RequestToJoin user={guestUser} auth={auth} />;
  }

  return (
    <LayerEditorProvider>
      {canJoin && <ConferenceView
        firebase={firebaseApp}
        user={guestUser}
        conferenceId={conferenceId}
        roomType={roomType}
      />}
    </LayerEditorProvider>
  );
};

export default Conference;