import React, { useState, useRef, useEffect } from "react";
import {
	Input,
  Button,
} from "@100mslive/react-ui";
import { timeFormat, deTimeFormat } from '../../utils/timeFormat';
import { ReactComponent as ArrowIcon } from "../../image/icon/chevron-down.svg";
import { Collapse } from "react-collapse";
import { compareObject } from "../../utils/compareObject";
import { info } from "autoprefixer";
import { PencilIcon, ArrowRightIcon } from "@100mslive/react-icons";
import { useSelector, useDispatch } from "react-redux";import { 
  setDownloadTimeRange,
  setSliderValue
} from '../../helpers/redux/reducers/videoSplitReducer';

const SpeakerLabelChange = ({
  key,
  speaker = {},
  curISO,
  changeSpeakers,
  updateSpeakersLabel= ()=>{}
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const inputRef = useRef();
  const speakerLabelRef = useRef('');

  useEffect(() => {
    speakerLabelRef.current = speaker?.to;
  }, [speaker?.to]);

  const handleChange = (e) => {
    speakerLabelRef.current = e.target.value;
  }

  useEffect(() => {
    if (isEdit) {
      inputRef.current?.focus();
    }
  }, [isEdit])

  const handleSave = () => {
    setIsEdit(false);
    let newSpeakersList = JSON.parse(JSON.stringify(changeSpeakers));
    newSpeakersList = newSpeakersList.map(_speaker => {
      if (_speaker.to === speaker.to) {
        return {
          ...speaker,
          to: speakerLabelRef.current,
        }
      } else {
        return _speaker;
      }
    })
    updateSpeakersLabel(curISO?.roomId, curISO?.recordingTime, newSpeakersList);
  }
  
  const handleKeyUp = (e) => {
    if (e.code === 'Enter') {
      handleSave();
    }
  }

  return (
    <div
      key={key}
      className="px-2 mt-1 text-white"
    >
      {!isEdit && 
        <div 
          className="flex cursor-pointer" 
          onClick={() => {
            setIsEdit(true)
          }}
        > 
          <span>
            {speaker?.to}
          </span>
          <PencilIcon className="ml-2"/> 
        </div>
      }
      {isEdit && 
        <Input 
          ref={inputRef}
          defaultValue={speaker?.to}
          onChange={handleChange}
          onBlur={handleSave}
          onKeyUp={handleKeyUp}
        />
      }
    </div>
  )
}

const TranscriptList = ({ 
  isOpen = false, 
  close = () => {}, 
  transcripts, 
  isPlaying = false,
  currentTime,
  setCurrentTime = () => {},
  curISO,
  updateSpeakersLabel = () => {},
  videoDuration = 0,
}) => {
  const scrollRef = useRef();
  const insideScrollRef = useRef();
  const [currentIdx, setCurrentIdx] = useState(-1);
  const transcriptsRef = useRef();
  const [searchKey, setSearchKey] = useState('');
  const [isSpeakerNameChange, setIsSpeakerNameChange] = useState(false);

  const [srcSpeakers, setSrcSpeakers] = useState([]);
  const [changeSpeakers, setChangeSpeakers] = useState([]);

  const downloadTimeRange = useSelector((state) => state.videoSplit.downloadTimeRange);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsSpeakerNameChange(false)
  }, [curISO?.url])

  useEffect(() => {
    transcriptsRef.current = transcripts;
    let _srcSpeakers = [];
    for (let transcript of transcripts) {
      if (!_srcSpeakers.some(speaker => speaker === transcript?.speaker?.replace("[", '')?.replace("]", ''))) {
        _srcSpeakers.push(transcript.speaker?.replace("[", '')?.replace("]", ''));
      }
    }
    setSrcSpeakers(_srcSpeakers);
  }, [transcripts]);

  useEffect(() => {
    let _srcSpeakers = [];
    if (srcSpeakers && Array.isArray(srcSpeakers) && curISO?.dbSpeakersInfo && Array.isArray(curISO?.dbSpeakersInfo)) {
      _srcSpeakers = srcSpeakers?.map(speaker => {
        if (curISO?.dbSpeakersInfo?.some(info => info?.from === speaker)) {
          return curISO?.dbSpeakersInfo?.find(info => info?.from === speaker);
        } else {
          return {
            from: speaker,
            to: speaker
          }
        }
      })
      setChangeSpeakers(_srcSpeakers);
    }
  }, [curISO?.dbSpeakersInfo, srcSpeakers]);

  useEffect(() => {
    for (let i = 0; i < transcriptsRef.current.length; i++) {
      if (transcriptsRef.current[i].created - 1000 < currentTime * 1000 
        && (i === transcriptsRef.current.length - 1 || transcriptsRef.current[i + 1].created - 1000 > currentTime * 1000)) 
      {
        setCurrentIdx(i);  
        break;
      }
    }
  }, [currentTime]);

  useEffect(() => {
    if (currentIdx < 0) return;
    const dy = document.querySelector(`#t_${currentIdx}`).getBoundingClientRect().top;
    scrollRef.current.scrollTo({
      top: dy - insideScrollRef.current.getBoundingClientRect().top,
      behavior: 'smooth'
    });
  }, [currentIdx])

  const handleFindNext = (e) => {
    if (searchKey === '') return;

    for (let i = currentIdx + 1; i < transcriptsRef.current.length; i++) {
      if (transcriptsRef.current[i].message.toLowerCase().indexOf(searchKey) >= 0) {
        setCurrentIdx(i); 
        setCurrentTime((transcriptsRef.current[i].created) / 1000)
        return;
      }
    }

    for (let i = 0; i < transcriptsRef.current.length; i++) {
      if (transcriptsRef.current[i].message.toLowerCase().indexOf(searchKey) >= 0) {
        setCurrentIdx(i); 
        setCurrentTime((transcriptsRef.current[i].created) / 1000);
        return;
      }
    }
  }

  const userSpeakerLabel = (text) => {
    const speaker = changeSpeakers?.find(_speaker => `[${_speaker?.from}]` === text);
    if (speaker) {
      return `[${speaker?.to}]`;
    } else {
      return text;
    }
  };

  const handleSetFirstTime = (t) => {
    const preFirstTime = deTimeFormat(downloadTimeRange?.startTime);
    const preLastTime = preFirstTime + downloadTimeRange?.duration;
    const startTime = timeFormat(t);
    const duration = preLastTime < t ? videoDuration - t : preLastTime - t;
    dispatch(setDownloadTimeRange({startTime, duration}));
    dispatch(setSliderValue([t / videoDuration * 100, (t + duration) / videoDuration * 100]));
  }

  const handleSetLastTime = (t) => {
    const preFirstTime = deTimeFormat(downloadTimeRange?.startTime);
    const preLastTime = preFirstTime + downloadTimeRange?.duration;
    const startTime = preFirstTime > t ? timeFormat(0) : timeFormat(preFirstTime);
    const duration = t - deTimeFormat(startTime);
    dispatch(setDownloadTimeRange({startTime, duration}));
    dispatch(setSliderValue([deTimeFormat(startTime) / videoDuration * 100, t / videoDuration * 100]));
  }

  return (
    <>
    <div
      className={`${isOpen ? 'p-2' : 'hidden'} relative bg-gray-400 h-full w-400 rounded-r flex flex-col`}
      style={{
        height: 'calc(100vh - 292px)',
        minHeight: '500px'
      }}
    >
      <div 
        className="absolute right-2 top-2 rounded-full bg-gray-800"
        onClick={() => close()}
      >
        <ArrowIcon className="w-6 h-6 rotate-90 cursor-pointer text-white" />
      </div>
      <label className=""><h4 className="text-gray-700 font-bold mb-2">Transcripts</h4></label>
      <div className="flex items-center">
        <Input 
          css={{ width: "100%", mt: "$0", bg: "gray" }}
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
        />
        <Button 
          onClick={handleFindNext}
          className="p-0 text-xs ml-2"
        >
          Find Next
        </Button>
      </div>
      <div
        className={
          `flex justify-between items-center mt-2 cursor-pointer  text-gray-800
            ${!isSpeakerNameChange ? 'border-b border-gray-500 rounded-b' : ''}`
        }
        onClick={() => setIsSpeakerNameChange(!isSpeakerNameChange)}
      >
        Change Speaker Labels
        <ArrowIcon className={`w-5 h-5 ml-2 text-gray-600 ${isSpeakerNameChange ? 'rotate-180' : ''}`} />
      </div>
      {isSpeakerNameChange &&
        <div 
          className="w-full overflow-y-scroll able-namechange bg-gray-500 rounded"
          style={{height: '150px'}}
        >
          {changeSpeakers?.map((speaker, idx) => (
            <SpeakerLabelChange
              key={idx}
              speaker={speaker} 
              curISO={curISO}
              changeSpeakers={changeSpeakers}
              updateSpeakersLabel={updateSpeakersLabel}
            />
          ))}
        </div>
      }
      <div
        ref={scrollRef}
        id="scroll"
        className="able-transcripts relative flex flex-col p-1 bg-gray-500 rounded overflow-y-scroll mt-3"
        style={{'height': 'calc(100% - 80px)'}}
      >
        {transcripts?.isProcessing &&
          <div className="text-white">
            <p>Looks like you uploaded this a few minutes ago.</p> 
            <p>Getting transcript will take about 10 ~ 30% of the audio duration of the media file.</p>
          </div>
        }
        <ol ref={insideScrollRef}>
        {transcripts && Array.isArray(transcripts) && transcripts?.map((transcript, idx) => (
          <li
            id={`t_${idx}`}
            key={`t_${idx}`}
            className={`${idx === currentIdx && (isPlaying || searchKey) ? 'bg-gray-200' : ''} relative cursor-pointer rounded hover:bg-gray-300 hover:text-blue-700 flex flex-col mb-1`}
            onClick = {() => setCurrentTime((transcript.created - 1000) / 1000)}
          >
            <div className="flex justify-between items-end">
              <span
                // className="text-blue-700"
                style={{color: "#0CA0BF"}}
              >{userSpeakerLabel(transcript.speaker.replace(/\d+_/, '').replace('_', ' '))}</span>
              <div className="flex items-center">
                <span className="text-xs italic" style={{color: "#0CA0BF"}}>
                  {timeFormat(transcript.created / 1000)}
                </span>
                <button
                  className="cursor-pointer ml-1"
                  onClick={(e) => handleSetFirstTime(transcript.created / 1000)}
                >
                  <ArrowRightIcon className="rotate-90 text-gray-700 hover:text-blue-500 w-4 h-4 hover:w-5 hover:h-5" />
                </button>
                <button
                  className="cursor-pointer"
                  onClick={(e) => handleSetLastTime(transcript.created / 1000)}
                >
                  <ArrowRightIcon className="rotate-90 text-gray-700 hover:text-blue-500 w-4 h-4 hover:w-5 hover:h-5" />
                </button>
              </div>
            </div>
            <p className={`${isPlaying && idx === currentIdx ? 'text-black' : 'text-white'} pl-2`}>
              {transcript.message?.toLowerCase().indexOf(searchKey) >= 0 && searchKey !== '' && (
              <>
                {transcript.message.substr(0, transcript.message.toLowerCase().indexOf(searchKey))}
                {(
                  <span className="text-red-500">{transcript.message.substr(transcript.message?.toLowerCase().indexOf(searchKey), searchKey.length)}</span>
                )}  
                {transcript.message.split(transcript.message.substr(0, transcript.message?.toLowerCase().indexOf(searchKey) + searchKey.length))[1]}
              </>)}
              {(transcript.message?.toLowerCase().indexOf(searchKey) < 0 || searchKey === '') && (<>{transcript.message}</>)}
            </p>
          </li>
        ))}
        </ol>
      </div>
    </div>
    </>
  )
}

export default TranscriptList;
