import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "../components/button";
import Modal from "../components/modal";
import InputField from "../components/forms/inputField";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {ReactComponent as UserAvatar } from "../icons/user-profile.svg";
import ProfileImg from "../image/profile_man.png";

const ProfileModal = ({
  isOpen,
  onClose,
  onSubmit,
  title = "Profile",
  user,
  rtmpUrl = "",
  scheduledDate,
}) => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      photoUrl: user?.photoUrl,
      firstName: user?.firstName,
      lastName: user?.lastName,
      title: user?.title,
      company: user?.company,
      fieldOne: user?.fieldOne,
      fieldTwo: user?.fieldTwo,
      fieldThree: user?.fieldThree
    },
  });

  const [photoFile, setPhotoFile] = useState();

  useEffect(() => {
    if (user) {
      reset({
        photoUrl: user?.photoUrl,
        firstName: user?.firstName,
        lastName: user?.lastName,
        title: user?.title,
        company: user?.company,
        fieldOne: user?.fieldOne,
        fieldTwo: user?.fieldTwo,
        fieldThree: user?.fieldThree
      })
    }
  }, [reset, user, isOpen]);

  const [loading, setLoading] = useState(false);

  const _onSubmit = async (data) => {
    setLoading(true);
    await onSubmit({
      ...data,
      photoUrl: photoFile?.[0]
    });
    setPhotoFile();
    setLoading(false);
  }

  const handlePhotoUpload = (e) => {
    document.querySelector('#photoUrl').click();
  }

  return (
    <Modal isOpen={isOpen} closeModal={onClose} className="w-1/4" isHeader={false} isFooter={false} initialTop={5}>
      <div className="bg-gray-500 w-full p-2 rounded-t-md flex items-center">
        <h2 className="font-medium text-xl text-gray-100">{title}</h2>
      </div>
      <div className="px-4">
        <form
          onSubmit={handleSubmit(_onSubmit)}
          className="flex flex-col gap-2 p-4"
        >
          <div className="flex justify-center shape-bg-3">
            <div
              onClick={handlePhotoUpload}
              className="w-20 h-20 rounded-full border border-2 border-dotted border-gray-700 cursor-pointer overflow-hidden"
            >
            {/* <UserAvatar className="w-8 h-8 text-gray-200"/> */}
              <img 
                src={photoFile?.[0] ? URL.createObjectURL(photoFile?.[0]) : (user?.photoUrl ? user?.photoUrl : ProfileImg)} 
                alt="profile" 
              />
            </div>
            <input
              id="photoUrl"
              className="hidden"
              type="file"
              accept="image/*" 
              onChange={(e) => {
                setPhotoFile(e.target.files);
              }}
            />
          </div>
          <div className="grid grid-cols-2 gap-2">
            <InputField
              id="firstName"
              label="First Name"
              className="w-full"
              labelStyle="text-gray-900"
              {...register('firstName', { required: true })}
              required
            />
            <InputField
              id="lastName"
              label="Last Name"
              className="w-full"
              labelStyle="text-gray-900"
              {...register('lastName', { required: true })}
              required
            />
          </div>
          <InputField
            id="title"
            label="Title"
            className="w-full"
            labelStyle="text-gray-900"
            {...register('title', { required: true })}
            required
          />
          <InputField
            id="company"
            label="Company"
            className="w-full"
            labelStyle="text-gray-900"
            {...register('company', { required: true })}
            required
          />
          <InputField
            id="fieldOne"
            label="Field One"
            className="w-full"
            labelStyle="text-gray-900"
            {...register('fieldOne', { required: true })}
            required
          />
          <InputField
            id="fieldTwo"
            label="Field Two"
            className="w-full"
            labelStyle="text-gray-900"
            {...register('fieldTwo', { required: true })}
            required
          />
          <InputField
            id="fieldThree"
            label="Field Three"
            className="w-full"
            labelStyle="text-gray-900"
            {...register('fieldThree', { required: true })}
            required
          />
          <div className="flex flex-row-reverse mt-4">
            <Button
              className="rounded bg-gray-500 text-gray-100 w-24"
              type="submit"
              disabled={loading}
            >
              {!loading && 'Submit'}
              {loading &&
                <div className="w-5 h-5">
                  <div className="uploading-spinner"></div>
                </div>
              }
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ProfileModal;
