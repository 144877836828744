import { 
	useVideo,
} from "@100mslive/react-sdk";

export const VideoCam = ({
  id,
  videoTrackId,
  style,
  className
}) => {
  const { videoRef } = useVideo({
    trackId: videoTrackId
  });
  return (
    <video
      id={id}
      ref={videoRef}
      style={style}
      autoPlay
      muted
      playsInline
      className={className}
    />
  )
};
