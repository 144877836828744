import React, { useState } from "react";
import Button from "../button";

const Edit = ({ teleprompter, editTextRef, handleSave, handleCancel }) => {
  return (
		<div className="h-full flex flex-col">
			<textarea
				autoFocus={true}
				ref={editTextRef}
				defaultValue={teleprompter?.text}
				// onChange={handleUpdateText}
				className="w-full h-full border-none rounded pt-5 text-lg"
			>
			</textarea>
			<div className="flex justify-between pl-5 pr-5 m-2">
				<Button
					className="w-16 bg-gray-600 text-white"
					onClick={handleSave}>Save</Button>
				<Button
					className="w-16 bg-gray-100"
					onClick={handleCancel}>Cancel</Button>
			</div>
		</div>
	)
};

export default Edit;
