import React from "react";
import {
  Button,
  Flex,
  Input
} from "@100mslive/react-ui";
import { 
  CrossIcon,
  PencilIcon
} from "@100mslive/react-icons";
import Modal from "../../../components/modal";
import { InteractionContext } from "../../../helpers/contexts/interactionContext";

const ScheduleModal = ({
  isScheduleModal,
  setIsScheduleModal,
  handleSetSchedule,
  curSchedule,
}) => {
  const [scheduledTime, setScheduledTime] = React.useState();

  React.useEffect(() => {
    if (curSchedule?.scheduledAt) {
      setScheduledTime(curSchedule?.scheduledAt)
    }
  }, [curSchedule?.scheduledAt])

  const getTimeString = (src) => {
    const time = new Date(src);
    const year = time.getFullYear();
    const month = time.getMonth() + 1;
    const day = time.getDate();
    const hour = time.getHours();
    const minute = time.getMinutes();
    const monthString = month < 10 ? `0${month}` : `${month}`;
    const hourString = hour < 10 ? `0${hour}` : `${hour}`;
    const minuteString = minute < 10 ? `0${minute}` : `${minute}`;
    const dayString = day < 10 ? `0${day}` : `${day}`;
    return `${year}-${monthString}-${dayString}T${hourString}:${minuteString}`;
  }
  
  return (
    <Modal
      isOpen={isScheduleModal}
      // closeModal={() => setIsScheduleModal(false)}
      className="w-full left-0"
      isHeader={false}
      isFooter={false}
    >
      <div className="custom-bg-1 w-full p-2 rounded-t-md flex items-center">
        <h2 className="font-medium text-xl text-gray-100">
          Schedule For <span className="italic text-blue-500">{curSchedule?.name}</span></h2>
      </div>
      <div 
        className="p-2"
      >
        <Input 
          type="datetime-local" 
          value={getTimeString(scheduledTime)}
          onChange={(e) => {
            setScheduledTime(new Date(e.target.value).getTime())
          }}
        />
      </div>
      <Flex
          justify="around"
          css={{
            my: '$5',
            px: '$3'
          }}
        >
          <Button
            onClick={async () => {
              handleSetSchedule(curSchedule?.id, scheduledTime);
              setIsScheduleModal(false);
            }}
            css={{ h: '$11' }}
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              setIsScheduleModal(false);
            }}
            variant="standard"
            css={{ h: '$11' }}
          >
            Cancel
          </Button>
        </Flex>
    </Modal>
  )
};

const ScheduleCell = ({ 
  row, 
  setSchedule,
  setIsScheduleModal,
  cancelSchedule
}) => {
  return (
    <div className="flex">
      <Button
        variant="standard"
        css={{
          h: '$10',
          mx: '$1'
        }}
        onClick={() => {
          setSchedule(row)
          setIsScheduleModal(true);
        }}
      >
        {!row?.scheduledAt && "schedule"}
        {row?.scheduledAt &&
          <>
            <span>{new Date(row?.scheduledAt).toLocaleDateString()} {new Date(row?.scheduledAt).toLocaleTimeString()}</span>
            <PencilIcon className="w-5 h-5 text-white ml-1" />
          </>
        }
      </Button>
      {row?.scheduledAt &&
        <Button
          variant="standard"
          css={{
            h: '$10',
            mx: '$1',
            px: '$1'
          }}
          onClick={() => cancelSchedule(row?.id)}
        >Cancel Schedule</Button>
      }
    </div>
  )
};

const InteractionList = ({
  interactions=[],
  removeInteraction=()=>{},
  scheduleInteraction=()=>{},
  cancelSchedule=()=>{}
}) => {
  const [schedule, setSchedule] = React.useState('');
  const [isScheduleModal, setIsScheduleModal] = React.useState(false);
  const { testInteraction, triggerInteraction } = React.useContext(InteractionContext);

  return (
    <>
      <ScheduleModal
        isScheduleModal={isScheduleModal}
        curSchedule={schedule}
        setIsScheduleModal={setIsScheduleModal}
        handleSetSchedule={scheduleInteraction}
      />
      <table className="w-full">
        <thead>
          <tr>
            <th className="bg-gray-700 text-white text-left pl-2 border-r border-gray-500">Name</th>
            <th className="bg-gray-700 text-white text-left pl-2 border-r border-gray-500">Type</th>
            <th className="bg-gray-700 text-white text-left pl-2 border-r border-gray-500">Quote</th>
            <th className="bg-gray-700 text-white text-left pl-2 border-r border-gray-500">Scheduled</th>
            <th className="bg-gray-700 text-white text-left pl-2  border-gray-500">Target</th>
            <th className="bg-gray-700 text-white text-left pl-2  border-gray-500"></th>
            <th className="bg-gray-700 text-white text-left pl-2  border-gray-500"></th>
            <th className="bg-gray-700 text-white text-left pl-2 border-r border-gray-500"></th>
          </tr>
        </thead>
        <tbody>
          {interactions?.map((row, idx) => (
            <tr
              key={idx}
              className="border-b h-8"
            >
              <td>{row?.name}</td>
              <td>{row?.type?.name}</td>
              <td>{row?.content?.body?.slice(0, 50)}</td>
              <td>
                <ScheduleCell 
                  row={row}
                  setSchedule={setSchedule}
                  setIsScheduleModal={setIsScheduleModal}
                  cancelSchedule={cancelSchedule}
                />
              </td>
              <td>{row?.filterMatch?.name}&nbsp;{row?.filterMatch.key === 'all' ? '' : '='}&nbsp;{row?.filterMatch.key === 'all' ? '' : row?.filterMatch?.value}</td>
              <td className="text-center">
                <Button
                  css={{
                    h: '$10'
                  }}
                  onClick={() => testInteraction(row)}
                >Test</Button>
              </td>
              <td>
                <Button
                  css={{
                    h: '$10'
                  }}
                  onClick={() => triggerInteraction(row)}
                >Trigger</Button>
              </td>
              <td>
                <CrossIcon 
                  className="text-red-500 hover:text-red-300 w-5 h-5 cursor-pointer" 
                  onClick={(e) => removeInteraction(row.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
};

export default InteractionList;
