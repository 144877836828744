import React from 'react';
import TextLayerForm from "./textLayerForm";
import TimerLayerForm from "./timerLayerForm";
import ImageLayerForm from "./imageLayerForm"
import VideoLayerForm from "./videoLayerForm";
import StreamLayerForm from "./streamLayerForm";

const LayerFormContainer = ({ children }) => (
  <div className="p-0">{children}</div>
);

const LayerForm = ({
  layers,
  activeLayer,
  updateLayer,
  removeLayer,
  createSubLayers,
  ...props
}) => {
  const layer = layers?.find(({ id }) => id === activeLayer);

  if (!activeLayer || !layer) return null;

  if (layer.type === "image") {
    return (
      <LayerFormContainer>
        <ImageLayerForm
          {...layer}
          updateLayer={updateLayer}
          removeLayer={removeLayer}
        />
      </LayerFormContainer>
    );
  }

  if (layer.type === "video") {
    return (
      <LayerFormContainer>
        <VideoLayerForm
          {...layer}
          updateLayer={updateLayer}
          removeLayer={removeLayer}
        />
      </LayerFormContainer>
    );
  }

  if (layer.type === "stream") {
    return (
      <LayerFormContainer>
        <StreamLayerForm
          {...layer}
          updateLayer={updateLayer}
          removeLayer={removeLayer}
          updateSublayer={props?.updateSublayer}
          removeSublayer={props?.removeSublayer}
          createSubLayers={createSubLayers}
          addNewFont={props.addNewFont}
          customFonts={props.customFonts}
          activeSubLayers={props?.activeSubLayers}
        />
      </LayerFormContainer>
    );
  }

  if (layer.type === "text") {
    return (
      <LayerFormContainer>
        <TextLayerForm
          {...layer}
          updateLayer={updateLayer}
          removeLayer={removeLayer}
          addNewFont={props.addNewFont}
          customFonts={props.customFonts}
        />
      </LayerFormContainer>
    );
  }

  if (layer.type === "timer") {
    return (
      <LayerFormContainer>
        <TimerLayerForm
          {...layer}
          updateLayer={updateLayer}
          removeLayer={removeLayer}
          addNewFont={props.addNewFont}
          customFonts={props.customFonts}
        />
      </LayerFormContainer>
    );
  }

  return null;
};

export default LayerForm;
