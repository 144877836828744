import { useEffect } from "react";
import { CheckIcon, RadioIcon } from "@100mslive/react-icons"
import { RadioGroup, Flex, Label } from "@100mslive/react-ui"

const SpeakerViewSetting = ({
  isShow,
  isAutoSpeaker,
  setIsAutoSpeaker,
  participants,
  topAxis=8,
  curManualSpeaker,
  setCurManualSpeaker,
}) => {
  useEffect(() => {
    if (isAutoSpeaker) {
      setCurManualSpeaker();
    }
  }, [isAutoSpeaker, setCurManualSpeaker]);

  return (
    <div
      className={`flex flex-col absolute left-full top-${topAxis} w-48 custom-bg-2 p-2 rounded ${isShow?'':'hidden'}`}
    >
      <div className="flex justify-between mb-1">
        <RadioGroup.Root value={isAutoSpeaker} onValueChange={setIsAutoSpeaker}>
          <Flex align="center" css={{ mr: "$8" }}>
            <RadioGroup.Item
              value={true}
              id="autoDynamic"
              css={{ mr: "$1" }}
            >
              <RadioGroup.Indicator />
            </RadioGroup.Item>
            <Label htmlFor="autoDynamic" css={{fontSize: "$sm"}}>Auto</Label>
          </Flex>
          <Flex align="center" css={{ cursor: "pointer" }}>
            <RadioGroup.Item
              value={false}
              id="manualDynamic"
              css={{ mr: "$1" }}
            >
              <RadioGroup.Indicator />
            </RadioGroup.Item>
            <Label htmlFor="manualDynamic" css={{fontSize: "$sm"}}>Manual</Label>
          </Flex>
        </RadioGroup.Root>
      </div>
      {participants?.map((participant, idx) => (
        <div
          key={participant?.identity}
          className="flex px-3 mt-2"
          onClick={(e) => {
            setCurManualSpeaker(participant?.identity);
            setIsAutoSpeaker(false);
          }}
        >
          {curManualSpeaker === participant.identity && <CheckIcon className="text-green-500"/>}
          {idx + 1} {participant?.firstName} {participant?.lastName}
        </div>
      ))}
    </div>
  )
};

export default SpeakerViewSetting;
