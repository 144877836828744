import React, { useEffect, useMemo } from "react";
import { 
	useVideo,
  useHMSStore,
	selectScreenShareByPeerID,
} from "@100mslive/react-sdk";
import { VideoCam } from "./videoCam";
// TODO:
// - Improve loader css stability (sometimes creates too large of an element)

const Video = ({
  loading = false,
  peer,
  isRemote,
  width,
  height,
  isLocal = false,
  className,
  isSharing,
  ...props
}) => {
  const participant = useMemo(() => peer, [peer]);

  const videoTrackId = useMemo(() => participant?.videoTrack, [participant?.videoTrack]);
  
  const screenshareVideoTrack = useHMSStore(selectScreenShareByPeerID(participant?.id));
  const { videoRef: sharingVideoRef } = useVideo({
    trackId: screenshareVideoTrack?.id
  });

  const style = useMemo(() =>({
    width: `${width}px`,
    height: `${height - 5}px`,
    objectFit: "cover",
    background: "black",
    zIndex: props?.style?.zIndex,
    bottom: props?.style?.bottom ?? '0',
    transform: `scale(${isRemote ? 1 : -1}, 1)`
  }), [width, height, isRemote, props]);

  return (
    <>
      <div className={`${loading ? "block" : "hidden"} loader`} />
      {!isSharing && 
        <VideoCam
          id={`v_${participant?.id}`}
          videoTrackId={videoTrackId}
          style={style}
          className={`${className ? className : ""} ${
            !loading ? "block w-full h-full" : "hidden"
          }`}
        />
      }
      
      {isSharing && <video
        id={`v_${participant?.id}`}
        ref={sharingVideoRef}
        style={style}
        autoPlay
        muted
        playsInline
        className={`${className ? className : ""} ${
          !loading ? "block w-full h-full" : "hidden"
        }`}
      />}
    </>
  );
};

export default Video;
