export const btnStyle = (isTeleprompter) => isTeleprompter ?
  {
    transform : `translate(0px, 0px)`,
    width: '20px',
    height: '30px',
    padding: '0px',
    // transition: 'all 0.5s',
  } : {
    transform: 'translate(40px, 0px)',
    width: '40px',
    height: '50px',
    padding: '0',
    // transition: 'all 0.5s'
  };
export const teleprompterStyle = (isTeleprompter, isWaitingRoom = true, isBeamUser = false) => isTeleprompter ?
  {
    zIndex: 2,
    height: `calc(100vh - ${(isWaitingRoom ? 205 : 110) - (isBeamUser ? 205 : 0)}px)`,
    transform: 'translate(0px, 0px)',
    transition: 'transform 0.5s'
  } : {
    zIndex: 2,
    height: `calc(100vh - ${(isWaitingRoom ? 205 : 110) - (isBeamUser ? 205 : 0)}px)`,
    transform: 'translate(-100%, 0px)',
    transition: 'transform 0.5s'
  };

export const canvasStyle = (isTeleprompter, isBeamUser = false) => isTeleprompter ? 
  {
    // background: '#e5e5e5',
    left: `${isBeamUser ? 25 : 16.7}vw`,
    // width: `calc(${isBeamUser ? 75 : 66.7}vw - 0px)`,
    width: `calc(${isBeamUser ? '75vw' : '83.3vw - 350px'})`,
    transition: 'all 0.5s',
  } : {
    // background: '#e5e5e5',
    left: '0px',
    // width: `calc(${isBeamUser ? 100 : 83.3}vw - 0px)`,
    width: `${isBeamUser ? '100vw' : 'calc(100vw - 350px)'}`,
    transition: 'all 0.5s',
  };
