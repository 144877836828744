import React, { createContext, useContext, useEffect, useState } from "react";
// import { createLocalTracks, createLocalVideoTrack } from "twilio-video";
import DeviceSelection from "../../components/rtc/deviceSelection";
import { MediaDeviceContext } from "./mediaDeviceContext";

export const LocalTracksContext = createContext({});

export const LocalTracksProvider = ({ children }) => {
  const [localTracks, setLocalTracks] = useState();
  const [deviceSelectionOpen, setDeviceSelectionOpen] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [localScreenShare, setLocalScreenShare] = useState(false);
  const [localCameraTrack, setLocalCameraTrack] = useState(false);
  const [params, setParams] = useState({});
  const [isSharingIntent, setIntent] = useState(false);
  const [vbImage, setvbImage] = useState();
  let virtualBackground;

  const {
    videoDeviceId,
    audioInputDeviceId,
    audioOutputDeviceId,
    changeVideoDevice,
    changeAudioInputDevice,
    changeAudioOutputDevice,
  } = useContext(MediaDeviceContext);

  const handleDeviceSelectionClose = () => {
    setDeviceSelectionOpen(false);
  };

  const openDeviceSelection = () => {
    setDeviceSelectionOpen(true);
  };

  const closeDevideSelection = () => {
    setDeviceSelectionOpen(false);
  };

  const rebuildLocalTracks = (_param) => {
    
  }

  return (
    <LocalTracksContext.Provider
      value={{
        localTracks,
        acquiringTracks: loading,
        error,
        setLocalTracks,
        openDeviceSelection,
        videoDeviceId,
        audioInputDeviceId,
        audioOutputDeviceId,
        changeAudioInputDevice,
        changeAudioOutputDevice,
        changeVideoDevice,
        localScreenShare,
        setLocalScreenShare,
        localCameraTrack,
        setLocalCameraTrack,
        isSharingIntent,
        setIntent,
        closeDevideSelection,
        virtualBackground,
        vbImage,
        setvbImage
      }}
    >
      <DeviceSelection
        isOpen={deviceSelectionOpen}
        message={error}
        setMessage={setError}
        onClose={!error && handleDeviceSelectionClose}
      />
      {children}
    </LocalTracksContext.Provider>
  );
};
