export const timeFormat = (time) => {
	if (time <= 0) return `HH : MM : SS`;
	if (time <= 0) return ``;
	let h = `${Math.floor(time / 3600)}`;
	if (h.length === 1) h = `0${h}`;
	time = time % 3600;
	let m = `${Math.floor(time / 60)}`;
	if (m.length === 1) m = `0${m}`;
	let s = `${Math.floor(time) % 60}`;
	if (s.length === 1) s = `0${s}`;
	return `${h} : ${m} : ${s}`;
};

export const timeFormatV2 = (time) => {
	const hour = `${new Date(Math.floor(time)).getHours()}`;
	const minute = `${new Date(Math.floor(time)).getMinutes()}`;
	const second = `${new Date(Math.floor(time)).getSeconds()}`;
	const hourR = hour.length === 2 ? hour : `0${isNaN(hour) ? 0 : hour}`;
	const minuteR = minute.length === 2 ? minute : `0${isNaN(minute) ? 0 : minute}`;
	const secondR = second.length === 2 ? second : `0${isNaN(second) ? 0 : second}`;
	return `${hourR}:${minuteR}:${secondR}`;
}

export const isEqual = (a, b) => {
	if (!a) return !b;
	if (!b) return !a;
	const aVal = typeof(a) === 'object' ? JSON.stringify(a) : a;
	const bVal = typeof(b) === 'object' ? JSON.stringify(b) : b;
	return aVal === bVal;
};

export const defaultFonts = [
	{
		family: "Cabin", // Family Name
		styles: [
			"600..700", // Range, if family supports it.
			"100..200italic", // Range with italic
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500", // Regular with weight
			444, // Regular weight for variable font
		],
	},
	{
		family: "Roboto", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "Futura", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "Open Sans", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "Lato", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "Montserrat", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "Oswald", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "Slabo", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "Raleway", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "PT Sans", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "Merriweather", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	}
];

export const audioFormats = [
	"MP3", "WAV", "M4A", "FLAC", "MP4", "WMA", "AAC"
];

export const videoFormats = [
	"MP4", "AVI", "MOV", "WMV", "FLV", "AVCHD", "WebM", "MKV"
];
