
const Select = ({ list, value, onChange, title, titleClass="" }) => {
	return (
		<div className="flex flex-col mt-2">
			<span
				className={`${titleClass} mb-1`}
			>
				{title}:
			</span>
			{list?.length ? (
				<select className="cursor-default outline outline-1 outline-offset-2 border rounded border-gray-300 outline-gray-300" onChange={onChange} value={value}>
					{list.map((device) => (
						<option 
							value={device.deviceId}
							key={device.deviceId}
							// disabled={device.deviceId === 'none'}
						>
							{device.label}
						</option>
					))}
				</select>
			) : null}
		</div>
	);
};

export default Select;
