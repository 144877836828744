
export const timeFormat = (time) => {
  let _time = time >=0 ? time : 0;
  let h = `${Math.floor(_time / 3600)}`;
  if (h.length === 1) h = `0${h}`;
  _time = _time % 3600;
  let m = `${Math.floor(_time / 60)}`;
  if (m.length === 1) m = `0${m}`;
  let s = _time % 60;
  s = Math.floor(s * 1000) / 1000;
  if (s < 10) {
    s = `0${s}`
  }
  return `${h} : ${m} : ${s}`;
};

export const deTimeFormat = (timeFormat) => {
  const splitor1 = ":";
  const splitor2 = " : ";
  const src = timeFormat ?? '00:00:00';
  const timeValues = src.indexOf(splitor2) >= 0 ? src.split(splitor2) : src.split(splitor1);
  return parseFloat(timeValues?.[0] ?? 0) * 3600 + parseFloat(timeValues?.[1] ?? 0) * 60 + parseFloat(timeValues?.[2] ?? 0)
};
