import axios from "axios";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";

export class Transcriber {
  constructor(addTranscript) {
    this.isInitializing = true;
    this.username = '';
    this.setIsTranscriptionStarted = () => {};
    this.enabled = false;
    this.socket = null;
    this.totalTimeDiff = 0;
    this.totalCount = 0;
    this.streams = {};
    this.addTranscript = addTranscript;
    this.setIsInitializing = () => {};
    this.initialized = false;
    this.lastMessage = {};
    this.localPeerId = null;
    this.channel = null;
    this.recorder = null;
    this.sttTuningConfig = {
      timeSlice: 250,
      desiredSampRate: 48000,
      numberOfAudioChannels: 1,
      bufferSize: 4096,
    };
  }

  broadcast = (text, eventName = "transcription") => {
    // this.channel.trigger(`client-${eventName}`, { text, eventName });
  };

  async listen() {
    try {
      if (!window.__hms.sdk) return;
      const localPeer = window.__hms.sdk.getLocalPeer();
      if (!localPeer.audioTrack) return;
      const sampleRate = localPeer.audioTrack?.nativeTrack.getSettings().sampleRate ?? 48000;
      this.localPeerId = localPeer.peerId;
      this.streams[localPeer.peerId] = {
        stream: new MediaStream([localPeer.audioTrack.nativeTrack]),
        name: localPeer.name,
      };

      let url = process.env.REACT_APP_TRANSCRIPT_TOKEN_END_POINT;
      this.setIsInitializing(true);

      const res = await axios({
				method: 'post',
				url,
			});

      const body = res.data;

      if (body && body.token.token) {
        const token = body.token.token;
        this.socket = await new WebSocket(
          `wss://api.assemblyai.com/v2/realtime/ws?sample_rate=${sampleRate}&token=${token}`
        );
        this.socket.onmessage = message => {
          try {
            const res = JSON.parse(message.data);
            if (!res.text) return;
            if (res.text && this.enabled) {
              let peername = this.streams[this.localPeerId]["name"];
              //Limiting the transcript size based on its charecter length.
              let messageText = res.text
                // res.text.length >= 80
                //   ? res.text
                //       .split(" ")
                //       .slice(Math.max(res.text.split(" ").length - 10, 1))
                //       .join(" ")
                //   : res.text;

              this.addTranscript({
                speaker: `[${this.username}]`,
                audio_start: res.audio_start,
                message: messageText,
                created: res.created
              });

              this.broadcast(
                JSON.stringify({
                  peerId: this.localPeerId,
                  peername: peername,
                  transcript: messageText,
                  audio_start: res.audio_start,
                  created: res.created,
                  isEnabled: this.enabled,
                })
              );
            }
          } catch (err) {
            // console.error("transcription", err);
          }
        };

        this.socket.onerror = event => {
          this.setIsInitializing(false);
          // console.error("transcription", event);
          this.socket.close();
        };

        this.socket.onclose = event => {
          this.setIsTranscriptionStarted(false);
          try {
            this.socket = null;
            if (this.enabled) {
              this.listen();
            }
          } catch (err) {
            // console.error("transcription", err);
          }
        };

        this.socket.onopen = () => {
          this.setIsInitializing(false);
          this.setIsTranscriptionStarted(true);
          try {
            for (let i in this.streams) {
              this.observeStream(this.streams[i]["stream"]);
            }
          } catch (err) {
            // console.error("transcription", err);
          }
        };
      } else {
        // console.log("Unable to fetch dynamic token!!");
      }
    } catch (err) {
      // console.error("transcription", err);
    }
  }

  async observeStream(stream) {
    if (this.recorder) {
      this.recorder.stopRecording();
    }
    let recorder = new RecordRTC(stream, {
      ...this.sttTuningConfig,
      type: "audio",
      mimeType: "audio/webm;codecs=pcm",
      recorderType: StereoAudioRecorder,
      ondataavailable: blob => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64data = reader.result;
          if (
            this.socket &&
            this.enabled &&
            this.socket.readyState &&
            this.socket.readyState === 1
          ) {
            try {
              this.socket.send(
                JSON.stringify({ audio_data: base64data.split("base64,")[1] })
              );
            } catch (err) {
              // console.error("transcription", err);
            }
          }
        };
        reader.readAsDataURL(blob);
      },
    });
    recorder.startRecording();
    this.recorder = recorder;
  }

  enableTranscription(enable) {
    if (enable && !this.enabled) {
      this.enabled = true;
      this.listen();
    } else if (!enable && this.enabled) {
      this.enabled = false;
      this.socket?.close();
      this.recorder?.stopRecording();
      this.socket = null;
    }
  }
};
