export const roomConfig = {
  // template_id: "63255d78fe63cb9c2b63b6bb",
  template_id: "638851e07c86f5f83af2afe9",
  recording_info: {
    enabled: false,
    upload_info: {
      type: "s3",
      location: "cloud-conference-1",
      prefix: "",
      options: {
        region: "us-east-2"
      },
      credentials: {
        key: process.env.REACT_APP_AWS_KEY,
        secret: process.env.REACT_APP_AWS_SECRET,
      }
    }
  },
  region: "us"
};
