import React from "react";
import {
	selectAudioTrackByPeerID,
	selectLocalPeerID,
	selectAudioTrackVolume,
	selectPeerMetadata,
	selectPermissions,
	useHMSActions,
	useHMSStore,
	useParticipants,
} from "@100mslive/react-sdk";

import {
  Flex,
  Box,
  Text,
  Avatar,
  textEllipsis,
  Input,
  Dropdown,
  Slider,
	Tooltip,
} from "@100mslive/react-ui";

import {
  CrossIcon,
  ChangeRoleIcon,
  HandRaiseIcon,
  PeopleIcon,
  SearchIcon,
  VerticalMenuIcon,
  SpeakerIcon,
} from "@100mslive/react-icons";
import { ReactComponent as Microphone } from "../icons/microphone.svg";

const PeerVolumeController = ({ peerID, onMute, muted, isShowMuteIcon=false }) => {
	const [open, setOpen] = React.useState(false);
	const audioTrack = useHMSStore(selectAudioTrackByPeerID(peerID));
  const localPeerId = useHMSStore(selectLocalPeerID);
  const hmsActions = useHMSActions();
  // No volume control for local peer or non audio publishing role
  if (
		// peerID === localPeerId ||
		!audioTrack
	) {
    return null;
  }

	return (
		<div className="absolute bottom-0 right-0 z-5000">
			<Dropdown.Root open={open} onOpenChange={value => setOpen(value)}>
				<Dropdown.Trigger
					asChild
					data-testid="participant_more_actions"
					css={{ p: "$2", r: "$0", zIndex: 10000000000 }}
					tabIndex={0}
				>
					<div 
						className={`${
							muted
								? "text-red-600 hover:text-red-400"
								: "text-white hover:text-gray-300"
						} cursor-pointer z-30`}
					>
						<Microphone className="w-6 h-6" />
					</div>
					{/* </Text> */}
				</Dropdown.Trigger>
				<Dropdown.Content align="start" sideOffset={8} css={{ w: "$64" }}>
					<Dropdown.Item css={{ h: "auto" }}>
						<Flex direction="column" css={{ w: "100%" }}>
							<Flex align="center">
								<SpeakerIcon />
								<Text css={{ ml: "$4" }}>
									Volume{audioTrack.volume ? `(${audioTrack.volume})` : ""}
								</Text>
							</Flex>
							<Flex align="center">
								{isShowMuteIcon && <button
									id="mute_icon"
									onClick={(e) => {
										e.stopPropagation();
										onMute(e);
									}}
									className={`w-6 h-6 mr-2 ${
										muted
											? "text-red-600 hover:text-red-400"
											: "text-white hover:text-gray-300"
									} cursor-pointer z-30`}
								>
									<Tooltip
										title={`Click to ${muted ? "unmute" : 'mute'}`}
									>
										<Microphone className="w-6 h-6 mr-1 mb-1" />
									</Tooltip>
								</button>}
								<Slider
									onClick={e => e.stopPropagation()}
									css={{ my: "0.5rem" }}
									step={5}
									value={[audioTrack.volume]}
									onValueChange={e => {
										hmsActions.setVolume(e[0], audioTrack?.id);
									}}
								/>
							</Flex>
							
						</Flex>
					</Dropdown.Item>
				</Dropdown.Content>
				
			</Dropdown.Root>
		</div>
	)
};

export default PeerVolumeController;
