import React from "react";
import InteractionRequest from "./interactionRequest";
import InteractionRequestChat from "./interactionRequestChat";

const Interaction = ({
  isOpen,
  onClose,
  interaction,
  interact,
  deny,
  parentWidth,
  parentHeight
}) => {
  return (
    <>
      <InteractionRequest
        isOpen={isOpen && interaction?.type?.parentType === 'broadcastWindow'}
        onClose={onClose}
        interaction={interaction}
        interact={interact}
        deny={deny}
        parentWidth={parentWidth}
        parentHeight={parentHeight}
      />
      <InteractionRequestChat
        isOpen={isOpen && interaction?.type?.parentType === 'chatWindow'}
        onClose={onClose}
        interaction={interaction}
        interact={interact}
        deny={deny}
        parentWidth={parentWidth}
        parentHeight={parentHeight}
      />
    </>
  )
}

export default Interaction;
