import React, { useState, useRef } from "react";
import { DraggableCore } from "react-draggable";
import Draggable from "./draggable";
import { ReactComponent as CloseIcon } from "../icons/x-circle.svg";
import { ReactComponent as ExitFullScreenIcon } from "../icons/exit_fullscreen.svg";
import { TranscriptionContext } from "../helpers/contexts/transcriptionContext";
import {
  PlayCircleIcon,
  PauseCircleIcon
} from "@100mslive/react-icons";
import { Tooltip } from "@100mslive/react-ui";
import HourGlass from "./hourGlass";

const TranscriptModal = ({
	isOpen,
  isConnecting,
	closeModal = () => {}, 
	title = '',
	initialLeft = 40,
	initialTop = 30,
	className,
	children
}) => {
	const nodeRef = useRef();
	const ref = useRef();
  const scrollRef = useRef();

  const [isAutoScroll, setIsAutoScroll] = React.useState(true);

  const { 
    transcript,
  } = React.useContext(TranscriptionContext);
	
	React.useEffect(() => {
		ref.current = document.querySelector('body')
	}, [isOpen]);

  React.useEffect(() => {
    if (isAutoScroll) {
      scrollRef.current?.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      })
    }
  }, [transcript, isAutoScroll])

	const handleClose = (e) => {
		e.stopPropagation();
		closeModal();
	}
	return (
			<>
				{isOpen ? (
					<>
            <Draggable
              bounds={"parent"}
              onStart={() => {}}
              onStop={() => {}}
              onDrag={() => {}}
              parentRef={ref}
              position={{x: initialLeft, y: initialTop}}
              disabled={false}
              nodeRef={nodeRef}
              isVisible={true}
              zIndex={8000}
            >
              <div
                className={`cursor-move border-0 rounded-lg shadow-lg relative flex justify-between items-start min-w-400 w-full 
                  outline-none focus:outline-none ${className}`}
                onClick={(e) => e.stopPropagation()}
              >
                {isConnecting &&
                  <div className="w-500 h-200 flex justify-center items-center">
                    <HourGlass bgColor="#1c2532" />
                  </div>
                }
                {!isConnecting &&
                  <div className="flex flex-col">
                    {/*header*/}
                    <h3 className="text-3xl font-semibold">
                      {title}
                    </h3>
                      
                    {/*body*/}
                    <div 
                      ref={scrollRef}
                      className="h-200 transcrit-auto-scroll overflow-y-scroll"
                    >
                      {(transcript ?? []).map((item, idx) => (
                        <div 
                          key={idx}
                          className="text-white z-6000 bg-gray-800 w-500 h-auto p-2 rounded-b-lg"
                        >
                          {item?.speaker ?? ''}
                          {item?.message ?? ''}
                        </div>
                      ))}
                      
                    </div>
                  </div>
                }
                <div className="flex flex-col items-between">  
                  <button
                    className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={handleClose}
                  >
                    <CloseIcon className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none cursor-pointer hover:text-red-400" />
                  </button>
                  <Tooltip
                    title={isAutoScroll ? "Pause Auto Scroll" : "Auto Scroll"}
                    side="top"
                  >
                    <button
                      className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setIsAutoScroll(!isAutoScroll)}
                    >
                      {isAutoScroll &&
                        <PauseCircleIcon className="w-6 h-6 text-white text-2xl block outline-none focus:outline-none cursor-pointer hover:text-red-400" />
                      }
                      {!isAutoScroll &&
                        <PlayCircleIcon className="w-6 h-6 text-white text-2xl block outline-none focus:outline-none cursor-pointer hover:text-red-400" />
                      }
                    </button>
                  </Tooltip>
                </div>
              </div>
            </Draggable>
					</>
				) : null}
			</>
		);
}

export default TranscriptModal;
