import React, { useMemo, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FirebaseContext } from "../helpers/contexts/firebaseContext";
import { useDocumentQuery } from "../helpers/hooks/useFirebaseDocument";
import { ReactComponent as CheckedCircleIcon } from "../icons/check-circle.svg";
import { ReactComponent as LockClosedIcon } from "../icons/lock-closed.svg";
// import ccLogo from "../image/CC_logo.png";
import ccLogo from "../image/Voodoo Cast_logo_dark mode.svg";
import { getFirestore, collection, doc, updateDoc, setDoc, getDoc } from "@firebase/firestore";
import { updateProfile } from "@firebase/auth";

const FormField = ({
  fieldName,
  fieldLabel,
  register
}) => {
  return (
    <div className="mb-4">
      <label
        htmlFor={fieldName}
        className="block text-sm font-medium text-gray-100"
      >
        {fieldLabel}
      </label>
      <div className="mt-1">
        <input
          {...register(fieldName, { required: true })}
          id={fieldName}
          name={fieldName}
          type="text"
          placeholder={fieldLabel}
          required
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm"
        />
      </div>
    </div>
  )
};

const RequestToJoin = ({ user, auth }) => {
  const { register, handleSubmit } = useForm();
  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);
  const { conferenceId } = useParams();

  const confRef = doc(collection(db, "conferences"), conferenceId ?? "defaultConference");

  const [confData, confDataLoading, confDataError] = useDocumentQuery(confRef);

  const participantsRef = collection(confRef, "participants");

  const [partData] = useDocumentQuery(doc(participantsRef, user?.uid ?? "defaultLayer"));

  const [submitted, setSubmitted] = useState(false);

  const onRequest = async ({ 
    name = "",
    firstName = "",
    lastName = "",
    title = "",
    company = "",
    fieldOne = "",
    fieldTwo = "",
    fieldThree = "" 
  }) => {
    setSubmitted(true);
    if ((!partData || partData?.actionTime === 0) && firstName !== '') {
      updateProfile(user, { 
        displayName: `${firstName} ${lastName}`,
      });

      window.localStorage.setItem('guest_firstName', firstName);
      window.localStorage.setItem('guest_lastName', lastName);
      window.localStorage.setItem('guest_title', title);
      window.localStorage.setItem('guest_company', company);
      window.localStorage.setItem('guest_fieldOne', fieldOne);
      window.localStorage.setItem('guest_fieldTwo', fieldTwo);
      window.localStorage.setItem('guest_fieldThree', fieldThree);

      setDoc(doc(participantsRef, user?.uid), {
        id: user?.uid,
        name: `${firstName} ${lastName}`,
        firstName: firstName,
        lastName: lastName,
        title,
        company,
        fieldOne,
        fieldTwo,
        fieldThree,
        created: new Date(),
        canJoin: false,
        deny: false,
        actionTime: new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000,
      })
    }
    if ((!partData || partData?.actionTime === 0) && name !== '') {
      updateProfile(user, { 
        displayName: name
      });
      
      const firstName = window.localStorage.getItem('guest_firstName');
      const lastName = window.localStorage.getItem('guest_lastName');
      const title = window.localStorage.getItem('guest_title');
      const company = window.localStorage.getItem('guest_company');
      const fieldOne = window.localStorage.getItem('guest_fieldOne');
      const fieldTwo = window.localStorage.getItem('guest_fieldTwo');
      const fieldThree = window.localStorage.getItem('guest_fieldThree');

      const snap = await getDoc(doc(participantsRef, user?.uid));
      if (snap.exists()) {
        updateDoc(doc(participantsRef, user?.uid), {
          id: user?.uid,
          name,
          created: new Date(),
          canJoin: false,
          deny: false,
          actionTime: new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000,
          firstName,
          lastName,
          title,
          company,
          fieldOne,
          fieldTwo,
          fieldThree
        })
      } else {
        setDoc(doc(participantsRef, user?.uid), {
          id: user?.uid,
          name,
          created: new Date(),
          canJoin: false,
          deny: false,
          actionTime: new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000,
          firstName,
          lastName,
          title,
          company,
          fieldOne,
          fieldTwo,
          fieldThree
        })
      }
    }
  };

  if (confDataLoading) return null;

  if (confDataError) {
    // console.log(confDataError);
  }

  if (confData?.locked || partData?.deny) {
    return (
      <div className="shape-bg h-full w-full flex flex-col items-center justify-center">
        <Helmet>
          <title>Room Locked</title>
        </Helmet>
        <div className="bg-white rounded-lg shadow-md p-2 w-96 flex flex-col justify-center items-center gap-2">
          <LockClosedIcon className="w-24 h-24 text-red-600 mr-4" />
          <h2 className="text-xl font-medium">This conference is locked.</h2>
        </div>
      </div>
    );
  }

  if (submitted || (partData?.canJoin === false && partData?.actionTime > 0)) {
    return (
      <div className="shape-bg h-full w-full flex flex-col items-center justify-center">
        <Helmet>
          <title>Request to Join</title>
        </Helmet>
        <div className="bg-white rounded-lg shadow-md p-2 w-96 flex flex-col justify-center items-center gap-2">
          <div className="bg-gray-50 rounded-lg w-full flex flex-col justify-center items-center mb-4 p-2">
            <CheckedCircleIcon className="w-24 h-24 text-gray-700 mr-4" />
            <h2 className="text-xl font-medium">Request Submitted</h2>
          </div>
          <p>Waiting for host to accept...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="shape-bg h-full w-full flex flex-col items-center justify-center">
      <Helmet>
        <title>Request to Join</title>
      </Helmet>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-32 w-auto"
          src={ccLogo}
          alt="Conference Cloud Logo"
        ></img>
      </div>
      <div className="bg-gray-500 rounded-lg shadow-md p-2 w-96 mt-2">
        <h2 className="mb-6 text-center text-3xl font-extrabold text-white">
          Request to Join
        </h2>
        {!user?.displayName || user?.displayName === '' ? (
          <form onSubmit={handleSubmit(onRequest)}>
            <div>
              <FormField
                fieldName="firstName"
                fieldLabel="First Name"
                register={register}
              />
              <FormField
                fieldName="lastName"
                fieldLabel="Last Name"
                register={register}
              />
              <FormField
                fieldName="title"
                fieldLabel="Title"
                register={register}
              />
              <FormField
                fieldName="company"
                fieldLabel="Company"
                register={register}
              />
              <FormField
                fieldName="fieldOne"
                fieldLabel="Field One"
                register={register}
              />
              <FormField
                fieldName="fieldTwo"
                fieldLabel="Field Two"
                register={register}
              />
              <FormField
                fieldName="fieldThree"
                fieldLabel="Field Three"
                register={register}
              />
            </div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              Request Access
            </button>
          </form>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <p className="mb-4 text-lg text-gray-100">
              Hi, {user?.displayName}.
              <span
                className="text-sm text-blue-400 ml-2 cursor-pointer hover:text-blue-500"
                onClick={() => auth.signOut()}
              >
                (Not you?)
              </span>
            </p>
            <button
              type="submit"
              onClick={() => onRequest({name: user?.displayName})}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              Request Access
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestToJoin;
