import { useState, useMemo, useContext, useEffect } from "react";
import { ReactComponent as PlayIcon } from "../../icons/play1.svg";
import { ReactComponent as PauseIcon } from "../../icons/media_pause.svg";
import { usePlaylist } from "../../components/hooks/usePlaylist";
import { HMSPlaylistType } from "@100mslive/react-sdk";
import { timeFormat } from "../../utils/timeFormat";
import {
  selectAudioPlaylist,
  selectVideoPlaylist,
  selectPeerSharingVideoPlaylist,
  useHMSStore
} from "@100mslive/react-sdk";
import { Checkbox, Input, Flex, Label, Button } from "@100mslive/react-ui";
import { CheckIcon } from "@100mslive/react-icons";
import { PlaylistContext } from "../../helpers/contexts/playlistContext";
import UploadingSpinner from "../../components/uploadingSpinner";
import {
  HMSNotificationTypes,
  useHMSNotifications,
} from "@100mslive/react-sdk";
import { RefreshIcon } from "@100mslive/react-icons";
import SortBy from "./sortBy";

const Playlist = () => {
  const [isFullList, setIsFullList] = useState(true);
  const { active: activeVideo, list: videoPlaylist, actions: videoActions } = usePlaylist(HMSPlaylistType.video);
  const { active: activeAudio, list: audioPlaylist, actions: audioActions } = usePlaylist(HMSPlaylistType.audio);

  const peerSharingPlaylist = useHMSStore(selectPeerSharingVideoPlaylist);
  const [clicked, setClicked] = useState(false);
  const endedNotification = useHMSNotifications(HMSNotificationTypes.PLAYLIST_TRACK_ENDED);

  const [sortBy, setSortBy] = useState({});

  const { 
    playlist: medias,
    setPlaylist,
    activeType,
    setActiveType,
    isAutoplayOn,
    setIsAutoplayOn,
    activeMedia,
    setActiveMedia,
    autoLoadingId,
    setAutoLoadingId,
    refresh,
  } = useContext(PlaylistContext);

  const _activeMedia = useMemo(() => {
    let temp = JSON.parse(JSON.stringify(medias?.find(media => media.id === activeMedia?.id) ?? {}));
    if (temp?.type === 'video') {
      temp.playing = videoPlaylist?.find(media => media.id === temp?.id)?.playing;
    }
    if (temp?.type === 'audio') {
      temp.playing = audioPlaylist?.find(media => media.id === temp?.id)?.playing;
    }
    return temp;
  }, [activeMedia, medias, videoPlaylist, audioPlaylist]);

  useEffect(() => {
    if (medias?.some(media => media.id === clicked && media.playing)) {
      setClicked(false);
    }
  }, [medias, clicked]);

  const checkIfLoading = (id) => {
    if (id === autoLoadingId || (clicked === id && (!_activeMedia?.playing || !peerSharingPlaylist)))
      return true;
    return false;
  }

  useEffect(() => {
    if (_activeMedia?.playing) {
      // setClicked(false);
      setAutoLoadingId(false);
    }
  }, [_activeMedia, setAutoLoadingId]);

  const audioProgress = useHMSStore(selectAudioPlaylist.progress);
  const videoProgress = useHMSStore(selectVideoPlaylist.progress);

  const [draggingItem, setDraggingItem] = useState();
  const [draggingOver, setDraggingOver] = useState();

  const handleDraggingStart = (e, id) => {
    setDraggingItem(id);
  };

  const handleDraggingEnter = (e, id) => {
    setDraggingOver(id);
  };

  const handleDraggingEnd = (e, id) => {
    let draggingItemIdx, draggingOverIdx;
    if (draggingItem === draggingOver) return;
    setSortBy();
    for (let i = 0; i < medias?.length; i++) {
      if (medias?.[i].id === draggingItem) {
        draggingItemIdx = i;
      }
      if (medias?.[i].id === draggingOver) {
        draggingOverIdx = i;
      }
    }

    if (draggingOver === 'empty') {
      draggingOverIdx = medias?.length;
    }

    let firstList = medias?.slice(0, Math.min(draggingItemIdx, draggingOverIdx));
    let middleList = medias?.slice(Math.min(draggingItemIdx, draggingOverIdx) + 1, Math.max(draggingItemIdx, draggingOverIdx));
    let lastList = medias?.slice(Math.max(draggingItemIdx, draggingOverIdx) + 1, medias?.length);
    
    let inserting = [medias?.find(media=>media.id === draggingItem)];
    if (draggingOver !== 'empty') {
      inserting.push(medias?.find(media=>media.id === draggingOver));
    }

    if (draggingItemIdx < draggingOverIdx) {
      setPlaylist([
        ...firstList,
        ...middleList,
        ...inserting,
        ...lastList
      ]);
    } else {
      setPlaylist([
        ...firstList,
        ...inserting,
        ...middleList,
        ...lastList
      ]);
    }
    setDraggingItem();
    setDraggingOver();
  };

  const handleRemoveItem = (media) => {
    if (media.type === 'video') {
      videoActions.removeItem(media.id).then(data => {
        videoActions.removeItem(media.id);
        setPlaylist(medias?.filter(_media => _media.id !== media.id));
      })
    } else {
      audioActions.removeItem(media.id).then(data => {
        setPlaylist(medias?.filter(_media => _media.id !== media.id));
      })
    }
  }

  const handleRefresh = (e) => {
    setClicked(false);
    setActiveMedia();
    refresh();
  }

  return (
    <div className="mt-3 overflow-y-flow">
      <Flex align="center" justify="between" css={{w: '100%'}}>
        <Flex align="center" css={{ w: "auto", my: "$1" }}>
          <Checkbox.Root
            checked={isAutoplayOn}
            onCheckedChange={value => setIsAutoplayOn(value)}
            id="fileupload"
          >
            <Checkbox.Indicator>
              <CheckIcon width={16} height={16} />
            </Checkbox.Indicator>
          </Checkbox.Root>
          <Label
            htmlFor="fileupload"
            css={{ cursor: "pointer", ml: "$2" }}
          >
            Autoplay
          </Label>
        </Flex>
        <div
          className="flex text-white hover:text-yellow-500 cursor-pointer"
          onClick={handleRefresh}
        >
          <RefreshIcon
            className="w-6 h-6 mr-1"
          />
          Refresh
        </div>
        {medias?.length > 0 && 
          <Label
            css={{wordBreak: "unset"}}
            className="cursor-pointer"
            onClick={() => setIsFullList(!isFullList)}
          >
            {isFullList ? 'Hide Playlist' : 'View Playlist'}
          </Label>
        }
      </Flex>
      <SortBy
        sortBy={sortBy}
        setSortBy={setSortBy}
        playlist={medias}
        setPlaylist={setPlaylist}
      />
      {(!isFullList || !medias?.length) &&
        <div className="flex border-2 border-purple-500 rounded-lg p-1 mt-2">
          {!_activeMedia?.playing && 
            <PlayIcon 
              className="w-12 h-12 cursor-pointer text-white"
              onClick={() => {
                if (activeMedia?.type === HMSPlaylistType.video) {
                  videoActions.play(activeMedia?.id);
                  setActiveType('video');
                } else {
                  audioActions.play(activeMedia?.id);
                  setActiveType('audio');
                }
              }}
            />
          }
          {_activeMedia?.playing && 
            <PauseIcon 
              className="w-12 h-12 cursor-pointer text-white"
              onClick={() => {
                if (activeMedia?.type === HMSPlaylistType.video) {
                  videoActions.pause();
                } else {
                  audioActions.pause();
                }
              }}
            />
          }
          <div className="flex-grow flex justify-between items-center ml-2 text-white">
            <div className="flex flex-col">
              <span>
                {activeMedia?.name?.slice(0, 15)}{activeMedia?.name?.length > 15 ? '...' : ''}
              </span>
              <span>
                {timeFormat(activeMedia?.duration * (100 - (activeMedia?.type === HMSPlaylistType.video ? videoProgress : audioProgress)) / 100)}
              </span>
            </div>
            <span 
              className="w-24 rounded text-center cursor-pointer hover:bg-purple-500"
              onClick={() => setIsFullList(!isFullList)}
            >
              {isFullList ? 'Hide Playlist' : 'View Playlist'}
            </span>
          </div>
        </div>
      }
      {isFullList && medias?.length > 0 && 
        <div className="relative rounded-lg border-2 border-purple-500 p-2">
          {medias?.map((media, idx) => (
            <div
              key={media.id}
              className={`
                cursor-move flex p-1 w-full justify-between 
                ${idx !== medias?.length - 1 ? 'border-b border-purple-500 mb-1' : ''}
                ${activeMedia?.id === media?.id ? 'bg-purple-400 rounded' : ''}
                ${draggingItem === media?.id ? 'bg-purple-600 opacity-full' : ''}
                ${draggingOver === media?.id ? 'bg-purple-400 border-2 rounded' : ''}
              `}
              onDragStart={(e) => handleDraggingStart(e, media?.id)}
              onDragEnter={(e) => handleDraggingEnter(e, media?.id)}
              onDragEnd={(e) => handleDraggingEnd(e, media?.id)}
              draggable
            >
              <div className="relative w-14 h-12 flex justify-center items-center">
                <video
                  className="bg-black absolute w-14 h-12 rounded-lg left-0 top-0"
                  autoPlay={false}
                  playsInline={false}
                  controls={false}
                  src={media?.url}
                  muted={true}
                  loop={true}
                  onMouseEnter={(e) => {
                    e.target.play();
                  }}

                  onMouseLeave={e => {
                    e.target.pause();
                  }}
                  onTimeUpdate={(e) => {
                    // let playBack = 10;
                    // if (playBack > e.target.duration / 3 * 2) {
                    //   playBack = e.target.duration / 3 * 2;
                    // }
                    // if (e.target.currentTime > playBack) {
                    //   e.target.currentTime = 0;
                    // }
                  }}
                ></video>
                {(!media?.playing || (media.id === _activeMedia?.id && !_activeMedia?.playing)) && !checkIfLoading(media?.id) &&
                  <PlayIcon 
                    className={`z-50 w-6 h-6 cursor-pointer ${media?.url?.slice(0, 4) === 'http' ? 'text-white' : 'text-gray-400'}`}
                    onClick={() => {
                      if (clicked) return;
                      if (media?.url.slice(0, 4) !== 'http') return;
                      if (media?.type === HMSPlaylistType.video) {
                        videoActions.play(media?.id);
                        setActiveType('video');
                        try {
                          audioActions?.stop();
                        } catch (err) { 
                          // console.log(err) 
                        }
                      } else {
                        audioActions.play(media?.id);
                        setActiveType('audio');
                        try {
                          videoActions?.stop();
                        } catch (err) { 
                          // console.log(err) 
                        }
                      }
                      setActiveMedia(media);
                      setClicked(media?.id);
                    }}
                  />
                }
                {media?.playing && !checkIfLoading(media?.id) &&
                  <PauseIcon 
                    className="z-50 w-6 h-6 cursor-pointer text-white"
                    onClick={() => {
                      if (media?.type === HMSPlaylistType.video) {
                        videoActions.pause();
                      } else {
                        audioActions.pause();
                      }
                    }}
                  />
                }
                {checkIfLoading(media?.id) && <UploadingSpinner className="z-50 w-6 h-6"/>}
              </div>
              
              <div className="flex justify-between items-center ml-2 text-white" style={{width: 'calc(100% - 60px)'}}>
                <div className="flex flex-col overflow-x-hidden" style={{width: 'calc(100% - 100px)'}}>
                  <span>
                    {media?.name?.toString()?.slice(0, 14)}{media?.name?.toString()?.length > 14 ? '...' : ''}
                  </span>
                  <span>
                    {
                    timeFormat(
                      media?.duration * (100 - 
                        (media.id === _activeMedia?.id 
                          ? (_activeMedia?.type === HMSPlaylistType.video 
                            ? videoProgress 
                            : audioProgress)
                          : 0
                        )) / 100)
                    }
                  </span>
                </div>
                <span 
                  className="flex flex-col"
                >
                  <span>
                    {media?.url?.slice(0, 4) === 'http' ? '' : 'uploading...'}
                  </span>
                  <span
                    onClick={() => setIsFullList(!isFullList)}
                    className="w-24 rounded text-center cursor-pointer hover:bg-purple-500"
                  >
                    {isFullList ? 'Hide Playlist' : 'View Playlist'}
                  </span>
                  <span 
                    className="w-24 rounded text-center cursor-pointer hover:bg-purple-500"
                    onClick={() => {
                      handleRemoveItem(media)
                    }}
                  >
                    Remove
                  </span>
                </span>
              </div>
            </div>
          ))}
          <div
            className={`${draggingOver === 'empty' ? 'bg-purple-400 border-2 rounded h-6' : 'h-3'} w-full`}
            onDragEnter={(e) => handleDraggingEnter(e, 'empty')}
            onDragEnd={(e) => handleDraggingEnd(e, 'empty')}
            draggable
          ></div>
        </div>
      }
    </div>
  )
};

export default Playlist;
