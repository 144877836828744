import React from 'react';
import { useFirebaseAuthState } from "../hooks/useAuth";
import { useHost } from "../hooks/useHost";
import { createContext } from "react";
import { getFirestore, collection, doc, getDoc, setDoc, onSnapshot } from "firebase/firestore";
import { getDatabase, ref as rRef, onValue } from "@firebase/database";
import { FirebaseContext } from './firebaseContext';

export const AuthContext = createContext({});

const UserSessionProvider = ({ children, loader = <div className="loader">&nbsp;</div> }) => {
  const [_user, loading, error, auth] = useFirebaseAuthState();
  const [hostStatus, hostLavel, setHost, initHost, setMainHost, toggleHost] = useHost();
  const [user, setUser] = React.useState();
  const [conferenceId, setConferenceId] = React.useState('');
  const { firebaseApp } = React.useContext(FirebaseContext);
  const db = getFirestore(firebaseApp);

  const rdb = React.useMemo(() => getDatabase(firebaseApp), [firebaseApp]);
  const dbRef = React.useMemo(() => rRef(rdb, `${conferenceId ?? 'defaultConferenceId'}/status`)
    , [rdb, conferenceId]);

  const readFromDB = React.useCallback(async () => {
    if (_user && _user?.isAnonymous && conferenceId) {
      const snapshot = await getDoc(doc(db, "conferences", conferenceId, "participants", _user?.uid ?? "defaultUser"));
      const userData = snapshot.data();

      if (userData) {
        const preValue = _user ? JSON.stringify(_user) : _user;
        const curValue = JSON.stringify({
          uid: _user?.uid,
          isAnonymous: true,
          displayName: _user?.displayName ?? '',
          ...JSON.parse(JSON.stringify(userData))
        });

        if (preValue !== curValue) {
          setUser({
            uid: _user?.uid,
            isAnonymous: true,
            displayName: _user?.displayName ?? '',
            ...JSON.parse(JSON.stringify(userData))
          });
        }
      } else {
        setUser(_user);
      }
    } else {
      setUser(_user);
    }
  }, [db, conferenceId, _user]);

  React.useEffect(() => {
    readFromDB();
  }, [readFromDB]);

  React.useEffect(() => {
    onValue(dbRef, async (data) => {
      let temp = {};
      for (let key in data.val()) {
        if (key === undefined || key === null) continue;
        temp[key] = !!(data.val()[key].host || data.val()[key].mainHost);
      }
      await setHost(temp);
    });
    
    return () => {

    }
  }, [dbRef, setHost]);

  if (loading) {
    return loader;
  }

  if (error) {
    // console.error(error);

    throw error; // catch this with an ErrorBoundary
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        error,
        auth,
        hostStatus,
        hostLavel,
        setHost,
        initHost,
        setMainHost,
        toggleHost,
        setConferenceId
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default UserSessionProvider;
