import React, { useEffect, useMemo, useState, useContext, useCallback } from "react";
// import { default as fb } from "firebase";
import { getDatabase, ref as rRef, onValue, update, onDisconnect, serverTimestamp } from "firebase/database";
import { FirebaseContext } from '../contexts/firebaseContext';
import { compareObject } from "../../utils/compareObject.js";

export const useSelfPresence = ({ confId, user, roomType }) => {
  const { firebaseApp } = useContext(FirebaseContext);
  const rdb = useMemo(() => getDatabase(firebaseApp), [firebaseApp]);
  const [ref, setRef] = useState();

  const online = useMemo(() => {
    return {
      // modified: fb.database.ServerValue.TIMESTAMP,
      modified: serverTimestamp(),
      name: user?.displayName || user?.email || "",
      live: roomType === 'discussion' ? true : false,
      editingLive: false,
      muted: false,
    }
  }, [user?.displayName, user?.email, roomType]);

  useEffect(() => {
    setRef(rRef(rdb, `${confId}/status/${user?.uid}`));
  }, [rdb, confId, user?.uid]);

  useEffect(() => {
    if (ref) {
      // when a user disconnects, remove their record
      onDisconnect(ref, () => {
        try {
          update(ref, null);
        } catch (err) {
          // console.log(err);
        }
      })

      // when they connect, make their record
      update(ref, online);
    }

    return () => {
      if (ref) {
        try {
          update(ref, null);
        } catch (err) {
          // console.log(err);
        }
      }
    };
  }, [ref, online]);
};

/**
 * Published the users own presence and gathers all users from the realtime
 * database that are associated with the conference.
 *
 * @param connectionProperties
 * @param connectionProperties.firebase The firebase instance to use
 * @param connectionProperties.confId The conference to apply presence to
 * @param user A firebase user instance
 */
export const usePresence = ({ confId, user }) => {
  const { firebaseApp } = useContext(FirebaseContext);
  const rdb = useMemo(() => getDatabase(firebaseApp), [firebaseApp]);
  const ref = rRef(rdb, `${confId}/status`);

  const [users, setUsers] = useState({});

  // updates the state on value changes
  const handleValues = useCallback((snapshot) => {
    if (snapshot.val() === false) {
      setUsers({});
    }

    const preVal = JSON.stringify(users);
    const curVal = JSON.stringify(snapshot.val());

    // if (preVal !== curVal) setUsers(snapshot.val());
    if (!compareObject(users, snapshot.val())) setUsers(snapshot.val());
  }, [users]);

  useEffect(() => {
    onValue(ref, handleValues);
  }, [ref, handleValues]);

  return users;
};
