import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../button";
import InputField from "../forms/inputField";
import Modal from "../modal";

const LoadLayoutModal = ({ isOpen, onClose, layouts, onSubmit }) => {
  const [layout, setLayout] = useState();

	const handleSubmit = (e) => {
		if (!layout) return;
		onSubmit({id: layout.id})
	};

  return (
    <Modal
			isOpen={isOpen}
			closeModal={onClose}
			className="w-1/4"
			isHeader={false}
			isFooter={false}
		>
      <div className="custom-bg-1 text-white w-full p-2 rounded-t-md border-b border-gray-200 flex items-center">
        <h2 className="font-medium text-xl text-white">Load Layout</h2>
      </div>
			<div className="p-2 custom-bg-2 text-white">
				<ul className="border-solid border-2 p-2 rounded-md bg-white text-black">
					{layouts && layouts.map((_layout, idx) => (
						<li
							key={idx}
							className="hover:bg-blue-200 pl-1 cursor-pointer"
							onClick={() => setLayout(_layout)}
						>
							{_layout.name}
						</li>
					))}
				</ul>
			</div>
			<div className="p-2 custom-bg-2 text-white flex justify-between rounded-b">
				<div>
					<label className="font-bold">Selected Layout:</label>
					<label className="ml-2">{layout?.name}</label>
				</div>
				<Button className="rounded" type="submit" onClick={handleSubmit}>Load</Button>
			</div>
    </Modal>
  );
};

export default LoadLayoutModal;
