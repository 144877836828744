import React, { useState,
	useEffect,
	useRef,
	useContext,
	useMemo,
} from "react";
import Button from "../components/button";
import Modal from "../components/modal";
import { ReactComponent as XCircleIcon } from "../icons/x-circle.svg";
import DeviceSelection from "../components/rtc/deviceSelectionV";
import { LocalTracksContext } from "../helpers/contexts/localTracksContext";
import { ReactComponent as CameraIcon } from "../icons/camera.svg";
import { useVideoInputDevices } from "../helpers/hooks/useMediaDevices";
import { useConferenceCall } from "../helpers/hooks/useConferenceCall";
import Video from "../components/rtc/video";
import VideoByTrack from "../components/rtc/videoByTrack";
import { 
  useDevices,
  DeviceType
} from "@100mslive/react-sdk";
import { useVirtualBackground } from "../helpers/hooks/useVirtualBackground";

const VirtualBackgroundModal = ({
  isOpen,
  onCancel,
  onOk,
	virtualBackgrounds,
	deleteBackground,
	addBackground,
}) => {
	const [uploadingImage, setUploadingImage] = useState();
	const [loading, setLoading] = useState(false);
	const [currentImage, setCurrentImage] = useState();
	const [isLoadPreview, setIsLoadPreview] = useState();
	const checkbox = useRef();
	const [errorMsg, setErrorMsg] = useState("");
	const [isCameraOption, setIsCameraOption] = useState(false);
  const [_videoDeviceId, setVideoDeviceId] = useState();
  const [videoDevices, videoInputDevicesLoading] = useVideoInputDevices();
	const { localParticipant } = useConferenceCall();

	const {  updateDevice } = useDevices();

	const { addPlugin, removePlugin } = useVirtualBackground({ virtualBackgrounds });

	const videoDeviceId = useMemo(() => _videoDeviceId ?? videoDevices?.[0]?.deviceId, [_videoDeviceId, videoDevices]);

	const currentImageRef = useRef();
	const videoDeviceIdRef = useRef();

	const handleBGChange = (image) => {
		if (videoDeviceId === 'none') return;
		if (!videoDevices || videoDevices?.length === 0) return;
		if (!isOpen) return;

		setErrorMsg('');
		const updatePreview = async () => {
			setIsLoadPreview(true);
			try {
				await addPlugin(image);
			} catch (err) {
				// console.log(err);
				setErrorMsg('Something Error, please try again.');
				return;
			}
			setCurrentImage(image)
			setIsLoadPreview(false);
		};

		updatePreview();

		if (image) {
			if (checkbox.current) checkbox.current.checked = false;
		}
	};

	const uploadNewImage = async () => {
		setLoading(true);
		if (uploadingImage) await addBackground({img: uploadingImage});
		setUploadingImage();
		setLoading(false);
	};

 	const handleChangeVideoDevice = (id) => {
    setVideoDeviceId(id);

		updateDevice({
			deviceId: id,
			deviceType: DeviceType.videoInput
		})

		if (document.getElementById(`virtual_preview_${id}`))
			document.getElementById(`virtual_preview_${id}`).srcObject = null;
  };
	
	const handleNoneChange = (e) => {
		if (e.target.checked) {
			removePlugin();
			setCurrentImage();
		}
	};

	const handleOnOk = () => {
		onOk(currentImage);
		currentImageRef.current = currentImage;
		videoDeviceIdRef.current = videoDeviceId;
	}

	const handleOnCancel = async (e) => {
		await removePlugin();
		if (currentImageRef.current) {
			try {
				await addPlugin(currentImageRef.current);
			} catch (err) {
				// console.err(err);
			}
		}
		if (videoDeviceIdRef.current) {
			updateDevice({
				deviceId: videoDeviceIdRef.current,
				deviceType: DeviceType.videoInput
			})
		}
		onCancel();
	}

  return (
    <Modal isOpen={isOpen} closeModal={onCancel} isHeader={false} isFooter={false} className="w-1/2 -mt-32 flex flex-col h-4/6">
      <div className="custom-bg-1 w-full p-2 rounded-t-md flex items-center">
        <h2 className="font-medium text-xl text-gray-100">
          Virtual Background Settings
        </h2>
      </div>
			<div className="w-full p-2 flex flex-wrap custom-bg-2 px-4">
				<h2 className="font-medium text-lg text-gray-100 mr-5">
					Add New Image
				</h2>
					<div className="flex items-center">
						<div className="h-6 border-l border-gray-300 mr-2"></div>
						<input
							id="img"
							name="img"
							type="file"
							className="w-60 rounded custom-bg-2 text-gray-100 mr-1"
							onChange={(event) => setUploadingImage(event.target.files)}
						/>
						<Button className="p-2 bg-gray-500 text-gray-100 hover:bg-gray-300" onClick={uploadNewImage}>Upload</Button>
					</div>
					
					<div className="flex items-center">
						<div className="h-6 border-l border-gray-300 ml-4"></div>
						<Button
							className="p-1 mx-2"
							onClick={() => {
								setIsCameraOption(!isCameraOption);
							}}>
							<CameraIcon className="w-6 h-6" />
						</Button>
						<span className="font-bold text-md text-gray-100">current cam:</span>
						<span className="mx-2 italic text-md text-gray-100">{videoDevices?.filter(_=>_.deviceId === videoDeviceId)?.[0]?.label}</span>
					</div>
			</div>
			{errorMsg && <div className="text-red mt-2 flex items-center px-5">
				<p className="text-red-700 text-center">{errorMsg}</p>
			</div>}
			<div className="px-5 pb-2 flex flex-col relative custom-bg-2">
				<div className="w-full h-full absolute top-0 left-0 px-3">
					<DeviceSelection 
						isOpen={isCameraOption}
						message={errorMsg}
						setMessage={setErrorMsg}
						videoDeviceId={videoDeviceId}
						loading={videoInputDevicesLoading}
						videoDevices={videoDevices}
						changeVideoDevice={handleChangeVideoDevice}
						setError={setErrorMsg}
						onClose={() => setIsCameraOption(false)}
					/>
				</div>
			</div>
			
			<div className="grow-1 flex flex-wrap border-t border-gray-500 scrollbar-1 relative p-5 overflow-y-scroll custom-bg-2" style={{flexGrow: 2}}>
				{loading && <div className="absolute w-full h-full bg-gray-700 opacity-80 top-0 left-0 flex justify-center items-center">
						...uploading
					</div>}
				<div className="p-2 border border-gray-500 bg-white rounded m-2 h-10 w-32 h-24">
					<div className="flex items-center">
						<input className="mr-1 w-4 h-4" ref={checkbox} type="checkbox" onChange={handleNoneChange} /> None
					</div>
				</div>
				
				{virtualBackgrounds && virtualBackgrounds.filter(_b => _b.userId === 'default').map(b => (
					<div className="relative mr-2" key={b.id}>
						<img
							onClick={() => handleBGChange(b.url)}
							className={`w-32 h-24 cursor-pointer m-2 rounded border ${b.url === currentImage ? "border-red-400" : 'border-gray-500'}`}
							src={b.url}
							alt="virtual background"
						/>
					</div>
				))}
				{virtualBackgrounds && virtualBackgrounds.filter(_b => _b.userId !== 'default').map(b => (
					<div className="relative mr-2" key={b.id}>
						<img
							onClick={() => handleBGChange(b.url)}
							className={`w-32 h-24 cursor-pointer m-2 rounded border ${b.url === currentImage ? "border-red-400" : 'border-gray-500'}`}
							src={b.url}
							alt="virtual background"
						/>
						 <button
							className="absolute bg-white rounded-full shadow-lg -top-1 -right-2 text-yellow-600 hover:text-yellow-500"
							onClick={() => deleteBackground(b.id)}
						>
							<XCircleIcon className="w-6 h-6" />
						</button>
					</div>
				))}
			</div>
			
      <div className="flex flex-end justify-between w-full p-2 pb-4 rounded-b border-t border-gray-500 custom-bg-2">
				<div>
					{!videoDevices || videoDevices?.length === 0 ? "No Preview" : "Preview"}
					<div className="flex">
						{videoDevices?.map((v, idx) => (
							<div
								key={idx}
								className={`relative w-36 h-28 p-0 mr-1 ${v?.deviceId === videoDeviceId ? "border border-red-400 rounded-lg" : "" }`}
							>
								<span className="absolute top-0 left-0 z-4000 text-white">{v?.label?.split(" ")?.[0]}</span>
								{v.deviceId === videoDeviceId &&
									<Video
										peer={localParticipant}
										muted
										autoPlay
										className="rounded-md shadow"
									/>
								}
								{/* <video
									id={`virtual_preview_${v.deviceId}`}
									className={`w-full h-full border rounded relative object-fill ${v.deviceId === videoDeviceId ? "hidden" : ""}`}
									alt="Preview"
								></video> */}
								{v.deviceId !== videoDeviceId &&
									<VideoByTrack
										deviceId={v.deviceId}
										muted
										autoPlay
										className="rounded-md shadow"
									/>
								}
								{isLoadPreview && <div className="absolute w-full h-full top-0 left-0 z-3000 border rounded flex items-center justify-center bg-gray-400">...Loading</div>}
							</div>
						))}
					</div>
				</div>
				<div className="flex items-end">
					<Button
						className="p-2 w-20 mr-2 bg-gray-500 text-gray-100"
						onClick={async () => {
							handleOnOk();
						}}
					>
						OK
					</Button>
					<Button
						className="p-2 w-20"
						onClick={handleOnCancel}
					> Cancel </Button>
				</div>
      </div>
    </Modal>
  );
};

export default VirtualBackgroundModal;
