import React from "react";
import Modal from "../../components/modal";
import { AuthContext } from "../../helpers/contexts/userSessionContext";
import { ReactComponent as ChevronDown } from "../../icons/chevron-down.svg";
import { Button } from "@100mslive/react-ui";
import { InteractionContext } from "../../helpers/contexts/interactionContext";
import { CrossIcon } from "@100mslive/react-icons";

const InteractionHeader = ({
  onClose,
  isFullView,
  setIsFullView
}) => {
  return (
    <div className="bg-gray-600 rounded">
      <div 
        className="flex justify-between p-2 relative text-white"
      >
        <span className="font-bold text-lg">Admin user requests an interaction.</span>
        {/* <span
          className="text-blue-300 flex items-center cursor-pointer"
          onClick={() => setIsFullView(!isFullView)}
        >
          show more
          <ChevronDown className={`w-5 h-5 ${isFullView ? "rotate-180" : "animate-arrow-down"}  `} />
        </span> */}
        <CrossIcon 
          className="text-white w-5 h-5 border border-white rounded-full cursor-pointer" 
          onClick={() => onClose()}
        />
      </div>
    </div>
  )
};

const InteractionFooter = ({
  onClose,
  interaction,
  user,
  interact,
  deny
}) => {
  const { setIsOpenInteractionTab } = React.useContext(InteractionContext);
  const [isDismissed, setIsDismissed] = React.useState(false);
  const availableTime = 5 * 60000;
  
  React.useEffect(() => {
    const curTime = new Date().getTime();
    if (!interaction?.triggeredAt) return;
    if (curTime - interaction?.triggeredAt > availableTime) {
      setIsDismissed(true);
    } else {
      setTimeout(() => {
        setIsDismissed(true);
      }, interaction?.triggeredAt + availableTime - curTime)
    }
  }, [interaction, availableTime])

  return (
    <div className="flex justify-around mb-3">
      <Button
        css={{ h: "$11" }}
        onClick={async () => {
          // await interact({
          //   ...interaction,
          //   interactedUser: { userId: user?.uid, displayName: user?.displayName, email: user?.email },
          // });
          setIsOpenInteractionTab(false);
          setTimeout(() => setIsOpenInteractionTab(true));
          onClose();
        }}
      >Go to Interaction Tab</Button>
      {!isDismissed && <Button
        variant="standard"
        css={{ h: "$11" }}
        onClick={async () => {
          await deny({
            ...interaction,
            denied: true,
            deniedUser: { userId: user?.uid, displayName: user?.displayName ?? '', email: user?.email ?? '' },
          })
          onClose();
        }}
      >Deny</Button>}
    </div>
  )
};

const InteractionBody = ({
  interaction={}
}) => {
  return (
    <div className="flex flex-col items-center my-2">
      <p className="break-all">
        {interaction?.content?.body}
      </p>
    </div>
  )
}

const InteractionRequestChat = ({
  isOpen,
  onClose,
  interaction,
  interact=()=>{},
  deny=()=>{},
  parentWidth=0,
  parentHeight=0
}) => {
  const { user } = React.useContext(AuthContext);
  const [isFullView, setIsFullView] = React.useState(false);

  const modalStyle = React.useMemo(() => 
    !isOpen ? {
      transition: 'all 0.5s',
      left: `${parentWidth / 2 - 200}px`,
      top: '-300px',
    } : {
      left: `${parentWidth / 2 - 200}px`,
      top: '0px',
      zIndex: 100000,
      transform: `translate(0, ${parentHeight / 2 - 150}px)`,
      transition: 'transform 0.5s',
    }
  , [isOpen, parentWidth, parentHeight]);

  React.useEffect(() => {
    if (!isOpen) {
      setIsFullView(false)
    }
  }, [isOpen]);

  return (
    <>
      <div
        className="absolute top-0 right-0 z-10000 bg-gray-100 shadow w-400 flex flex-col"
        style={modalStyle}
      >
        <InteractionHeader
          onClose={onClose}
          isFullView={isFullView}
          setIsFullView={setIsFullView}
        />

        {/* {isFullView && */}
          <>
            <InteractionBody
              interaction={interaction}
            />
            <InteractionFooter
              onClose={onClose}
              interaction={interaction}
              user={user}
              interact={interact}
              deny={deny}
            />
          </>
        {/* } */}
      </div>
    </>
  )
};

export default InteractionRequestChat;
