import React from "react";
import CreateInteraction from "./createInteraction";
import { FirebaseContext } from "../../../helpers/contexts/firebaseContext";
import { getFirestore, doc, collection, setDoc, updateDoc, deleteDoc, query, where, orderBy} from "@firebase/firestore";
import { useCollectionQuery } from "../../../helpers/hooks/useFirebaseCollection";
import { nanoid } from "nanoid";
import InteractionList from "./interactionList";
import { useParams } from "react-router-dom";

const Interaction = ({
  isShow=false
}) => {
  const { user, firebaseApp } = React.useContext(FirebaseContext);
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, 'interactions');
  const [interactions, interactionsLoading] = useCollectionQuery(query(
    collectionRef, 
    where('userId', '==', user?.uid ?? 'defaultUser'),
    orderBy('createdAt', 'asc')
  ));
  const [persons, personsLoading] = useCollectionQuery(collection(doc(collection(db, 'profiles'), user?.uid ?? 'defaultUser'), 'persons'));
  const { conferenceId } = useParams();

  const filterTargets = (filterMatch) => {
    if (filterMatch?.key === 'all') {
      return persons ?? [];
    }
    let result = [];
    (persons ?? []).forEach(person => {
      if ((person?.[filterMatch?.key ?? 'xx'] ?? '')?.toLowerCase().indexOf((filterMatch?.value ?? '').toLowerCase()) >= 0) {
        result.push(person);
      }
    })
    return result;
  };

  const addInteraction = async (interaction) => {
    const id = nanoid();
    await setDoc(doc(collectionRef, id), {
      id,
      scheduledAt: '',
      userId: user?.uid,
      roomId: conferenceId,
      ...(interaction ?? {}),
      targets: filterTargets(interaction?.filterMatch ?? {}),
      createdAt: new Date().getTime()
    })
  }

  const removeInteraction = async (id) => {
    await deleteDoc(doc(collectionRef, id))
  };

  const scheduleInteraction = async (id, time) => {
    await updateDoc(doc(collectionRef, id), {
      scheduledAt: time
    })
  };

  const cancelSchedule = async (id) => {
    await updateDoc(doc(collectionRef, id), {
      scheduledAt: ''
    })
  };

  return (
    <div
      className={
        `${isShow ? "flex" : "hidden"} items-center
        flex-col absolute bg-white left-0 top-0 px-4 py-2 w-full h-full overflow-y-scroll interaction-page`
      }
    >
      <InteractionList
        interactions={interactions}
        removeInteraction={removeInteraction}
        scheduleInteraction={scheduleInteraction}
        cancelSchedule={cancelSchedule}
      />
      <CreateInteraction 
        updatingInteraction={null}
        updateInteraction={addInteraction}
      />      
    </div>
  )
};

export default Interaction;
