const compareArray = (first, second) => {
  if (first?.length !== second?.length) {
    return false;
  }
  return first.every(item => second.some(item2 => compareObject(item, item2)));
};

export const compareObject = (first, second) => {
  if (typeof(first) !== typeof(second)) {
    return false;
  }

  if (Array.isArray(first) !== Array.isArray(second)) {
    return false;
  }

  if (Array.isArray(first) === true) {
    return compareArray(first, second);
  } else if (typeof(first) === 'object') {
    if (first === null) {
      return second === null || second === undefined;
    }
    const firstKeys = Object.keys(first);
    const secondKeys = Object.keys(second);
    if (!compareArray(firstKeys, secondKeys)) {
      return false;
    }
    
    return firstKeys.every(item => compareObject(first[item], second[item]));
  } else if(typeof(first) === 'string') {
    return first === second;
  } else if (isNaN(first)) {
    return isNaN(second)
  } else {
    return first === second;
  }
};
