import { useEffect, useState } from "react";

export const useMediaDevices = () => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateDevices = async () => {
    const deviceUsable = new Promise((resolve, reject) =>
      navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(() => resolve()).catch(err => reject(err)));
    await deviceUsable.then(() => {}).catch(err => console.log(err));
    
    if (navigator.mediaDevices.getUserMedia) {
      setLoading(true);
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        setLoading(false);
        setDevices(devices);
      });
    }
  };

  useEffect(() => {
    // populate list for first load
    updateDevices();

    navigator.mediaDevices.addEventListener("devicechange", updateDevices);

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", updateDevices);
    };
  }, []);

  return [devices, loading];
};

export function useAudioInputDevices() {
  const [devices, loading] = useMediaDevices();
  return [devices.filter((device) => device.kind === "audioinput"), loading];
}

export function useVideoInputDevices() {
  const [devices, loading] = useMediaDevices();
  return [devices.filter((device) => device.kind === "videoinput"), loading];
}

export function useAudioOutputDevices() {
  const [devices, loading] = useMediaDevices();
  return [devices.filter((device) => device.kind === "audiooutput"), loading];
}
