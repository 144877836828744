import React from 'react';
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useFirebaseContext } from '../helpers/hooks/useFirebaseContext';
// import ccLogo from "../images/CC_logo.png";
import ccLogo from "../image/Voodoo Cast_logo_dark mode.svg";

const ForgotPassword = () => {
  const { register, handleSubmit } = useForm();
  const { forgotPassword, error, loading } = useFirebaseContext();
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async ({ email }) => {
    let emailSent = await forgotPassword({ email });
		setSubmitted(true);
  };

  return (
    <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 shape-bg w-full">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-32 w-auto"
          src={ccLogo}
          alt="Conference Cloud Logo"
        ></img>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
          <h2 className="mb-6 text-center text-3xl font-paytone-one text-gray-900">
            Reset Your Password
          </h2>
          {error && (
            <div className="bg-red-200 border border-red-400 text-red-700 rounded px-2 m-3">
              {error}
            </div>
          )}
          {submitted ? (
            <div className="space-y-6 text-center border border-yellow-500 bg-yellow-100 rounded text-yellow-600 p-4">
              Your password reset request was received.{" "}
            </div>
          ) : (
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    {...register('email', { required: true })}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm"
										disabled={loading}
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
									disabled={loading}
                >
                  Reset Password
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
