import { Button, Flex, Input, Tooltip } from "@100mslive/react-ui";
import {
  useRef,
  useState,
  useContext,
  useEffect,
} from "react";
import { useCollectionQuery } from "../../../helpers/hooks/useFirebaseCollection";
import { FirebaseContext } from "../../../helpers/contexts/firebaseContext";
import { getFirestore, doc, collection, setDoc, updateDoc, deleteDoc } from "@firebase/firestore";
import DropDown from "./dropDown.js";
import communicationType from "../communicationTypes.json";
import CommunicationBody from "./communicationBody.js";
import { nanoid } from "nanoid";
import axios from "axios";
import { ToastBatcher } from "../../../components/Toast/ToastBatcher";

const AddCommunication = ({
  height,
  curCommunicate,
  setCurCommunicate,
  communicates,
}) => {
  const [comName, setComName] = useState('');
  const [communicate, setCommunicate] = useState();
  const [target, setTarget] = useState();
  const [comDate, setComDate] = useState();
  const [comTime, setComTime] = useState();
  const [comBody, setComBody] = useState();
  const currentSetting = useRef();
  const stash = useRef();
  const ableEndDate = new Date(new Date().getTime() + 6 * 24 * 3600 * 1000 - 1);

  useEffect(() => {
    currentSetting.current = {
      comName, communicate, target, comDate, comTime
    }
  }, [comName, communicate, target, comDate, comTime]);

  const { user, firebaseApp } = useContext(FirebaseContext);
  const db = getFirestore(firebaseApp);
  const docRef = doc(collection(db, 'profiles'), user?.uid ?? 'defaultID');
  const [persons, personsLoading] = useCollectionQuery(collection(docRef, 'persons'));
  const [groups, groupsLoading] = useCollectionQuery(collection(docRef, 'groups'));
  const [templates, templatesLoading] = useCollectionQuery(collection(docRef, 'templates'));
  const [isScheduling, setIsScheduling] = useState(false);

  const targetings = [
    { name: 'All', targetType: '' },
    ...(groups ?? [])?.map(group => {
      return {
        ...group,
        targetType: 'group'
      }
    }),
    ...(persons ?? [])?.map(person => {
      return {
        ...person,
        name: `${person?.firstName} ${person?.lastName}`,
        targetType: 'user',
      }
    })
  ];

  const scheduleEmail = async () => {
    const axiosInstance = axios.create({
      baseURL: 'https://us-central1-voodoocast-ab022.cloudfunctions.net/',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })

    const scheduledDate = new Date(`${comDate}T${comTime}${comTime?.length === 5 ?  ':00' : ''}`);

    if (target.targetType === 'user') {
      const response = await axiosInstance.post(
        'scheduleEmail',
        {
          userId: user?.uid,
          name: comName,
          type: communicate,
          target: target,
          content: comBody,
          scheduledAt: scheduledDate.getTime() / 1000
        }
      );
      return response;
    } else if (target.targetType === 'group') {
      let result = [];
      for (let i = 0; i < target?.users?.length; i++) {
        const response = await axiosInstance.post(
          'scheduleEmail',
          {
            userId: user?.uid,
            name: comName,
            type: communicate,
            target: {...(target?.users?.[i]), ...target},
            content: comBody,
            scheduledAt: scheduledDate.getTime() / 1000
          }
        );
        result.push({
          batch_id: response?.data?.batchId,
          user_id: target?.users?.[i]?.id
        })
      }
      return result;
    } else {
      let result = [];
      for (let i = 0; i < persons?.length; i++) {
        const response = await axiosInstance.post(
          'scheduleEmail',
          {
            userId: user?.uid,
            name: comName,
            type: communicate,
            target: {...(persons?.[i]), ...target},
            content: comBody,
            scheduledAt: scheduledDate.getTime() / 1000
          }
        );
        result.push({
          batch_id: response?.data?.batchId,
          user_id: persons?.[i]?.id
        })
      }
      return result;
    }
  }

  const scheduleSMS = async () => {
    const axiosInstance = axios.create({
      baseURL: 'https://us-central1-voodoocast-ab022.cloudfunctions.net/',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })

    const scheduledDate = new Date(`${comDate}T${comTime}${comTime?.length === 5 ?  ':00' : ''}`);

    if (target.targetType === 'user') {
      const response = await axiosInstance.post(
        'scheduleSMS',
        {
          userId: user?.uid,
          name: comName,
          type: communicate,
          target: target,
          content: comBody,
          scheduledAt: scheduledDate.getTime()
        }
      );
      return response;
    } else if (target.targetType === 'group') {
      let result = [];
      for (let i = 0; i < target?.users?.length; i++) {
        const response = await axiosInstance.post(
          'scheduleSMS',
          {
            userId: user?.uid,
            name: comName,
            type: communicate,
            target: {...(target?.users?.[i]), ...target},
            content: comBody,
            scheduledAt: scheduledDate.getTime()
          }
        );
        result.push({
          batch_id: response?.data?.batchId,
          user_id: target?.users?.[i]?.id
        })
      }
      return result;
    } else {
      let result = [];
      for (let i = 0; i < persons?.length; i++) {
        const response = await axiosInstance.post(
          'scheduleSMS',
          {
            userId: user?.uid,
            name: comName,
            type: communicate,
            target: {...(persons?.[i]), ...target},
            content: comBody,
            scheduledAt: scheduledDate.getTime()
          }
        );
        result.push({
          batch_id: response?.data?.batchId,
          user_id: persons?.[i]?.id
        })
      }
      return result;
    }
  }

  const scheduleNotification = () => {
    if (target.targetType === 'user') {
      return {
        data: {
          batchId: ''
        }
      }
    } else if (target.targetType === 'group') {
      return target?.users?.map(_user => {
        return {
          batch_id: '',
          user_id: _user?.id,
          ..._user
        }
      })
    } else {
      return persons?.map(_user => {
        return {
          batch_id: '',
          user_id: _user?.id,
          ..._user
        }
      })
    }
  }

  const addCommunicate = async () => {
    if (!comName) return;
    if (!communicate) return;
    if (!target) return;
    if (!comDate) return;
    if (!comTime) return;
    setIsScheduling(true);
    let scheduledResponse;
    if (communicate?.type === 'email') {
      scheduledResponse = await scheduleEmail();
    } else if (communicate?.type === 'sms') {
      scheduledResponse = await scheduleSMS();
    } else {
      scheduledResponse = scheduleNotification();
    }

    let batchedTarget;
    if (target.targetType === 'user') {
      batchedTarget = {...target, batching: { batch_id: scheduledResponse?.data?.batchId, user_id: target?.id }}
    } else {
      batchedTarget = {...target, batching: scheduledResponse}
    } 

    const id = nanoid();
    await setDoc(doc(collection(db, 'communicates'), id), {
      id,
      userId: user?.uid,
      name: comName,
      type: communicate,
      target: batchedTarget,
      body: comBody,
      scheduledAt: `${comDate}T${comTime}${comTime?.length === 5 ?  ':00' : ''}`,
      status: 'scheduled'
    })
    setComName('');
    setCommunicate();
    setTarget();
    setComDate('');
    setComTime('');
    setComBody('');
    setIsScheduling(false);
  }

  const updateCommunicate = async () => {
    if (!comName) return;
    if (!communicate) return;
    if (!target) return;
    if (!comDate) return;
    if (!comTime) return;
    
    setIsScheduling(true);
    let scheduledResponse;
    
    let batchedTarget;
    if (communicate?.type === 'email') {
      const axiosInstance = axios.create({
        baseURL: 'https://us-central1-voodoocast-ab022.cloudfunctions.net/',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      await axiosInstance.post(
        'cancelEmail',
        {
          communicateId: curCommunicate
        }
      );
      scheduledResponse = await scheduleEmail();
    } else if (communicate?.type === 'sms') {
      const axiosInstance = axios.create({
        baseURL: 'https://us-central1-voodoocast-ab022.cloudfunctions.net/',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      await axiosInstance.post(
        'removeSMS',
        {
          communicateId: curCommunicate,
        }
      );
      scheduledResponse = await scheduleSMS();
    } else {
      scheduledResponse = target?.batching
    }
  
    if (target.targetType === 'user') {
      batchedTarget = {...target, batching: { batch_id: scheduledResponse?.data?.batchId, user_id: target?.id }}
    } else {
      batchedTarget = {...target, batching: scheduledResponse}
    } 

    await updateDoc(doc(collection(db, 'communicates'), curCommunicate), {
      id: curCommunicate,
      userId: user?.uid,
      name: comName,
      type: communicate,
      target: batchedTarget,
      body: comBody,
      scheduledAt: `${comDate}T${comTime}${comTime?.length === 5 ?  ':00' : ''}`,
      status: 'scheduled'
    })
    setIsScheduling(false);
    setComName('');
    setCommunicate();
    setTarget();
    setComDate('');
    setComTime('');
    // setComBody('');
  }

  useEffect(() => {
    const loadScheduled = async () => {
      if (curCommunicate) {
        const scheduled = communicates?.find(com => com.id === curCommunicate);
        setComName(scheduled?.name);
        setCommunicate(scheduled?.type);
        setTarget(scheduled?.target);
        setComDate(scheduled?.scheduledAt?.split('T')?.[0]);
        setComTime(scheduled?.scheduledAt?.split('T')?.[1]);
        if (!stash.current) {
          stash.current = JSON.parse(JSON.stringify(currentSetting.current ?? {}))
        }
      } else if (stash.current) {
        setComName(stash.current?.comName);
        setCommunicate(stash.current?.communicate);
        setTarget(stash.current?.target);
        setComDate(stash.current?.comDate);
        setComTime(stash.current?.comTime);
        stash.current = null;
      }
    }
    loadScheduled();
  }, [curCommunicate, communicates]);

  const saveTemplate = async (name, template) => {
    const id = nanoid();
    const updatingId = templates?.find(temp => temp.name === name)?.id;
    if (updatingId) {
      await updateDoc(doc(collection(docRef, 'templates'), updatingId), {
        template
      })
    } else {
      await setDoc(doc(collection(docRef, 'templates'), id), {
        id,
        name,
        template: template
      })
    }
  }

  const removeTemplate = (id) => {
    deleteDoc(doc(collection(docRef, 'templates'), id))
  }

  return (
    <Flex
      direction="column"
      className={`border-2 border-gray-500 rounded w-full h-full p-2`}
      style={{height: height}}
    >
      <Flex direction="column">
        <Flex
          justify="around"
          align="end"
          css={{
            w: '100%'
          }}
        >
          <Tooltip side="bottom" title="required">
            <Input 
              placeholder="Communication Name"
              css={{
                h: '$8',
                borderRadius: '$3',
                color: '$white',
                bg: 'black',
                mr: '$2'
              }}
              disabled={communicates?.find(com => com.id === curCommunicate)?.type?.type === 'sms'}
              value={comName}
              onChange={(e) => setComName(e.target.value)}
            />
          </Tooltip>
          <DropDown
            placeHolder="Communication Type"
            currentValue={communicate}
            setCurrentValue={setCommunicate}
            values={communicationType}
            disabled={communicates?.find(com => com.id === curCommunicate)?.type?.type === 'sms'}
          />
          <DropDown
            placeHolder="Targeting"
            currentValue={target}
            setCurrentValue={setTarget}
            values={targetings}
            dropdownType="targeting"
            disabled={communicates?.find(com => com.id === curCommunicate)?.type?.type === 'sms'}
          />
        </Flex>
        <Flex css={{ w: '$200', mt: '$2'}}>
          <Input
            type="date" 
            css={{
              h: '$8',
              borderRadius: '$3',
              color: '$white',
              w: '150px'
            }}
            max={
              communicate?.type === 'sms'
                ? `${ableEndDate.getFullYear()}-${ableEndDate.getMonth() < 9 ? '0' : ''}${ableEndDate.getMonth() + 1}-${ableEndDate.getDate() < 10 ? '0' : ''}${ableEndDate.getDate()}`
                : ''
            }
            disabled={communicates?.find(com => com.id === curCommunicate)?.type?.type === 'sms'}
            className="bg-gray-400"
            value={comDate}
            onChange={(e) => setComDate(e.target.value)}
          />
          <Input
            type="time" 
            css={{
              w: '150px',
              h: '$8',
              borderRadius: '$3',
              color: '$white',
              ml: '$1'
            }}
            disabled={communicates?.find(com => com.id === curCommunicate)?.type?.type === 'sms'}
            className="bg-gray-400"
            value={comTime}
            onChange={(e) => setComTime(e.target.value)}
          />
          {/* <Button 
            variant="standard" 
            onClick={() => {
              ToastBatcher.showToast({
                type: 'App_Notification',
                notification: {
                  title: 'hey'
                },
                duration: 10000
              })
            }}
          >no</Button> */}
        </Flex>
      </Flex>
      
      <CommunicationBody
        comBody={comBody}
        setComBody={setComBody}
        curCommunicate={curCommunicate}
        communicates={communicates}
        templates={templates}
        saveTemplate={saveTemplate}
        removeTemplate={removeTemplate}
      />
      
      <Flex justify="around" css={{w: '100%'}}>
        {/* <Button
          variant="standard"
          css={{
            h: '$10'
          }}
        >
          Preview
        </Button> */}
        {!curCommunicate &&
          <Button
            css={{
              h: '$10'
            }}
            onClick={() => addCommunicate()}
            disabled={isScheduling}
          >
            {isScheduling &&
              <div className="w-5 h-5 mr-1"><div className="uploading-spinner"></div></div>
            }
            Add Communication
          </Button>
        }
        {curCommunicate && communicates?.find(com => com.id === curCommunicate)?.status === 'processed' && 
          <Button
            variant="standard"
            css={{
              h: '$10',
              mr: '$2'
            }}
            onClick={() => {
              setCurCommunicate()
            }}
          >
            Cancel
          </Button>
        }
        {curCommunicate && communicates?.find(com => com.id === curCommunicate)?.status !== 'processed' && 
          <Flex>
            <Button
              variant="standard"
              css={{
                h: '$10',
                mr: '$2'
              }}
              onClick={() => {
                setCurCommunicate()
                setComName('');
                setCommunicate();
                setTarget();
                setComDate('');
                setComTime('');
              }}
            >
              Cancel Update
            </Button>
            <Button
              css={{
                h: '$10'
              }}
              disabled={isScheduling}
              onClick={async () => {
                await updateCommunicate();
                setCurCommunicate();
              }}
            >
              {isScheduling &&
                <div className="w-5 h-5 mr-1"><div className="uploading-spinner"></div></div>
              }
              Confirm Update
            </Button>
          </Flex>
        }
      </Flex>
    </Flex>
  )
};

export default AddCommunication;
