import {
  useState,
  useRef,
  useEffect
} from "react";
import { Box, Button, Flex, Input, Tooltip } from "@100mslive/react-ui";
import { CrossIcon } from "@100mslive/react-icons";

const CellInput = ({
  placeholder,
  data,
  setData,
  tabIndex,
  isRquired=false,
}) => {
  const inputRef = useRef();
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (isEdit) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  const handleKeyDown = (e) => {
    if (e.code === 'Tab') {
      e.preventDefault();
      inputRef.current.blur();
      const nextEle = e.shiftKey 
        ? document.querySelector(`.adding-person-list[tabindex='${tabIndex - 1}']`) 
        : document.querySelector(`.adding-person-list[tabindex='${tabIndex + 1}']`);
      if (nextEle) {
        nextEle.click();
      }
    }
  }

  return (
    <td 
      className="h-8"
    >
      {!isEdit && !isRquired &&
        <div
          tabIndex={tabIndex}
          className="adding-person-list cursor-pointer h-6 text-sm w-full border rounded p-1 bg-gray-500 text-white flex justify-center items-center"
          onClick={(e) => {
            setIsEdit(true);
          }}
        >
          <span>{data}</span>
        </div>
      }
      {!isEdit && isRquired &&
        <Tooltip title="Required Field" side="top">
          <div
            tabIndex={tabIndex}
            className="adding-person-list cursor-pointer h-6 text-sm w-full border rounded p-1 bg-gray-500 text-white flex justify-center items-center"
            onClick={(e) => {
              setIsEdit(true);
            }}
          >
            <span>{data}</span>
          </div>
        </Tooltip>
      }
      {isEdit && !isRquired &&
        <Input
          ref={inputRef}
          defaultValue={data}
          onChange={(e) => {
            setData(e.target.value)
          }}
          onBlur={(e) => {
            setIsEdit(false)
          }}
          css={{
            w: '100px',
            h: '18px',
            px: '$1',
            borderRadius: '$0'
          }}
          onKeyDown={handleKeyDown}
        />
      }
      {isEdit && isRquired &&
        <Tooltip title="Required Field" side="top">
          <Input
            ref={inputRef}
            defaultValue={data}
            onChange={(e) => {
              setData(e.target.value)
            }}
            onBlur={(e) => {
              setIsEdit(false)
            }}
            css={{
              w: '100px',
              h: '18px',
              px: '$1',
              borderRadius: '$0'
            }}
            onKeyDown={handleKeyDown}
          />
        </Tooltip>
      }
    </td>
  )
};

const RowInput = ({
  idx,
  person,
  setData,
  removeRow=()=>{}
}) => {
  return (
    <tr key={idx} className="border-b">
      <td>{idx + 1}</td>
      <CellInput
        isRquired={true}
        placeholder="first name"
        data={person?.firstName}
        setData={setData('firstName', idx)}
        tabIndex={7 * idx}
      />
      <CellInput
        isRquired={true}
        placeholder="last name"
        data={person?.lastName}
        setData={setData('lastName', idx)}
        tabIndex={7 * idx + 1}
      />
      <CellInput
        isRquired={true}
        placeholder="email"
        data={person?.email}
        setData={setData('email', idx)}
        tabIndex={7 * idx + 2}
      />
      <CellInput
        isRquired={true}
        placeholder="phone"
        data={person?.phoneNumber}
        setData={setData('phoneNumber', idx)}
        tabIndex={7 * idx + 3}
      />
      <CellInput
        placeholder="meta1"
        data={person?.meta1}
        setData={setData('meta1', idx)}
        tabIndex={7 * idx + 4}
      />
      <CellInput
        placeholder="meta2"
        data={person?.meta2}
        setData={setData('meta2', idx)}
        tabIndex={7 * idx + 5}
      />
      <CellInput
        placeholder="meta3"
        data={person?.meta3}
        setData={setData('meta3', idx)}
        tabIndex={7 * idx + 6}
      />
      <td>
        <CrossIcon
          className="w-4 h-4 text-red-500 cursor-pointer"
          onClick={() => removeRow(idx)}
        />
      </td>
    </tr>
  )
}

const UploadPersons = ({
  addPerson
}) => {
  const emptyRow = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    meta1: '',
    meta2: '',
    meta3: ''
  };
  const [addingPersons, setAddingPersons] = useState([emptyRow]);
  const [uploading, setUploading] = useState(false);
  const fileInput = useRef();

  const setData = (name, idx) => {
    return (data) => setAddingPersons(previous => {
      return previous?.map((item, _idx) => {
        if (_idx === idx) {
          return {
            ...item,
            [name]: data
          }
        } else {
          return item
        }
      })
    })
  };

  const removeRow = (idx) => {
    const firstList = addingPersons?.slice(0, idx) ?? [];
    const secondList = addingPersons?.slice(idx+1, addingPersons?.length);
    setAddingPersons([...firstList, ...secondList]);
  };

  const handleAddList = async (e) => {
    if (uploading) return;
    setUploading(true);
    let newList = [];
    for (let person of addingPersons) {
      if (person?.firstName && person?.lastName && person?.email && person?.phoneNumber) {
        await addPerson(person);
      } else {
        newList.push(person)
      }
    }
    setAddingPersons(newList);
    setUploading(false);
  }

  const fileReader = new FileReader();

  const handleFileChange = (e) => {
    if (e.target.files?.[0]) {
      fileReader.onload = (event) => {
        const text = event.target.result;
        const rows = text?.split('\n') ?? [];
        let adding = [];
        for (let i = 1; i < rows?.length; i++) {
          const values = rows?.[i]?.split(',') ?? [];
          adding?.push({
            firstName: values?.[0],
            lastName: values?.[1],
            email: values?.[2],
            phoneNumber: values?.[3],
            meta1: values?.[4],
            meta2: values?.[5],
            meta3: values?.[6],
          })
        }
        setAddingPersons([...addingPersons, ...adding]);
      }
      fileReader.readAsText(e.target.files?.[0]);
      e.target.value = null;
    }
  }

  return (
    <div className="upload-user border border-2 rounded border-gray-500 p-2 my-2 w-full overflow-scroll h-auto flex-grow">
      <table className="w-full">
        <thead className="pb-1">
          <tr className=" text-white text-sm">
            <th className="bg-gray-600 px-1 rounded-l">No</th>
            <th className="bg-gray-600 px-1">First Name</th>
            <th className="bg-gray-600 px-1">Last Name</th>
            <th className="bg-gray-600 px-1">Email</th>
            <th className="bg-gray-600 px-1">Phone</th>
            <th className="bg-gray-600 px-1">Meta 1</th>
            <th className="bg-gray-600 px-1">Meta 2</th>
            <th className="bg-gray-600 px-1">Meta 3</th>
            <th className="bg-gray-600 px-1 rounded-r"></th>
          </tr>
        </thead>
        <tbody>
        {addingPersons?.map((person, idx) => (
          <RowInput key={idx} idx={idx} person={person} setData={setData} removeRow={removeRow}/>
        ))}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td colSpan={1}>
              <Button 
                className="rounded cursor-pointer flex justify-center items-center text-white m-1 text-sm z-0"
                onClick={() => {
                  setAddingPersons(previous => {
                    return [...previous, emptyRow]
                  })
                }}
              >
                1 line
                <CrossIcon className="w-4 h-4 cursor-pointer rotate-45" />
              </Button>
            </td>
            <td>
              <Button
                className="bg-gray-500 rounded cursor-pointer flex justify-center items-center text-white m-1"
                onClick={() => {
                  fileInput?.current?.click();
                }}
              >
                <span className="text-sm text-center">Upload CSV</span>
              </Button>
              <Input type="file" css={{display: 'none'}} accept=".csv" ref={fileInput} onChange={handleFileChange} />
            </td>
            <td colSpan={2}>
              <Button
                className="bg-gray-500 rounded cursor-pointer flex justify-center items-center text-white m-1"
                onClick={handleAddList}
              >
                {uploading && <div className="w-5 h-5 mr-2"><div className="uploading-spinner"></div></div>}
                <span className="text-sm">Enter</span>
              </Button>
            </td>
            <td></td><td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
};

export default UploadPersons;
