
import React, { useState, useEffect } from "react";
import Button from "../../components/button";
import {
  CrossIcon,
  PlayCircleIcon,
  PauseCircleIcon,
  TrashIcon,
} from "@100mslive/react-icons";
import {
  selectAudioPlaylist,
  useHMSStore
} from "@100mslive/react-sdk";
import RangeBar from "../../components/RangeBar";
import { timeFormat } from '../../utils/timeFormat';

const AudioList = ({
  medias,
  curTab,
  activeAudio,
  handlePlayMedia,
  handlePauseMedia,
  handleSeekTo,
  handleDelete,
  updateTitle,
  deleting,
}) => {
  const audioProgress = useHMSStore(selectAudioPlaylist.progress);
  const [audioStarting, setAudioStarting] = useState(false);
  
  useEffect(() => {
    setAudioStarting(false);
  }, [activeAudio, activeAudio?.playing]);

  const handleTogglePlay = (e) => {
    const mediaId = e.currentTarget?.id ?? e.target.id;
    if (activeAudio?.id === mediaId?.split('a76')[1] && activeAudio?.playing) {
      handlePauseMedia(e);
    } else {
      handlePlayMedia(e);
      setAudioStarting(mediaId?.split('a76')[1]);
    }
  };
  
  return (
    <>
      {medias && medias.filter(media => media.type === curTab)?.map((media, idx) => (
        <div
          key={idx}
          className={`relative w-full mt-1 border-2 border-purple-500 rounded-lg`}
        >
          <div className="w-full h-12 pt-6 rounded-lg bg-purple-800 p-1 flex justify-between" >
            <button
              id={'a76' + media.id}
              className="bg-none text-white"
              onClick={handleTogglePlay}
            >
              {(activeAudio?.id !== media.id || !activeAudio?.playing) && audioStarting !== media.id &&
                <PlayCircleIcon id={'a76' + media.id} />
              }
              {(activeAudio?.id !== media.id || !activeAudio?.playing) && audioStarting === media.id && 
                <div className="w-5 h-5">
                  <div className="uploading-spinner"></div>
                </div>
              }
              {activeAudio?.id === media.id && activeAudio?.playing && 
                <PauseCircleIcon id={'a76' + media.id} />
              }
            </button>
            {activeAudio?.id === media.id &&
              <div className="flex w-full items-center mt-1">
                <RangeBar
                  getNumber={handleSeekTo}
                  width="100%"
                  backgroundColor="gray"
                  fillColor="blue"
                  fillSecondaryColor="blue"
                  headColor="white"
                  headShadow="white"
                  headShadowSize={6}
                  progress={audioProgress}
                  mousePosition={(position, width) => {timeFormat(activeAudio?.duration / width * position * 1000)}}
                />
              </div>
            }
            <button
              id={'a76' + media.id}
              className="bg-none text-white"
              onClick={handleDelete}
            >
              {deleting === media.id && 
                <div className="w-5 h-5">
                  <div className="uploading-spinner"></div>
                </div>
              }
              {deleting !== media.id && <TrashIcon id={'a76' + media.id} />}
            </button>
          </div>
          <input
            id={'a76' + media.id}
            type='text'
            value={media.title}
            onChange={(e) => {updateTitle(e.target.id.split('a76')[1], e.target.value)}}
            style={{position: 'absolute', left: 0, top: 0, background: 'black', width: '100%', height: '20px', color: 'white', border: 'none'}}
          />
        </div>
      ))}
    </>
  )
};

export default AudioList;
