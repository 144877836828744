import { useState } from "react";
import { CheckIcon, RadioIcon } from "@100mslive/react-icons"
import { RadioGroup, Flex, Label } from "@100mslive/react-ui"

const DynamicCanvasSetting = ({
  isShow,
  activeScene,
  scenes,
  setActiveScene,
  isAutoDynamic,
  setAutoDynamic,
}) => {
  
  return (
    <div
      className={`flex flex-col absolute left-full top-0 w-48 custom-bg-2 p-2 rounded ${isShow?'':'hidden'}`}
    >
      <div className="flex justify-between mb-1">
        <RadioGroup.Root value={isAutoDynamic} onValueChange={setAutoDynamic}>
          <Flex align="center" css={{ mr: "$8" }}>
            <RadioGroup.Item
              value={true}
              id="autoDynamic"
              css={{ mr: "$1" }}
            >
              <RadioGroup.Indicator />
            </RadioGroup.Item>
            <Label htmlFor="autoDynamic" css={{fontSize: "$sm"}}>Auto</Label>
          </Flex>
          <Flex align="center" css={{ cursor: "pointer" }}>
            <RadioGroup.Item
              value={false}
              id="manualDynamic"
              css={{ mr: "$1" }}
            >
              <RadioGroup.Indicator />
            </RadioGroup.Item>
            <Label htmlFor="manualDynamic" css={{fontSize: "$sm"}}>Manual</Label>
          </Flex>
        </RadioGroup.Root>
      </div>
      {Array.from(Array(12).keys()).map((i) =>
        <div
          key={`dynamic_${i}`}
          className="flex my-1 hover:bg-gray-500 px-3 rounded"
          onClick={() => {
            setActiveScene(scenes?.find(scene => scene.dynamicId === i + 1)?.id)
          }}
        >
          {activeScene === scenes?.find(scene => scene.dynamicId === i + 1)?.id &&
            <CheckIcon className="text-green-500"/>
          }
          {i + 1} Speaker{i > 0 ? 's' : ''}
        </div>
      )}
    </div>
  )
};

export default DynamicCanvasSetting;
