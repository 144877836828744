
import axios from "axios";
import { getFirestore, doc, setDoc, collection, updateDoc } from "firebase/firestore";
import { roomConfig } from "../../config/roomConfig";
import { nanoid } from "nanoid";

export const roomCreate = async ({
  name, roomType, userId, firebaseApp
}) => {
  let url = `https://us-central1-voodoocast-ab022.cloudfunctions.net/managementToken`;
  const {data: managementToken} = await axios({
    url,
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  url = `https://api.100ms.live/v2/rooms`;
  /* Not use template setting */
  // formData.set('recording_info', JSON.stringify(roomConfig.recording_info));

  const res = await axios({
    method: 'post',
    url,
    parmas: {
      "get_token": "1",
      // api_token: '4aa5a6d60dfe41ee81649db7029a31e8',
      "api_token": 'a7a52ee48f2348799d11b1766ac0b605'
    },
    headers: {
      'Authorization': `Bearer ${managementToken}`,
      'Content-Type': 'application/json'
    },
    data: {
      name: name.split(' ').join('_'),
      description: '',
      region: 'us',
      template_id: roomConfig.template_id
    }
  });

  if (res.data?.enabled === false) {
    roomToggleEnable(res.data?.id, true);
  }

  await initializeAutoScenes({
    roomId: res.data?.id, roomName: name, roomType, userId, firebaseApp
  })
  
  return res.data?.id;
}

export const roomToggleEnable = async (id, enabled=true) => {
  let url = `https://us-central1-voodoocast-ab022.cloudfunctions.net/managementToken`;
  const {data: managementToken} = await axios({
    url,
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  url = `https://api.100ms.live/v2/rooms/${id}`;

  await axios({
    method: 'post',
    url,
    parmas: {
      "get_token": "1",
      // api_token: '4aa5a6d60dfe41ee81649db7029a31e8',
      "api_token": 'a7a52ee48f2348799d11b1766ac0b605'
    },
    headers: {
      'Authorization': `Bearer ${managementToken}`,
      'Content-Type': 'application/json'
    },
    data: {
      enabled: enabled
    }
  });
}

export const initializeAutoScenes = async ({
  roomId, roomName, roomType, userId, firebaseApp
}) => {
  if (!roomId) return;
  const db = getFirestore(firebaseApp);
  const roomRef = doc(collection(db, "conferences"), `${roomId ?? 'default'}`);
  await setDoc(roomRef, {
    id: roomId,
    name: roomName ?? '',
    type: roomType ?? '',
    userId,
    isAutoDynamic: true,
    timeStamp: new Date().getTime(),
  });
  const groupsRef = collection(doc(collection(db, 'profiles'), userId ?? 'defaultUser'), 'groups');
  await setDoc(doc(groupsRef, roomId), {
    id: roomId,
    name: roomName ?? '',
    type: roomType ?? '',
    users: []
  })

  const scenesRef = collection(roomRef, 'scenes');
  for (let i = 1; i <= 12; i++) {
    const id = nanoid();
    await setDoc(doc(scenesRef, id), {
      name: `${i} Speaker${i === 1 ? '' : 's'}`, dynamicId: i,
      layers: [],
      isSingleView: false
    });
    if (i === 1) {
      updateDoc(roomRef, {
        activeScene: id
      })
    }
  }
  const id = nanoid();
  setDoc(doc(scenesRef, id), {
    id, switchOnCreate: false, name: "Speaker View", isSingleView: true 
  });
};
