import React from "react";
import { useParams } from "react-router-dom";
import Producer from "./producer";
import Conference from "./conference";
import { InteractionProvider } from "../helpers/contexts/interactionContext";

const DiscussionPage = () => {
  const { role } = useParams();
  
  return (
    <InteractionProvider>
      {(role === 'producer' || role === 'administrator') &&
        <Producer roomType="discussion" />
      }
      {(role !== 'producer' && role !== 'administrator') &&
        <Conference roomType="discussion" />
      }
    </InteractionProvider>
  )
};

export default DiscussionPage;
