import { 
  useState,
  useEffect,
  useRef
} from "react";
import { timeFormatV2 } from "../../utils/utils";
import { 
  PlayCircleIcon,
  PencilIcon,
} from "@100mslive/react-icons";
import { Input } from "@100mslive/react-ui";
import LoadingSpinner from "../../components/loadingSpinner";
import { compareObject } from "../../utils/compareObject";

const TitleComponent = ({
  recording,
  updateTitle = () => {},
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef();
  const titleRef = useRef(recording?.title);

  useEffect(() => {
    titleRef.current = recording?.title;
    setIsLoading(false);
  }, [recording?.title]);

  useEffect(() => {
    if (isEdit) {
      inputRef.current.focus();
    }
  }, [isEdit])

  const handleChange = (e) => {
    titleRef.current = e.target.value;
  }

  const handleSave = () => {
    if (titleRef.current !== recording?.title) {
      setIsLoading(true);
      updateTitle(recording?.roomId, recording?.recordingTime, titleRef.current ?? '');
    }
    setIsEdit(false);
  }

  const handleKeyUp = (e) => {
    if (e.code === 'Enter') {
      handleSave();
    }
  }
  
  return (
    <div className="flex text-gray-300 items-center mt-1">
      <span>Title:</span>
      {!isEdit && 
        <div className="relative w-full">
          <div 
            className="w-auto ml-2 border-b border-dashed h-5 cursor-pointer flex items-center"
            onClick={() => setIsEdit(true)}
          >
            {recording?.title?.slice(0, 18)}{recording?.title?.length > 18 ? '...' : ''}
            <PencilIcon className="w-4 h-4 ml-2" />
          </div>
          {isLoading && 
            <div className="absolute left-14 top-0 w-5 h-5">
              <div className="uploading-spinner"></div>
            </div>
          }
        </div>
      }
      {isEdit &&
        <Input 
          ref={inputRef}
          className="ml-2 w-auto"
          defaultValue={recording?.title ?? ''}
          onBlur={handleSave}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
        />
      }
      {/* <PencilIcon 
        className="w-5 h-5 cursor-pointer ml-2"
        onClick={() => setIsEdit(!isEdit)}
      /> */}
    </div>
  )
}

const CurDateRecordings = ({
  isRecLoading,
  curDateRecordings=[],
  curDate,
  curISO,
  setCurISO,
  updateTitle
}) => {
  useEffect(() => {
    const recording = curDateRecordings?.find(rec => rec.roomVideo === curISO?.url);
    if (recording) {
      const newISO = {
        date: curDate,
        url: recording.roomVideo,
        transcript: recording.transcript,
        isRoomVideo: true,
        roomId: recording.roomId,
        recordingTime: recording.recordingTime,
        dbSpeakersInfo: recording.speakers ?? []
      }
      if (!compareObject(newISO, curISO)) {
        setCurISO(newISO)
      }
    }
  }, [curDateRecordings, curISO, curDate, setCurISO])

  return (
    <div className="able-video-list h-auto overflow-y-scroll border border-1 border-gray-500 rounded mt-1 pl-1 grow shrink bg-gray-800">
      {curDateRecordings?.map((recording, idx) => 
        <div
          key={idx}
          className={`px-3 flex flex-col mt-1 ${idx !== 0 ? "border-t" : ""}`}
        >
          <TitleComponent 
            recording={recording}
            updateTitle={updateTitle}
          />
          <div className="text-gray-300 italic text-sm">
            Recorded at <span className="text-purple-300 mr-1">{timeFormatV2(recording.recordingTime)}</span>
            in <span className="text-green-300">{recording.roomName}</span> room
          </div>
          <button
            className={
              `w-32 h-8 ${curISO?.url === recording.roomVideo ? 'bg-green-300' : 'bg-gray-500'}
              text-gray-100 flex justify-between rounded items-center pr-2 mt-2`
            }
            onClick={(e) => {
              setCurISO({
                date: curDate,
                url: recording.roomVideo,
                transcript: recording.transcript,
                isRoomVideo: true,
                roomId: recording.roomId,
                recordingTime: recording.recordingTime,
                dbSpeakersInfo: recording.speakers ?? []
              })
            }}
          >
            <PlayCircleIcon
              className={`w-8 h-8`}
            /> 
            Room Video
          </button>
          {recording?.isoArr?.map((iso, _idx) =>
            <button
              key={_idx}
              className={
                `w-32 h-8 ${curISO?.url === iso ? 'bg-green-300' : 'bg-gray-500'}
                text-gray-100 flex justify-between rounded items-center pr-2 mt-1`
              }
              onClick={(e) => {
                setCurISO({
                  date: curDate,
                  url: iso,
                  transcript: recording.transcript,
                  isRoomVideo: false
                })
              }}
            >
              <PlayCircleIcon
                className={`w-8 h-8`}
              /> 
              {iso.split('/')?.[6]?.split('-')?.[0]}
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default CurDateRecordings;
