import React, { useRef, useMemo, useState } from "react";
import { useFullscreen, useToggle } from "react-use";
import screenfull from "screenfull";
import {
  selectVideoPlaylist,
  selectVideoPlaylistVideoTrackByPeerID,
  useHMSActions,
  useHMSStore,
  useVideo
} from "@100mslive/react-sdk";
import { ShrinkIcon, ExpandIcon, CrossIcon } from "@100mslive/react-icons";
import { Flex, IconButton, Text, Video } from "@100mslive/react-ui";
import { VideoPlaylistControls } from "./PlaylistControls";
import { UI_SETTINGS } from "../../common/constants";
import { useUISettings } from "../AppData/useUISettings";
import LoadingSpinner from "../loadingSpinner";

export const VideoPlayer = ({ peerId, parentWidth=0, parentHeight=0 }) => {
  const videoTrack = useHMSStore(selectVideoPlaylistVideoTrackByPeerID(peerId));
  const active = useHMSStore(selectVideoPlaylist.selectedItem);
  const isAudioOnly = useUISettings(UI_SETTINGS.isAudioOnly);
  const hmsActions = useHMSActions();
  const ref = useRef(null);
  const [loading, setLoading] = useState(true);
  const [show, toggle] = useToggle(false);
  const isFullscreen = useFullscreen(ref, show, {
    onClose: () => toggle(false),
  });

  const videoStyle = useMemo(() => ({
    width: `${parentWidth}px`,
    height: `${parentHeight}px`,
  }), [parentWidth, parentHeight]);

  const { videoRef } = useVideo({
    trackId: videoTrack?.id
  });

  return (
    <div
      direction="column"
      justify="center"
      className="relative"
      style={{ 
        width: parentWidth ? `${parentWidth}px` : "100%", 
        height: parentHeight ? `${parentHeight}px` : "100%", 
        zIndex: 5000
      }}
      ref={ref}
    >
      {/* {loading &&
        <div className="w-full h-full flex items-center justify-center bg-gray-500 opacity-60">
          <LoadingSpinner />
        </div>
      } */}
      {active && (
        <Flex
          justify="between"
          align="center"
          css={{
            bg: "none",
            width: '100%',
            p: "$2 $2 $2 $6",
            borderTopLeftRadius: "$1",
            borderTopRightRadius: "$1",
            position: 'absolute',
          }}
        >
          <Text css={{ color: "$textPrimary" }}>{active.name}</Text>
          <IconButton
            css={{
              color: "$white",
              position: "absolute",
              right: 0,
              zIndex: 5000,
            }}
            onClick={() => {
              hmsActions.videoPlaylist.stop();
            }}
            data-testid="videoplaylist_cross_btn"
          >
            <CrossIcon />
          </IconButton>
        </Flex>
      )}
      {/* <Video
        trackId={videoTrack?.id}
        attach={!isAudioOnly}
        css={{
          "@lg": { objectFit: "contain", h: "auto" },
          r: "$1",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      /> */}
      <video
        ref={videoRef}
        className="w-full h-full bg-black"
        // onLoadStart={() => {
        //   console.log('start loading')
        // }}
        // onLoadedData={() => {
        //   console.log('end loading')
        // }}
        autoPlay
        muted
        playsInline
        style={videoStyle}
      />
      <VideoPlaylistControls>
        {screenfull.enabled && (
          <IconButton
            onClick={() => toggle()}
            css={{
              color: "$white",
              height: "max-content",
              alignSelf: "center",
              cursor: "pointer",
            }}
          >
            {isFullscreen ? <ShrinkIcon /> : <ExpandIcon />}
          </IconButton>
        )}
      </VideoPlaylistControls>
    </div>
  );
};
