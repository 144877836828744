import React, { useState } from "react";
import DeleteConferenceModal from "../features/deleteConferenceModal";
import { formatRelative, fromUnixTime } from "date-fns";
import Button from "./button";
import { ReactComponent as LoginIcon } from "../icons/login.svg";
import { ReactComponent as UserAddIcon } from "../icons/user-add.svg";
import TrashImg from "../icons/CC_icon_trash.png";

export const SessionCard = ({ id, name, type="broadcast", scheduledDate, onDelete }) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const handleDelete = async () => {
    setIsRemoving(true);
    await onDelete();
    setIsRemoving(false);
    setConfirmDeleteOpen(false);
  };

  const typeCaption = (str) => {
    if (str === 'broadcast')
      return "Broadcast"
    else if (str === 'watchParty')
      return "Watch Party"
    else if (str === 'discussion')
      return "Discussion"
    else if (str === 'viewing')
      return "Viewing"
    return ""
  };

  return (
    <>
      {confirmDeleteOpen && (
        <DeleteConferenceModal
          key={id}
          isOpen={true}
          isRemoving={isRemoving}
          onClose={() => setConfirmDeleteOpen(false)}
          name={name}
          onSubmit={handleDelete}
        />
      )}
      <div className="flex gap-2 items-center">
        <div 
          className="flex text-gray-200 hover:bg-gray-400 rounded p-2 items-center justify-between"
          style={{width: '60vw'}}
        >
          <div style={{width: '40vw'}}>
            <div className="font-bold">{name}</div>
            <div className="text-gray-400 capitalize">
              {scheduledDate?.seconds
                ? formatRelative(
                    fromUnixTime(scheduledDate?.seconds),
                    new Date()
                  )
                : "Not Scheduled"}
            </div>
          </div>
          <span className="w-28">{typeCaption(type)}</span>
          <div className="flex gap-2 ml-2 items-center">
            <Button
              className="flex custom-bg-3 text-gray-300 items-center gap-1"
              onClick={() => {
                if (type === 'broadcast')
                  window.location.href = `/conference/${id}/producer`
                else if (type === 'discussion')
                  window.location.href = `/discussion/${id}/producer`
                else if (type === 'watchParty')
                  window.location.href = `/watch_party/${id}/producer`
                else if (type === 'viewing')
                  window.location.href = `/viewing/${id}/producer`
              }}
            >
              <LoginIcon className="w-4 h-4" /> Enter
            </Button>
            <Button
              className="flex custom-bg-3 text-gray-300 items-center gap-1"
              onClick={() => {
                if (type === 'broadcast') {
                  navigator.clipboard.writeText(
                    `${window.location.href}conference/${id}/respondent`
                  );
                } else if (type === 'discussion') {
                  navigator.clipboard.writeText(
                    `${window.location.href}discussion/${id}/respondent`
                  );
                } else if (type === 'watchParty') {
                  navigator.clipboard.writeText(
                    `${window.location.href}watch_party/${id}/respondent`
                  );
                } else if (type === 'viewing') {
                  navigator.clipboard.writeText(
                    `${window.location.href}viewing/${id}/respondent`
                  );
                }
              }}
            >
              <UserAddIcon className="w-4 h-4" /> Invite
            </Button>
            <button className="text-gray-400 hover:text-gray-700 flex items-center">
              {/* <TrashIcon
                className="w-6 h-6"
                onClick={() => setConfirmDeleteOpen(true)}
              /> */}
              <img
                onClick={() => setConfirmDeleteOpen(true)}
                className="w-6 h-6 min-w-6 h-4 ml-2 text-blue-400 hover:text-blue-200"
                src={TrashImg}
                alt="trash"
                style={{ minWidth: '15px' }}
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
