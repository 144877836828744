import React, { useState } from 'react';
import styled from "@emotion/styled";
import LayerEditor from "./layerEditor";
import UploadMedia from "./uploadMedia";
import { Chat } from "../components/ChatFB/Chat";
import RecordingListEmbeded from "./recordingListEmbeded";
import { 
	// Tab as ReactTab, 
	// TabList, 
	TabPanel, 
	// Tabs 
} from "react-tabs";

import { Tab as ReactTab } from "../components/ReactTabs/Tab";
import { TabList } from "../components/ReactTabs/TabList";
import { Tabs } from "../components/ReactTabs/Tabs";

import { ReactComponent as TemplateIcon } from "../icons/template.svg";
import { ReactComponent as ChatIcon } from "../icons/chat.svg";
import { ReactComponent as FilmIcon } from "../icons/film.svg";
import { ReactComponent as RecordingIcon } from "../icons/play1.svg";
import { ReactComponent as InteractionIcon } from "../icons/interaction.svg";
import { RecordingListOnEditor } from './IsoVideo/recordingListOnEditor';
import { PlaylistProvider } from '../helpers/contexts/playlistContext';
import { InteractionContext } from '../helpers/contexts/interactionContext';
import ChatInteractions from '../components/Interaction/chatInteractions';
import TestBCFlow from '../components/Streaming/YouTubeOAuth/TestBCFlow';

const StyledTabPanelContainer = styled.div`
.tab-panel {
	display: none !important;
}

.react-tabs__tab-panel--selected {
	display: block !important;
}
`;

const StyledTabList = styled(TabList)`
li {
	color: #d1d5db;
	border-bottom: 3px solid transparent;
}

li:hover {
	color: #4b5563;
}

.react-tabs__tab--selected {
	color: red;
	/*border-bottom: 3px solid #fcd34d;*/
}
`;

StyledTabList.tabsRole = "TabList";

const LayerEditorControls = ({ room, conferenceId, conference, docRef }) => {
	const [activeTab, setActiveTab] = useState(0);
	const { isOpenInteractionTab, setIsOpenInteractionTab } = React.useContext(InteractionContext);
	const interactionTabRef = React.useRef();

	React.useEffect(() => {
		if (isOpenInteractionTab) {
			setActiveTab(4);
			// interactionTabRef.current.click();
			document.querySelector('.interaction-tab').click();
		}
	}, [isOpenInteractionTab]);

	React.useEffect(() => {
		if (activeTab !== 4) {
			setIsOpenInteractionTab(false);
		}
	}, [activeTab, setIsOpenInteractionTab]);

	return (
		<PlaylistProvider>
			<StyledTabPanelContainer className="w-full bg-cPurple custom-shadow flex flex-col absolute right-0 h-full">
				<TestBCFlow />
				<Tabs
					id="layer_tabs"
					className="border-b border-gray-400 h-full py-1 overflow-y-scroll overflow-x-hidden" defaultIndex={0}
					onSelect={(index) => {
						setActiveTab(index);
					}}
				>
					<StyledTabList className="flex justify-around mt-2">
						<ReactTab
							className="cursor-pointer flex items-center rounded-t px-2 bg-cPurple"
							// onClick={() => setActiveTab(0)}
						>
							<TemplateIcon className="w-4 h-4 mr-1" />
							{activeTab === 0 ? 'Editor' : ''}
						</ReactTab>
						<ReactTab
							className="cursor-pointer flex items-center rounded-t px-2 bg-cPurple"
							// onClick={() => setActiveTab(1)}
						>
							<FilmIcon className="w-4 h-4 mr-1" />
							{activeTab === 1 ? 'Media' : ''}
						</ReactTab>
						<ReactTab
							className="cursor-pointer flex items-center rounded-t px-2 bg-cPurple"
							// onClick={() => setActiveTab(2)}
						>
							<RecordingIcon className="w-4 h-4 mr-1 text-red" />
							{activeTab === 2 ? 'Recordings' : ''}
						</ReactTab>
						<ReactTab
							className="cursor-pointer flex items-center rounded-t px-2 bg-cPurple"
							// onClick={() => setActiveTab(3)}
						>
							<ChatIcon className="w-4 h-4 mr-1" />
							{activeTab === 3 ? 'Chat' : ''}
						</ReactTab>
						<ReactTab
							ref={interactionTabRef}
							className="cursor-pointer flex items-center rounded-t px-2 bg-cPurple interaction-tab"
							// onClick={() => setActiveTab(4)}
						>
							<InteractionIcon className="w-4 h-4 mr-1" />
							{activeTab === 4 ? 'Interaction' : ''}
						</ReactTab>
					</StyledTabList>
					{/* <div className="w-full h-0 border border-gray-500"></div> */}
					<TabPanel
						style={{ height: "calc(100% - 45px)" }}
						className="tab-panel bg-cPurple-light"
					>
						<LayerEditor
							room={room}
							conferenceId={conferenceId}
							currentViewType={conference?.currentViewType || 'desktop'}
							sceneId={conference?.activeScene}
						/>
					</TabPanel>
					<TabPanel
						style={{ height: "calc(100% - 35px)" }}
						className="tab-panel bg-cPurple-light layer-tabs overflow-y-scroll"
					>
						<p className="pl-2 text-gray-100">Media uploads</p>
						<UploadMedia docRef={docRef} conference={conference}/>
					</TabPanel>
					<TabPanel
						style={{ height: "calc(100% - 35px)" }}
						className="tab-panel bg-cPurple-light p-2 layer-tabs overflow-y-scroll"
					>
						{/* <RecordingListEmbeded type={'embeded'} conference={conference} docRef={docRef} /> */}
						<RecordingListOnEditor conferences={[conference]} />
					</TabPanel>
					<TabPanel
						style={{ height: "calc(100% - 40px)" }}
						className="tab-panel bg-cPurple-light px-2"
					>
						<Chat />
					</TabPanel>
					<TabPanel
						style={{ height: "calc(100% - 40px)" }}
						className="tab-panel bg-cPurple-light px-2"
					>
						<ChatInteractions />
					</TabPanel>
				</Tabs>
			</StyledTabPanelContainer>
		</PlaylistProvider>
	)
};

export default LayerEditorControls;
