const compareList = (a, b) => {
  if (a.recordingTime < b.recordingTime)
    return 1;
  if (a.recordingTime > b.recordingTime)
    return -1;
  return 0;
};

export const analyzeRecordings = (credential, contents, conferences) => {
  let result = [];
  let urlPrefix = `https://${credential.bucket}.s3.${credential.region}.amazonaws.com/`;

  (contents ?? []).forEach(content => {
    const roomId = content?.directory?.split('/')?.[1];
    const roomName = conferences.find(conference => conference.id === roomId)?.name;
    const recordingDate = content?.directory?.split('/')?.[2];
    
    const isoArr = content?.contents.filter(item => {
      const extension = item.Key.split('.')?.[1];
      const isRoom = item.Key.split('/')?.[3]?.substring(0, 4) === 'Rec-';
      return extension === 'mp4' && !isRoom;
    })
    const roomArr = content?.contents.filter(item => {
      const extension = item.Key.split('.')?.[1];
      const isRoom = item.Key.split('/')?.[3]?.substring(0, 4) === 'Rec-';
      return extension === 'mp4' && isRoom;
    })
    
    let recordingArr = roomArr.map(room => {
      const recordingTime = room.Key.split('/')?.[3]?.split('-')?.[2]?.split('.')?.[0];
      const transcript = content?.contents.find(item => item.Key.indexOf(recordingTime) >= 0 && item.Key.split('.')?.[1] === 'json')?.Key;
      return {
        roomVideo: `${urlPrefix}${room.Key}`,
        roomId,
        roomName,
        recordingTime,
        isoArr: isoArr.filter(iso => {
          const isoName = iso.Key.split('/')?.[3];
          return (isoName.indexOf(recordingTime) >= 0);
        }).map(item => `${urlPrefix}${item.Key}`),
        transcript: transcript ? `${urlPrefix}${transcript}` : ''
      }
    }) ?? [];
    
    let rest = {};
    for (let item of isoArr) {
      if (
        !recordingArr.some(recording => recording.isoArr.some(_item => _item.indexOf(item.Key) >= 0))
      ) {
        const recordingTime = item.Key.split('/')?.[3]?.split('-')?.[1]?.split('.')?.[0];
        if (Object.keys(rest).some(r => r === recordingTime)) {
          rest[recordingTime].filter(rr => rr !== `${urlPrefix}${item.Key}`).push(`${urlPrefix}${item.Key}`)
        } else {
          rest[recordingTime] = [`${urlPrefix}${item.Key}`];
        }
      }
    }

    (Object.keys(rest) ?? []).forEach(item => {
      const transcript = content?.contents?.find(_item => item.Key?.indexOf(item) >= 0 && _item.Key.split('.')?.[1] === 'json')?.Key;
      recordingArr.push({
        roomVideo: '',
        roomId,
        roomName,
        recordingTime: item,
        isoArr: rest[item] ?? [],
        transcript: transcript ? `${urlPrefix}${transcript}` : ''
      })
    })

    recordingArr = recordingArr.sort(compareList);

    if ((recordingArr ?? []).length > 0) {
      result.push({
        recordingDate,
        recordingArr,
      })
    }
  })

  return result;
};
