import React from "react";
import {
  StarIcon,
  ThumbsUpIcon,
  ThumbsDownIcon
} from "@100mslive/react-icons";
import { ReactComponent as StarFillIcon } from "../../icons/SimpleStar.svg";

const RatingEle = ({
  value=0,
  setValue=()=>{},
  starNum=5,
  disabled=false,
  isShowThumbUp=true,
  isShowThumbDown=true,
  isMiniSized=false,
}) => {
  const [hovered, setHovered] = React.useState(0);

  React.useEffect(() => {
    setHovered(0);
  }, [value]);

  return (
    <div className="flex">
      {isShowThumbDown &&
        <button className={`${(hovered || value) < 2 ? '' : 'invisible'}`}>
          <ThumbsDownIcon className={`${isMiniSized ? "w-5 h-5" : "w-8 h-8"} mr-1 text-yellow-500 cursor-pointer`} />
        </button>
      }
      {[...Array(starNum)].map((star, idx) => (
        <button
          key={idx}
          onMouseEnter={e => {
            if (!disabled) setHovered(idx + 1)
          }}
          onMouseLeave={e => {
            if (!disabled) setHovered(value)
          }}
          onClick={e => {
            if (!disabled) setValue(idx + 1)
          }}
        >
          {idx < (hovered || value) &&
            <StarFillIcon className={`${isMiniSized ? "w-5 h-5" : "w-8 h-8"} mr-1 text-yellow-500 cursor-pointer`} />
          }
          {idx >= (hovered || value) &&
            <StarIcon className={`${isMiniSized ? "w-5 h-5" : "w-8 h-8"} mr-1 text-yellow-500 cursor-pointer`} />
          }
        </button>
      ))}
      {isShowThumbUp &&
        <button className={`${(hovered || value) === starNum ? '' : 'invisible'}`}>
          <ThumbsUpIcon className={`${isMiniSized ? "w-5 h-5" : "w-8 h-8"} mr-1 text-yellow-500 cursor-pointer`} />
        </button>
      }
    </div>
  )
};

export default RatingEle;
