import { configureStore } from '@reduxjs/toolkit'
import videoSplitReducer from './reducers/videoSplitReducer';
import youtubeRtmpReducer from './reducers/youtubeRtmpReducer';

export default configureStore({
  reducer: {
    videoSplit: videoSplitReducer,
    youtubeRtmp: youtubeRtmpReducer,
  },
})
