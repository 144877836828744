import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import { 
  selectPeers,
  useAVToggle,
	useHMSActions,
  useHMSStore,
  HMSPlaylistType,
  selectIsAllowedToPublish,
  selectIsLocalScreenShared,
  selectIsSomeoneScreenSharing,
  selectRecordingState,
  useScreenShare
} from "@100mslive/react-sdk";
import DeviceSetting from "./deviceSettings";
import Modal from "../components/modal";
import { useSidepaneToggle } from "../components/AppData/useSidepane";
import { SIDE_PANE_OPTIONS } from "../common/constants";
import { Button, Tooltip, Popover, } from "@100mslive/react-ui";
import { TranscriptionButton } from "../components/transcriptionButton";
import { Playlist } from "../components/Playlist/Playlist.js";
import { isScreenshareSupported } from "../common/utils";
import IconButton from "../components/IconButton";
import { MusicIcon } from "@100mslive/react-icons";
import DeviceSelection from "../components/rtc/deviceSelection";
import { TranscriptionContext } from "../helpers/contexts/transcriptionContext";
import TranscriptModal from "../components/transcriptModal";
import { ISOProvider } from "../helpers/contexts/isoContext";
import { StreamActions } from "../components/Header/StreamActions";
import axios from "axios";

function Footer({ role="um_moderator"}) {
  const {
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    toggleAudio,
    toggleVideo
  } = useAVToggle();

  const hmsActions = useHMSActions();

  const peers = useHMSStore(selectPeers);

  const [isDeviceSetting, setDeviceSetting] = useState(false);
  const [isScreenShare, setScreenShare] = useState(false);
  const amIScreenSharing = useHMSStore(selectIsLocalScreenShared);
  const screenshareOn = useHMSStore(selectIsSomeoneScreenSharing);
  const [error, setError] = useState(false);

  const toggleChat = useSidepaneToggle(SIDE_PANE_OPTIONS.CHAT);
  const { transcript, isShowTranscript, setIsShowTranscript } = useContext(TranscriptionContext)

  useEffect(() => {
    if (role === 'um_observer' && isLocalAudioEnabled) {
      toggleAudio();
    }
  }, [isLocalAudioEnabled, role, toggleAudio]);

  useEffect(() => {
    if (role === 'um_observer' && isLocalVideoEnabled) {
      toggleVideo();
    }
  }, [isLocalVideoEnabled, role, toggleVideo]);

  useEffect(() => {
    if (!amIScreenSharing) {
      setScreenShare(false);
    }
  }, [amIScreenSharing]);

  const openDeviceSetting = (e) => {
    setDeviceSetting(true);
  }

  const closeDeviceSetting = (e) => {
    setDeviceSetting(false);
  }

  const toggleScreenShare = async (e) => {
    if (screenshareOn && !isScreenShare) return;
    if (isScreenShare) {
      // setLoading(true);
      await hmsActions.setScreenShareEnabled(false);
      // setLoading(false);
    } else {
      // setLoading(true);
      await hmsActions.setScreenShareEnabled(true, {
        videoOnly: false,
        // audioOnly: false,
      });
      // setLoading(false);
    }
    setScreenShare(!isScreenShare);
  }

  const handleLeave = (e) => {
		hmsActions.leave();
    window.location.href = `/`;
    // if (role === 'producer')
		//   window.location.href = `/`;
    // else 
    //   window.open('','_self').close()
	}

  const filterSpeaker = (str = '') => {
    let result = str.replace('[', '').replace(']', '');
    if (str.indexOf('*_*') >= 0) {
      result = result.replace(/[a-zA-Z0-9]*\*_\*/, '');
    }
    return `[${result}]`;
  }

  return (
    role === 'um_observer' ? <></> : <>
      <DeviceSelection
        isOpen={isDeviceSetting}
        message={error}
        setMessage={setError}
        onClose={!error && closeDeviceSetting}
      />

      <TranscriptModal
        isOpen={isShowTranscript}
        closeModal={() => setIsShowTranscript(false)}
        isHeader={false}
        isFooter={false}
        className="bg-gray-500 text-white px-2 py-1"
      >
        {(transcript ?? [])?.map((transcription, idx) => (
          <div
            key={idx}
            className="flex"
          >
            <span className="w-36 whitespace-nowrap overflow-x-hidden mr-3">
              {filterSpeaker(transcription?.speaker)}
            </span>
            {transcription?.message}
          </div>
        ))}
      </TranscriptModal>

      <div className="control-bar flex items-end">
        {role !== 'um_respondent' && role !== 'um_observer' &&
          <div className="flex flex-col items-start rounded-lg p-2 mr-10" style={{background: '#607d8b'}}>
            <span className="text-xs">Playlist</span>
            {/* <ScreenshareAudio /> */}
            <div className="flex justify-around mt-1">
              <div className="p-1">
                <Playlist type={HMSPlaylistType.audio} />
              </div>
              <div className="p-1">
                <Playlist type={HMSPlaylistType.video} />
              </div>
            </div>
          </div>
        }
        <div className="flex items-start">
          <button className="btn-control" onClick={toggleAudio}>
            {isLocalAudioEnabled ? "MUTE" : "UNMUTE"}
          </button>
          {role !== 'um_observer' && <button className="btn-control" onClick={() => toggleVideo()}>
            {isLocalVideoEnabled ? "HIDE" : "UNHIDE"}
          </button>}
          {role !== 'um_respondent' && role !== 'um_observer' && <button className="btn-control" onClick={toggleScreenShare}>
            {isScreenShare ? "STOP" : "SHARE"}
          </button>}

          <div className="btn-control flex justify-center items-center">
            <TranscriptionButton />
          </div>
              
          <button className="btn-control" onClick={openDeviceSetting}>
            DEVICE
          </button>
          {(role === 'um_moderator' || role === 'administrator' || role === 'producer') && 
            <button className="btn-control" onClick={toggleChat}>
              CHAT
            </button>
          }
          <button className="btn-control-leave bg-red-300" onClick={handleLeave}>
            LEAVE
          </button>

          {(role === 'administrator' || role === 'producer') && 
            <ISOProvider users={peers}>
              <div className="ml-10 mt-2">
                <StreamActions />
              </div>
            </ISOProvider>
          }
          {(role === 'administrator' || role === 'producer') && 
            <Tooltip title={window.location.href.replace("/producer", "/respondent")}>
              <Button
                variant="standard"
                className="rounded ml-5 bg-gray-500 text-white px-2 mt-2 h-6"
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href.replace("/producer", "/respondent"))
                }}
              >
                {`</>`} Copy Invite Link
              </Button>
            </Tooltip>
          }
        </div>
      </div>
    </>
  );
}

export default Footer;
