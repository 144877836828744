import React, { useRef } from "react";
import { ReactComponent as DuplicateIcon } from "../icons/document-duplicate.svg";

const CopyField = ({ value }) => {
  const inputRef = useRef();

  const handleInputClick = () => {
    inputRef.current.focus();
    inputRef.current.select();
    document.execCommand("copy");
  };

  return (
    <div className="flex items-center text-gray-700">
      <input
        type="text"
        className="w-72 h-7 p-px rounded-md text-sm font-medium mr-1 border-gray-300 focus:border-yellow-400 focus:ring-yellow-400"
        ref={inputRef}
        readOnly
        value={value}
        onClick={handleInputClick}
      />
      <button className="hover:text-yellow-400" onClick={handleInputClick}>
        <DuplicateIcon className="w-5 h-5" />
      </button>
    </div>
  );
};

export default CopyField;
