import { nanoid } from "nanoid";
import React, { useState, useEffect, useMemo, useContext, useCallback, useRef } from "react";
import { Helmet } from "react-helmet";
import Button from "../../components/button";
import UploadMediaModal from "../uploadImageModal";
import { deleteFile } from "../../helpers/firebaseUpload";
import { useLayerEditorContext } from "../../helpers/hooks/useLayerEditorContext";
import { FirebaseContext } from "../../helpers/contexts/firebaseContext";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFirestore, collection, doc, updateDoc, getDoc, getDocs, setDoc, deleteDoc, query, orderBy } from "@firebase/firestore";
import { CrossIcon } from "@100mslive/react-icons";
import { usePlaylist } from "../../components/hooks/usePlaylist";
import { HMSPlaylistType } from "@100mslive/react-sdk";
import ImageList from "./imageList";
import AudioList from "./audioList";
import VideoList from "./videoList";
import Playlist from "./playlist";
import { compareObject } from "../../utils/compareObject";
import UploadForm from "./uploadForm";
import { uploadToS3 } from "../../utils/uploadToS3";
import { Input } from "@100mslive/react-ui";
import { audioFormats, videoFormats } from "../../utils/utils";
import { PlaylistContext } from "../../helpers/contexts/playlistContext";
import AWS from 'aws-sdk';
import { manualTranscript } from "../IsoVideo/manualTranscript";
import axios from "axios";

const apiToken = "a7a52ee48f2348799d11b1766ac0b605";

const UploadMedia = ({ docRef, conference, conf }) => {
  const { firebaseApp } = useContext(FirebaseContext);
  const storage = getStorage(firebaseApp)
  const db = getFirestore(firebaseApp);
  // const videoRef = collection(db, "videos");
  const videoRef = collection(doc(collection(docRef, "scenes"), conference?.id ?? 'defaultConference'), 'videos');
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [medias, setMedias] = useState([]);
  const [deleting, setDeleting] = useState(false);
	const { activeZone } = useLayerEditorContext();

  const [curTab, setCurTab] = useState('video');
  const [isShowUploadForm, setIsShowUploadForm] = useState(false);

  const { active: activeVideo, list: videoPlaylist, actions: videoActions } = usePlaylist(HMSPlaylistType.video);
  const { active: activeAudio, list: audioPlaylist, actions: audioActions } = usePlaylist(HMSPlaylistType.audio);

  const collectionRef = useMemo(() => collection(docRef, "scenes"), [docRef]);

  const fileInputRef = useRef();

  const { handleUploadNewFiles, playlist } = useContext(PlaylistContext);

  const updateMediaList = useCallback(async () => {
    if (videoRef && conference) {
      const snapshots = await getDocs(query(videoRef, orderBy('timeStamp', 'desc')));
      let temp = [];
      snapshots.forEach(doc => {
        if (doc.data().userId === conference.userId)
          temp.push(doc.data());
      });
      if (
        // JSON.stringify(medias) !== JSON.stringify(temp)
        !compareObject(medias, temp)
      ) {
        setMedias(temp);
      } else {
        // console.log('medias equal')
      }
    }
  }, [videoRef, conference, medias]);

  useEffect(() => {
    updateMediaList();
  }, [updateMediaList]);

  useEffect(() => {
    const thumbs = document.querySelectorAll('.video-thumb');
    if (thumbs) {
      Array.from(thumbs).forEach(thumb => {
        updateDoc(doc(videoRef, thumb.id.split('a76')[1]), {
          duration: thumb.duration
        });
        thumb.onloadeddata = (e) => {
          updateDoc(doc(videoRef, e.target.id.split('a76')[1]), {
            duration: e.target.duration
          });
        }
      });
    }
    updateDoc(doc(collectionRef, conference.activeScene), {
      playVideo: {
        url: '',
        state: false,
        currentTime: 0,
      }
    });
  }, [medias, collectionRef, videoRef, conference?.activeScene]);

  const handleAddToPlaylist = (src=[], type=HMSPlaylistType.video) => {
    let list = JSON.parse(JSON.stringify(playlist ?? []));
    src.forEach(media => {
      if (!list.find(_list => _list.id === media.id)) {
        list.push(media);
      }
    })
    if (type === HMSPlaylistType.video) {
      // videoActions?.setList(list?.filter(media => media.type === 'video'));
      videoActions?.setList(src);
    } else {
      // audioActions?.setList(list?.filter(media => media.type === 'audio'));
      audioActions?.setList(src);
    }
  }

  const handlePlayMedia = (e) => {
    const mediaId = (e.currentTarget?.id ?? e.target.id)?.split('a76')[1];
    let src = {
      type: curTab === 'video' ? HMSPlaylistType.video : HMSPlaylistType.audio,
      id: mediaId,
      name: medias?.find(media => media.id === mediaId)?.title,
      url: medias?.find(media => media.id === mediaId)?.url
    }
    handleAddToPlaylist([src], curTab === 'video' ? HMSPlaylistType.video : HMSPlaylistType.audio);
    if (curTab === 'video') {
      videoActions.play(mediaId);
    } else {
      audioActions.play(mediaId);
    }
  };

  const handlePauseMedia = (e) => {
    if (curTab === 'video') {
      videoActions.pause();
    } else {
      audioActions.pause();
    }
  }

  const handleSeekTo = (e) => {
    if (curTab === 'video') {
      const duration = activeVideo?.duration || medias.find(media => media.id === activeVideo?.id)?.duration;
      videoActions.seekTo(duration ? duration * e.target.value / 100 : e.target.value);
    } else {
      const duration = activeAudio?.duration || medias.find(media => media.id === activeAudio?.id)?.duration;
      audioActions.seekTo(duration ? duration * e.target.value / 100 : e.target.value);
    }
  }

  const createMediaLayer = (src = null) => {
    const id = nanoid();

    setDoc(doc(collection(doc(collectionRef, conference.activeScene), "layers"), id), {
      id,
      type: curTab,
      src,
      x: 0,
      y: 0,
      z: 3,
      volume: 1,
      muted: true,
      currentTime: 0,
      playStatus: 0,
      height: 250,
      width: 444,
      zone: activeZone,
      isCustomRatio: true,
      currentViewType: conference?.currentViewType || 'desktop',
    });

    return id;
  };

  const [progress, setProgress] = useState(0);
  const ReactS3Client = useRef();

  useEffect(() => {
    const config = {
      accessKey: process.env.REACT_APP_AWS_KEY,
      bucket: "cloud-conference-1",
      // dir,
      region: "us-east-2",
      secretKey: process.env.REACT_APP_AWS_SECRET
    }
    AWS.config.update({ 
      bucketName: config.bucket,
      // dirName: s3credential.dir, /* optional */
      region: config.region,
      accessKeyId: config.accessKey,
      secretAccessKey: config.secretKey,
    });
    ReactS3Client.current = new AWS.S3({
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey,
      region: config.region
    });
  }, []);

  const getManualTranscript = async (url) => {
    const axiosInstance = axios.create({
      baseURL: 'https://api.assemblyai.com/',
      headers: {
        'Accept': 'application/json',
        'authorization': apiToken,
        'Content-Type': 'application/json',
      }
    })

    const response = await axiosInstance.post(
      'v2/transcript',
      {
        audio_url: url,
        speaker_labels: true
      }
    );
    return response.data.id;
  }

  const uploadTranscript = async (transcriptId, dir, objectName) => {
    window.Buffer = window.Buffer || require('buffer').Buffer;
    const config = {
      accessKey: process.env.REACT_APP_AWS_KEY,
      bucket: "cloud-conference-1",
      dir,
      region: "us-east-2",
      secretKey: process.env.REACT_APP_AWS_SECRET
    }
    const objectData = JSON.stringify({
      assemblyaiId: transcriptId
    });
    const objectType = 'application/json'; 
    
    const params = {
      Bucket: config.bucket,
      Key: objectName,
      Body: objectData,
      ContentType: objectType,
    };

    const result = await ReactS3Client.current.putObject(params).promise();
    // console.log('transcription *.json uploaded', result);
  };

  const uploadToBucket = async (files) => {
    for (let i = 0; i < files?.length; i++) {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const date = today.getDate();
      const config = {
        bucketName: "cloud-conference-1",
				dirName: `beam/${conference?.id}/${year}${month < 10 ? '0'+month : month}${date < 10 ? '0'+date : date}`,
				region: "us-east-2",
				accessKeyId: process.env.REACT_APP_AWS_KEY,
				secretAccessKey: process.env.REACT_APP_AWS_SECRET,
      }
      const recordedTime = new Date().getTime();
      await uploadToS3({
        config,
        file: files?.[i],
        fileName: `Rec-${conference?.id}-${recordedTime}`
      })
      const snapshot = await getDoc(doc(collection(db, 'recordings'), conference?.id));
      const snapData = snapshot.data();
      let recInfos = snapData?.recordings ?? [];
      recInfos.push({
        recordingTime: recordedTime?.toString(),
        speakers: [],
        title: files?.[i]?.name ?? ''
      })
      setDoc(doc(collection(db, 'recordings'), conference?.id), {
        recordings: recInfos
      })
      ////////// get and upload transcript
      const assemblyaiId = await getManualTranscript(
        `https://${config.bucketName}.s3.${config.region}.amazonaws.com/${config.dirName}/Rec-${conference?.id}-${recordedTime}.mp4`
      );
      await uploadTranscript(assemblyaiId ?? [], config.dirName, `${config.dirName}/transcript-${recordedTime}.json`)
    }
  }

  const addMediaLayer = async ({ img }) => {
    for (let i = 0; i < img?.length; i++) {
      const file = img?.[i];
      if (!file) return;
      const fileId = `${new Date().getTime()}-${file.name.split(' ').join('_').split('+').join('_')}`;
      const fileRef = ref(storage, fileId);
      const task = uploadBytesResumable(fileRef, file);   // <--- See the difference here
      const _promise = new Promise((resolve, reject) => {
        task.on('state_changed' , 

          function progress(snapshot){
            let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(percentage);
          },
          function error(err){

          },
          async function complete(data){
            getDownloadURL(task.snapshot.ref).then((downloadURL) => {
              const id = nanoid();
              setDoc(doc(videoRef, id), {
                id,
                url: downloadURL,
                userId: conference.userId,
                type: curTab,
                title: file.name,
                timeStamp: new Date().getTime(),
              });
              updateMediaList();
              setProgress(0);
              resolve(id);
            });
          }
        );
      })
      let res;
      await _promise.then(data => res = data);
    }
    // return res;
  };

  const updateTitle = async (id, title) => {
    let temp = JSON.parse(JSON.stringify(medias));
    temp.find(_t => _t.id === id).title = title;
    setMedias(temp);
    if (id) {
      updateDoc(doc(videoRef, id), {
        title: title ?? ''
      });
    }
  };

  const handleDelete = async (e) => {
    const mediaId = e.currentTarget?.id ?? e.target.id;
    const _media = medias.find(_v => _v.id === mediaId?.split('a76')[1]);
    setDeleting(mediaId?.split('a76')[1]);
    await deleteFile(firebaseApp)({ name: _media.url });
    await deleteDoc(doc(videoRef, mediaId?.split('a76')[1]));
    let temp = JSON.parse(JSON.stringify(medias));
    temp = temp.filter(_t => _t.id !== mediaId?.split('a76')[1]);
    setMedias(temp);
    setDeleting(false);
  };

  const handleAddToScene = (e) => {
    const mediaId = e.currentTarget?.id ?? e.target.id;
    const _media = medias.find(_v => _v.id === mediaId?.split('a76')[1]);
    createMediaLayer(_media?.url);
  };

  const handleFileChange = async (e) => {
    await handleUploadNewFiles(e.target.files);
    e.target.value = null;
    e.target.files = null;
  };

  return (
		<div style={{paddingLeft: '5px'}}>
      <Helmet>
        <title>Upload Media</title>
      </Helmet>
      <UploadMediaModal
        isOpen={videoModalOpen}
        onClose={() => setVideoModalOpen(false)}
        progress={progress}
        onSubmit={addMediaLayer}
        extension={`${curTab}/*`}
        caption={`Upload ${curTab.toUpperCase()}`}
      />
      <div className="flex w-full">
        <Button
          className={`w-1/4 text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 
            hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 
            font-medium rounded-l-lg text-sm px-5 py-2.5 text-center ${curTab === 'image' ? 'bg-blue-700 text-white' : ''}`}
          onClick={() => setCurTab('image')}
        >
          Image
        </Button>
        <Button
          className={`w-1/4 text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 
          hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 
          font-medium rounded-sm text-sm px-5 py-2.5 text-center ${curTab === 'audio' ? 'bg-blue-700 text-white' : ''}`}
          onClick={() => setCurTab('audio')}
        >
          Audio
        </Button>
        <Button
          className={`w-1/4 text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 
          hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 
          font-medium rounded-sm text-sm px-5 py-2.5 text-center ${curTab === 'video' ? 'bg-blue-700 text-white' : ''}`}
          onClick={() => setCurTab('video')}
        >
          Video
        </Button>
        <Button
          className={`w-1/4 text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 
          hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 
          font-medium rounded-r-lg text-sm px-5 py-2.5 text-center ${curTab === 'playlist' ? 'bg-blue-700 text-white' : ''}`}
          onClick={() => {
            setCurTab('playlist');
            setIsShowUploadForm(false);
          }}
        >
          Playlist
        </Button>
      </div>
      <div className="flex">
        {curTab !== 'playlist' &&
          <div className="flex flex-wrap mt-1">
            <Button
              className="text-xs px-1 bg-purple-700 text-white hover:text-gray-100 hover:bg-purple-800"
              onClick={() => {
                // setVideoModalOpen(true)
                setIsShowUploadForm(!isShowUploadForm);
              }}
            >
              <CrossIcon className="w-4 h-4 rotate-45"/> {curTab === 'image' ? 'Image' : 'Media'}
            </Button>
          </div>
        }
        {curTab !== 'image' &&
          <div className="flex flex-wrap mt-1 ml-1">
            <Button
              className="text-xs px-1 bg-purple-700 text-white hover:text-gray-100 hover:bg-purple-800"
              onClick={() => {
                fileInputRef.current?.click();
              }}
            >
              <CrossIcon className="w-4 h-4 rotate-45"/> Playlist
            </Button>
          </div>
        }
      </div>
      <Input 
        ref={fileInputRef} 
        type="file"
        multiple={true}
        onChange={handleFileChange}
        css={{display: 'none'}}
        accept={'audio/*, video/*'}
      />
      <UploadForm 
        isOpen={isShowUploadForm}
        handleClose={() => setIsShowUploadForm(false)}
        extension={`${curTab}/*`}
        progress={progress}
        onSubmit={addMediaLayer}
        uploadToS3={uploadToBucket}
      />
      <div className="">
        <div className={`flex flex-wrap w-full ${curTab === 'image' ? '' : 'hidden'}`}>
          <ImageList
            medias={medias}
            curTab={curTab}
            handleAddToScene={handleAddToScene}
            deleting={deleting}
            handleDelete={handleDelete}
            updateTitle={updateTitle}
          />
        </div>
        <div className={`${curTab === 'audio' ? '' : 'hidden'}`}>
          <AudioList
            medias={medias}
            curTab={curTab}
            activeAudio={activeAudio}
            handlePlayMedia={handlePlayMedia}
            handlePauseMedia={handlePauseMedia}
            handleSeekTo={handleSeekTo}
            deleting={deleting}
            handleDelete={handleDelete}
            updateTitle={updateTitle}
          />
        </div>
        <div className={`${curTab === 'video' ? '' : 'hidden'}`}>
          <VideoList
            medias={medias}
            curTab={curTab}
            handlePlayMedia={handlePlayMedia}
            handleAddToScene={handleAddToScene}
            deleting={deleting}
            handleDelete={handleDelete}
            updateTitle={updateTitle}
            handlePauseMedia={handlePauseMedia}
            handleSeekTo={handleSeekTo}
            activeVideo={activeVideo}
          />
        </div>
        <div className={`${curTab === 'playlist' ? '' : 'hidden'}`}>
          <Playlist 
            medias={playlist}
          />
        </div>
      </div>
		</div>
  );
};

export default UploadMedia;
