import S3 from 'react-aws-s3';

export const uploadToS3 = ({
  config,
  file,
  fileName
}) => {
  window.Buffer = window.Buffer || require('buffer').Buffer;
  const uploadS3 = new S3(config);
  return new Promise((resolve, reject) => {
    uploadS3.uploadFile(file, `${fileName}.mp4`)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        // console.error(err);
        reject(err);
      })
  });
};
