import React from "react";
import { useForm } from "react-hook-form";
import Button from "../button";
import InputField from "../forms/inputField";
import Modal from "../modal";

const SaveLayoutModal = ({ isOpen, onClose, onSubmit }) => {
  const { register, handleSubmit } = useForm();

  return (
    <Modal
      isOpen={isOpen}
      closeModal={onClose}
      className="w-400"
      isHeader={false}
      isFooter={false}
    >
      <div className="bg-gray-100 w-full p-2 rounded-t-md border-b border-gray-200 flex items-center custom-bg-1">
        <h2 className="font-medium text-xl text-gray-100">Save Layout</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="p-4 custom-bg-2">
        <InputField
          id="name"
          label="Name"
          labelStyle="text-gray-100"
          {...register('name', { required: true })}
        />
        <div className="flex flex-row-reverse mt-4">
          <Button 
            type="submit"
            className="rounded"
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default SaveLayoutModal;
