export const defaultDynamicCoordinatesInVertical = ({ numOfSpeakers }) => {
  if (numOfSpeakers <= 0) return [];

  if (numOfSpeakers > 6) return defaultDynamicCoordinatesInVertical({ numOfSpeakers: 6 })

  const xGap = 2;
  const yGap = 1.5;
  const xPadding = 3.5;
  const yPadding = 2.5;
  const rows = [0, 1, 2, 2, 2, 3, 3];
  const cols = [0, 1, 1, 2, 2, 2 ,2];
  const emptyCols = [
    [],
    [0],
    [0, 0],
    [0, 1],
    [0, 0],
    [0, 0, 1],
    [0, 0, 0]
  ];
  const cellWidth = (100 - 2 * xPadding - (cols[numOfSpeakers] - 1) * xGap) / cols[numOfSpeakers];
  const cellHeight = (100 - 2 * yPadding - (rows[numOfSpeakers] - 1) * yGap) / rows[numOfSpeakers];
  
  let coordinates = [];

  for (let i = 0; i < numOfSpeakers; i++) {
    let curRow = Math.floor(i / cols[numOfSpeakers]);
    let curCol = Math.floor(i % cols[numOfSpeakers]);
    
    coordinates.push({
      mobile_x: xPadding + cellWidth * curCol + xGap * curCol + (cellWidth + xGap) * emptyCols[numOfSpeakers][curRow] / 2,
      mobile_y: yPadding + cellHeight * curRow + yGap * curRow,
      mobile_width: cellWidth / 100 * 607.5,
      // mobile_height: Math.min(cellHeight, cellWidth) / 100 * 1080,
      mobile_height: cellHeight / 100 * 1080,
    })
  }

  return coordinates;
};
