import React, { useCallback } from 'react';
import { useEffect, useMemo, useState, useContext, useRef } from "react";
import { getFirestore, collection, doc, getDoc, setDoc, onSnapshot, query } from "firebase/firestore";
import { useLoading } from "./useLoading";
import { AuthContext } from "../contexts/userSessionContext";
import { compareObject } from "../../utils/compareObject";

export const snapShotToData = (snapshot, idField) => {
  if (!snapshot.exists()) {
    return;
  }

  return {
    ...snapshot.data(),
    ...(idField ? { [idField]: snapshot.id } : null),
  };
};

export const useFirebaseCollection = (query, options) => {
  const { error, loading, reset, setError, setValue, value } = useLoading();

  const ref = useRef(query);
  const { user } = useContext(AuthContext);
  useEffect(() => {
    let listener;
    
    if (ref.current) {
      listener = onSnapshot(ref.current, setValue, setError);
    }

    return () => {
      listener();
    };
  }, [user, setValue, setError]);

  return [value, loading, error];
};

export const useCollectionQuery = (query, options) => {
  const idField = options?.idField;
  const { error, loading, setError, setValue, setLoading, value } = useLoading();

  const listener = useCallback((snapshots) => {
    const curValues = snapshots?.docs?.map(snap => {
      return {
        ...snap.data(),
        ...(idField ? { [idField]: snap.id } : null),
      }
    });
    
    if (!compareObject(value, curValues)) {
      setValue(curValues);
    }
      setLoading(false);
  }, [setValue, value, idField, setLoading]);

  useEffect(() => {
    
    onSnapshot(query, listener, setError);
    
    setLoading(false);
  }, [query, listener, setError, setLoading]);

  
  return [value, loading, error];
};
