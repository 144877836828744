import React from 'react';
import { useContext } from "react";
import { ConferenceCallContext } from '../contexts/conferenceCallContext';

export const useConferenceCall = () => {
  const values = useContext(ConferenceCallContext);

  return {
    ...values
  };
};
