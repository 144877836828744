import React from "react";
import Button from "../components/button";
import Modal from "../components/modal";

const DeleteConferenceModal = ({ isOpen, onClose, onSubmit, name, isRemoving=false }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-1/4" isHeader={false} isFooter={false}>
      <div className="custom-bg-1 w-full p-2 rounded-t-md flex items-center">
        <h2 className="font-medium text-xl text-gray-100">Delete Conference</h2>
      </div>
      <div className="p-4">
        <div className="text-gray-900 text-lg">
          Are you sure you want to delete conference,{" "}
          <span className="font-bold">{name}</span>?<br />
          This action cannot be undone.
        </div>
        <div className="flex flex-row-reverse mt-4 gap-4">
          <Button className="p-2" onClick={onSubmit}>
            {!isRemoving && 'Yes'}
            {isRemoving &&
              <div className="h-5 w-5">
                <div className="uploading-spinner"></div>
              </div>
            }
          </Button>
          <Button className="p-2" onClick={onClose}>No</Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConferenceModal;
