import {
  useState
} from "react";
import { Button, Flex, Input, Select, RadioGroup, Tooltip } from "@100mslive/react-ui";
import roomTypes from "../roomTypes.json";
import { ReactComponent as ChevronDownIcon } from "../../../icons/chevron-down.svg";
import { ArrowRightIcon, RefreshIcon } from "@100mslive/react-icons";
import DeleteConferenceModal from "../../deleteConferenceModal";

const Rooms = ({
  groups: rooms = [],
  setCopiedGroups,
  loading,
  addGroup,
  updateGroup,
  removeGroup,
  selectedGroup,
  setSelectedGroup,
  selectedRemoveUsers,
  setSelectedRemoveUsers,
  configs=[],
  loadConfig,
  removeConfig,
  handleRefreshGroups,
}) => {
  const [addingRoom, setAddingRoom] = useState({
    name: '',
    type: roomTypes?.[0]?.type
  });
  const [isCreating, setIsCreating] = useState(false);
  const [isRoomShow, setIsRoomShow] = useState();
  const [curConfig, setCurConfig] = useState(configs?.[0]?.id);
  const [removingRoom, setRemovingRoom] = useState();
  const [isRemoving, setIsRemoving] = useState(false);
  const [error, setErr] = useState('');
  
  const handleSelectUsers = (event, person) => {
    let temp = selectedRemoveUsers?.map(user => {
      if (user.id === person?.id) {
        return {
          ...user,
          selected: event.target.checked
        }
      } else {
        return user
      }
    }) ?? [];
    if (!temp.some(user => user.id === person?.id)) {
      temp.push({
        ...person,
        selected: event.target.checked
      })
    }
    setSelectedRemoveUsers(temp);
  };

  const isSelected = (id) => {
    return selectedRemoveUsers?.find(user => user.id === id)?.selected;
  }

  return (
    <Flex
      direction="column"
      css={{
        w: 'calc(50% - 50px)'
      }}
    >
      <DeleteConferenceModal
        isOpen={removingRoom}
        onClose={() => setRemovingRoom(false)}
        name={removingRoom?.name?.toUpperCase()}
        isRemoving={isRemoving}
        onSubmit={async () => {
          setIsRemoving(true);
          await removeGroup(removingRoom?.id);
          setIsRemoving(false);
          setRemovingRoom(false)
        }}
      />
      <Flex
        direction="column"
        css={{
          h: '66%'
        }}
      >
        <Flex justify="between">
          <Flex align="center">
            <span className="text-black text-lg mr-2">Rooms</span>
            <div
              className="pointer bg-blue-500 rounded-lg w-5 h-5 flex justify-center items-center cursor-pointer"
              onClick={() => {
                handleRefreshGroups();
              }}
            >
              <RefreshIcon className="w-4 h-4 text-white" />
            </div>
          </Flex>
          <Flex align="center">
            Name
            <div
              className="bg-blue-500 rounded-sm w-4 h-4 flex items-center justify-center mx-1 cursor-pointer"
              onClick={() => {
                let temp = JSON.parse(JSON.stringify(rooms));
                setCopiedGroups(temp?.sort((a, b) => {
                  if (a.name?.toUpperCase() > b.name.toUpperCase()) return 1;
                  if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
                  return 0;
                }))
              }}
            >
              <ArrowRightIcon className="text-white rotate-90 w-4 h-4" />
            </div>
            <div
              className="bg-blue-500 rounded-sm w-4 h-4 flex items-center justify-center mr-2 cursor-pointer"
              onClick={() => {
                let temp = JSON.parse(JSON.stringify(rooms));
                setCopiedGroups(temp?.sort((a, b) => {
                  if (a.name.toUpperCase() > b.name.toUpperCase()) return -1;
                  if (a.name.toUpperCase() < b.name.toUpperCase()) return 1;
                  return 0;
                }))
              }}
            >
              <ArrowRightIcon className="text-white -rotate-90 w-4 h-4" />
            </div>
            Type
            <div
              className="bg-blue-500 rounded-sm w-4 h-4 flex items-center justify-center mx-1 cursor-pointer"
              onClick={() => {
                let temp = JSON.parse(JSON.stringify(rooms));
                setCopiedGroups(temp?.sort((a, b) => {
                  if (a.type > b.type) return 1;
                  if (a.type < b.type) return -1;
                  return 0;
                }))
              }}
            >
              <ArrowRightIcon className="text-white rotate-90 w-4 h-4" />
            </div>
            <div
              className="bg-blue-500 rounded-sm w-4 h-4 flex items-center justify-center cursor-pointer"
              onClick={() => {
                let temp = JSON.parse(JSON.stringify(rooms));
                setCopiedGroups(temp?.sort((a, b) => {
                  if (a.type > b.type) return -1;
                  if (a.type < b.type) return 1;
                  return 0;
                }))
              }}
            >
              <ArrowRightIcon className="text-white -rotate-90 w-4 h-4" />
            </div>
          </Flex>
        </Flex>
        <div className="upload-user border border-2 rounded border-gray-500 p-2 mb-1 w-full overflow-scroll h-auto flex-grow bg-white">
          <RadioGroup.Root
            value={selectedGroup}
            onValueChange={setSelectedGroup}
          >
            <Flex direction="column" css={{w: '100%'}}>
              {rooms?.map(room => (
                <div className="flex flex-col w-full" key={room?.id}>
                  <div
                    className="text-white rounded-lg bg-gray-700 px-2 mb-1 flex justify-between items-center cursor-pointer w-full"
                    onClick={() => {
                      setIsRoomShow(previous => {
                        return {
                          ...previous,
                          [`r_${room?.id}`]: !previous?.[`r_${room?.id}`]
                        }
                      })
                    }}
                  >
                    <div className="flex items-center h-7">
                      <RadioGroup.Item
                        value={room?.id}
                        id={`group_${room?.id}`}
                        css={{ mr: "$4" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <RadioGroup.Indicator />
                      </RadioGroup.Item>
                      <span className="mr-2 text-sm">{room?.name?.toUpperCase()}</span>
                    </div>
                    <div className="flex items-center">
                      <span className="w-14 italic text-sm">{room?.users?.length} user{room?.users?.length > 1 ? 's' : ''}</span>
                      <span className="italic text-sm mr-2 w-28">type: {roomTypes?.find(type => type.type === room?.type)?.label}</span>
                      <button
                        className="rounded-lg bg-gray-600 hover:bg-gray-500 px-2 mr-2 h-5 text-sm w-24"
                        onClick={() => {
                          // removeGroup(room?.id)
                          setRemovingRoom(room)
                        }}
                      >
                        remove room
                      </button>
                      <ChevronDownIcon className={`w-4 h-4 cursor-pointer hover:text-gray-300 ${isRoomShow?.[`r_${room?.id}`] ? 'rotate-180' : ''}`} />
                    </div>
                  </div>
                  {isRoomShow?.[`r_${room?.id}`] && 
                    <div className="group-users pb-1 mx-1 px-1 mb-2 -mt-1 overflow-x-scroll bg-gray-200 rounded-b">
                      <table>
                        {room?.users?.map(user => (
                          <tr key={user?.id} className="border-b border-gray-500">
                            <td>
                              <Input
                                type="checkbox"
                                className="w-4 h-4 mr-1"
                                checked={isSelected(user?.id)}
                                onClick={(e) => {
                                  handleSelectUsers(e, user)
                                }}
                              />
                            </td>
                            <td>
                              <div className="text-sm bg-gray-200 text-black rounded p-1 h-6 flex items-center border justify-center">
                                {user?.firstName}
                              </div>
                            </td>
                            <td>
                              <div className="text-sm bg-gray-200 text-black rounded p-1 h-6 flex items-center border justify-center">
                                {user?.lastName}
                              </div>
                            </td>
                            <td>
                              <div className="text-sm bg-gray-200 text-black rounded p-1 h-6 flex items-center border justify-center">
                                {user?.email}
                              </div>
                            </td>
                            <td>
                              <div className="text-sm bg-gray-200 text-black rounded p-1 h-6 flex items-center border justify-center">
                                {user?.phoneNumber}
                              </div>
                            </td>
                            <td>
                              <div className="text-sm bg-gray-200 text-black rounded p-1 h-6 flex items-center border justify-center">
                                {user?.meta1}
                              </div>
                            </td>
                            <td>
                              <div className="text-sm bg-gray-200 text-black rounded p-1 h-6 flex items-center border justify-center">
                                {user?.meta2}
                              </div>
                            </td>
                            <td>
                              <div className="text-sm bg-gray-200 text-black rounded p-1 h-6 flex items-center border justify-center">
                                {user?.meta3}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  }
                </div>
              ))}
            </Flex>
          </RadioGroup.Root>
        </div>
      </Flex>
      <Flex
        direction="column"
        css={{
          mt: '$6'
        }}
      >
        <Flex align="center" justify="between">
          <span>Create Room</span>
          <Flex>
            <Input 
              css={{
                h: '$12',
                w: '$28',
                mr: '$4'
              }}
              value={addingRoom?.name ?? ''}
              onChange={(e) => {
                setAddingRoom(previous => {
                  return {
                    ...previous,
                    name: e.target.value,
                  }
                })
              }}
            />
            <select
              className="w-28 h-8 rounded-lg bg-gray-900 text-white"
              defaultValue={roomTypes?.[0]?.type}
              onChange={(e) => {
                setAddingRoom(previous => {
                  return {
                    ...previous,
                    type: e.target.value,
                  }
                })
              }}
            >
              {roomTypes?.map((roomType, idx) => (
                <option key={idx} value={roomType?.type}>{roomType?.label}</option>
              ))}
            </select>
          </Flex>
          <Button
            css={{
              w: '$28',
              h: '$12'
            }}
            onClick={async () => {
              setIsCreating(true)
              try {
                await addGroup(addingRoom)
              } catch (err) {
                setErr('Something Error!');
                setTimeout(() => setErr(''), 3000);
              }
              setIsCreating(false)
            }}
            disabled={isCreating}
          >
            Enter
          </Button>
        </Flex>
        {error && <span className="text-red-500">{error}</span>}
        <Flex
          align="center"
          justify="between"
          css={{mt: '$3'}}
        >
          <span>Load Configuration</span>
          <select
            className="w-40 h-8 rounded-lg bg-gray-900 text-white"
            defaultValue={configs?.[0]?.id ?? null}
            onChange={(e) => {
              setCurConfig(e.target.value);
            }}
          >
            <option value=''></option>
            {configs?.map(config => (
              <option key={config.id} value={config.id}>{config.name}</option>
            ))}
          </select>
          <Tooltip title="Load Configuration" side="bottom">
            <Button
              css={{
                w: '$20',
                h: '$12'
              }}
              onClick={() => loadConfig(curConfig)}
            >
              Load
            </Button>
          </Tooltip>
          <Tooltip title="Remove Configuration" side="bottom">
            <Button
              css={{
                w: '$20',
                h: '$12'
              }}
              onClick={() => {
                removeConfig(curConfig)
                setCurConfig('');
              }}
              variant="standard"
            >
              Remove
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  )
};

export default Rooms;
