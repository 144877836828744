import { InfoIcon } from "@100mslive/react-icons";
import { Flex, Input, Label, Text, Tooltip } from "@100mslive/react-ui";
import React, { useCallback, useState } from "react";
import {
  RTMP_RECORD_DEFAULT_RESOLUTION,
  RTMP_RECORD_RESOLUTION_MAX,
  RTMP_RECORD_RESOLUTION_MIN,
} from "../../common/constants";
import { DialogRow } from "../../primitives/DialogContent";
import { ReactComponent as DesktopMobileIcon } from "../../icons/desktop-mobile.svg";
import { ReactComponent as DesktopIcon } from "../../icons/desktop_1.svg";
import { ReactComponent as MobileIcon } from "../../icons/mobile.svg";

export const ResolutionInput = ({
  onResolutionChange,
  disabled,
  tooltipText,
  css,
}) => {
  const [resolution, setResolution] = useState(RTMP_RECORD_DEFAULT_RESOLUTION);

  const resolutionChangeHandler = useCallback(
    event => {
      const { name, value } = event.target;
      let width = name === "resWidth" ? Number(value) : resolution.width;
      let height = name === "resHeight" ? Number(value) : resolution.height;

      if (width === 0) {
        width = null;
      }
      if (height === 0) {
        height = null;
      }
      const newResolution = {
        width: !isNaN(width) ? width : RTMP_RECORD_DEFAULT_RESOLUTION.width,
        height: !isNaN(height) ? height : RTMP_RECORD_DEFAULT_RESOLUTION.height,
      };
      setResolution(newResolution);
    },
    [resolution]
  );

  const handleToggleDevice = (e) => {
    if (resolution?.width !== 1920) {
      setResolution({
        width: 1920,
        height: 1080
      })
    } else {
      setResolution({
        width: 1080,
        height: 1920
      })
    }
  }

  return (
    <DialogRow breakSm css={css}>
      <Flex gap={1}>
        <Label css={{ mb: "$8" }}>Resolution</Label>
        {tooltipText && (
          <Tooltip title={tooltipText}>
            <div>
              <InfoIcon color="#B0C3DB" />
            </div>
          </Tooltip>
        )}
      </Flex>
      <Flex
        justify="between"
        css={{ width: !tooltipText ? "100%" : "70%", "@sm": { width: "100%" } }}
        gap={2}
        direction="column"
      >
        <Flex justify="between" align="end" gap={2}>
          <Flex direction="column" css={{ width: "50%" }}>
            <Text variant="xs">Width</Text>
            <Input
              css={{ width: "100%", mt: "$4" }}
              name="resWidth"
              value={resolution.width}
              onChange={resolutionChangeHandler}
              readOnly={disabled}
              min={RTMP_RECORD_RESOLUTION_MIN}
              max={RTMP_RECORD_RESOLUTION_MAX}
              onBlur={() => onResolutionChange(resolution)}
              type="number"
            />
          </Flex>
          <Flex direction="column" css={{ width: "50%" }}>
            <Text variant="xs">Height</Text>
            <Input
              css={{ width: "100%", mt: "$4" }}
              name="resHeight"
              value={resolution.height}
              onChange={resolutionChangeHandler}
              onBlur={() => onResolutionChange(resolution)}
              readOnly={disabled}
              min={RTMP_RECORD_RESOLUTION_MIN}
              max={RTMP_RECORD_RESOLUTION_MAX}
              type="number"
            />
          </Flex>
          <Flex direction="row">
            <Tooltip
              side="top"
              align="end"
              outlined={true}
              title={
                <span className="text-md">
                  Swap to Desktop Resolution
                </span>
              }
            >
              {/* <DesktopMobileIcon
                onClick={handleToggleDevice}
                className="border border-1 border-gray-700 rounded p-1 w-8 h-9 text-gray-300 cursor-pointer hover:bg-yellow-100"
              /> */}
              <DesktopIcon
                onClick={(e) => {
                  setResolution({
                    width: 1920,
                    height: 1080
                  })
                }}
                className="border border-1 border-gray-700 rounded p-1 w-8 h-9 text-gray-300 cursor-pointer hover:bg-yellow-100"
              />
            </Tooltip>
            <Tooltip
              side="top"
              align="end"
              outlined={true}
              title={
                <span className="text-md">
                  Swap to Mobile Resolution
                </span>
              }
            >
              <MobileIcon
                onClick={(e) => {
                  setResolution({
                    width: 1080,
                    height: 1920
                  })
                }}
                className="border border-1 border-gray-700 rounded p-1 w-8 h-9 text-gray-300 cursor-pointer hover:bg-yellow-100 ml-1"
              />
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    </DialogRow>
  );
};
