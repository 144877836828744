import { nanoid } from "nanoid";
import React, { useMemo, useState, useContext, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Tab as ReactTab, TabList, TabPanel, Tabs } from "react-tabs";
import Layout from "../components/layout/index.js";
import TabButton from "../components/tabButton";
import AuthCheck from "../features/authCheck";
import RecordingList from "../features/recordingList";
import CreateConferenceModal from "../features/createConferenceModal";
import { getFirestore, collection, doc, getDoc, setDoc, onSnapshot, deleteDoc, query, orderBy, updateDoc } from "firebase/firestore";
import { FirebaseContext } from '../helpers/contexts/firebaseContext';
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { AuthContext } from "../helpers/contexts/userSessionContext";
import { roomCreate, roomToggleEnable } from "../features/services/roomService";
import { SessionCardList } from "../components/sessionCardList";
import { SessionCard } from "../components/sessionCard";
import { AddAction } from "../components/addAction";
import { MainView } from "../features/IsoVideo/mainView.js";
import {
  useHMSActions,
  useHMSStore,
  selectIsConnectedToRoom
} from "@100mslive/react-sdk";

const compare = (a, b) => {
  if (a.name < b.name)
    return -1;
  else if (a.name > b.name)
    return 1;
  
  return 0;
};

const Main = () => {
  const [mainView, setMainView] = useState('schedule');

  const hmsActions = useHMSActions();
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const [createConferenceModalOpen, setCreateConferenceModalOpen] = useState(
    false
  );

  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);

  const [_conferences, conferencesLoading] = useCollectionQuery(
    query(collection(db, "conferences"), orderBy('timeStamp', 'desc')),
    {
      idField: "id",
    }
  );

  const { user, loading: userLoading } = useContext(AuthContext);

  // const conferences = _conferences
  // ?.filter(conference => conference.userId === user?.uid || conference.id === '64e39841b620ecc2333ce34f')
  // .sort(compare);
  const conferences = _conferences?.filter(conference => conference.userId === user?.uid)
    // .sort(compare);
  const groupsRef = collection(doc(collection(db, 'profiles'), user?.uid ?? 'defaultUser'), 'groups');
  const [groups, groupsLoading] = useCollectionQuery(groupsRef);

  useEffect(() => {
    if (isConnected) {
      hmsActions.leave();
    }
  }, [isConnected, hmsActions])

  useEffect(() => {
    if (user) {
      const updateTimeStamp = async () => {
        await setDoc(doc(collection(db, 'currentTime'), 'current_time'), {
          id: 'current_time',
          timestamp: new Date().getTime(),
        })
      }
      updateTimeStamp();
    } else {
      // window.location.href="/login"
    }
  }, [user, db]);

  const handleDelete = async (id) => {
    await roomToggleEnable(id, false);
    await deleteDoc(doc(collection(db, "conferences"), id));
    await deleteDoc(doc(groupsRef, id));
  };

  const handleConferenceCreate = async ({ name, roomType }) => {
    await roomCreate({
      name, roomType, firebaseApp, userId: user?.uid
    });

    setCreateConferenceModalOpen(false);
  };

  if (userLoading) {
    return (
      <div className="loader" />
    )
  }

  return (
    <AuthCheck>
      <Layout>
        <Helmet>
          <title>Conference Cloud</title>
        </Helmet>
        <CreateConferenceModal
          key="create-conference-modal"
          isOpen={createConferenceModalOpen}
          onClose={() => setCreateConferenceModalOpen(false)}
          onSubmit={handleConferenceCreate}
        />
        <Tabs
          className="p-12 w-3/4"
          selectedTabClassName="react-tabs__tab--selected"
        >
          <TabList className="flex gap-px">
            <TabButton
              el={ReactTab} 
              className="h-10 text-lg text-gray-200"
              onClick={() => setMainView('schedule')}
            >
              Schedule
            </TabButton>
            <TabButton
              el={ReactTab}
              className="h-10 text-lg text-gray-200"
              onClick={() => setMainView('recording')}
            >
              Recordings
            </TabButton>
          </TabList>
          <div className="relative rounded-b-xl rounded-r-xl w-full p-4 pt-10 pb-12 custom-bg-3 min-h-500">
            <div className={`flex gap-3 w-full justify-around flex-wrap h-full ${mainView === 'recording' ? 'hidden' : ''}`}>
              <div className="h-full">
                <div className={`flex ${conferences && conferences.length ? "justify-between" : "flex-col justify-center items-center min-h-400"} items-center mb-4`}>
                  <div>
                    <h3 className="text-2xl font-paytone-one text-gray-200">Studio Schedule</h3>
                  </div>
                  <AddAction
                    onClick={() => setCreateConferenceModalOpen(true)}
                  />
                </div>
                <SessionCardList>
                  {conferences?.map((conference, idx) => (
                    <SessionCard
                      key={idx}
                      {...conference}
                      onDelete={async () => await handleDelete(conference.id)}
                    />
                  ))}
                </SessionCardList>
              </div>
            </div>
            <div className={`${mainView === 'schedule' ? 'hidden' : ''} absolute left-0 top-0`}>
              <MainView conferences={conferences}/>
            </div>
          </div>
        </Tabs>
      </Layout> 
    </AuthCheck>
  );
};

export default Main;
