import React, { useRef, useContext, useState, useEffect } from "react";
import useResizeObserver from "use-resize-observer";
import { FirebaseContext } from '../helpers/contexts/firebaseContext';
import { uploadRecord } from "./services/uploadRecord";
import StreamEditor from "./streamEditor";
import { hmsStore } from "../hms";
import {
  selectDominantSpeaker,
} from '@100mslive/hms-video-store';

export const StreamEditorHolder = ({
  room,
  conference,
  user,
  scenes,
  localParticipant,
  streamParticipants,
  sessions,
  mySession,
  currentState,
  conferenceId,
  countdown,
  onOpenCountdown,
  stageHolderRef,
  isBeamUser,
  isWaitingRoom = false,
  isTeleprompter = false,
  updateConference = () => {},
}) => {
  const containerRef = useRef();
  const { width, height } = useResizeObserver({ ref: containerRef });
  const { firebaseApp } = useContext(FirebaseContext);
  const _uploadRecord = async (recordUrl, fileName, setProgress) => 
    uploadRecord(firebaseApp, conferenceId, recordUrl, fileName, setProgress);

  const [activeSpeaker, setActiveSpeaker] = useState();

  const onActiveSpeaker = (peer, prevPeer) => {
    // if (prevPeer?.name) console.log(`previous active speaker - ${prevPeer?.name} with role - ${prevPeer?.roleName}`, {prevPeer})
    // if (peer?.name) console.log(`current active speaker - ${peer?.name} with role - ${peer?.roleName}`, {peer})
    if (peer?.name) {
      setActiveSpeaker(peer.id);
    }
    if (prevPeer?.name) {
      setActiveSpeaker(false);
    }
  }

  hmsStore.subscribe(onActiveSpeaker, selectDominantSpeaker);

  const containerWidth = '100%';

  return (
    <div
      id="container_ref"
      ref={containerRef}
      className={`flex-grow overflow-y-hidden mb-2 flex flex-col items-center ${isBeamUser ? "justify-center" : ""}`}
      style={{
        width: containerWidth,
        height: `calc(100vh - ${isBeamUser ? 0 : isWaitingRoom ? 300 : 200}px)`
      }}
    >
      <StreamEditor
        room={room}
        parentWidth={width}
        parentHeight={height}
        key={conference?.activeScene}
        scenes={scenes}
        activeScene={conference?.activeScene}
        participants={streamParticipants}
        renderLocalParticipant={currentState ? (mySession?.editingLive) : (mySession?.live)}
        color={conference?.activeScene?.background?.color}
        backgroundImage={conference?.activeScene?.background?.image}
        mySession={mySession}
        sessions={sessions}
        uploadRecord={_uploadRecord}
        countdown={countdown}
        onOpenCountdown={onOpenCountdown}
        stageHolderRef={stageHolderRef}
        isBeamUser={isBeamUser}
        isWaitingRoom={isWaitingRoom}
        updateConference={updateConference}
        currentViewType={conference?.currentViewType || 'desktop'}
        activeSpeaker={activeSpeaker}
      />
    </div>
  )
}
