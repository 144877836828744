import React, { useEffect } from "react";

const UploadingStatusModal = ({ progress, isOpen, onClose, caption, mask=true, error }) => {
	useEffect(() => {
		if (progress === 100 && mask) {
			setTimeout(() => onClose(), 2000);
		}
	}, [progress, mask, onClose]);

	if (isOpen) {
		return (
			<div className="fixed w-full h-full top-0 left-0 flex justify-center items-center" style={{zIndex:10000000}}>
				{mask && <div
					className="absolute top-0 left-0 w-full h-full bg-gray-400 opacity-70"
					style={{zIndex: 10000000}}
				></div>}
				<div className="w-80 h-auto bg-white rounded-md" style={{zIndex: 10000000, marginTop: mask ? '-200px' : '80px'}}>
					<div className="p-2 bg-gray-300 rounded-t-md">
						{caption}
					</div>
					<div className="relative p-2">
						{error && <p>
							{error}
						</p>}
						{!error && <p>...Uploading {Math.floor(progress)}% done</p>}
						<div className="rounded-md relative">
							<div className="bg-blue-400 h-4 rounded-md" style={{ width: `${progress}%`}}></div>
						</div>
					</div>
				</div>
			</div>
		)
	} else {
		return (
			<></>
		)
	}
};

export default UploadingStatusModal;
