import React from 'react';
import { nanoid } from "nanoid";
import { useMemo, useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../helpers/contexts/userSessionContext";
import DropDownMenu from "../components/dropDownMenu";
import UploadImageModal from "../features/uploadImageModalNProgress";
import UploadMediaModal from "../features/uploadImageModalNProgress";
import UploadPPTModal from "../features/uploadImageModalNProgress";
import { firebaseUpload } from "../helpers/firebaseUpload";
import { FirebaseContext } from '../helpers/contexts/firebaseContext';
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { useDocumentQuery } from "../helpers/hooks/useFirebaseDocument";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
import LayerForm from "../components/layerForms/layerForm";
import Button from "../components/button";
import { Collapse } from "react-collapse";
import { ReactComponent as ChevronDownIcon } from "../icons/chevron-down.svg";
import BackgroundControl from '../components/layerFormControl/backgroundControl';
import ParticipantControl from '../components/layerFormControl/participantControl';
import ForegroundControl from '../components/layerFormControl/foregroundControl';
import OverlayControl from '../components/layerFormControl/overlayControl';
import { getFirestore, collection, doc, getDoc, setDoc, updateDoc, deleteDoc, getDocs } from '@firebase/firestore';
import { useConferenceCall } from '../helpers/hooks/useConferenceCall';
import { ReactComponent as PlusIcon } from "../icons/plus.svg";
import axios from "axios";
import { compareObject } from '../utils/compareObject';

const LayerEditor = ({
  room,
  sceneId,
  conferenceId,
  currentViewType = 'desktop',
}) => {
  const [activeLayerIndex, setActiveLayerIndex] = useState(-1);
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [subImgModalOpen, setSubImgModalOpen] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [currentZone, setCurrentZone] = useState('background');
  const [PPTModalOpen, setPPTModalOpen] = useState(false);

  const containerRef = useRef();

  const { 
    activeLayer, setActiveLayer, currentState, setEdited, backgroundImg, activeZone, 
  } = useLayerEditorContext();

  const [targetZone, setTargetZone] = useState('foreground');

  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);

  const confRef = useMemo(() => doc(collection(db, "conferences"), conferenceId ?? "defaultConference"), [db, conferenceId]);
  const scenesRef = useMemo(() => collection(confRef, "scenes"), [confRef]);

  const { user, hostStatus } = useContext(AuthContext);

  const [editable, setEditable] = useState(() => {
    if (hostStatus) {
      if (user)
        return hostStatus[user?.uid];
      else return false;
    } return false;
  });

  const { participants: _participants, localParticipant } = useConferenceCall({});
  const participants = [localParticipant, ...(_participants ?? [])];

  useEffect(() => {
    setEditable(() => {
      if (hostStatus) {
        if (user)
          return hostStatus[user?.uid];
        else return false;
      } return false;
    });
  }, [hostStatus, user, conferenceId]);

  useEffect(() => {
    if (activeZone) {
      setCurrentZone(activeZone);
    }
  }, [activeZone]);

  useEffect(() => {
    const checkExist = async () => {
      const snapshot = await getDoc(doc(scenesRef, 'editing_scene'));
      if (!snapshot.exists()) {
        setDoc(doc(scenesRef, 'editing_scene'), {
          name: 'EditingScene',
          layers: [],
          background: {},
        })
      }
    }
    checkExist();
  }, [scenesRef])

  const layersRef = useMemo(() => collection(doc(scenesRef, sceneId ?? 'xx'), "layers"), [scenesRef, sceneId]);
  const customFontsRef = useMemo(() => collection(db, 'fonts'), [db]);

  const [conference, confLoading] = useDocumentQuery(confRef);

  const [scenes, scenesLoading] = useCollectionQuery(scenesRef, {
    idField: "id",
  });

  const [layers, layersLoading] = useCollectionQuery(layersRef, {
    idField: "id",
  });

  const zoneLayers = layers?.filter(_ => _?.zone === activeZone);

  const activeSubLayersRef = collection(doc(layersRef, activeLayer ?? 'yyy'), 'sublayers');

  const [_customFonts, customFontsLoading] = useCollectionQuery(customFontsRef, {
    idField: "id",
  });

  const customFonts = useMemo(() => _customFonts?.filter(font => font.userId === user?.uid), [_customFonts, user]);
  const selectLavel = useMemo(() => activeZone === 'participant' ? "Select Participant" : "Select Layer", [activeZone]);
  const activeScene = useMemo(() => scenes?.find(({ id }) => id === conference?.activeScene), [scenes, conference?.activeScene]);
  const editingScene = useMemo(() => scenes?.find(({ id }) => id === 'editing_scene'), [scenes]);

  // const [activeSubLayers, activeSubLayersLoading] = useCollectionQuery(activeSubLayersRef, {
	// 	idField: "id",
	// });
  const [activeSubLayers, setActiveSubLayers] = useState([]);

  useEffect(() => {
    getDocs(collection(doc(layersRef, activeLayer ?? 'yyy'), 'sublayers')).then(snapshots => {
      let _activeSubLayers = [];
      snapshots.forEach(snapshot => _activeSubLayers.push(snapshot.data()));
      if (!compareObject(activeSubLayers, _activeSubLayers)) {
        setActiveSubLayers(_activeSubLayers);
      }
    })
  }, [layersRef, activeLayer, activeSubLayers, participants]);


  useEffect(() => {
    if (layers && layers.length > 0) {
      let i = 0;
      for (let layer of zoneLayers) {
        if (layer.id === activeLayer) {
          setActiveLayerIndex(i);
          break;
        }
        i++;
      }
    }
  }, [activeLayer, layers, zoneLayers]);

  useEffect(() => {
    const ele = document.getElementById('onstage');
    if (activeScene && editingScene) {
      ele.style.backgroundImage = editable && currentState ? `url(${editingScene?.background?.image}` : `url(${activeScene?.background?.image}`;
      ele.style.backgroundColor = editable && currentState ? editingScene?.background?.color : activeScene?.background?.color;
    }
      // let img = document.createElement('img');
      let img = new Image();
      img.style.width = ele?.style.width;
      img.style.height = ele?.style.height;
      img.style.backgroundSize = ele?.style.backgroundSize;
      img.style.backgroundPosition = ele?.style.backgroundPosition;
      img.src = ele?.style.backgroundImage.split('"')[1];
      img.style.background = ele?.style.backgroundColor;
      img.className = 'background';
      img.crossOrigin = 'anonymous';
      if (backgroundImg.current) delete[backgroundImg.current];
      backgroundImg.current = img;
    
  }, [activeScene, editingScene, editable, currentState, backgroundImg]);

  const isParticipantExist = (_layer) => {
    if (activeZone !== 'participant') return true;
    const res = participants?.filter(_ => _.identity === _layer.id);
    if (res && res?.length > 0) return true;
    return false;
  };

  const updateLayer = ({ id, data }) => {
    const topZIndex = getTopZIndex();
    updateDoc(doc(layersRef, id), data);

    if (data.z >= topZIndex) {
      updateTopZIndex(data.z + 1);
    }
    
    setEdited(true);
  };

  const removeLayer = (id) => {
    deleteDoc(doc(layersRef, id));
    setEdited(true);
  };

  const updateSublayer = ({ id, data }) => {
    updateDoc(doc(activeSubLayersRef, id), data);

    setEdited(true);
  };

  const removeSublayer = (id) => {
    deleteDoc(doc(activeSubLayersRef, id));
    setEdited(true);
  };

  const updateTopZIndex = (index) => {
    updateDoc(doc(scenesRef, editable && currentState ? 'editing_scene' : sceneId), {
      topZIndex: index
    });
  };

  const getTopZIndex = () => {
    let _z = -1;
    zoneLayers?.forEach(_ => {
      _z = Math.max(_z, _?.z)
    });
    return _z;
  };

  const createTextLayer = (zone='foreground') => {
    const id = nanoid();
    const topZIndex = getTopZIndex();

    setDoc(doc(collection(doc(scenesRef, editable && currentState ? 'editing_scene' : conference?.activeScene), "layers"), id), {
      id,
      type: "text",
      text: "New Text Layer",
      x: 0,
      y: 0,
      z: topZIndex + 1,
      width: 300,
      height: 100,
      textColor: 'black',
      textAlign: 'center',
      backgroundColor: 'none',
      zone,
      currentViewType,
    });
    setEdited(true);
    setActiveLayer(null);
    return id;
  };

  const createSubTextLayer = (streamId) => {
    const id = nanoid();
    const topZIndex = getTopZIndex();

    setDoc(doc(collection(doc(collection(doc(scenesRef, conference?.activeScene), "layers"), streamId), 'sublayers'), id), {
      id,
      type: "text",
      text: "New Text Layer",
      x: 0,
      y: 0,
      z: topZIndex + 1,
      width: 400,
      height: 100,
      textColor: 'black',
      backgroundColor: 'none',
      currentViewType,
    });
    setEdited(true);
    setActiveLayer(null);
    return id;
  };

  const createImageLayer = ({ src = null, height = 100, width = 100, isCustomRatio }) => {
    const id = nanoid();
    const topZIndex = getTopZIndex();

    setDoc(doc(collection(doc(scenesRef, editable && currentState ? 'editing_scene' : conference?.activeScene), "layers"), id), {
      id,
      type: "image",
      src,
      x: 0,
      y: 0,
      // z: zoneLayers?.length ? zoneLayers?.length : 0,
      z: topZIndex + 1,
      height,
      width,
      isCustomRatio,
      zone: targetZone?targetZone:'foreground',
      currentViewType,
    });
    setEdited(true);
    return id;
  };

  const createSubImageLayer = ({ src = null, height = 100, width = 100, isCustomRatio }) => {
    const id = nanoid();
    const topZIndex = getTopZIndex();
    if (!activeLayer) return;

    setDoc(doc(activeSubLayersRef, id), {
      id,
      type: "image",
      src,
      x: 0,
      y: 0,
      // z: zoneLayers?.length ? zoneLayers?.length : 0,
      z: topZIndex + 1,
      height,
      width,
      isCustomRatio,
      zone: targetZone?targetZone:'foreground',
      currentViewType,
    });
    setEdited(true);
    return id;
  };

  const addImageAndLayer = async ({ img, isCustomRatio }) => {
    const file = img?.[0];
    if (!file) return;
    const url = await firebaseUpload(firebaseApp)({
      name: file.name,
      file,
    });

    const imgObj = new Image();
    const objUrl = window.URL.createObjectURL(file);
    imgObj.onload = function () {
      const maxWidth = 500;
      const maxHeight = 500;
      let width = this.width;
      let height = this.height;

      if (width > maxWidth) {
        let ratio = maxWidth / width;
        height = height * ratio;
        width = width * ratio;
      }

      if (height > maxHeight) {
        let ratio = maxHeight / height;
        height = height * ratio;
        width = width * ratio;
      }

      createImageLayer({
        src: url,
        height: Math.round(height),
        width: Math.round(width),
        isCustomRatio
      });
    };
    imgObj.src = objUrl;
    setEdited(true);
    setImgModalOpen(false);
  };

  const addSubImageAndLayer = async ({ img, isCustomRatio }) => {
    const file = img?.[0];
    if (!file) return;
    const url = await firebaseUpload(firebaseApp)({
      name: file.name,
      file,
    });

    const imgObj = new Image();
    const objUrl = window.URL.createObjectURL(file);
    imgObj.onload = function () {
      const maxWidth = 500;
      const maxHeight = 500;
      let width = this.width;
      let height = this.height;

      if (width > maxWidth) {
        let ratio = maxWidth / width;
        height = height * ratio;
        width = width * ratio;
      }

      if (height > maxHeight) {
        let ratio = maxHeight / height;
        height = height * ratio;
        width = width * ratio;
      }

      createSubImageLayer({
        src: url,
        height: Math.round(height),
        width: Math.round(width),
        isCustomRatio
      });
    };
    imgObj.src = objUrl;
    setEdited(true);
    setSubImgModalOpen(false);
  };

  const createVideoLayer = ({src = null, isCustomRatio}) => {
    const id = nanoid();
    const topZIndex = getTopZIndex();

    setDoc(doc(collection(doc(scenesRef, editable && currentState ? 'editing_scene' : conference?.activeScene), "layers"), id), {
      id,
      type: "video",
      src,
      x: 0,
      y: 0,
      z: topZIndex + 1,
      volume: 1,
      muted: true,
      currentTime: 0,
      playStatus: 0,
      height: 250,
      width: 444,
      isCustomRatio,
      zone: targetZone?targetZone:'foreground',
      currentViewType,
    });
    setEdited(true);
    return id;
  };

  const createTimerLayer = (zone='foreground') => {
    const id = nanoid();
    const topZIndex = getTopZIndex();

    setDoc(doc(collection(doc(scenesRef, editable && currentState ? 'editing_scene' : conference?.activeScene), "layers"), id), {
      id,
      type: "timer",
      zone,
      x: 0,
      y: 0,
      z: topZIndex + 1,
      volume: 1,
      muted: true,
      currentTime: 0,
      playStatus: 0,
      height: 50,
      width: 250,
      backgroundColor: 'black',
      textColor: 'white',
      currentViewType,
    });
    setEdited(true);
    return id;
  };

  const createSubTimerLayer = (zone='foreground') => {
    const id = nanoid();
    const topZIndex = getTopZIndex();

    setDoc(doc(activeSubLayersRef, id), {
      id,
      type: "timer",
      zone,
      x: 0,
      y: 0,
      z: topZIndex + 1,
      volume: 1,
      muted: true,
      currentTime: 0,
      playStatus: 0,
      height: 100,
      width: 400,
      backgroundColor: 'black',
      textColor: 'white',
      currentViewType,
    });
    setEdited(true);
    return id;
  };

  const applyToOthers = async () => {
    for (let participant of participants) {
      if (participant?.identity !== activeLayer) {
        const snapshots = await getDocs(collection(doc(layersRef, participant?.identity), 'sublayers'));
        for (let sublayer of snapshots?.docs) {
          await deleteDoc(doc(collection(doc(layersRef, participant?.identity), 'sublayers'), sublayer?.id));
        }

        for (let sublayer of activeSubLayers) {
          const id = nanoid();
          await setDoc(doc(collection(doc(layersRef, participant?.identity), 'sublayers'), id), {
            ...sublayer,
            id: id
          });
        }
      }
    }
  };

  const addVideoAndLayer = async ({ img, isCustomRatio }) => {
    const file = img?.[0];
    if (!file) return;

    const url = await firebaseUpload(firebaseApp)({
      name: file.name,
      file,
    });

    createVideoLayer({src: url, isCustomRatio});
    setVideoModalOpen(false);
  };

  const addNewFont = async ({ font, family, format }) => {
    const file = font[0];

    const url = await firebaseUpload(firebaseApp)({
      name: file.name,
      file,
    });

    const id = nanoid();

    setDoc(doc(customFontsRef, id), {
      id,
      userId: user?.uid,
      family, format, url
    });
  };


  const addPPTAndLayer = async ({ img }) => {
    const file = img?.[0];
    if (!file) return;

    // const val = await firebaseUpload(firebase)({
    //   name: file.name,
    //   file,
    // });

    // const url = await val.ref.getDownloadURL();

    // curl -i -X POST -d '{"apikey": "_YOUR_API_KEY_", "file":"http://google.com/", "outputformat":"png"}' http://api.convertio.co/convert
    // curl -i -X GET http://api.convertio.co/convert/_ID_/status
    const reader = new FileReader();
    
    const getFileAsBase64 = new Promise((resolve, reject) => {
      reader.onload = (event) => {
        // Remove the metadata before the base64-encoded string.
        const startIndex = reader.result.toString().indexOf("base64,");
        const copyBase64 = reader.result.toString().substr(startIndex + 7);
        resolve(copyBase64);
        // PowerPoint.createPresentation(copyBase64);
      };
      
      try {
        reader.readAsDataURL(file);
      } catch(err) {
        reject(err);
      }
    });

    const fileInBase64 = await getFileAsBase64;

    const cId = await axios.post(`http://api.convertio.co/convert`, {
      "apikey": `6921ff2148415a331c6e9dbfdc3c66fa`,
      "input": "base64",
      "file": fileInBase64,
      "filename": file.name,
      "outputformat": "jpeg"
    }).then(res => res.data.data.id);
    
    if (cId) {
      const getStatus = new Promise((resolve, reject) => {
        const checkStatus = async () => {
          const res = await axios.get(`http://api.convertio.co/convert/${cId}/status`);
          if (res.data.data.step === 'finish' && res.data.status === 'ok') {
            resolve(res.data.data);
          } else if (res.data.status === 'error') {
            reject();
          } else {
            setTimeout(checkStatus, 1000);
          }
        }
        checkStatus();
      });

      const convertedData = await getStatus;

      const zipData = await axios.get(convertedData.output.url);
    } else {
      alert('PPT conversion failed');
    }
    setPPTModalOpen(false);
  };

  const getStreamName = (id) => {
    return participants?.find(participant => participant?.identity === id)?.name?.replace(/[a-zA-Z0-9]*\*_\*/, '');
  }

  if (confLoading || scenesLoading || layersLoading) {
    return <div className="loader">&nbsp;</div>;
  }

  return (
    <div ref={containerRef} id='layer_setting' className="h-full">
      <UploadImageModal
        isOpen={imgModalOpen}
        onClose={() => setImgModalOpen(false)}
        onSubmit={addImageAndLayer}
      />
      <UploadImageModal
        isOpen={subImgModalOpen}
        onClose={() => setSubImgModalOpen(false)}
        onSubmit={addSubImageAndLayer}
      />
      <UploadMediaModal
        isOpen={videoModalOpen}
        onClose={() => setVideoModalOpen(false)}
        onSubmit={addVideoAndLayer}
        caption="Upload Video"
        extension="mp4"
      />
      <UploadPPTModal
        isOpen={PPTModalOpen}
        onClose={() => setPPTModalOpen(false)}
        onSubmit={addPPTAndLayer}
        caption="Upload PPT"
        extension="ppt, pptx"
      />

      <div className="flex flex-col gap-5 p-2">
        <div>
          <div className="">
            {activeZone !== 'interaction' && activeZone !== 'director' && activeZone !== 'communicate' && 
              <h3 className="pb-3 text-gray-100 font-bold text-2xl leading-relaxed">
                {activeZone ? activeZone[0].toUpperCase() + activeZone.slice(1) : ""} Zone
              </h3>
            }
          </div>
          {activeZone !== 'participant' && activeZone !== 'interaction' && activeZone !== 'director' && activeZone !== 'communicate' &&
            <DropDownMenu 
              text="Add Layer" 
              position="relative" 
              className="text-md w-full pr-2 flex justify-between bg-cPurple hover:bg-cPurple text-gray-100 border-none"
            >
              <DropDownMenu.MenuItem
                className="pl-5 text-gray-100 text-md cursor-pointer hover:bg-cPurple flex items-center"
                onClick={() => createTextLayer(activeZone)}
              >
                <PlusIcon className="w-6 h-6" /> Add Text
              </DropDownMenu.MenuItem>
              <DropDownMenu.MenuItem
                className="pl-5 text-gray-100 cursor-pointer hover:bg-cPurple flex"
                onClick={() => {
                  setActiveLayer(null);
                  setTargetZone(activeZone);
                  setPPTModalOpen(true);
                }}
              >
                <PlusIcon className="w-6 h-6" /> Add PPT
              </DropDownMenu.MenuItem>
              <DropDownMenu.MenuItem
                className="pl-5 text-gray-100 cursor-pointer hover:bg-cPurple flex"
                onClick={() => {
                  setActiveLayer(null);
                  setTargetZone(activeZone);
                  setImgModalOpen(true);
                }}
              >
                <PlusIcon className="w-6 h-6" /> Add Image
              </DropDownMenu.MenuItem>
              <DropDownMenu.MenuItem
                className="pl-5 text-gray-100 cursor-pointer hover:bg-cPurple flex"
                onClick={() => {
                  setVideoModalOpen(true);
                  setTargetZone(activeZone);
                  setActiveLayer(null);
                }}
              >
                <PlusIcon className="w-6 h-6" /> Add Video
              </DropDownMenu.MenuItem>
              <DropDownMenu.MenuItem
                className="pl-5 text-gray-100 cursor-pointer hover:bg-cPurple flex"
                onClick={() => {
                  createTimerLayer(activeZone);
                  setActiveLayer(null);
                }}
              >
                <PlusIcon className="w-6 h-6" /> Add Timer
              </DropDownMenu.MenuItem>
            </DropDownMenu>
          }
          {activeZone !== 'interaction' && activeZone !== 'director' && activeZone !== 'communicate' && 
            <DropDownMenu
              text={!activeLayer?selectLavel:zoneLayers?.find(_layer => _layer.id === activeLayer)?.type + '-' 
                + (activeZone === 'participant' ? getStreamName(activeLayer) : activeLayerIndex)}
              position="relative" className="mt-3 w-full pr-2 flex justify-between bg-cPurple hover:bg-cPurple text-gray-100 border-none"
            >
              {zoneLayers && participants && zoneLayers?.filter(_ => isParticipantExist(_)).map((layer, idx) => (
                <DropDownMenu.MenuItem
                  key={layer.id}
                  className="pl-5 text-gray-100 cursor-pointer hover:bg-cPurple flex"
                  onClick={() => {setActiveLayer(layer.id); setActiveLayerIndex(idx)}}
                >
                  {layer.type}-{activeZone === 'participant' ? getStreamName(layer.id) : idx}
                </DropDownMenu.MenuItem>
              ))}
            </DropDownMenu>
          }
        </div>
      </div>
      <div className="px-2 bg-cPurple-light">
        <LayerForm
          activeLayer={activeLayer}
          layers={zoneLayers}
          customFonts={customFonts}
          createSubLayers={{
            createSubTextLayer,
            setSubImgModalOpen,
            createSubTimerLayer,
            applyToOthers,
          }}
          updateLayer={updateLayer}
          removeLayer={removeLayer}
          updateSublayer={updateSublayer}
          removeSublayer={removeSublayer}
          addNewFont={addNewFont}
          activeSubLayers={activeSubLayers}
        />
      </div>
      
      {activeZone === 'background' && 
        <BackgroundControl
          parentHeight={containerRef.current?.offsetHeight}
          activeScene={activeScene}
          firebase={firebaseApp}
          scenesRef={scenesRef}
        />
      }
    </div>
  );
};

export default LayerEditor;
