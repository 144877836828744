import React from 'react';
import { useState, useMemo, useEffect } from "react";
import { getDatabase, ref, onValue, set, child, push, update, get } from "firebase/database";
import { isEqual } from '../../utils/utils';
import { init } from 'logrocket';

export const useHost = () => {
  const rdb = useMemo(() => getDatabase(), []);
	const [hostStatus, setHostStatus] = useState({});

	useEffect(() => {
		return () => {
			setHostStatus({});
		}
	}, []);
	
	const hostLavel = async (conferenceId) => {
		const cRef = ref(rdb, `${conferenceId}/status`);

		const lavelPromise = new Promise((resolve, reject) => {
			let tempLavel = {};
			let caption;
			get(cRef).then((data) => {
				for (let uid in data.val()) {
					if (data.val()[uid].mainHost)
						caption = 'admin host';
					else if (data.val()[uid].host)
						caption = 'co host';
					else caption = 'guest';
					tempLavel[uid] = caption;
				}
				resolve(tempLavel);
			});
		});

		return await lavelPromise.then((res) => {
			return res;
		});
	};

  const toggleHost = async (conferenceId, uid) => {
		
    const uRef = ref(rdb, `${conferenceId}/status/${uid}`);
    let temp = hostStatus ? JSON.parse(JSON.stringify(hostStatus)) : {};
		temp = { ...temp, [uid]: !!!temp[uid] }

		await update(uRef, {
			host: temp[uid],
		});

		initHost(conferenceId);
	}

	const initHost = (conferenceId) => {
    const cRef = ref(rdb, `${conferenceId}/status`);
		let temp = hostStatus ? JSON.parse(JSON.stringify(hostStatus)) : {};

    get(cRef).then((data) => {
			for (let uid in data.val()) {
				if (uid)
					temp = { ...temp, [uid]: !!data.val()[uid].host || !!data.val()[uid].mainHost }
			}
			if (!isEqual(hostStatus, temp)) setHostStatus(temp);
		});
	}

	const setHost = async (value) => {
		let flag = false;
		for (let key in value) {
			if (value[key] !== hostStatus[key]) {
				flag = true;
				break;
			}
		}
		if (flag) {
			await setHostStatus(value);
		}
	}

	const setMainHost = async (conferenceId, uid) => {
		const uRef = ref(rdb, `${conferenceId}/status/${uid}`);
		const snap = await get(uRef);

		if (!snap.exists() || !isEqual({mainHost: true,host: true}, {mainHost:snap.val()?.mainHost, host:snap.val()?.host})) {
			update(uRef, {
				mainHost: true,
				host: true,
			});
		}
	}

  return [hostStatus, hostLavel, setHost, initHost, setMainHost, toggleHost];
};
