import React from 'react';
import { useContext, useMemo } from "react";
import { 
  selectPeers,
  useHMSStore,
  selectPeersScreenSharing,
} from "@100mslive/react-sdk";
import Publication from "./publication";
import { useAudioChannelContext } from "../../helpers/hooks/useAudioChannelContext";
import { AuthContext } from "../../helpers/contexts/userSessionContext";
import Video from './video';

const Participant = ({ width, height, z, participant, disableAudio = false }) => {
  const presenters = useHMSStore(selectPeersScreenSharing);
  const isSharing = useMemo(() => presenters.some(peer => peer.id === participant.id), [presenters, participant]);

  return (
    <Video
      width={width ? width : height / 1080 * 1920}
      height={height}
      peer={participant}
      className="h-full object-cover"
      style={{zIndex: z}}
      isLocal
      isSharing={isSharing}
    />
  );
};

export default Participant;
