import React from 'react';
import Button from "../button";
import InputField from "../forms/inputField";
import { ReactComponent as SortAscendingIcon } from "../../icons/sort-ascending.svg";
import { ReactComponent as SortDescendingIcon } from "../../icons/sort-descending.svg";
import DropDownMenu from "../dropDownMenu";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { useLayerEditorContext } from '../../helpers/hooks/useLayerEditorContext';
import TextSublayerForm from "./textSublayerForm";
import TimerSublayerForm from "./timerSublayerForm";
import ImageSublayerForm from "./imageSublayerForm"
const Z_LIMIT = 30;

const LayerFormContainer = ({ children }) => (
  <div className="p-0">{children}</div>
);

const StreamLayerForm = ({
  z,
  src: url,
  width,
  height,
  id,
  updateLayer,
  removeLayer,
  createSubLayers,
  ...props
}) => {
  const { activeSublayer: activeSublayerId } = useLayerEditorContext();

  const activeSublayer = props?.activeSubLayers?.find(layer => layer.id === activeSublayerId);

  const handleBringForward = () => {
    // don't go over Z_LIMIT
    updateLayer({
      id,
      data: { z: z < Z_LIMIT ? z + 1 : z },
    });
  };

  const handleSendBack = () => {
    // don't go negative
    updateLayer({
      id,
      data: { z: z > 0 ? z - 1 : z },
    });
  };

  const handleDimensionUpdate = ({
    width: givenWidth,
    height: givenHeight,
  }) => {
    updateLayer({
      id,
      data: {
        width: parseInt(givenWidth ? givenWidth : width),
        height: parseInt(givenHeight ? givenHeight : height),
      },
    });
  };

  return (
    <div>
      <div className="w-full border-b border-gray-100 mb-4">
        <h3 className="text-gray-100 font-bold text-lg leading-relaxed">
          Stream Element
        </h3>
      </div>

      <div className="border border-1 rounded p-2 relative">
        <label className="absolute -top-3 left-2 bg-cPurple-light text-white px-2">
          Sub Layer
        </label>
        <DropDownMenu
          text="Add SubLayer"
          position="relative"
          className="text-md w-full pr-2 mt-2 flex justify-between bg-cPurple hover:bg-cPurple text-gray-100 border-none"
        >
          <DropDownMenu.MenuItem
            className="pl-5 text-gray-100 text-md cursor-pointer hover:bg-cPurple flex items-center"
            onClick={() => {
              createSubLayers?.createSubTextLayer(id);
            }}
          >
            <PlusIcon className="w-6 h-6" /> Add Text
          </DropDownMenu.MenuItem>
          <DropDownMenu.MenuItem
            className="pl-5 text-gray-100 cursor-pointer hover:bg-cPurple flex"
            onClick={() => {
              createSubLayers?.setSubImgModalOpen(true);
            }}
          >
            <PlusIcon className="w-6 h-6" /> Add Image
          </DropDownMenu.MenuItem>
          {/* <DropDownMenu.MenuItem
            className="pl-5 text-gray-100 cursor-pointer hover:bg-cPurple flex"
            onClick={() => {
            }}
          >
            <PlusIcon className="w-6 h-6" /> Add Video
          </DropDownMenu.MenuItem> */}
          <DropDownMenu.MenuItem
            className="pl-5 text-gray-100 cursor-pointer hover:bg-cPurple flex"
            onClick={() => {
              createSubLayers?.createSubTimerLayer();
            }}
          >
            <PlusIcon className="w-6 h-6" /> Add Timer
          </DropDownMenu.MenuItem>
        </DropDownMenu>

        <Button
          className="rounded text-white bg-cPurple-light mt-2 underline border-none hover:bg-cPurple-light"
          onClick={() => {
            createSubLayers?.applyToOthers();
          }}
        >
          Apply to Others
        </Button>

        {activeSublayer && activeSublayer?.type === 'text' && 
          <LayerFormContainer>
            <TextSublayerForm
              {...activeSublayer}
              updateLayer={props?.updateSublayer}
              removeLayer={props?.removeSublayer}
              addNewFont={props.addNewFont}
              customFonts={props.customFonts}
            />
          </LayerFormContainer>
        }
        {activeSublayer && activeSublayer?.type === 'timer' && 
          <LayerFormContainer>
            <TimerSublayerForm
              {...activeSublayer}
              updateLayer={props?.updateSublayer}
              removeLayer={props?.removeSublayer}
              addNewFont={props.addNewFont}
              customFonts={props.customFonts}
            />
          </LayerFormContainer>
        }
        {activeSublayer && activeSublayer?.type === 'image' && 
          <LayerFormContainer>
            <ImageSublayerForm
              {...activeSublayer}
              updateLayer={props?.updateSublayer}
              removeLayer={props?.removeSublayer}
            />
          </LayerFormContainer>
        }
      </div>

      <div className="bg-cPurple-light rounded-lg p-1 mb-4 flex flex-col justify-between">
        <div className="mb-4">
          <h4 className="text-gray-100 font-bold text-lg mb-2">Dimensions</h4>
          <div className="flex gap-2">
            {/* <div> */}
              <InputField
								className="w-20 bg-cPurple-light text-white"
                label="Width"
                labelStyle="text-gray-100"
                id="width"
                value={Math.floor(width * 1000) / 1000}
                type="number"
                onChange={(event) =>
                  handleDimensionUpdate({ width: event.target.value })
                }
              />
            {/* </div> */}
            {/* <div> */}
              <InputField
								className="w-20 bg-cPurple-light text-white"
                label="Height"
                labelStyle="text-gray-100"
                id="height"
                value={Math.floor(height * 1000) / 1000}
                type="number"
                onChange={(event) =>
                  handleDimensionUpdate({ height: event.target.value })
                }
              />
            {/* </div> */}
          </div>
        </div>
        <div>
          <h3 className="text-gray-100 font-bold text-lg mb-2">Stacking</h3>
          <div className="flex gap-1 mb-2">
            <Button onClick={handleBringForward} className="w-1/2 text-xs px-1 bg-purple-700 text-white hover:text-gray-700">
              <SortAscendingIcon className="w-4 h-4" />
              Bring Forward
            </Button>
            <Button className="w-1/2 text-xs px-1 bg-purple-700 text-white hover:text-gray-700" onClick={handleSendBack}>
              <SortDescendingIcon className="w-4 h-4" /> Send Back
            </Button>
          </div>
          <span className="italic text-gray-100">Layer Level: {z}</span>
        </div>
      </div>
      {/* <div>
        <Button danger className="w-full" onClick={() => removeLayer(id)}>
          <TrashIcon className="w-4 h-4 mr-px" />
          Remove
        </Button>
      </div> */}
    </div>
  );
};

export default StreamLayerForm;
