import React, { useState, useEffect } from "react";
import Button from "../components/button";
import Modal from "../components/modal";

const UploadImageModal = ({ isOpen, onClose, onSubmit, progress, caption='Upload Image', extension='' }) => {
  const [file, setFile] = useState();
  const [ uploading, setUploading ] = useState(false);

  const handleChange = (e) => {
    setFile(e.target.files);
  };

  useEffect(() => {
    setUploading(false);
  }, [isOpen]);

  const handleSubmit = async (e) => {
    if (uploading) return;
    setUploading(true);
    await onSubmit({ img: file });
    setFile();
    setUploading(false);
    // onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={onClose}
      className="w-1/4"
      isHeader={false}
      isFooter={false}
    >
      <div className="custom-bg-1 w-full p-2 rounded-t-md border-b border-gray-200 flex items-center">
        <h2 className="font-medium text-xl text-gray-100">{caption}</h2>
      </div>
      <div className="custom-bg-2 p-4 text-gray-100">
        {/* <InputField id="name" label="Name" ref={register} required /> */}
        <input id="img" name="img" type="file" onChange={handleChange} accept={extension?extension:''}/>
        <div className="flex flex-row-reverse mt-4">
          <Button
            type="submit"
            disabled={uploading}
            onClick={handleSubmit}
            className="rounded"
          >
            {!uploading && "Submit"}
            {uploading && `...Uploading ${Math.floor(progress)}%`}
          </Button>
        </div>
        {uploading && <div style={{width: '100%', height: '10px', padding: '1px', border: 'solid thin gray', borderRadius: '2px'}}>
          <div style={{width: `${progress}%`, height: '7px', background: 'blue', borderRadius: '2px'}}>

          </div>
        </div>}
      </div>
    </Modal>
  );
};

export default UploadImageModal;
