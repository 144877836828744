import React, { 
  createContext, useState, useContext, useMemo, useEffect, useCallback, useRef
} from "react";
import {
  selectIsConnectedToRoom,
  useHMSActions,
  useHMSStore,
  selectPeers,
  selectLocalPeerID,
  selectLocalPeerName,
  selectAudioTrackByPeerID, 
  selectVideoTrackByPeerID,
} from "@100mslive/react-sdk";
import { useParams } from "react-router-dom";
import { getDatabase, ref as rRef, onValue, update } from "@firebase/database";
import { getFirestore, getDocs, collection, doc, } from "@firebase/firestore";
import { FirebaseContext } from "./firebaseContext";
import { AuthContext } from "./userSessionContext";
import { usePresence } from "../hooks/usePresence";
import { useCollectionQuery } from "../hooks/useFirebaseCollection";

export const ConferenceCallContext = createContext();

const ConferenceCallProvider = ({ roomType, children }) => {
  const [beamParticipant, setBeamParticipant] = useState();
  const [isBeamUser, setIsBeamUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isJoined, setIsJoined] = useState(false);
  
  const { firebaseApp } = useContext(FirebaseContext);
  const rdb = useMemo(() => getDatabase(firebaseApp), [firebaseApp]);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);
  
  const { conferenceId: roomId, role } = useParams();
  
  const participantsRef = collection(doc(collection(db, 'conferences'), roomId ?? "defaultConference"), 'participants');
  const [__participants, __participantLoading] = useCollectionQuery(participantsRef, {
    idField: "id",
  });

  const recordingStartedRef = useMemo(() => rRef(rdb, `/recording/${roomId}/started`), [rdb, roomId]);

  const hmsActions = useHMSActions();
  const localPeerID = useHMSStore(selectLocalPeerID);
  const localPeerName = useHMSStore(selectLocalPeerName);
  const peers = useHMSStore(selectPeers);
  const isConnected = useHMSStore(selectIsConnectedToRoom);

  const { user } = useContext(AuthContext);
  // const { user } = useContext(FirebaseContext);

  const _participants = useMemo(() => peers?.map(peer => {
    const participantProfile = __participants?.find(p => p.id === peer.name.split('*_*')[0]);
    if (!participantProfile) {
      return {
        identity: peer.name.split('*_*')[0],
        peerId: peer.id,
        name: peer.name.split('*_*')[1],
        ...peer
      }
    } else {
      return {
        identity: peer.name.split('*_*')[0],
        peerId: peer.id,
        name: peer.name.split('*_*')[1],
        ...participantProfile,
        ...peer
      }
    }

  }), [peers, __participants]);

  const sessions = usePresence({ user, confId: roomId });

  const localParticipant = useMemo(() => {
    const _localP = _participants.find(participant => participant.peerId === localPeerID && participant.roleName !== 'hls-viewer');
    return {
      ..._localP,
      ...user,
    }
  } , [user, _participants, localPeerID]);

  const participants = useMemo(() => 
    _participants.filter(participant => (participant.peerId !== localPeerID && participant.name.split('*_*')[1] !== 'beam') && participant.roleName !== 'hls-viewer')
  , [_participants, localPeerID]);
  
  const validParticipants = useMemo(() => participants.filter(
    (participant) => participant.identity in (sessions ? sessions : {})
  ), [participants, sessions]);

  const waitingRoomParticipants = useMemo(() => validParticipants.filter((participant) => {
    return (
      !Boolean(sessions[participant.identity].live) &&
      participant.identity !== "restreamer"
    );
  }), [validParticipants, sessions]);

  const streamParticipants = useMemo(() => validParticipants.filter((participant) => 
    Boolean(sessions[participant.identity].live)
  ), [sessions, validParticipants]);

  const getToken = useCallback(async () => {
    let _role = role?.replace('producer', 'broadcaster').replace('um_', '') ?? 'respondent';
    if (user?.isAnonymous && (!role || role === 'producer')) {
      _role = 'beam'
    }
    
    let url = `https://us-central1-voodoocast-ab022.cloudfunctions.net/roomJoin?role=${_role}&room_id=${roomId}`;
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }, [role, roomId, user]);

  const recordingStartedListener = useCallback((snapshot) => {
    const { status, peer_id } = snapshot?.val() ?? {};
    if (status === 'beam.started.success' && localPeerID && peer_id) {
      if (localPeerID !== peer_id) {
        update(recordingStartedRef, {
          status: 'beam.joined.success'
        })
      }
    }
  }, [recordingStartedRef, localPeerID]);

  useEffect(() => {
    return onValue(recordingStartedRef, recordingStartedListener);
  }, [recordingStartedRef, recordingStartedListener])

  const isJoining = useRef(false);

  useEffect(() => {
    if (roomId && !loading && !isJoining.current) {
      if (isConnected) {
        return;
      }
      let username = '';
      if (!user?.displayName) {
        if (user?.isAnonymous && (role === 'producer' || role === 'hls-viewer')) {
          username = 'beam'
        } else {
          return;
        }
      } else {
        username = user?.displayName;
      }
      setLoading(true);
      isJoining.current = true;
      getToken().then(token => {
        hmsActions.join({
          userName: `${user?.uid}*_*${username.split('_').join(' ')}`,
          authToken: token.token,
          settings: {
            isAudioMuted: roomType === 'viewing' ? true : false,
            isVideoMuted: roomType === 'viewing' ? true : false,
          },
          rememberDeviceSelection: true
        }).then(data => {
          setIsJoined(true);
        })
        // setLoading(false);
      });
    }
  }, [user, roomId, role, hmsActions, getToken, loading, isConnected, roomType]);

  useEffect(() => {
    if (isConnected) {
      setLoading(false)
      isJoining.current = false;
    }
  }, [isConnected])

  useEffect(() => {
    window.onunload = () => {
      if (isConnected) {
        hmsActions.leave();
      }
    };
  }, [hmsActions, isConnected]);


  const localAudioTrack = useHMSStore(selectAudioTrackByPeerID(localPeerID));
  const localVideoTrack = useHMSStore(selectVideoTrackByPeerID(localPeerID));
  
  const localTracks = useMemo(() => {
    return [localAudioTrack, localVideoTrack];
  }, [localAudioTrack, localVideoTrack])

  const room = useMemo(() => {
    return { 
      id: roomId,
      localParticipant,
      participants,
      validParticipants,
      waitingRoomParticipants,
      streamParticipants
    }
  }, [roomId, participants, validParticipants, waitingRoomParticipants, streamParticipants, localParticipant]);

  return (
    <ConferenceCallContext.Provider
      value={{
        isJoined,
        loading: loading,
        beamParticipant,
        isBeamUser,
        room,
        localTracks,
        localParticipant,
        participants,
        validParticipants,
        waitingRoomParticipants,
        streamParticipants,
      }}
    >
      {children}
    </ConferenceCallContext.Provider>
  )
};

export default ConferenceCallProvider;
