import {
  useState,
  useContext,
  useEffect,
} from "react";
import { Flex } from "@100mslive/react-ui";
import AddCommunication from "./addCommunication";
import CommunicationList from "./communicationList";
import { ReactComponent as ChevronDownIcon } from "../../../icons/chevron-down.svg";
import { useCollectionQuery } from "../../../helpers/hooks/useFirebaseCollection";
import { FirebaseContext } from "../../../helpers/contexts/firebaseContext";
import { getFirestore, doc, collection, setDoc, updateDoc, deleteDoc } from "@firebase/firestore";
import axios from "axios";
import './index.css';

const Communicate = ({
  isShow=false,
}) => {
  const [isList, setIsList] = useState(true);
  const { user, firebaseApp } = useContext(FirebaseContext);
  const [statusCheck, setStatusCheck] = useState(false);

  const db = getFirestore(firebaseApp);
  const [_communicates, communicatesLoading] = useCollectionQuery(collection(db, 'communicates'), {
    idField: 'id'
  })
  const communicates = (_communicates ?? [])?.filter(com => com.userId === user?.uid);
  
  const [curCommunicate, setCurCommunicate] = useState();

  useEffect(() => {
    if (curCommunicate) {
      setIsList(false);
    }
  }, [curCommunicate]);

  const removeCommunicate = async (id) => {
    const removingCommunicate = communicates?.find(com => com.id === id);
    if (removingCommunicate?.status === 'processed') {
      await deleteDoc(doc(collection(db, 'communicates'), id))
    } else if (removingCommunicate?.type?.type === 'email') {
      const axiosInstance = axios.create({
        baseURL: 'https://us-central1-voodoocast-ab022.cloudfunctions.net/',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      await axiosInstance.post(
        'cancelEmail',
        {
          communicateId: removingCommunicate?.id
        }
      );
      await deleteDoc(doc(collection(db, 'communicates'), id))
    } else if (removingCommunicate?.type?.type === 'sms') {
      const axiosInstance = axios.create({
        baseURL: 'https://us-central1-voodoocast-ab022.cloudfunctions.net/',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      await axiosInstance.post(
        'removeSMS',
        {
          communicateId: removingCommunicate?.id
        }
      );
      await deleteDoc(doc(collection(db, 'communicates'), id))
    } else {
      await deleteDoc(doc(collection(db, 'communicates'), id))
    }
  }

  return (
    <div
      className={`${isShow ? "flex" : "hidden"} absolute bg-white left-0 top-0 px-4 py-2 w-full h-full`}
    >
      <Flex 
        direction="column"
        align="end"
        className="w-2/3 h-full"
      >
        <CommunicationList
          height={isList ? 'h-3/5' : 'h-1/6'}
          communicates={communicates}
          curCommunicate={curCommunicate}
          setCurCommunicate={setCurCommunicate}
          removeCommunicate={removeCommunicate}
        />
        <Flex justify="between" css={{ w: '100%' }}>
          <Flex
            direction="column"
            className="bg-gray-500 hover:bg-gray-600 text-white rounded w-4 cursor-pointer my-1"
            onClick={() => setIsList(previous => !previous)}
          >
            <ChevronDownIcon className="w-4 h-4 rotate-180 -mt-1" />
            <ChevronDownIcon className="w-4 h-4 -mt-2 -mb-1" />
          </Flex>
          <Flex
            direction="column"
            className="bg-gray-500 hover:bg-gray-600 text-white rounded w-4 cursor-pointer my-1"
            onClick={() => setIsList(previous => !previous)}
          >
            <ChevronDownIcon className="w-4 h-4 rotate-180 -mt-1" />
            <ChevronDownIcon className="w-4 h-4 -mt-2 -mb-1" />
          </Flex>
        </Flex>
        <AddCommunication 
          height={isList ? 'calc(40% - 30px)' : 'calc(83% - 30px)'} 
          curCommunicate={curCommunicate}
          setCurCommunicate={setCurCommunicate}
          communicates={communicates}
        />
      </Flex>
      <iframe 
        src={`https://voodoocast-ab022.web.app/create_new_user/${user?.uid}`}
        title="other"
        style={{
          marginLeft: '10px',
          width: '33%',
          height: '100%'
        }}
        allow="clipboard-write"
      ></iframe>
      {/* <iframe 
        src={`http://localhost:3001/create_new_user/${user?.uid}`} title="other"
        style={{
          marginLeft: '10px',
          width: '33%',
          height: '100%'
        }}
        allow="clipboard-write"
      ></iframe> */}
    </div>
  )
};

export default Communicate;
