import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './index.css'
import reportWebVitals from './reportWebVitals'
import { HMSRoomProvider } from "@100mslive/react-sdk";
import { hmsActions, hmsNotifications, hmsStats, hmsStore } from "./hms.js";
import { FeatureFlags } from "./features/services/FeatureFlags";
import { FeatureFlagsInit } from "./features/services/FeatureFlags";
import App from './App'
import Main from './pages/main'
import Login from './pages/login';
import FirebaseProvider from './helpers/contexts/firebaseContext';
import UserSessionProvider from './helpers/contexts/userSessionContext';
import Register from './pages/register';
import ForgotPassword from './pages/forgotPassword';
import Producer from './pages/producer';
import Conference from './pages/conference';
import DiscussionHost from './pages/discussionHost';
import DiscussionClient from './pages/discussionClient';
import { LayerEditorProvider } from './helpers/contexts/layerEditorContext';
import { AppData } from "./components/AppData/AppData.jsx";
import { HMSThemeProvider, Box } from "@100mslive/react-ui";
import { ErrorBoundary } from "./components/ErrorBoundary";
import HLSViewer from './pages/hlsViewer';
import DiscussionPage from './pages/discussionPage';
import BroadcastPage from './pages/broadcastPage';
import WatchpartyPage from './pages/watchPartyPage';
import ViewingPage from './pages/viewingPage';
import store from './helpers/redux/store';
import { Provider } from 'react-redux';
import VimeoOAuth from './components/Streaming/VimeoOAuth';
import { GoogleOAuthProvider } from '@react-oauth/google';

const envPolicyConfig = JSON.parse(process.env.REACT_APP_POLICY_CONFIG || "{}");

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
        <Provider store={store}>
          <HMSThemeProvider
            themeType={'dark'}
            // aspectRatio={getAspectRatio({ width, height })}
            // theme={{
            //   colors: {
            //     brandDefault: color,
            //     brandDark: shadeColor(color, -30),
            //     brandLight: shadeColor(color, 30),
            //     brandDisabled: shadeColor(color, 10),
            //   },
            //   fonts: {
            //     sans: [font, "Inter", "sans-serif"],
            //   },
            // }}
          >
            <HMSRoomProvider
              isHMSStatsOn={FeatureFlags.enableStatsForNerds}
              actions={hmsActions}
              store={hmsStore}
              notifications={hmsNotifications}
              stats={hmsStats}
            >
              <AppData
                appDetails={"{}"}
                policyConfig={envPolicyConfig}
                recordingUrl={''}
                logo={""}
                tokenEndpoint={""}
              />
              <FeatureFlagsInit />
              <FirebaseProvider>
                <UserSessionProvider>
                  {/* <LayerEditorProvider> */}
                    <Router>
                      <Routes>
                        <Route exact path="/login" element={<Login />} />
                        <Route exact path="/register" element={<Register />} />
                        <Route exact path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/conference/:conferenceId/:role" element={<BroadcastPage />} />
                        {/* <Route path="/conference/:conferenceId/" element={<Conference />} /> */}
                        <Route path="/discussion/:conferenceId/:role" element={<DiscussionPage />} />
                        {/* <Route path="/discussion/:conferenceId/" element={<DiscussionClient />} /> */}
                        <Route path="/watch_party/:conferenceId/:role" element={<WatchpartyPage />} />
                        <Route path="/viewing/:conferenceId/:role" element={<ViewingPage />} />
                        <Route path="/" element={<Main />} />
                        {/* <Route path="/hls-view" element={React.lazy(() => import("./components/layout/HLSView"))} /> */}
                        <Route path="/hls-view/:conferenceId/:role" element={<HLSViewer />} />
                        <Route path="/vimeo_oauth" element={<VimeoOAuth />} />
                        <Route path="*" element={<App />}/>
                      </Routes>
                    </Router>
                  {/* </LayerEditorProvider> */}
                </UserSessionProvider>
              </FirebaseProvider>
            </HMSRoomProvider>
          </HMSThemeProvider>
        </Provider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  </React.StrictMode>
)

reportWebVitals()
