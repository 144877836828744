import { fromUnixTime } from "date-fns";
import { nanoid } from "nanoid";
import React, { useState, useContext, useEffect, useMemo, useRef, useCallback } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import Layout from "../components/layout/index.js";
import AddAllGuests from "./addAllGuests";
import AttendeesRoom from "./attendeesRoom";
import HeaderQuickControl from "../components/headerQuickControl/index.js";
import CreateConferenceModal from "./createConferenceModal";
import LayerEditorControls from "./layerEditorControls";
import RoomLock from "./roomLock";
import CountdownModal from "./countdownTimeModal";
import { AuthContext } from "../helpers/contexts/userSessionContext";
import { useConferenceCall } from "../helpers/hooks/useConferenceCall";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { useDocumentQuery } from "../helpers/hooks/useFirebaseDocument";
import { usePresence, useSelfPresence } from "../helpers/hooks/usePresence";
import { ReactComponent as CogIcon } from "../icons/cog.svg";
import { ReactComponent as TeleIcon} from "../icons/teleprompter-1.svg";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
import Teleprompter from "../components/teleprompter/index";
import { ReactComponent as ArrowDrag } from "../icons/chevron-down.svg";
import Button from "../components/button";
import { timeFormat } from "../utils";
import { getFirestore, collection, doc, updateDoc, setDoc, getDocs, getDoc } from "firebase/firestore";
import { getDatabase, ref as rRef, onValue, update } from "firebase/database";
import { FirebaseContext } from '../helpers/contexts/firebaseContext';
import { StreamEditorHolder } from "./streamEditorHolder.js";
import { btnStyle, teleprompterStyle, canvasStyle } from "../components/teleprompterStyle.js";
import { useAudioChannelContext } from "../helpers/hooks/useAudioChannelContext";
import DropDownMenu from "../components/dropDownMenu";
import { Notifications } from "../components/Notifications/Notifications.jsx";
import { ToastContainer } from "../components/Toast/ToastContainer";
import { waitingRoomStyle } from "../components/waitingRoomStyle.js";
import AttendeesRoomMinimized from "./attendeesRoomMinimized.js";
import SidePane from "../components/layout/SidePane";
import { defaultDynamicCoordinates } from "../utils/defaultDynamicCoordinates.js";
import { defaultDynamicCoordinatesInVertical } from "../utils/defaultDynamicCoordinatesInVertical.js";
import { compareObject } from "../utils/compareObject.js";
import { keyToNum } from "../utils/keyToNum.js";
import { ClosedCaptionIcon } from "@100mslive/react-icons";
import { TranscriptionContext } from "../helpers/contexts/transcriptionContext.js";
import RealtimeTranscriptModal from "../components/transcriptModalBC";
import { Tooltip } from "@100mslive/react-ui";

/**
 * Conference is the chief component responsible for rendering the Conference view. There
 * are two primary end-users for this view. Viewers, as in anonymous users, and Producers,
 * the people running the conference.
 */

const Loader = () => <div className="loader">&nbsp;</div>

const Broadcaster = ({
  roomType
}) => {
  const [editConferenceModal, setEditConferenceModal] = useState(false);
  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);
  const rdb = useMemo(() => getDatabase(firebaseApp), [firebaseApp]);
  const { conferenceId } = useParams();
  const { user, loading: userLoading, hostStatus, setMainHost, initHost } = useContext(AuthContext);
  const { currentState, isTeleprompter, setIsTeleprompter, setScenes, setCurManualSpeaker } = useLayerEditorContext();
  const [ countdownTime, setCountdownTime ] = useState(-1);
  const [ isOpenCountTime, setOpenCountTime ] = useState(false);
  const [ countdownMessage, setCountdownMessage] = useState('');
  const [ countdown, setCountdown ] = useState('HH : MM : SS');
  const [attendeeHeight, setAttendeeHeight] = useState();
  const attendeeRef = useRef();
  const teleprompterRef = useRef();
  const stageHolderRef = useRef();
  const { setConferenceId, currentAudioChannel, setCurrentAudioChannel } = useAudioChannelContext();
  const [isWaitingRoom, setIsWaitingRoom] = useState(true);
  const isBeamUser = useMemo(() => user?.isAnonymous, [user?.isAnonymous]);

  useEffect(() => {
    if (roomType === 'viewing') {
      setIsWaitingRoom(false);
    }
  }, [roomType])

  useEffect(() => {
    if (attendeeRef.current)
      setAttendeeHeight(attendeeRef.current.offsetHeight);
  }, []);

  const initializeHostStatus = useCallback(() => {
    initHost(conferenceId);
    if( conferenceId && user && setMainHost) {
      setMainHost(conferenceId, user?.uid);
    }
  }, [conferenceId, user, initHost, setMainHost])

  useEffect(() => {
    initializeHostStatus();
  }, [conferenceId, user, initializeHostStatus]);

  useEffect(() => {
    if (countdownTime === 0) {
      setCountdownMessage("Time is up!");
      setOpenCountTime(true);
    }
    if (countdownTime >=0) {
      setCountdown(timeFormat(countdownTime));
      setTimeout(() => {
        setCountdownTime(countdownTime - 1);
      }, 1000);
    }
  }, [countdownTime]);

  useSelfPresence({ confId: conferenceId, user, currentState, roomType });
  // Create a presence with the real-time datastore. This is important and will be used to manage
  // join requests later on.
  const userSessions = usePresence({ user, confId: conferenceId });

  // Join the conference call
  const {
    loading: joining,
    room,
    localParticipant,
    waitingRoomParticipants,
    streamParticipants,
    participants
  } = useConferenceCall({ conferenceId, user, sessions: userSessions, currentState });

  const { 
    isConnecting,
    isShowTranscript,
    setIsShowTranscript,
    setIsLocalTranscriptEnabled
  } = useContext(TranscriptionContext);

  const mySession = useMemo(() => userSessions?.[user?.uid], [userSessions, user]);

  const docRef = doc(collection(db, "conferences"), conferenceId ?? "defaultConference");
  
  const [conference, loading] = useDocumentQuery(docRef);

  const collectionRef = collection(docRef, "scenes");

  const [scenes, scenesLoading] = useCollectionQuery(collectionRef, {
    idField: "id",
  });

  const activeScene = scenes?.find(scene => scene.id === conference?.activeScene);

  useEffect(() => {
    if (activeScene?.isSingleView === true) {
      waitingRoomParticipants?.forEach(participant => {
        const statusRef = rRef(rdb, `${conferenceId}/status/${participant.identity}`);
        update(statusRef, {
          live: true,
        })
      })
      const statusRef = rRef(rdb, `${conferenceId}/status/${localParticipant?.identity}`);
      update(statusRef, {
        live: true,
      })
    }
  }, [activeScene?.isSingleView, conferenceId, rdb, waitingRoomParticipants, localParticipant]);

  useEffect(() => {
    setScenes(scenes);
  }, [scenes, setScenes]);
  
  const setActiveScene = useCallback((id) => {
    updateDoc(docRef, {
      activeScene: id,
    });
  }, [docRef]);

  const handleShortKeys = useCallback((e) => {
    const ctrlKey = e.ctrlKey;
    const altKey = e.altKey;
    const shiftKey = e.shiftKey;
    const keyCode = keyToNum(e.code);

    if (ctrlKey && shiftKey && altKey && keyCode > 0) {
      const userCreatedScenes = scenes?.filter(scene => !!!scene?.dynamicId && !!!scene?.isSingleView);
      setActiveScene(userCreatedScenes?.[Math.min(userCreatedScenes?.length, keyCode) - 1]?.id);
    } else if (ctrlKey && shiftKey && keyCode > 0) {
      setActiveScene(scenes?.find(scene => scene?.dynamicId === keyCode)?.id);
      updateDoc(docRef, {
        isAutoDynamic: false
      })
    } else if (ctrlKey && altKey && keyCode > 0) {
      setActiveScene(scenes?.find(scene => scene.isSingleView)?.id);
      const allParticipants = [localParticipant, ...participants];
      setCurManualSpeaker(allParticipants?.[(Math.min(allParticipants?.length, keyCode) - 1)]?.identity);
    }
  }, [setActiveScene, scenes, docRef, localParticipant, participants, setCurManualSpeaker]);

  useEffect(() => {
    document.addEventListener('keydown', handleShortKeys);

    return () => {
      document.removeEventListener('keydown', handleShortKeys);
    };
  }, [handleShortKeys])

  const createNewScene = useCallback(async ({ 
    switchOnCreate = false, 
    name = "", 
    isEditingScene = false, 
    dynamicId = 0,
    isSingleView = false,
  }) => {
    if (isEditingScene) {
      await setDoc(doc(collectionRef, 'editing_scene'), {
        name,
        layers: [],
      });
      return 'editing_scene';
    }
    const id = nanoid();
    await setDoc(doc(collectionRef, id ?? 'default'), {
      name,
      layers: [],
      dynamicId,
      isSingleView
    });
    if (switchOnCreate) {
      setActiveScene(id);
    }
    return id;
  }, [setActiveScene, collectionRef]);

  const updateConference = (vals) => {
    if (vals?.scheduledDate) {
      vals.scheduledDate = (vals.scheduledDate?.toDate?.() ?? vals.scheduledDate) || new Date();
    }
    updateDoc(docRef, vals);
    setEditConferenceModal(false);
  };

  const curLiveParticipants = useRef([]);
  const liveParticipants = useMemo(() => mySession?.live ? 
        [localParticipant, ...(streamParticipants ?? [])] : [ ...(streamParticipants ?? [])]
  , [mySession?.live, localParticipant, streamParticipants]);

  useEffect(() => {
    // check if beam user
    if (localParticipant?.isAnonymous) return;
    if (
      liveParticipants?.length && collectionRef && activeScene?.dynamicId > 0 
      && liveParticipants?.length !== activeScene?.dynamicId && conference?.isAutoDynamic
    ) {
      setActiveScene(scenes?.find(scene => scene.dynamicId === Math.max(1, liveParticipants?.length))?.id);
    }
  }, [scenes, liveParticipants?.length, setActiveScene, collectionRef, activeScene?.dynamicId, 
    conference?.isAutoDynamic, localParticipant?.isAnonymous]);

  useEffect(() => {
    // check if beam user
    if (localParticipant?.isAnonymous) return;
    if (liveParticipants && collectionRef) {
      if (activeScene?.isSingleView === true) {
        liveParticipants.forEach((participant, idx) => {
          const defaultCoordinates = defaultDynamicCoordinates({ numOfSpeakers: 1 });
          let updatingData = {
            id: participant.identity,
            type: "stream",
            z: 0,
            mobile_x: 0,
            mobile_y: 0,
            mobile_z: 0,
            mobile_height: 250,
            mobile_width: 444,
            zone: "participant",
            ...defaultCoordinates?.[0]
          };
          try {
            if (participant.identity) {
              getDoc(doc(collection(doc(collectionRef, scenes?.find(scene => scene.isSingleView === true)?.id ?? 'default'), "layers"), participant.identity ?? 'default')).then(snapshot => {
                if (!snapshot.exists()) {
                  setDoc(doc(collection(doc(collectionRef, scenes?.find(scene => scene.isSingleView === true)?.id ?? 'default'), "layers"), participant.identity ?? 'default'), updatingData);
                } else {
                  updateDoc(doc(collection(doc(collectionRef, scenes?.find(scene => scene.isSingleView === true)?.id ?? 'default'), "layers"), participant.identity ?? 'default'), updatingData);
                }
              })
            }
          } catch (err) {
            // console.log('here error 3', err)
          }
        })
      }
      const liveParticipantIds = (liveParticipants ?? []).map(p => p.uid);
      if (!compareObject(curLiveParticipants.current, [liveParticipantIds, activeScene?.dynamicId, conference?.currentViewType]) && activeScene?.dynamicId > 0) {
        curLiveParticipants.current = [liveParticipantIds, activeScene?.dynamicId, conference?.currentViewType];
        // setActiveScene(scenes?.find(scene => scene.dynamicId === Math.max(1, liveParticipants?.length))?.id);
        const numOfSpeakers = activeScene?.dynamicId ?? liveParticipants?.length;
        let defaultCoordinates = conference?.currentViewType === 'mobile' 
          ? defaultDynamicCoordinatesInVertical({ numOfSpeakers })
          : defaultDynamicCoordinates({ numOfSpeakers });
        let defaultOneSpeakerCoordinates = conference?.currentViewType === 'mobile' 
          ? defaultDynamicCoordinatesInVertical({ numOfSpeakers: 1 })
          : defaultDynamicCoordinates({ numOfSpeakers: 1 });
        
        liveParticipants.forEach((participant, idx) => {
          let coordinates = defaultCoordinates?.[idx] ?? defaultOneSpeakerCoordinates?.[0];
          let updatingData = {
            id: participant.identity,
            type: "stream",
            z: 0,
            // mobile_x: 0,
            // mobile_y: 0,
            // mobile_z: 0,
            // mobile_height: 250,
            // mobile_width: 444,
            zone: "participant",
            [conference?.currentViewType === 'mobile' ? 'mobile_ratio' : 'ratio']: conference?.currentViewType !== 'mobile' 
              ? 1920 / 1080 :  coordinates?.mobile_width / coordinates?.mobile_height,
            ...coordinates
          };
          try {
            if (participant.identity) {
              getDoc(doc(collection(doc(collectionRef, scenes?.find(scene => scene.dynamicId === numOfSpeakers)?.id ?? 'default'), "layers"), participant.identity ?? 'default')).then(snapshot => {
                if (!snapshot.exists()) {
                  setDoc(doc(collection(doc(collectionRef, scenes?.find(scene => scene.dynamicId === numOfSpeakers)?.id ?? 'default'), "layers"), participant.identity ?? 'default'), updatingData);
                } else {
                  updateDoc(doc(collection(doc(collectionRef, scenes?.find(scene => scene.dynamicId === numOfSpeakers)?.id ?? 'default'), "layers"), participant.identity ?? 'default'), updatingData);
                }
              })
            }
          } catch (err) {
            // console.log('here error', err)
          }
        })
      }
    }
  }, [collectionRef, liveParticipants, activeScene?.dynamicId, localParticipant?.isAnonymous,
    activeScene?.isSingleView, setActiveScene, scenes, conference?.currentViewType]);

  useEffect(() => {
    // check if beam user
    if (localParticipant?.isAnonymous) return;
    let participants = mySession?.live ? [localParticipant, ...streamParticipants] : [...streamParticipants];
    let _overloaded = [];
    if (activeScene?.dynamicId > 0 && (participants?.length > activeScene?.dynamicId || (conference?.currentViewType === 'mobile' && participants?.length > 6))) {
      let sliceNum = activeScene?.dynamicId;
      if (conference?.currentViewType === 'mobile') {
        sliceNum = Math.min(sliceNum, 6);
      }
      _overloaded = participants?.slice(sliceNum);
    } 
    waitingRoomParticipants?.forEach(participant => {
      const statusRef = rRef(rdb, `${conferenceId}/status/${participant.identity}`)
      if (_overloaded?.some(__overloaded => __overloaded?.identity === participant?.identity)) {
        update(statusRef, {
          isOverloaded: true,
        })
      } else {
        update(statusRef, {
          isOverloaded: false,
        })
      }
    })
    streamParticipants?.forEach(participant => {
      const statusRef = rRef(rdb, `${conferenceId}/status/${participant.identity}`)
      if (_overloaded?.some(__overloaded => __overloaded?.identity === participant?.identity)) {
        update(statusRef, {
          isOverloaded: true,
        })
      } else {
        update(statusRef, {
          isOverloaded: false,
        })
      }
    })
  }, [localParticipant, waitingRoomParticipants, streamParticipants, activeScene?.dynamicId, 
    mySession?.live, rdb, conferenceId, conference?.currentViewType])

  const initializeStreamLayers = useCallback(() => {
    // check if beam user
    if (localParticipant?.isAnonymous) return;

    if (streamParticipants && collectionRef && conference?.activeScene && localParticipant) {
      if (activeScene?.dynamicId) {
        return;
      }
      getDocs(collection(doc(collectionRef, conference?.activeScene ?? 'defaultScene'), "layers")).then(snapshots => {
        const layers = Array.from(snapshots.docs).map(snap => snap.data());
        const needToCreate = streamParticipants.filter(peer => !layers.some(layer => layer.id === peer.identity));
        
        let updatingData = {
          id: localParticipant.identity,
          type: "stream",
          x: 0,
          y: 0,
          z: 0,
          mobile_x: 0,
          mobile_y: 0,
          mobile_z: 0,
          height: 250,
          width: 444,
          mobile_height: 250,
          mobile_width: 444,
          zone: "participant",
        };

        if (!layers.some(layer => layer.id === localParticipant?.identity) && localParticipant.identity) {
          setDoc(doc(collection(doc(collectionRef, conference?.activeScene ?? 'defaultScene'), "layers"), localParticipant.identity ?? 'default'), updatingData);
        }

        needToCreate.forEach((participant, idx) => {
          let updatingData = {
            id: participant.identity,
            type: "stream",
            x: 0,
            y: 0,
            z: 0,
            mobile_x: 0,
            mobile_y: 0,
            mobile_z: 0,
            height: 250,
            width: 444,
            mobile_height: 250,
            mobile_width: 444,
            zone: "participant",
          };
          if (participant.identity)
            setDoc(doc(collection(doc(collectionRef, conference?.activeScene ?? 'default'), "layers"), participant.identity ?? 'default'), updatingData);
        })
      })
    }
  }, [collectionRef, conference, localParticipant, streamParticipants, activeScene?.dynamicId])
  
  useEffect(() => {
    initializeStreamLayers();
  }, [localParticipant, streamParticipants, initializeStreamLayers])

  if (loading || scenesLoading) {
    return (
      <div className="fixed w-full h-full left-0 top-0 flex items-center justify-center">
        <Loader />
      </div>
    )
  }

  const onOpenCountdown = (event) => {
    setOpenCountTime(true);
  };

  const onCloseCountDown = (event) => {
    setCountdownMessage('');
    setOpenCountTime(false);
  }

  return (
    <Layout
      isBeamUser={isBeamUser}
      titleBarContent={
        <div className="flex items-center gap-3 height-2.5">
          <h1 className="text-xl font-extrabold text-gray-800 tracking-loose">
            {/* {conference?.name} */}
          </h1>
          <CogIcon
            className="h-5 w-5 text-gray-200 hover:text-gray-500 cursor-pointer"
            onClick={() => setEditConferenceModal(true)}
          />
          <HeaderQuickControl conferenceId={conferenceId} isHost={true}/>
          {/* <VirtualBackground /> */}
        </div>
      }
    >
      <div className="z-5000">
        <ToastContainer />
        <Notifications />
        <SidePane />
      </div>
      <Helmet>
        <title>VoodooCast - Producer</title>
      </Helmet>
      <RealtimeTranscriptModal
        isOpen={isShowTranscript}
        isConnecting={isConnecting}
        closeModal={() => {
          setIsShowTranscript(false);
          setTimeout(() => setIsLocalTranscriptEnabled(false));
        }}
        initialLeft={50}
        initialTop={50}
        className="bg-gray-800 opacity-90"
      />
      {editConferenceModal && (
        <CreateConferenceModal
          title="Edit Conference"
          isOpen={true}
          onClose={() => setEditConferenceModal(false)}
          onSubmit={updateConference}
          name={conference?.name}
          rtmpUrl={conference?.rtmpUrl}
          scheduledDate={
            conference?.scheduledDate?.seconds
              ? fromUnixTime(conference.scheduledDate.seconds)
              : new Date()
          }
        />
      )}
      <CountdownModal
        isOpen={isOpenCountTime}
        onClose={onCloseCountDown}
        setCountdownTime={setCountdownTime}
        message={countdownMessage}
      />
      {/* {isImageLayersLoading &&
        <div className="flex items-center justify-center w-full h-full fixed custom-bg-4 z-10000 left-0 top-0">
          <Loader />
        </div>
      } */}
      <div className="w-full flex relative h-full">
        <div
          ref={teleprompterRef}
          className={`${isBeamUser ? "w-1/4" : "w-2/12"} pt-5 pl-3 pr-3 relative`}
          style={teleprompterStyle(isTeleprompter, isWaitingRoom, isBeamUser)}
        >
          {!isBeamUser && 
            <Button
              className={` h-10 absolute right-0 rounded p-2 py-2 ${isTeleprompter ? "top-14 bg-green-400 w-6 border-none": "top-0 w-12 bg-green-500"} `}
              style={btnStyle(isTeleprompter)}
              onClick={() => {
                updateDoc(docRef, {
                  teleprompter: {
                    ...conference?.teleprompter,
                    status: {
                      ...conference?.teleprompter?.status,
                      isOpen: !isTeleprompter
                    },
                  }
                });
                setIsTeleprompter(!isTeleprompter);
              }}
            >
              {!isTeleprompter && <>
                  <TeleIcon className="w-10 h-10" />
                </>
              }
              {isTeleprompter && <ArrowDrag className="w-6 h-6" style={{	transform: 'rotate(90deg)' }} /> }
            </Button>
          }  
        
          <Teleprompter
            isHost={hostStatus[user?.uid]}
            isBeamUser={isBeamUser}
            conferenceId={conferenceId}
            isTeleprompter={isTeleprompter}
            setIsTeleprompter={setIsTeleprompter}
            parentRef={teleprompterRef}
          />
        </div>
        <div
          ref={stageHolderRef}
          className="p-5 px-0 pb-0 flex flex-col items-center gap-2 absolute h-full"
          style={{
            ...canvasStyle(isTeleprompter, isBeamUser),
            height: isBeamUser ? '100%' : 'calc(100% - 30px)'
          }}
        >
          <StreamEditorHolder
            room={room}
            conference={conference}
            user={user}
            scenes={scenes}
            localParticipant={localParticipant}
            streamParticipants={streamParticipants}
            mySession={mySession}
            sessions={userSessions}
            currentState={currentState}
            conferenceId={conferenceId}
            countdown={countdown}
            onOpenCountdown={onOpenCountdown}
            stageHolderRef={stageHolderRef}
            isBeamUser={isBeamUser}
            isWaitingRoom={isWaitingRoom}
            isTeleprompter={isTeleprompter}
            updateConference={updateConference}
          />
        </div>
        {!isBeamUser && <div
          className="absolute w-2/12 right-0"
          style={{
            width: '350px',
            height: `calc(100vh - ${(isWaitingRoom ? 220 : 90) - (activeScene?.isSingleView ? 30 : 0)}px)`
          }}
        >
          <LayerEditorControls room={room} conference={conference} conferenceId={conferenceId} docRef={docRef} />
        </div>}
      </div>
      <div
        className=" custom-bg-3 shadow-lg rounded-sm flex flex-col overflow-initial p-1 absolute left-0 bottom-0 w-full z-3000"
        style={waitingRoomStyle(isWaitingRoom, isBeamUser, activeScene?.isSingleView)}
      >
        {!isBeamUser && roomType !== 'viewing' && 
          <button
            className="absolute left-1 text-gray-100 -top-4 w-8 h-4 custom-bg-3 rounded-t"
            onClick={() => setIsWaitingRoom(!isWaitingRoom)}
          >
            <ArrowDrag className="w-8 h-4" style={!isWaitingRoom ? {transform: 'rotate(180deg)'} : {}}/>
          </button>
        }

        <div className="flex justify-between">
          
          {!activeScene?.isSingleView && <h3 className="text-gray-300 font-extrabold text-xl leading-none ml-px pt-1">
            Waiting Room
          </h3>}

          {(!isWaitingRoom || isBeamUser) && 
            <AttendeesRoomMinimized 
              roomType={roomType}
              localParticipant={room && room.localParticipant}
              producer={!user?.isAnonymous}
              sessions={userSessions}
              participants={waitingRoomParticipants}
              streamParticipants={streamParticipants}
              userIsLive={mySession?.live}
              isBeamUser={isBeamUser}
            />
          }

          {(!isBeamUser && !activeScene?.isSingleView) && <div className="flex gap-2 items-center">
          
            <div className="text-lg flex items-center mb-1">
              <Tooltip
                title="Transcription"
                align="center"
                side="top"
              >
                <button
                  className="bg-green-500 hover:bg-green-400 text-gray-700 rounded px-2 mr-1"
                  onClick={() => {
                    const flag = !isShowTranscript;
                    setIsShowTranscript(flag);
                    setTimeout(() => setIsLocalTranscriptEnabled(flag));
                  }}
                >
                  <ClosedCaptionIcon className="w-7 h-7 text-white" />
                </button>
              </Tooltip>
              <button
                className={`${currentAudioChannel === 'Cue' ? "bg-red-500 hover:bg-red-400" : "bg-green-500 hover:bg-green-400"} text-gray-700 rounded px-2`}
                onClick={(e) => {
                  if (currentAudioChannel === 'Cue') {
                    setCurrentAudioChannel('On Stage');
                  } else {
                    setCurrentAudioChannel('Cue');
                  }
                }}
              >
                Cue
              </button>
              <RoomLock />
              <DropDownMenu
                className="flex justify-between px-1 custom-bg-3 border-none text-sm text-gray-100 items-center"
                childCls="custom-bg-2 shadow-lg rounded"
                // icon="screen"
                text={'Max Guests'}
              >
                <DropDownMenu.MenuItem
                  className="text-gray-100 -mt-2"
                >
                  <input
                    name="maxParticipants"
                    type="number"
                    className="h-8 rounded-lg mb-px text-black"
                    onClick={e => e.stopPropagation()}
                  />
                </DropDownMenu.MenuItem>
              </DropDownMenu>
            </div>
            <AddAllGuests />
          </div>}
        </div>

        <div
          ref={attendeeRef}
          className="overflow-x-scroll w-full custom-bg-2 rounded scrollbar-1"
          style={{ height: `calc(100% - ${activeScene?.isSingleView ? 10 : 46}px) `}}
        >
          <AttendeesRoom
            roomType={roomType}
            joining={joining || userLoading}
            room={room}
            height={attendeeHeight}
            localParticipant={room && room.localParticipant}
            producer={!user?.isAnonymous}
            sessions={userSessions}
            participants={waitingRoomParticipants}
            streamParticipants={streamParticipants}
            // userIsLive={currentState ? (mySession?.editingLive) : (mySession?.live)}
            userIsLive={mySession?.live}
            isBeamUser={isBeamUser}
            activeScene={activeScene}
            currentViewType={conference?.currentViewType}
          />
        </div>
      </div>
    
    </Layout>
  );
};

export default Broadcaster;
