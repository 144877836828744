import {
  useState,
  useRef,
  useEffect
} from "react";
import { Flex, Input, Tooltip  } from "@100mslive/react-ui"
import { CrossIcon } from "@100mslive/react-icons";
import { ReactComponent as AscendingIcon } from "../../../icons/asc-sort.svg";
import { ReactComponent as DescendingIcon } from "../../../icons/desc-sort.svg";
import "./director.css";

const CellInput = ({
  placeholder,
  data,
  setData,
  tabIndex,
  isRquired=false,
  bgColor="bg-gray-600"
}) => {
  const inputRef = useRef();
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (isEdit) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  const handleKeyDown = (e) => {
    if (e.code === 'Tab') {
      e.preventDefault();
      inputRef.current.blur();
      const nextEle = e.shiftKey 
        ? document.querySelector(`.person-list[tabindex='${tabIndex - 1}']`) 
        : document.querySelector(`.person-list[tabindex='${tabIndex + 1}']`);
      
      if (nextEle) {
        nextEle.click();
      }
    }
  }

  return (
    <td 
      className="h-8"
    >
      {!isEdit && !isRquired &&
        <div
          tabIndex={tabIndex}
          className={`person-list cursor-pointer h-6 text-sm w-full border rounded p-1 
            ${bgColor} text-white flex justify-center items-center`}
          onClick={(e) => {
            setIsEdit(true);
          }}
        >
          <span>{data}</span>
        </div>
      }
      {!isEdit && isRquired &&
        <Tooltip title="Required Field" side="top">
          <div
            tabIndex={tabIndex}
            className={`person-list cursor-pointer h-6 text-sm w-full border rounded p-1 
              ${bgColor} text-white flex justify-center items-center`}
            onClick={(e) => {
              setIsEdit(true);
            }}
          >
            <span>{data}</span>
          </div>
        </Tooltip>
      }
      {isEdit && !isRquired &&
        <Input
          ref={inputRef}
          defaultValue={data}
          onChange={(e) => {
            setData(e.target.value)
          }}
          onBlur={(e) => {
            setIsEdit(false)
          }}
          css={{
            w: '100%',
            h: '18px',
            px: '$1',
            borderRadius: '$0'
          }}
          onKeyDown={handleKeyDown}
        />
      }
      {isEdit && isRquired &&
        <Tooltip title="Required Field" side="top">
          <Input
            ref={inputRef}
            defaultValue={data}
            onChange={(e) => {
              setData(e.target.value)
            }}
            onBlur={(e) => {
              setIsEdit(false)
            }}
            css={{
              w: '100%',
              h: '18px',
              px: '$1',
              borderRadius: '$0'
            }}
            onKeyDown={handleKeyDown}
          />
        </Tooltip>
      }
    </td>
  )
};

const RowInput = ({
  idx,
  person,
  setData,
  removeRow=()=>{},
  selectedAddingUsers=[],
  setSelectedAddingUsers=()=>{},
}) => {
  const handleSelectUsers = (event, id) => {
    let temp = selectedAddingUsers?.map(user => {
      if (user.id === id) {
        return {
          ...user,
          selected: event.target.checked
        }
      } else {
        return user
      }
    }) ?? [];
    if (!temp.some(user => user.id === id)) {
      temp.push({
        ...person,
        selected: event.target.checked
      })
    }
    setSelectedAddingUsers(temp);
  };

  const isSelected = (id) => {
    return selectedAddingUsers?.find(user => user.id === id)?.selected;
  }

  return (
    <tr key={idx} className="border-b">
      <td>
        <Input
          type="checkbox"
          className="w-4 h-4 mr-1"
          checked={isSelected(person?.id)}
          onClick={(e) => {
            handleSelectUsers(e, person?.id)
          }}
        />
      </td>
      <CellInput
        isRquired={true}
        placeholder="first name"
        bgColor={'bg-gray-600'}
        data={person?.firstName}
        setData={(data) => setData({
          ...person,
          firstName: data
        })}
        tabIndex={7 * idx}
      />
      <CellInput
        isRquired={true}
        placeholder="last name"
        bgColor={'bg-gray-600'}
        data={person?.lastName}
        setData={(data) => setData({
          ...person,
          lastName: data
        })}
        tabIndex={7 * idx + 1}
      />
      <CellInput
        isRquired={true}
        placeholder="email"
        data={person?.email}
        setData={(data) => setData({
          ...person,
          email: data
        })}
        tabIndex={7 * idx + 2}
      />
      <CellInput
        isRquired={true}
        placeholder="phone"
        data={person?.phoneNumber}
        setData={(data) => setData({
          ...person,
          phoneNumber: data
        })}
        tabIndex={7 * idx + 3}
      />
      <CellInput
        placeholder="meta1"
        data={person?.meta1}
        setData={(data) => setData({
          ...person,
          meta1: data
        })}
        tabIndex={7 * idx + 4}
      />
      <CellInput
        placeholder="meta2"
        data={person?.meta2}
        setData={(data) => setData({
          ...person,
          meta2: data
        })}
        tabIndex={7 * idx + 5}
      />
      <CellInput
        placeholder="meta3"
        data={person?.meta3}
        setData={(data) => setData({
          ...person,
          meta3: data
        })}
        tabIndex={7 * idx + 6}
      />
      <td>
        <CrossIcon
          className="w-4 h-4 text-red-500 cursor-pointer"
          onClick={() => removeRow(person?.id)}
        />
      </td>
    </tr>
  )
}

const SortSVG = ({
  curKey,
  sortObj
}) => {
  if (curKey && curKey === sortObj?.sortKey) {
    if (sortObj?.direction) {
      return (
        <AscendingIcon className="w-4 h-4 ml-1" />
      )
    } else {
      return (
        <DescendingIcon className="w-4 h-4 ml-1" />
      )
    }
  } else {
    return (<></>)
  }
};

const SortTBHeader = ({
  label,
  sortKey,
  sortObj,
  setSortObj
}) => {
  return (
    <th
      className="sort-tb-header bg-gray-700 px-1 cursor-pointer"
      onClick={() => {
        setSortObj({
          sortKey: sortKey,
          direction: !!!sortObj?.direction
        })
      }}
    >
      <div className="flex items-center justify-center w-full">
        {label} <SortSVG curKey={sortKey} sortObj={sortObj} />
      </div>
    </th>
  )
};

const PersonsList = ({
  persons=[],
  updatePerson,
  removePerson,
  loading,
  selectedAddingUsers,
  setSelectedAddingUsers,
}) => {
  const [sortObj, setSortObj] = useState({});

  const compareBy = (sorting) => {
    return (a, b) => {
      if (a?.[sorting?.sortKey] > b?.[sorting?.sortKey]) {
        return sorting?.direction ? 1 : -1;
      }
      if (a?.[sorting?.sortKey] < b?.[sorting?.sortKey]) {
        return sorting?.direction ? -1 : 1;
      }
      return 0;
    }
  }

  const sortedPersons = (sortObj?.sortKey ? persons?.sort(compareBy(sortObj)) : persons) ?? [];

  return (
    <Flex
      direction="column"
      css={{
        minHeight: '300px',
        height: '66%',
      }}
    >
      <span className="text-black text-lg">Users</span>
      <div className="upload-user bg-white border border-2 border-gray-500 rounded p-2 mb-1 w-full overflow-scroll h-auto flex-grow">
        <table className="w-full">
          <thead className="pb-1">
            <tr className=" text-white text-sm">
              <th className="bg-gray-700 px-1 rounded-l"></th>
              <SortTBHeader label="First Name" sortKey="firstName" sortObj={sortObj} setSortObj={setSortObj} />
              <SortTBHeader label="Last Name" sortKey="lastName" sortObj={sortObj} setSortObj={setSortObj} />
              <SortTBHeader label="Email" sortKey="email" sortObj={sortObj} setSortObj={setSortObj} />
              <SortTBHeader label="Phone" sortKey="phoneNumber" sortObj={sortObj} setSortObj={setSortObj} />
              <SortTBHeader label="Meta1" sortKey="meta1" sortObj={sortObj} setSortObj={setSortObj} />
              <SortTBHeader label="Meta2" sortKey="meta2" sortObj={sortObj} setSortObj={setSortObj} />
              <SortTBHeader label="Meta3" sortKey="meta3" sortObj={sortObj} setSortObj={setSortObj} />
              <th className="bg-gray-700 px-1 rounded-r"></th>
            </tr>
          </thead>
          <tbody>
            {sortedPersons?.map((person, idx) => (
              <RowInput
                key={idx}
                idx={idx}
                person={person}
                setData={updatePerson}
                removeRow={removePerson}
                selectedAddingUsers={selectedAddingUsers}
                setSelectedAddingUsers={setSelectedAddingUsers}
              />
            ))}
          </tbody>
        </table>
      </div>
    </Flex>
  )
};

export default PersonsList;
