import React, {useState} from 'react';
import Button from "../button.js";
import InputField from "../forms/inputField";
import { ReactComponent as SortAscendingIcon } from "../../icons/sort-ascending.svg";
import { ReactComponent as SortDescendingIcon } from "../../icons/sort-descending.svg";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";
import { ReactComponent as TextLeftAlign } from "../../icons/text-left-align1.svg";
import { ReactComponent as TextRightAlign } from "../../icons/text-right-align1.svg";
import { ReactComponent as TextCenterAlign } from "../../icons/text-center-align1.svg";
import { ReactComponent as PaintIcon } from "../../icons/paint-icon.svg";
// import RichTextEditor from "react-rte";
import { SketchPicker } from 'react-color';
import { defaultFonts } from "../../utils/utils";
import UploadFontModal from "../../features/uploadFontModal";
import TextChangeModal from "../../features/textChangeModal";

const fontSizes = [
	8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60 
];

const Z_LIMIT = 30;

const TextLayerForm = ({ z, width, height, id, updateLayer, removeLayer, ...props }) => {
	// const [richEditorValue, setRichEditorValue] = useState(RichTextEditor.createValueFromString(props.text, 'html'));
	const [textColor, setTextColor] = useState();
	const [backgroundColor, setBackgroundColor] = useState();
	const [fontFamily, setFontFamily] = useState(props.fontFamily);
	const [fontSize, setFontSize] = useState(props.fontSize);
	const [isShowColorPicker, setIsShowColorPicker] = useState(false);
	const [fontBold, setFontBold] = useState('normal');
	const [fontStyle, setFontStyle] = useState('normal');
	const [textDecoration, setTextDecoration] = useState('none');
	const [isUploadNewFont, setIsUploadNewFont] = useState(false);
	const [isBackgroundPicker, setIsBackgroundPicker] = useState(false);
	const [isChangeText, setIsChangeText] = useState(false);

	const handleBringForward = () => {
		// don't go over Z_LIMIT
		updateLayer({
			id,
			data: { z: z < Z_LIMIT ? z + 1 : z },
		});
	};

	const handleSendBack = () => {
		// don't go negative
		updateLayer({
			id,
			data: { z: z > 0 ? z - 1 : z },
		});
	};

	const handleDimensionUpdate = ({
		width: givenWidth,
		height: givenHeight,
	}) => {
		updateLayer({
			id,
			data: {
				width: parseInt(givenWidth ? givenWidth : width),
				height: parseInt(givenHeight ? givenHeight : height),
			},
		});
	};

	const handleChangeFontFamily = (e) => {
		setFontFamily(e.target.value);
		updateLayer({
			id,
			data: {
				fontFamily: e.target.value
			}
		});
	};

	const handleChangeFontSize = (e) => {
		setFontSize(e.target.value);
		updateLayer({
			id,
			data: {
				fontSize: e.target.value
			}
		});
	};

	const handleAddNewFont = async ({ font, family, format }) => {
    await props.addNewFont({font, family, format});
    setIsUploadNewFont(false);
  };

	const handleChangeColor = (color) => {
		setTextColor(color.hex);
		updateLayer({
			id,
			data: {
				textColor: color.hex
			}
		});
	};

	const handleBGChangeColor = (color) => {
		setBackgroundColor(color.hex);
		updateLayer({
			id,
			data: {
				backgroundColor: color.hex
			}
		});
	};

	const handleToggleFontBold = (e) => {
		let _fontBold = fontBold === 'normal' ? 'bold' : 'normal';
		setFontBold(_fontBold);
		updateLayer({
			id,
			data: {
				fontWeight: _fontBold
			}
		});
	};

	const handleToggleItalic = (e) => {
		let _fontStyle = fontStyle === 'normal' ? 'italic' : 'normal';
		setFontStyle(_fontStyle);
		updateLayer({
			id,
			data: {
				fontStyle: _fontStyle
			}
		});
	};

	const handleToggleUnderlined = (e) => {
		let _textDecoration = textDecoration !== 'underline' ? 'underline' : 'none';
		setTextDecoration(_textDecoration);
		updateLayer({
			id,
			data: {
				textDecoration: _textDecoration
			}
		});
	};

	const handleToggleLineThrough = (e) => {
		let _textDecoration = textDecoration !== 'line-through' ? 'line-through' : 'none';
		setTextDecoration(_textDecoration);
		updateLayer({
			id,
			data: {
				textDecoration: _textDecoration
			}
		});
	};

	const handleTextAlign = (align) => {
		updateLayer({
			id,
			data: {
				textAlign: align
			}
		});
	};

	const handleTextUpdate = (text) => {
		updateLayer({
			id,
			data: {
				text: text
			}
		})
	}
	
	return (
		<div>
			<UploadFontModal
        isOpen={isUploadNewFont}
        onClose={() => setIsUploadNewFont(false)}
        onSubmit={handleAddNewFont}
      />
			{/* <TextChangeModal
				isOpen={isChangeText}
				onClose={() => {
					setIsChangeText(false);
				}}
				text={props.text}
				onTextEdit={handleTextUpdate}
				{...props}
			/> */}
			<div className="w-full border-b border-gray-100 mb-2">
				<h3 className="text-gray-100 font-bold text-lg leading-relaxed">
					Text Element
				</h3>
			</div>
			<div className="bg-cPurple-light rounded-lg p-1 mb-4 flex flex-col justify-between">
				<div>
					<h3 className="text-gray-100 font-bold text-lg mb-2">Stacking</h3>
					<div className="flex gap-1 mb-2">
						<Button onClick={handleBringForward} className="w-1/2 text-xs px-1 bg-purple-700 text-white hover:text-gray-700">
							<SortAscendingIcon className="w-4 h-4" />
							Bring Forward
						</Button>
						<Button className="w-1/2 text-xs px-1 bg-purple-700 text-white hover:text-gray-700" onClick={handleSendBack}>
							<SortDescendingIcon className="w-4 h-4" /> Send Back
						</Button>
					</div>
					<span className="italic text-gray-100">Layer Level: {z}</span>
				</div>
				<div className="mb-4 mt-2">
					<h4 className="text-gray-100 font-bold text-lg mb-2">Dimensions</h4>
					<div className="flex gap-2 items-end">
						<div>
							<InputField
								className="w-20 bg-cPurple-light text-white"
								label="Width"
								labelStyle="text-gray-100"
								id="width"
								value={Math.floor(width * 1000) / 1000}
								type="number"
								onChange={(event) =>
									handleDimensionUpdate({ width: event.target.value })
								}
							/>
						</div>
						<div>
							<InputField
								className="w-20 bg-cPurple-light text-white"
								label="Height"
								labelStyle="text-gray-100"
								id="height"
								value={Math.floor(height * 1000) / 1000}
								type="number"
								onChange={(event) =>
									handleDimensionUpdate({ height: event.target.value })
								}
							/>
						</div>
						
					</div>
					{isChangeText &&
						<textarea
							className="w-full h-full rounded mt-1 bg-purple-500 border border-1"
							value={props?.text}
							onChange={(e) => handleTextUpdate(e.target.value)}
						></textarea>
					}
					<Button
						className="rounded p-2 bg-purple-700 text-white hover:text-gray-700 mt-1" 
						onClick={() => {setIsChangeText(!isChangeText)}}
					>
						{isChangeText ? 'Confirm' : 'Change Text'}
					</Button>
				</div>
				<div>
					<div className="w-full flex justify-between">
						<select
							className="h-8 text-xs rounded bg-cPurple-light text-white"
							value={fontFamily}
							onChange={handleChangeFontFamily}
						>
							{/* {fontFmailies.map((font, index) => (
								<option key={`family_${index}`} value={font}>{font}</option>
							))} */}
							{defaultFonts.map((font, index) => (
								<option key={`family_${index}`} value={font.family}>{font.family}</option>
							))}
							{props.customFonts && props.customFonts.map((font, index) => (
								<option key={`custom_family_${index}`} value={font.family}>{font.family}</option>
							))}
						</select>
						<Button
							className="rounded p-2 bg-purple-700 text-white hover:text-gray-700" 
							onClick={() => {setIsUploadNewFont(true)}}
						>
							New Font
						</Button>
					</div>
					<div className="flex items-center justify-between mt-2 mb-2">
						<select
							className="h-8 text-xs rounded bg-cPurple-light text-white"
							value={fontSize}
							onChange={handleChangeFontSize}
						>
							{fontSizes.map((size, index) => (
								<option key={`size_${index}`} value={size}>{size}</option>
							))}
						</select>
						<Button
							className="rounded m-0 w-8 bg-purple-700 text-white hover:text-gray-700 px-1 py-1"
							onClick={() => {
								setIsBackgroundPicker(!isBackgroundPicker);
								setIsShowColorPicker(false);
							}}
						>
							<PaintIcon className="w-full h-full"/>
						</Button>
						<Button 
							onClick={() => {
								setIsShowColorPicker(!isShowColorPicker);
								setIsBackgroundPicker(false);
							}}
							className="rounded m-0 w-8 flex flex-col p-0 bg-purple-700 text-white hover:text-gray-700"
						>
							<span className="font-bold">A</span>
							<div className="w-3" style={{ background: props.textColor, height: '3px' }}></div>
						</Button>
						<Button onClick={handleToggleFontBold} className="rounded m-0 w-8 bg-purple-700 text-white hover:text-gray-700">
							<span className="font-bold">B</span>
						</Button>
						<Button onClick={handleToggleItalic} className="rounded m-0 w-8 bg-purple-700 text-white hover:text-gray-700">
							<span className="italic font-bold">I</span>
						</Button>
						<Button onClick={handleToggleUnderlined} className="rounded m-0 w-8 bg-purple-700 text-white hover:text-gray-700">
							<span className="underline font-bold">U</span>
						</Button>
						<Button onClick={handleToggleLineThrough} className="rounded m-0 w-8 bg-purple-700 text-white hover:text-gray-700">
							<span className="line-through font-bold">U</span>
						</Button>
					</div>
					<div className="flex">
						<Button
							onClick={() => handleTextAlign('left')}
							className="rounded m-0 w-8 p-0 px-0 bg-purple-700 text-white hover:text-gray-700"
						>
							<TextLeftAlign className="w-6"/>
						</Button>
						<Button
							onClick={() => handleTextAlign('center')}
							className="rounded m-0 w-8 p-0 px-0 bg-purple-700 text-white hover:text-gray-700"
						>
							<TextCenterAlign />
						</Button>
						<Button
							onClick={() => handleTextAlign('right')}
							className="rounded m-0 w-8 p-0 px-0 bg-purple-700 text-white hover:text-gray-700"
						>
							<TextRightAlign />
						</Button>
					</div>
					<div className="flex flex-col mt-2">
						{/* <Button className="p-2 bg-purple-700 text-white hover:text-gray-700" onClick={() => setIsBackgroundPicker(!isBackgroundPicker)}>
							Background Color <div className="w-6 h-6 rounded ml-2" style={{background: props.backgroundColor}}></div>
						</Button> */}
						<div>
							<Button className="p-2 mt-1 bg-purple-700 text-white hover:text-gray-700" onClick={(e) => {
									setBackgroundColor(null);
									updateLayer({
										id,
										data: {
											backgroundColor: 'none'
										}
									});
								}}
							> BG Transparent </Button>
						</div>
					</div>
					{isShowColorPicker && <div className="p-2 w-full flex">
						<SketchPicker width={"100%"} className="w-full" color={ textColor } onChangeComplete={ handleChangeColor }/>
					</div>}
					{isBackgroundPicker && <div className="pt-2 w-full flex">
						<SketchPicker width={"100%"} className="w-full" color={ backgroundColor?backgroundColor:0 } onChangeComplete={ handleBGChangeColor }/>
					</div>}
				</div>
				{/* <div>
					<RichTextEditor value={richEditorValue} onChange={handleRichEditorChange}/>
				</div> */}
				
			</div>
			<div>
				<Button danger className="w-full" onClick={() => removeLayer(id)}>
					<TrashIcon className="w-4 h-4 mr-px" />
					Remove
				</Button>
			</div>
		</div>
	);
};

export default TextLayerForm;
