import React, { useState, useEffect, useRef } from "react";
import { DraggableCore } from "react-draggable";

const Draggable = ({
  id,
  children,
  onStop,
  onDrag,
  position,
  parentRef,
  bounds,
  disabled,
  isVisible,
  setIsBeingReposition=()=>{},
  zIndex
}) => {
  const [flag, setFlag] = useState(false);
  const [x, setX] = useState(position?.x);
  const [y, setY] = useState(position?.y ?? 0);
  const [firstX, setFirstX] = useState(0);
  const [firstY, setFirstY] = useState(0);
  const [dx, setDx] = useState(0);
  const [dy, setDy] = useState(0);
  const nodeRef = useRef();

  useEffect(() => {
    setX(position?.x);
    setY(position?.y);
  }, [position?.x, position?.y])

  const style = zIndex ? {
    top: `${flag ? y : y}%`,
    left: `${flag ? x : x}%`,
    zIndex,
  } : {
    top: `${flag ? y : y}%`,
    left: `${flag ? x : x}%`,
  };

  const onHandleStart = (event, details) => {
    setIsBeingReposition(id);
    setFirstX(details.x);
    setFirstY(details.y);
  };

  const onHandleDrag = (event, details) => {
    setFlag(true);
    setX((parseInt(firstX + details.deltaX - dx) / parentRef.current.offsetWidth) * 100);
    setY((parseInt(firstY + details.deltaY - dy) / parentRef.current.offsetHeight) * 100);
    setFirstX(firstX + details.deltaX);
    setFirstY(firstY + details.deltaY);
    // onDrag(null, { x, y });
  };

  const onHandleStop = (event, details) => {
    onDrag(null, { x, y });
    setIsBeingReposition(null);
    setFirstX(0);
    setFirstY(0);
    setTimeout(() => {
      setFlag(false);
    }, 10);
  };

  const onHandleMouseDown = (event) => {
    setFirstX(nodeRef.current.offsetLeft);
    setFirstY(nodeRef.current.offsetTop);
    setDx(event.clientX - nodeRef.current.offsetLeft - parentRef?.current?.getBoundingClientRect().x);
    setDy(event.clientY - nodeRef.current.offsetTop - parentRef?.current?.getBoundingClientRect().y);
    // event.stopPropagation();
  };

  return (
    <DraggableCore
      onMouseDown={onHandleMouseDown}
      onStart={onHandleStart}
      onStop={onHandleStop}
      onDrag={onHandleDrag}
      bounds={bounds}
      disabled={disabled}
      nodeRef={nodeRef}
    >
      <div
        id={id}
        className="dragging" 
        ref={nodeRef} 
        style={{ position: 'absolute', ...style, visibility: isVisible ? 'initial' : 'hidden' }}
        // style={{ position: 'absolute', ...style, display: isVisible ? 'initial' : 'none' }}
      >
        {/* {React.cloneElement(React.Children.only(children), {
          style: { ...children.props.style, ...style },
        })} */}
        {children}
      </div>
    </DraggableCore>
  );
};

export default Draggable;
