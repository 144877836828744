import HLSView from "../components/layout/HLSView";
import ConferenceCallProvider from "../helpers/contexts/conferenceCallContext";
import { LayerEditorProvider } from "../helpers/contexts/layerEditorContext";
const HLSViewer = () => {
  return (
    <LayerEditorProvider>
      <ConferenceCallProvider>
        <HLSView />
      </ConferenceCallProvider>
    </LayerEditorProvider>
  )
};

export default HLSViewer;
