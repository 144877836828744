import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";

import { getFirestore, collection, doc, getDoc, setDoc, updateDoc, deleteDoc, onSnapshot } from "firebase/firestore";
import { FirebaseContext } from '../helpers/contexts/firebaseContext';
import { useParams } from "react-router-dom";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import TextLayer from './textLayer';
import ImageLayer from './imageLayer';
import TimerLayer from './timerLayer';
import { AuthContext } from "../helpers/contexts/userSessionContext";

const SubLayers = ({
  id: subLayerId,
  activeScene,
  screenLocked = false,
  editable,
  currentViewType,
  activeLayer,
  setActiveLayer,
  semiActiveLayer,
  setSemiActiveLayer,
  ...props
}) => {
  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);
	const { conferenceId } = useParams();

	const [isBeingReposition, setIsBeingReposition] = useState(false);
  
  const parentRef = useRef();

  const activeRef = 
    collection(
      doc(
        collection(
          doc(
            collection(
              doc(
                collection(
                  db, "conferences"
                ), conferenceId ?? "defaultConference"
              ), "scenes"
            ), activeScene ?? "defaultScene"
          ), "layers"
        ), subLayerId ?? "defaultLayer"
      ), 'sublayers'
    );

  const [subLayers, subLayersLoading] = useCollectionQuery(activeRef, {
		idField: "id",
	});

	const hoveredLayer = subLayers?.filter(_=> _.id === semiActiveLayer)?.[0];

  const customFontsRef = collection(db, 'fonts');
	const [customFonts, customFontsLoading] = useCollectionQuery(customFontsRef, {
		idField: "id",
	});

  const compare = (a, b) => {
    if (a.width * a.height > b.width * b.height) return 1;
    if (a.width * a.height < b.width * b.height) return -1;
    if (a.z > b.z) return 1;
    if (a.z < b.z) return -1;
    return 0;
  };

  const handleDrag = (id) => (_, { x, y }) => {
		if (isBeingReposition !== id) setIsBeingReposition(id);
		try {
			if (currentViewType === 'mobile') {
				updateDoc(doc(activeRef, id), {
					mobile_x: x,
					mobile_y: y,
				});
			} else {
				updateDoc(doc(activeRef, id), {
					x,
					y,
				});
			}
		} catch (err) {
			// console.log(err);
		}
	};

  const handleResize = ({ id, height, width }) => {
		if (isBeingReposition !== id) setIsBeingReposition(id);
		if (currentViewType === 'mobile') {
			updateDoc(doc(activeRef, id), {
				mobile_height: height,
				mobile_width: width,
			});
		} else {
			updateDoc(doc(activeRef, id), {
				height,
				width,
			});
		}
	};

  const handleBringForward = ({id, z}) => {
		if (currentViewType === 'mobile') {
			updateDoc(doc(activeRef, id), {
				mobile_z: z < 30 ? z + 1 : z
			});
		} else {
			updateDoc(doc(activeRef, id), {
				z: z < 30 ? z + 1 : z
			});
		}
	};

	const handleBringBack = ({id, z}) => {
		if (currentViewType === 'mobile') {
			updateDoc(doc(activeRef, id), {
				mobile_z: z > 0 ? z - 1 : z
			});
		} else {
			updateDoc(doc(activeRef, id), {
				z: z > 0 ? z - 1 : z
			});
		}
	};

  const handleTextEdit = (id, text) => {
		updateDoc(doc(activeRef, id), {
			text,
		});
	};

	const handleRemove = (id) => {
		deleteDoc(doc(activeRef, id));
	}

	const handleOnStop = () => {
		setIsBeingReposition(null);
	};

  const setTime = (id, time, status = 1) => {
		updateDoc(doc(activeRef, id), {
			currentTime: time,
			// playStatus: status,
		})
	}

	const isSelected = (id) => id === activeLayer;

  const onSelectClick = (layer) => {
		if (layer.id === activeLayer) {
			// setActiveLayer(undefined);
		} else {
			setActiveLayer && setActiveLayer(layer.id);
      props?.parentSelect && props?.parentSelect();
		}
	};

  const handleMouseMove = (e) => {
    if (screenLocked) return;
    const w = Number(parentRef.current.style.width.replace('px', ''));
    const h = Number(parentRef.current.style.height.replace('px', ''));
    const pl = parentRef?.current?.offsetLeft;
    const pt = parentRef?.current?.offsetTop;
    const x = (e.clientX - parentRef.current.offsetLeft - pl) * 1920 / w;
    const y = (e.clientY - parentRef.current.offsetTop - pt - 58) * 1080 / h;

    let _layers = subLayers?.filter(_layer => 
      _layer.x / 100 * 1920 < x
      && _layer.y / 100 * 1080 < y + 10
      && _layer.x / 100 * 1920 + _layer.width + 10 >= x 
      && _layer.y / 100 * 1080 + _layer.height >= y
      && document.getElementById(_layer.id)
    );
    _layers = _layers?.sort(compare);
    if (!_layers || _layers.length === 0) {
      setSemiActiveLayer(null);
    } else {
      setSemiActiveLayer(_layers[0].id);
    }
  }

  return (
    <div
      ref={parentRef}
      className="parent-ref absolute left-0 top-0 w-full h-full"
      onMouseMove={handleMouseMove}
      onBlur={() => {
        setActiveLayer();
        setSemiActiveLayer();
      }}
    >
      {subLayers?.map(subLayer => {
        if (subLayer?.type === 'image') {
          return (
            <ImageLayer
              key={subLayer.id}
              {...subLayer}
              editable={editable}
              onDrag={handleDrag}
              onResize={handleResize}
              width={subLayer.width}
              height={subLayer.height}
              selected={isSelected(subLayer.id)}
              hovered={semiActiveLayer === subLayer.id && !screenLocked && editable}
              hoveredLayer={hoveredLayer}
              onClick={() => editable && onSelectClick(subLayer)}
              viewWidth={props?.viewWidth ?? 0}
              viewHeight={props?.viewHeight ?? 0}
              x={subLayer.x}
              y={subLayer.y}
              isCustomRatio={subLayer.isCustomRatio}
              handleBringForward={handleBringForward}
              handleBringBack={handleBringBack}
              isBeingReposition={isBeingReposition}
              setIsBeingReposition={setIsBeingReposition}
              zoneIndex={1}
              zone={subLayer?.zone}
              parentRef={parentRef}
              isVisible={props?.isVisible}
              loadedImageCount={0}
              setLoadedImageCount={() => {}}
            />
          )
        }
        
        if (subLayer?.type === 'text') {
          return (
            <TextLayer
              key={subLayer.id}
              {...subLayer}
              editable={editable }
              onDrag={handleDrag}
              onResize={handleResize}
              selected={isSelected(subLayer.id)}
              hovered={semiActiveLayer === subLayer.id && !screenLocked && editable}
              hoveredLayer={hoveredLayer}
              width={subLayer.width}
              height={subLayer.height}
              onClick={() => editable && onSelectClick(subLayer)}
              onTextEdit={(text) => handleTextEdit(subLayer.id, text)}
              viewWidth={props?.viewWidth ?? 0}
              viewHeight={props?.viewHeight ?? 0}
              x={subLayer.x}
              y={subLayer.y}
              customFonts={customFonts}
              onRemove={handleRemove}
              handleBringForward={handleBringForward}
              handleBringBack={handleBringBack}
              isBeingReposition={isBeingReposition}
              setIsBeingReposition={setIsBeingReposition}
              zoneIndex={1}
              zone={subLayer?.zone}
              parentRef={parentRef}
              isVisible={props?.isVisible}
              participant={props?.participant}
            />
          );
        }
        
        if (subLayer?.type === 'timer') {
          return (
            <TimerLayer
              {...subLayer}
              editable={editable }
              onDrag={handleDrag}
              onStop={handleOnStop}
              key={subLayer.id}
              onRemove={handleRemove}
              onResize={handleResize}
              width={subLayer.width}
              height={subLayer.height}
              selected={isSelected(subLayer.id)}
              hovered={semiActiveLayer === subLayer.id && !screenLocked && editable}
              hoveredLayer={hoveredLayer}
              onClick={() => editable && onSelectClick(subLayer)}
              viewWidth={props?.viewWidth ?? 0}
              viewHeight={props?.viewHeight ?? 0}
              x={subLayer.x}
              y={subLayer.y}
              handleBringForward={handleBringForward}
              handleBringBack={handleBringBack}
              setTime={(time, playStatus) => setTime(subLayer.id, time, playStatus)}
              endTime={subLayer.endTime}
              customFonts={customFonts}
              isBeingReposition={isBeingReposition}
              setIsBeingReposition={setIsBeingReposition}
              zoneIndex={1}
              zone={subLayer?.zone}
              parentRef={parentRef}
              isVisible={props?.isVisible}
            />
          );
        }
        
        return (<></>)
      })}
    </div>
  )
}

export default SubLayers;
