import { Resizable } from "re-resizable";
import React, { useRef, useState, useEffect } from "react";
import { install } from "resize-observer";
import { ReactComponent as XCircleIcon } from "../icons/x-circle.svg";
import Draggable from './draggable';
import Button from "../components/button";
import { ReactComponent as SortAscendingIcon } from "../icons/sort-ascending.svg";
import { ReactComponent as SortDescendingIcon } from "../icons/sort-descending.svg";
import { createGlobalStyle } from 'styled-components';
import { ReactComponent as EditIcon } from "../icons/pencil.svg";
import TextChangeModal from "./textChangeModal";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
// TODO
// - Ratio resize?
install();

const GlobalStyle = createGlobalStyle`
  body {
    @font-face {
			font-family: '${props => props.family}';
			src: ${props => props.url};
    };
  }
`

const ratio = 1920 / 1080;

const scaleDimensions = ({ viewWidth, viewHeight, width, height, isCustomRatio }) => {
  const widthPercentage = viewWidth / 1920;
  const heightPercentage = viewHeight / 1080;
  // const computedWidth = widthPercentage * (width > 0 ? width : 500);
  // const computedHeight = heightPercentage * (height > 0 ? height : 500);
  const computedHeight = heightPercentage * (height > 0 ? height : 500);
  const computedWidth = widthPercentage * (width > 0 ? width : 500);

  return {
    width: computedWidth,
    height: computedHeight,
  };
};

const deScaleDimensions = ({ viewWidth, viewHeight, width, height }) => {
	const widthPercentage = viewWidth / 1920;
	const heightPercentage = viewHeight / 1080;
  
	// const computedWidth = width / widthPercentage;
	// const computedHeight = height / heightPercentage;
	const computedHeight = height / heightPercentage;
	const computedWidth = computedHeight * ratio;
  
	return {
	  width: computedWidth,
	  height: computedHeight,
	};
};

const TextLayer = ({
	onDrag,
	src,
	id,
	x = 0,
	y = 0,
	z = 0,
	height: givenHeight,
	width: givenWidth,
	onClick,
	onResize,
	onRemove,
	onPlay,
	onPause,
	volume,
	playStatus,
	muted,
	currentTime,
	onVolumeChange,
	selected,
	hovered,
	editable,
	viewWidth,
	viewHeight,
	isCustomRatio,
	handleBringForward,
	handleBringBack,
	setTime,
	onTextEdit,
	isBeingReposition,
	setIsBeingReposition,
	zoneIndex,
	hoveredLayer,
	zone,
	parentRef,
	isVisible,
	...props
}) => {
	const ref = useRef();
	const [isTextChangeModal, setIsTextChangeModal] = useState(false);
	const [isTextEdit, setIsTextEdit] = useState(false);

	useEffect(() => {
		if (!selected) {
			setIsTextEdit(false);
		}
	}, [selected]);

	const { width, height } = scaleDimensions({
		viewWidth,
		viewHeight,
		width: givenWidth,
		height: givenHeight,
		isCustomRatio
	});
	const { textActive, setTextActive } = useLayerEditorContext();
	
	const isInHoveredLayer = () => {
		if (z > hoveredLayer?.z && hoveredLayer?.zone === zone) {
		  if (hoveredLayer?.x < x && hoveredLayer?.x + hoveredLayer?.width > x
			&& hoveredLayer?.y < y && hoveredLayer?.y + hoveredLayer?.height > y) {
			  return true;
			}
		  if (hoveredLayer?.x < x + givenWidth && hoveredLayer?.x + hoveredLayer?.width > x + givenWidth
			&& hoveredLayer?.y < y && hoveredLayer?.y + hoveredLayer?.height > y) {
			  return true;
			}
		  if (hoveredLayer?.x < x && hoveredLayer?.x + hoveredLayer?.width > x
			&& hoveredLayer?.y < y + givenHeight && hoveredLayer?.y + hoveredLayer?.height > y + givenHeight) {
			  return true;
			}
		  if (hoveredLayer?.x < x + givenWidth && hoveredLayer?.x + hoveredLayer?.width > x + givenWidth
			&& hoveredLayer?.y < y + givenHeight && hoveredLayer?.y + hoveredLayer?.height > y + givenHeight) {
			  return true;
			}
		}
		return false;
	  };
 
	const baseZ = isInHoveredLayer() ? 1500 : 0;
	
	const style = {
		position: "absolute",
		zIndex: isTextChangeModal ? 2800 : isBeingReposition === id ? 2500 : textActive === id ? 2300 : hovered ? 2000 : selected ? 1500 + baseZ : isInHoveredLayer() ? 2000 + z : 250 * zoneIndex + z,
		width: '150px',
		height: '20px',
	};

	const textStyle = {
		fontFamily: props.fontFamily,
		color: props.textColor,
		fontSize: `${props.fontSize}px`,
		fontWeight: props.fontWeight,
		fontStyle: props.fontStyle,
		textDecoration: props.textDecoration,
		textAlign: props.textAlign,
		background: props.backgroundColor,
	}

	const applyDynamicTags = (text) => {
		let result = text.replaceAll('{firstName}', props?.participant?.firstName ?? '');
		result = result.replaceAll('{First Name}', props?.participant?.firstName ?? '');
		result = result.replaceAll('{FIRST NAME}', props?.participant?.firstName ?? '');
		
		result = result.replaceAll('{lastName}', props?.participant?.lastName ?? '');
		result = result.replaceAll('{Last Name}', props?.participant?.lastName ?? '');
		result = result.replaceAll('{LAST NAME}', props?.participant?.lastName ?? '');

		result = result.replaceAll('{title}', props?.participant?.title ?? '');
		result = result.replaceAll('{Title}', props?.participant?.title ?? '');
		result = result.replaceAll('{TITLE}', props?.participant?.title ?? '');

		result = result.replaceAll('{company}', props?.participant?.company ?? '');
		result = result.replaceAll('{Company}', props?.participant?.company ?? '');
		result = result.replaceAll('{COMPANY}', props?.participant?.company ?? '');

		result = result.replaceAll('{fieldOne}', props?.participant?.fieldOne ?? '');
		result = result.replaceAll('{Field One}', props?.participant?.fieldOne ?? '');
		result = result.replaceAll('{FIELD ONE}', props?.participant?.fieldOne ?? '');

		result = result.replaceAll('{fieldTwo}', props?.participant?.fieldTwo ?? '');
		result = result.replaceAll('{Field Two}', props?.participant?.fieldTwo ?? '');
		result = result.replaceAll('{FIELD TWO}', props?.participant?.fieldTwo ?? '');

		result = result.replaceAll('{fieldThree}', props?.participant?.fieldThree ?? '');
		result = result.replaceAll('{Field Three}', props?.participant?.fieldThree ?? '');
		result = result.replaceAll('{FIELD THREE}', props?.participant?.fieldThree ?? '');

		return result;
	};

	return (
		<>
			{props.customFonts && props.customFonts.map((font, index) => (
				<GlobalStyle key={`global_font_${index}`} family={font.family} url={`url(${font.url})`} format={font.format?font.format:'ttf'} />
			))}
			{/* <TextChangeModal
				isOpen={isTextChangeModal}
				onClose={() => {
					setIsTextChangeModal(false);
					setTextActive(null);
				}}
				text={props.text}
				onTextEdit={onTextEdit}
				{...props}
			/> */}
			<Draggable
				id={id}
				onDrag={onDrag(id)}
				x={x}
				y={y}
				disabled={!editable || isTextEdit}
				viewHeight={viewHeight}
				viewWidth={viewWidth}
				isTimer={true}
				style={style}
				setIsBeingReposition={setIsBeingReposition}
				parentRef={parentRef}
				isVisible={isVisible}
			>
				<>
				<Resizable
					style={{
						position: "absolute",
						zIndex: isTextChangeModal ? 2800 : isBeingReposition === id ? 2500 : textActive === id ? 2300 : hovered ? 2000 : selected ? 1500 + baseZ : isInHoveredLayer() ? 2000 + z : 250 * zoneIndex + z,
					}}
					ref={ref}
					bounds="parent"
					handleClasses="handle"
					handleWrapperClass="re-handle"
					className={`inline-block absolute ${
						(hovered || isBeingReposition === id) ? "border-2 border-yellow-500" : selected ? "border-2 border-red-500" : ""
					}`}
					enable={{
						top: false,
						right: true && editable,
						bottom: true && editable,
						bottomLeft: false,
						bottomRight: true && editable,
						topRight: false,
						topLeft: false,
					}}
					size={{ width, height }}
					// lockAspectRatio={!isCustomRatio}
					onResizeStart={(e) => {
						e.stopPropagation();
						setIsBeingReposition(id);
					}}
					onResizeStop={(e, direction, ref, d) => {
						const deScale = deScaleDimensions({
							viewWidth,
							viewHeight,
							width: width + d.width,
							height: height + d.height,
						});
						const countedW =(width + d.width) / viewWidth * 1920;
						const countedH = (height + d.height) / viewHeight * 1080;
						onResize({
							id,
							width: countedW,
							height: countedH 
						});
						setIsBeingReposition(null);
					}}
					onClick={(e) => {
						e.stopPropagation();
						if (isTextEdit) setIsTextEdit(true);
						onClick(e);
					}}
				>
					{editable && selected && <button
						className="absolute -top-3 -right-3 bg-white rounded-full w-5 h-5 text-red-500 hover:text-red-400"
						onClick={() => onRemove(id)}
					>
						<XCircleIcon className="w-5 h-5" />
					</button>}
					<div
						className="absolute w-full h-full cursor-move"
					>
						<div className="absolute w-full h-full flex items-center rounded" style={textStyle}>
							{!isTextEdit && <div className="w-full">{applyDynamicTags(props.text)}</div>}
							{/* {isTextEdit && */}
								<textarea
									className={`absolute left-0 top-0 w-full h-full ${!isTextEdit ? 'hidden' : ''}`}
									defaultValue={props?.text}
									onFocus={(e) => {
										setIsTextEdit(true)
									}}
									onClick={(e) => {
										e.stopPropagation()
										onClick(e);
									}}
									onChange={(e) => onTextEdit(e.target.value)}
								></textarea>
							{/* } */}
							{editable && selected && !isTextEdit && <button
								className="w-8 h-8 absolute top-0 left-0 text-red-500"
								onClick={(e) => {
									// setIsTextChangeModal(true);
									e.stopPropagation();
									setIsTextEdit(!isTextEdit);
									setTextActive(id);
								}}
							>
								<EditIcon />
							</button>}
						</div>
					</div>
					{/* {selected && <div className="absolute flex flex-col top-0 left-0 w-16 h-8" style={{zIndex: isTextChangeModal ? 2800 : isBeingReposition === id ? 2500 : textActive === id ? 2300 : hovered ? 2000 : selected ? 1500 + baseZ : isInHoveredLayer() ? 2000 + z : 250 * zoneIndex + z}}>
						<div>
							<Button
								className="w-8"
								onClick={(e) => {
									e.stopPropagation();
									handleBringForward({id, z});
								}}
							>
								<SortAscendingIcon className="w-4 h-4" />
							</Button>
							<Button
								className="w-8"
								onClick={(e) => {
									e.stopPropagation();
									handleBringBack({id, z});
								}}
							>
								<SortDescendingIcon className="w-4 h-4" />
							</Button>
						</div>
						<span className="text-red-500 font-bold">level: {z}</span>
					</div>} */}
				</Resizable>
				</>
			</Draggable>
		</>
	);
};
  
export default React.memo(TextLayer);

