import { useRef, useState } from "react";
import { Button, Flex, Input } from "@100mslive/react-ui";

const LoadTemplateForm = ({
  isOpen,
  templates = [],
  onConfirmLoad = async ()=>{},
  onConfirmRemove = async ()=>{},
  onClose=()=>{},
}) => {
  const [curId, setCurId] = useState('');

  const handleLoad = async (e) => {
    if (curId) {
      onConfirmLoad(curId)
      setCurId();
    }
  };

  const handleRemove = async (e) => {
    if (curId) {
      onConfirmRemove(curId);
      setCurId();
    }
  };

  if (isOpen) {
    return (
      <>
        <div className="fixed w-full h-full left-0 top-0" onClick={() => onClose()}></div>
        <Flex 
          direction="column"
          className="absolute w-64 right-0 top-6 bg-gray-300 border border-gray-500 rounded px-2 pb-2 z-50"
        >
          <span className="mb-2">Load from a Template</span>
          <Flex justify="between" align="center" css={{mb: '$2'}}>
            <select
              className="w-40 h-8 rounded-lg bg-gray-900 text-white"
              onChange={(e) => {
                setCurId(e.target.value);
              }}
            >
              <option value=''></option>
              {templates?.map(template => (
                <option key={template.id} value={template.id}>{template.name}</option>
              ))}
            </select>
            <Button
              variant="standard"
              onClick={handleRemove}
              css={{ fontSize: '15px', px: '$1', py: '$1', h: '$10', w: '$50'}}
            >Remove</Button>
          </Flex>
          
          <Flex justify="between">
            <Button
              variant="standard"
              onClick={() => onClose()}
              css={{ fontSize: '15px', px: '$1', py: '$1', h: '$10', w: '$50'}}
            >Cancel</Button>
            <Button
              css={{ fontSize: '15px', px: '$1', py: '$1', h: '$10', w: '70px'}}
              onClick={handleLoad}
            >Load</Button>
          </Flex>
        </Flex>
      </>
    )
  } else {
    return (<></>)
  }
};

export default LoadTemplateForm;
