import { useEffect, useRef, useState } from "react";
import {
  useHMSActions,
  useHMSStore,
  selectIsLocalVideoPluginPresent,
  selectIsAllowedToPublish,
} from "@100mslive/react-sdk";

export const useVirtualBackground = ({ virtualBackgrounds = [] }) => {
  const pluginRef = useRef(null);
  const hmsActions = useHMSActions();
  const isAllowedToPublish = useHMSStore(selectIsAllowedToPublish);
  const [isVBSupported, setIsVBSupported] = useState(false);
  const isVBPresent = useHMSStore(
    selectIsLocalVideoPluginPresent("@100mslive/hms-virtual-background")
  );

  const createPlugin = async () => {
    if (!pluginRef.current) {
      const { HMSVirtualBackgroundPlugin } = await import(
        "@100mslive/hms-virtual-background"
      );
      pluginRef.current = new HMSVirtualBackgroundPlugin("none", true);
    }
  }
  useEffect(() => {
    createPlugin().then(() => {
      //check support of plugin
      const pluginSupport = hmsActions.validateVideoPluginSupport(
        pluginRef.current
      );
      setIsVBSupported(pluginSupport.isSupported);
    });
  }, [hmsActions]);

  const getVirtualBackground = () => {

  }

  const addPlugin = async (image) => {
    try {
      await createPlugin();
      window.HMS.virtualBackground = pluginRef.current;
      if (image) {
        const img = document.createElement("img");
        img.alt = "VB";
        img.src = image;
        await pluginRef.current.setBackground(img);
        //Running VB on every alternate frame rate for optimized cpu usage
        await hmsActions.addPluginToVideoTrack(pluginRef.current, 15);
      }
    } catch (err) {
      // console.error("add virtual background plugin failed", err);
      throw(err);
    }
  }

  const removePlugin = async () => {
    if (pluginRef.current) {
      await hmsActions.removePluginFromVideoTrack(pluginRef.current);
      pluginRef.current = null;
    }
  }

  return {
    isVBPresent,
    addPlugin,
    removePlugin
  };
};
