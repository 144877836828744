import React from "react";

const useDismissed = ({
  interaction={},
  isResponded=false,
  isDenied=false,
}) => {
  const [isDismissed, setIsDismissed] = React.useState(false);
  const availableTime = 5 * 60000;

  React.useEffect(() => {
    const curTime = new Date().getTime();
    if (!interaction?.triggeredAt) return;
    if (curTime - interaction?.triggeredAt > availableTime) {
      setIsDismissed(true);
    } else {
      setTimeout(() => {
        setIsDismissed(true);
      }, interaction?.triggeredAt + availableTime - curTime)
    }
  }, [interaction, availableTime])

  return {
    isDismissed: isDismissed && !isResponded && !isDenied
  }
}

export default useDismissed;
