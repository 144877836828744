
import axios from "axios";

const apiToken = "a7a52ee48f2348799d11b1766ac0b605";

export const manualTranscript = async ({
  url,
  setIsLoading=()=>{},
  setResult=()=>{}
}) => {
  const handleUpload = async (url) => {
    const axiosInstance = axios.create({
      baseURL: 'https://api.assemblyai.com/',
      headers: {
        'Accept': 'application/json',
        'authorization': apiToken,
        'Content-Type': 'application/json',
      }
    })

    const response = await axiosInstance.post(
      'v2/transcript',
      {
        audio_url: url,
        speaker_labels: true
      }
    );
    setIsLoading(true);
    const transcript = await getResult(response.data.id);
    return transcript;
  }

  const analizeResult = (words) => {
    const endLetters = ['.', '?', '!'];
    let result = [];
    let text = "";
    let startP = 0;
    for (let i = 0; i < words.length; i++) {
      if (endLetters.find(letter => letter === words[i].text[words[i].text.length - 1]) || i === words.length - 1) {
        result.push({
          speaker: `[${words[startP].speaker}]`,
          message: `${text} ${words[i].text}`,
          created: words[startP].start,
          end: words[i].end,
        });
        startP = i + 1;
        text = "";
      } else {
        text = `${text} ${words[i].text}`
      }
    }

    return result;
  };

  const getResult = (id) => {
    return new Promise((resolve, reject) => {
      const timerInt = setInterval(() => {
        const assembly = axios.create({
          baseURL: "https://api.assemblyai.com/v2",
          headers: {
              authorization: apiToken,
              "content-type": "application/json",
          },
        });

        assembly
          .get(`/transcript/${id}`)
          .then((res) => {
            if (res.data?.status === 'completed') {
              clearInterval(timerInt);
              resolve(analizeResult(res.data.words))
            }
          })
          .catch((err) => reject(err));
      }, 1000)
    });
  };

  const transcript = await handleUpload(url);
  setResult(transcript);
  setIsLoading(false);
  return transcript;
}
