import React from "react";

const Tab = ({ el: Element, children, className, active = false, ...props }) =>
  Element ? (
    <Element
      className={`custom-bg-1 hover:bg-gray-300 rounded-t-md px-3 py-px text-gray-200 hover:text-white font-medium cursor-pointer whitespace-nowrap text-sm flex justify-center items-center ${
        className ? className : ""
      }`}
      {...props}
    >
      {children}
    </Element>
  ) : (
    <button
      className={`${
        active ? "bg-gray-100 text-white" : "custom-bg-3 text-gray-200"
      } hover:bg-yellow-300 hover:text-white rounded-t-md px-4 py-1 font-medium cursor-pointer whitespace-nowrap text-sm`}
    >
      {children}
    </button>
  );

Tab.tabsRole = "Tab";

export default Tab;
