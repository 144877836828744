
import { ReactComponent as CollectionIcon } from "../icons/collection.svg";

export const SessionCardList = ({ children }) => {
  return (
    <>
      {children && 
        <div className="session-card flex flex-col gap-2 max-h-500 overflow-y-scroll">{children}</div>
      }
      {!children &&
        <div className="flex flex-col justify-center items-center gap-2 w-72">
          <CollectionIcon className="w-10 h-10 text-gray-300" />
          <div className="font-semibold text-gray-400">No Sessions</div>
        </div>
      }
    </>
  )
};
