import { useState } from "react";
import Modal from "../../../components/modal";
import { Button, Flex, Input } from "@100mslive/react-ui";

const SaveConfigModal = ({
  isSaveConfigModal,
  setIsSaveConfigModal,
  handleSaveConfig,
}) => {
  const [configName, setConfigName] = useState('');
  return (
    <Modal
      isOpen={isSaveConfigModal}
      closeModal={() => setIsSaveConfigModal(false)}
      className="w-1/4 left-0"
      isHeader={false}
      isFooter={false}
    >
      <div className="custom-bg-1 w-full p-2 rounded-t-md flex items-center">
        <h2 className="font-medium text-xl text-gray-100">Save Configuration</h2>
      </div>
      <div className="p-2">
        <Input 
          placeholder="Config Name" 
          value={configName}
          onChange={(e) => setConfigName(e.target.value)}
        />
        <Flex
          justify="between"
          css={{
            mt: '$5',
            px: '$3'
          }}
        >
          <Button
            onClick={async () => {
              handleSaveConfig(configName)
              setIsSaveConfigModal(false);
              setConfigName('');
            }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setConfigName('');
              setIsSaveConfigModal(false);
            }}
            variant="standard"
          >
            Cancel
          </Button>
        </Flex>
      </div>
    </Modal>
  )
}

export default SaveConfigModal;
