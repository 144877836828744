import React from 'react';
import { useMemo, useContext } from "react";
import useSWR from "swr";
import { fetcher } from "../../fetcher";
import { FirebaseContext } from '../../contexts/firebaseContext';
import { useDocumentQuery } from "../useFirebaseDocument";
import { getFirestore, collection, doc, updateDoc } from '@firebase/firestore';

/**
 * Polls a service to see if a userId can join a given conferenceId at a refreshInterval.
 *
 * @param reqInfo Properties needed to formulate request body
 * @param reqInfo.conferenceId The conference ID the user is trying to join
 * @param reqInfo.userId The users ID
 * @param reqInfo.refreshInterval The polling interval to check if the user can join
 */
export const useCanJoinRoom = ({
  conferenceId,
  userId,
  refreshInterval = 1000,
}) => {
  const body = useMemo(() => ({ userId, conferenceId }), [
    userId,
    conferenceId,
  ]);
  const expiration = 24 * 3600 * 1000;
  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);

  const participantRef = doc(collection(doc(collection(db, "conferences"), conferenceId ?? "defaultConference"), "participants"), userId ?? 'xx');

  const [participantData] = useDocumentQuery(participantRef);

  const { data, error, loading } = useSWR(
    [`${process.env.REACT_APP_FUNCTIONS_URL}/isConferenceJoinable`, body],
    (url, data) => {
      return fetcher(url, {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
    },
    { refreshInterval }
  );

  if (!userId || !conferenceId) return [];
  
  const checkExpiration = () => {
    const offset = new Date().getTimezoneOffset();
    if (new Date().getTime() + offset * 60 * 1000 < participantData?.actionTime + expiration && participantData?.canJoin === true)
      return true;
    else if (participantData?.canJoin) {
      updateDoc(participantRef, {
        canJoin: false,
        deny: false,
        actionTime: 0,
      })
    } else if (new Date().getTime() + offset * 60 * 1000 > participantData?.actionTime + expiration && participantData?.deny) {
      updateDoc(participantRef, {
        canJoin: false,
        deny: false,
        actionTime: 0,
      })
    }
    return false;
  }

  if (error) {
    // console.log(error);
  }

  return [checkExpiration() && !!data?.result, loading, error];
};
