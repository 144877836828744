import React, { 
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext
} from "react";
import {
  selectAppData,
  useHMSActions,
  useHMSStore,
  useRecordingStreaming,
} from "@100mslive/react-sdk";
import {
  AddCircleIcon,
  EndStreamIcon,
  GoLiveIcon,
  PencilIcon,
  SettingsIcon,
  TrashIcon,
} from "@100mslive/react-icons";
import {
  Accordion,
  Box,
  Button,
  Flex,
  Input,
  Label,
  Loading,
  Text,
} from "@100mslive/react-ui";
import {
  Container,
  ContentBody,
  ContentHeader,
  ErrorText,
  RecordStream,
} from "./Common";
import { ResolutionInput } from "./ResolutionInput";
import { useSetAppDataByKey } from "../AppData/useUISettings";
import {
  UserPreferencesKeys,
  useUserPreferences,
  useUserPreferencesOnDB,
} from "../hooks/useUserPreferences";
import { getDefaultMeetingUrl } from "../../common/utils";
import {
  APP_DATA,
  RTMP_RECORD_DEFAULT_RESOLUTION,
} from "../../common/constants";
import { FirebaseContext } from "../../helpers/contexts/firebaseContext";
import { getFirestore, collection, doc } from "@firebase/firestore";
import { useParams } from "react-router-dom";
import FacebookLogin from '@greatsumini/react-facebook-login';
import axios from "axios";
import YouTubeOAuth from "./YouTubeOAuth/SignInButton";
import TestBCFlow from "./YouTubeOAuth/TestBCFlow";
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsLive,
  setIsTestingBC,
  setLoading,
  setError,
} from "../../helpers/redux/reducers/youtubeRtmpReducer";

export const RTMPStreaming = ({ onBack }) => {
  const { isRTMPRunning } = useRecordingStreaming();
  const hmsActions = useHMSActions();

  const {
    isLive,
  } = useSelector(state => state.youtubeRtmp);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLive && isRTMPRunning) {
      setTimeout(() => {
        dispatch(setIsLive(true));
        dispatch(setIsTestingBC(true))
      }, 1000)
    }
  }, [isLive, isRTMPRunning]);

  const facebookPageLoginSuccess = async response => {
    const accessToken = response?.accessToken;
    console.log({accessToken});
    // const fbAccount = await axios.post(`https://graph.facebook.com/v12.0/me/accounts?access_token=${accessToken}`);
    // console.log({fbAccount})
    const rtmpSetting = await axios.post(`https://graph.facebook.com/v12.0/me/live_videos?status=LIVE_NOW&access_token=${accessToken}`);
    console.log({rtmpSetting})
    const streamUrl = rtmpSetting.secure_stream_url;
    let meetingURL = getDefaultMeetingUrl().replace('http://localhost:3000', 'https://100ms.voodoocast.io');
    hmsActions.startRTMPOrRecording({
      rtmpURLs: [streamUrl],
      meetingURL,
    });
  }

  return (
    <Container>
      <ContentHeader
        title="Start Streaming"
        content="Choose a destination"
        onBack={onBack}
      />
      <ContentBody Icon={SettingsIcon} title="RTMP">
        Live Stream your call to Twitch, YouTube, Facebook and any app which
        supports RTMP, all at the same time
      </ContentBody>
      {/* <FacebookLogin
        // appId="223478960323702"
        // appId="670024114784626"
        appId="318452637289113"
        onSuccess={facebookPageLoginSuccess}
        onFail={(error) => {
          console.log('Login Failed!', error);
        }}
        onProfileSuccess={(response) => {
          console.log('Get Profile Success!', response);
        }}
      />
      <Button
        css={{
          mx: '$10',
          my: '$3'
        }}
        onClick={(e) => {
          let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;
          window.open('https://d85b-38-75-137-97.ngrok-free.app/vimeo_oauth', 'test', params);
        }}
      >
        Stream To Vimeo
      </Button> */}
      {!isRTMPRunning ? <StartRTMP /> : <EndRTMP />}
    </Container>
  );
};

const StartRTMP = () => {
  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);
  const { conferenceId } = useParams();
  const preferenceRef = doc(
    collection(
      doc(
        collection(db, 'conferences'), conferenceId ?? 'default')
        , 'preferences'
      )
    , UserPreferencesKeys.RTMP_URLS ?? 'default'
  );

  // const [rtmpPreference = [], setRTMPPreference] = useUserPreferences(
  //   UserPreferencesKeys.RTMP_URLS
  // );

  const [rtmpPreference = [], setRTMPPreference] = useUserPreferencesOnDB(
    preferenceRef
  );

  const defaultRtmpStreams = useMemo(() => ([
    {
      name: "Stream",
      id: Date.now(),
      rtmpURL: "",
      streamKey: "",
      isYoutube: false,
      youtubeLiveStreamId: '',
    },
  ]), []);

  const [rtmpStreams, setRTMPStreams] = useState(
    rtmpPreference.length > 0
      ? rtmpPreference
      : defaultRtmpStreams
  );

  useEffect(() => {
    if (rtmpPreference?.length && JSON.stringify(rtmpStreams) === JSON.stringify(defaultRtmpStreams))
      setRTMPStreams(rtmpPreference ?? [])
  }, [rtmpPreference, defaultRtmpStreams, rtmpStreams]);

  useEffect(() => {
    if (JSON.stringify(rtmpStreams) !== JSON.stringify(defaultRtmpStreams))
      setRTMPPreference(rtmpStreams)
  }, [rtmpStreams, setRTMPPreference, defaultRtmpStreams]);

  const hmsActions = useHMSActions();
  const recordingUrl = useHMSStore(selectAppData(APP_DATA.recordingUrl));
  const [error, setError] = useState(false);
  const [record, setRecord] = useState(false);
  const [resolution, setResolution] = useState(RTMP_RECORD_DEFAULT_RESOLUTION);
  const [isRTMPStarted, setRTMPStarted] = useSetAppDataByKey(
    APP_DATA.rtmpStarted
  );
  const hasRTMPURL = rtmpStreams.some(
    value => value.rtmpURL && value.streamKey
  );

  return (
    <Box
      css={{ overflowY: "auto" }}
      as="form"
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <YouTubeOAuth
        rtmpStreams={rtmpStreams}
        setRTMPStreams={setRTMPStreams}
        updateStream={updateStream}
      />
      <TestBCFlow />
      {rtmpStreams.length > 0 && (
        <Box css={{ px: "$10" }}>
          <Accordion.Root
            type="single"
            collapsible
            defaultValue={rtmpStreams[0].id}
          >
            {rtmpStreams.map(rtmp => {
              return (
                <Accordion.Item
                  value={rtmp.id}
                  key={rtmp.id}
                  defaultOpen={true}
                  css={{
                    border: "2px solid $surfaceLight !important",
                    r: "$1",
                    my: "$4",
                  }}
                >
                  <AccordionHeader
                    defaultOpen={true}
                    rtmp={rtmp}
                    setRTMPStreams={setRTMPStreams}
                  />
                  <Accordion.Content css={{ px: "$8", py: 0 }}>
                    <RTMPForm {...rtmp} rtmpStreams={rtmpStreams} setRTMPStreams={setRTMPStreams} />
                  </Accordion.Content>
                </Accordion.Item>
              );
            })}
          </Accordion.Root>
        </Box>
      )}
      {rtmpStreams.length < 3 && (
        <Flex
          justify="end"
          css={{
            px: "$10"
          }}
        >
          <Button
            variant="standard"
            outlined
            icon
            css={{
              my: "$1", 
              w: "auto",
              py: "$1",
              px: "$2"
            }}
            onClick={() => {
              setRTMPStreams(streams => [
                ...streams,
                {
                  name: "Stream",
                  id: Date.now(),
                  rtmpURL: "",
                  streamKey: "",
                  isYoutube: false,
                },
              ]);
            }}
          >
            <AddCircleIcon /> Add Stream
          </Button>
        </Flex>
      )}

      <ResolutionInput
        onResolutionChange={setResolution}
        css={{
          flexDirection: "column",
          alignItems: "start",
          px: "$10",
          my: "$8",
        }}
      />
      <RecordStream record={record} setRecord={setRecord} />
      <Box css={{ p: "$8 $10", "@lg": { display: "flex", gap: "$4" } }}>
        <Button
          variant="primary"
          icon
          type="submit"
          css={{ w: "100%", my: "$4" }}
          disabled={isRTMPStarted || (rtmpStreams.length === 0 && !record)}
          onClick={async () => {
            try {
              const hasInvalidData = rtmpStreams.find(
                value =>
                  (value.rtmpURL && !value.streamKey) ||
                  (value.streamKey && !value.rtmpURL)
              );
              if (hasInvalidData || (rtmpStreams.length > 0 && !hasRTMPURL)) {
                return;
              }
              setError("");
              setRTMPStarted(true);
              const urls = hasRTMPURL
                ? rtmpStreams.filter(rtmp => rtmp.rtmpURL && rtmp.streamKey).map(
                    value => `${value.rtmpURL}/${value.streamKey}`
                  )
                : [];
              let meetingURL = (recordingUrl || getDefaultMeetingUrl()).replace('http://localhost:3000', 'https://100ms.voodoocast.io');
              hmsActions.startRTMPOrRecording({
                rtmpURLs: urls,
                meetingURL,
                resolution: getResolution(resolution),
                record: record,
              });
              setRTMPPreference(rtmpStreams);
            } catch (error) {
              // console.error(error);
              setError(error.message);
              setRTMPStarted(false);
            }
          }}
        >
          {isRTMPStarted ? (
            <Loading size={24} color="currentColor" />
          ) : (
            <GoLiveIcon />
          )}
          {isRTMPStarted ? "Starting stream..." : "Go Live"}
        </Button>
        <ErrorText error={error} />
      </Box>
    </Box>
  );
};

const EndRTMP = () => {
  const hmsActions = useHMSActions();
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState("");
  const { isRTMPRunning } = useRecordingStreaming();

  useEffect(() => {
    if (inProgress && !isRTMPRunning) {
      setInProgress(false);
    }
  }, [inProgress, isRTMPRunning]);

  return (
    <Box css={{ p: "$4 $10" }}>
      <ErrorText error={error} />
      <Button
        variant="danger"
        css={{ w: "100%", r: "$0", my: "$8" }}
        icon
        loading={inProgress}
        disabled={inProgress}
        onClick={async () => {
          try {
            setInProgress(true);
            await hmsActions.stopRTMPAndRecording();
          } catch (error) {
            setError(error.message);
            setInProgress(false);
          }
        }}
      >
        <EndStreamIcon />
        End Stream
      </Button>
    </Box>
  );
};

const ActionIcon = ({ icon: Icon, onClick }) => {
  return (
    <Text as="span" css={{ mx: "$2", cursor: "pointer" }} onClick={onClick}>
      <Icon width={16} height={16} />
    </Text>
  );
};

const FormLabel = ({ id, children }) => {
  return (
    <Label
      htmlFor={id}
      css={{ color: "$textHighEmp", my: "$4", fontSize: "$sm" }}
    >
      {children}
    </Label>
  );
};

const RTMPForm = ({ name, rtmpURL, id, streamKey, rtmpStreams, setRTMPStreams, isYoutube, youtubeLiveStreamId, youtubeStartTimestamp }) => {
  const formRef = useRef(null);

  const { loading: isYoutubeLoading } = useSelector(state => state.youtubeRtmp);

  const isExpired = isYoutube && Date.now() - youtubeStartTimestamp > 2 * 3600 * 1000;

  return (
    <Flex id={id} direction="column" css={{ mb: "$8", position: 'relative', p: '$1' }} ref={formRef}>
      <>
        {isExpired && <span className="text-red-300"> This YouTube Live is Expired.</span>}
        <FormLabel id="rtmpName">
          RTMP NAME
          <Asterik />
        </FormLabel>
        <Input
          placeholder="Enter RTMP Name"
          id="name"
          name="name"
          value={name}
          disabled={isYoutube}
          onChange={e => {
            setRTMPStreams(streams =>
              updateStream({
                streams,
                id,
                value: e.target.value,
                key: e.target.name,
              })
            );
          }}
          required
        />
        <FormLabel id="rtmpURL">
          RTMP URL
          <Asterik />
        </FormLabel>
        <Input
          placeholder="Enter RTMP URL"
          id="rtmpURL"
          name="rtmpURL"
          value={rtmpURL}
          disabled={isYoutube}
          onChange={e => {
            setRTMPStreams(streams =>
              updateStream({
                streams,
                id,
                value: e.target.value,
                key: e.target.name,
              })
            );
          }}
          required
        />
        <FormLabel id="streamKey">
          Stream Key
          <Asterik />
        </FormLabel>
        <Input
          placeholder="Enter Stream Key"
          id="streamKey"
          name="streamKey"
          disabled={isYoutube}
          value={streamKey}
          onChange={e => {
            setRTMPStreams(streams =>
              updateStream({
                streams,
                id,
                value: e.target.value,
                key: e.target.name,
              })
            );
          }}
          required
        />
      </>
    </Flex>
  );
};

const Asterik = () => {
  return (
    <Text variant="sm" as="span" css={{ color: "$error", mx: "$2" }}>
      *
    </Text>
  );
};
const AccordionHeader = ({ rtmp, setRTMPStreams }) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  return (
    <Accordion.Header css={{ px: "$8" }}>
      {edit ? (
        <Input
          defaultValue={rtmp.name}
          autoFocus
          onKeyDown={e => {
            if (e.key === 'Enter') {
              const value = e.currentTarget.value.trim();
              if (value) {
                setRTMPStreams(streams =>
                  streams.map(stream => {
                    if (stream.id === rtmp.id) {
                      stream.name = value;
                    }
                    return stream;
                  })
                );
                setEdit(false);
              }
            }
          }}
        />
      ) : (
        <Text css={{ flex: "1 1 0" }}>{rtmp.name}</Text>
      )}

      <Flex css={{ mx: "$4", gap: "$2" }}>
        <ActionIcon
          onClick={e => {
            e.stopPropagation();
            setEdit(true);
          }}
          icon={PencilIcon}
        />
        <ActionIcon
          onClick={() => {
            setRTMPStreams(streams =>
              streams.filter(stream => stream.id !== rtmp.id)
            );
            dispatch(setError(''));
          }}
          icon={TrashIcon}
        />
      </Flex>
    </Accordion.Header>
  );
};

const updateStream = ({ streams, id, key, value }) =>
  streams.map(stream => {
    if (stream.id === id) {
      return {
        ...stream,
        [key]: value,
      };
    }
    return stream;
  });

export function getResolution(recordingResolution) {
  const resolution = {};
  if (recordingResolution.width) {
    resolution.width = recordingResolution.width;
  }
  if (recordingResolution.height) {
    resolution.height = recordingResolution.height;
  }
  if (Object.keys(resolution).length > 0) {
    return resolution;
  }
}
