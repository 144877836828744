import React, { useState, useRef, useEffect, useMemo, useCallback, useContext } from "react";
import useVideoPlayer from "../../helpers/hooks/useVideoPlayer";
import TranscriptList from "./transcriptList";
import CollapseWidth from "../../components/collapseWidth";
import RecordingCalendar from "./recordingCalendar";
import AWS from 'aws-sdk';
import Modal from "../../components/transcriptModal";
import axios from "axios";
import CurDateRecordings from "./curDateRecordings";
import Video from "./video";
import { getFirestore, collection, doc, getDoc, setDoc, onSnapshot, deleteDoc, query, orderBy, updateDoc } from "firebase/firestore";
import { FirebaseContext } from '../../helpers/contexts/firebaseContext';
import { useCollectionQuery } from "../../helpers/hooks/useFirebaseCollection";
import { useDispatch } from "react-redux";
import { setDownloadTimeRange } from "../../helpers/redux/reducers/videoSplitReducer";

const apiToken = "a7a52ee48f2348799d11b1766ac0b605";

const dateFormat = (date) => {
  const year = date?.substring(0, 4);
  const month = date?.substring(4, 6);
  const day = date?.substring(6, 8);
  return `${year}/${month}/${day}`;
}

const AbleVideo = ({curISO: curISOFromList =null, recordings, ...props}) => {
  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);
  const [curISO, setCurISO] = useState({});
  const [curDate, setCurDate] = useState();
  const [curDateRecordings, setCurDateRecordings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState();

  const dispatch = useDispatch();

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isTranscriptModalOpen, setIsTranscriptModalOpen] = useState(false);

  const [recordingInfos, recordingInfoLoading] = useCollectionQuery(collection(db, 'recordings'), {idField: 'id'});

  useEffect(() => {
    setIsTranscriptModalOpen(isFullScreen);
  }, [isFullScreen]);

  useEffect(() => {
    setCurISO(curISOFromList);
    setCurDate(curISOFromList?.date ? new Date(dateFormat(curISOFromList?.date)) : new Date())
  }, [curISOFromList]);

  useEffect(() => {
    setVideoDuration(0);
  }, [curISO])

  const ReactS3Client = useRef();

  useEffect(() => {
    if (isLoading) {
      const config = {
        accessKey: process.env.REACT_APP_AWS_KEY,
        bucket: "cloud-conference-1",
        // dir,
        region: "us-east-2",
        secretKey: process.env.REACT_APP_AWS_SECRET
      }
      AWS.config.update({ 
        bucketName: config.bucket,
				// dirName: s3credential.dir, /* optional */
				region: config.region,
				accessKeyId: config.accessKey,
				secretAccessKey: config.secretKey,
      });
      ReactS3Client.current = new AWS.S3({
        accessKeyId: config.accessKeyId,
        secretAccessKey: config.secretAccessKey,
        region: config.region
      });
    }
  }, [isLoading]);

  const uploadTranscript = useCallback(async (transctipt, dir, objectName) => {
    window.Buffer = window.Buffer || require('buffer').Buffer;
    const config = {
      accessKey: process.env.REACT_APP_AWS_KEY,
      bucket: "cloud-conference-1",
      dir,
      region: "us-east-2",
      secretKey: process.env.REACT_APP_AWS_SECRET
    }
    const objectData = JSON.stringify(transctipt);
    const objectType = 'application/json'; 
    
    const params = {
      Bucket: config.bucket,
      Key: objectName,
      Body: objectData,
      ContentType: objectType,
    };

    const result = await ReactS3Client.current.putObject(params).promise();
  }, []);

  const videoRef = useRef();

  const {
    transcripts,
    playerState,
    playVideo,
    togglePlay,
    reset,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoBackward,
    handleVideoSpeed,
    handleRefresh,
    toggleMute,
    setTranscriptArr,
    setCurrentTime
  } = useVideoPlayer(videoRef, curISO?.transcript); 

  useEffect(() => {
    if (result && (!transcripts || transcripts?.length === 0)) {
      // Check if room video
      const urlSplits = curISO?.url?.split('/');
      if (urlSplits?.[6]?.split('-')?.[0] !== 'Rec') {
        return;
      }
      const recordedTime = urlSplits?.[6]?.split('-')?.[2]?.split('.')?.[0];
      const dir = `beam/${urlSplits?.[4]}/${urlSplits?.[5]}`
			const objectName = `${dir}/transcript-${recordedTime}.json`; 
      const transcript = JSON.parse(JSON.stringify(result));
      uploadTranscript(transcript, dir, objectName)
      setTranscriptArrRef.current(transcript);
      setResult()
    }
  }, [result, uploadTranscript, curISO?.url, transcripts]);

  useEffect(() => {
    const setCurRecs = async () => {
      if (curDate) {
        const year = curDate.getFullYear();
        const month = curDate.getMonth() + 1;
        const date = curDate.getDate();
        const _date = `${year}${month < 10 ? '0'+month : month}${date < 10 ? '0'+date : date}`;
        const selected = recordings?.filter(recording => recording.recordingDate === _date) ?? [];
        let selectedRecs = [];
        selected?.forEach(group => {
          selectedRecs = [...selectedRecs, ...group?.recordingArr];
        })
        let updating = [];
        for (let recording of selectedRecs) {
          const recInfoOnDB = recordingInfos?.find(info => info.id === recording?.roomId);
          const adding = {
            ...recording,
            title: recInfoOnDB?.recordings?.find(rec => rec.recordingTime?.toString() === recording.recordingTime?.toString())?.title ?? '',
            speakers: recInfoOnDB?.recordings?.find(rec => rec.recordingTime?.toString() === recording.recordingTime?.toString())?.speakers ?? []
          }
          updating.push(adding);
        }
        setCurDateRecordings(updating);
      }
    }
    setCurRecs();
  }, [curDate, recordings?.recordingArr, recordings, db, recordingInfos]);

  const updateTitle = async (roomId, recordingTime, title) => {
    const snapshot = await getDoc(doc(collection(db, 'recordings'), roomId));
    const recsOnDb = snapshot?.data();
    let recsList = recsOnDb?.recordings ?? [];
    recsList = recsList?.map(rec => {
      if (rec?.recordingTime === recordingTime) {
        return {
          ...rec,
          title
        }
      } else {
        return rec
      }
    })
    if (!recsList?.some(rec => rec.recordingTime === recordingTime)) {
      recsList.push({
        recordingTime,
        title,
        speakers: []
      })
    }
    setDoc(doc(collection(db, 'recordings'), roomId), {
      recordings: recsList
    })
  };

  const updateSpeakersLabel = async (roomId, recordingTime, labels = []) => {
    const snapshot = await getDoc(doc(collection(db, 'recordings'), roomId));
    const recsOnDb = snapshot?.data();
    let recsList = recsOnDb?.recordings ?? [];
    recsList = recsList?.map(rec => {
      if (rec?.recordingTime === recordingTime) {
        return {
          ...rec,
          speakers: labels
        }
      } else {
        return rec
      }
    })
    if (!recsList?.some(rec => rec.recordingTime === recordingTime)) {
      recsList.push({
        recordingTime,
        title: '',
        speakers: labels
      })
    }
    setDoc(doc(collection(db, 'recordings'), roomId), {
      recordings: recsList
    })
  }

  const transcriptArr = useMemo(() => transcripts, [transcripts]);
  const [isTranscriptListOpen, setIsTranscriptListOpen] = useState(true);
  const [videoDuration, setVideoDuration] = useState(0);
  const [isVideoLoading, setIsVideoLoading] = useState(false);

  // useEffect(() => {
  //   console.log('transcripts changed');
  // }, [transcripts]);

  const setTranscriptArrRef = useRef();

  const resetVideo = useRef();

  useEffect(() => {
    resetVideo.current = reset;
  }, [reset])

  useEffect(() => {
    resetVideo.current();
    if (!curISO?.url) {
      videoRef.current.removeAttribute('src')
      videoRef.current.load()
    }
  }, [curISO?.url])

  useEffect(() => {
    setTranscriptArrRef.current = setTranscriptArr;
  }, [setTranscriptArr]);

  const analizeResult = (words) => {
    const endLetters = ['.', '?', '!'];
    let result = [];
    let text = "";
    let startP = 0;
    for (let i = 0; i < words.length; i++) {
      if (endLetters.find(letter => letter === words[i].text[words[i].text.length - 1]) || i === words.length - 1) {
        result.push({
          speaker: `[${words[startP].speaker}]`,
          message: `${text} ${words[i].text}`,
          created: words[startP].start,
          end: words[i].end,
        });
        startP = i + 1;
        text = "";
      } else {
        text = `${text} ${words[i].text}`
      }
    }

    return result;
  };

  useEffect(() => {
    if (curISO?.transcript) {
      const getTranscription = async () => {
        const response = await fetch(curISO?.transcript, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        return response.json();
      }
      
      getTranscription().then(transcripts => {
        // Check whether to load from assemblyai
        if (transcripts?.assemblyaiId) {
          const timerInt = setInterval(() => {
            const assembly = axios.create({
              baseURL: "https://api.assemblyai.com/v2",
              headers: {
                  authorization: apiToken,
                  "content-type": "application/json",
              },
            });
    
            assembly
              .get(`/transcript/${transcripts?.assemblyaiId}`)
              .then((res) => {
                if (res.data?.status === 'completed') {
                  clearInterval(timerInt);
                  setTranscriptArrRef.current(analizeResult(res.data.words))
                } else if (res.data?.status !== 'error') {
                  setTranscriptArrRef.current({
                    isProcessing: true,
                  })
                }
              })
              .catch((err) => {
                clearInterval(timerInt);
              });
          }, 1000)
        } else {
          setTranscriptArrRef.current(transcripts);
        }
      })
    } else {
      setTranscriptArrRef.current([]);
    }
  }, [curISO?.transcript]);

  const handleVideoLoad = (e) => {
    if (!videoDuration) {
      setVideoDuration(e.target.duration);
      dispatch(setDownloadTimeRange({
        startTime: '00:00:00',
        duration: e.target.duration
      }))
    }
    setIsVideoLoading(false);
  }

  const handleKeyDown = useCallback((event) => {
    if (event.code === 'Space') {
      togglePlay();
    } else if (event.code === 'ArrowLeft') {
      handleVideoBackward(1);
    } else if (event.code === 'ArrowRight') {
      handleVideoBackward(-1);
    } else if (event.code === 'Escape') {
      setIsFullScreen(false)
    }
  }, [togglePlay, handleVideoBackward]);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyDown);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const toggleFullScreen = (e) => {
    setIsFullScreen(!isFullScreen);
  }

  const disableEnter = (e) => {
    if (e.code === 'Enter' || e.code === 'Space') {
      e.preventDefault();
    }
  }

  return (
    <div className={`flex w-full ${props.className} cursor-default bg-none`}>
      <div
        className="flex flex-col bg-gray-400 rounded-bl-md p-1"
        style={{height: 'calc(100vh - 234px)', minHeight: '558px'}}
      >
        <RecordingCalendar 
          isRecLoading={props?.isLoading}
          recordingDate={curDate}
          setRecordingDate={setCurDate}
          recordings={recordings}
        />
        <CurDateRecordings 
          isRecLoading={props?.isLoading}
          curDateRecordings={curDateRecordings}
          curDate={curDate}
          curISO={curISO}
          setCurISO={setCurISO}
          updateTitle={updateTitle}
        />
      </div>

      <Video
        videoRef={videoRef}
        isFullScreen={isFullScreen}
        curISO={curISO}
        handleOnTimeUpdate={handleOnTimeUpdate}
        handleVideoLoad={handleVideoLoad}
        setIsVideoLoading={setIsVideoLoading}
        playVideo={playVideo}
        toggleFullScreen={toggleFullScreen}
        disableEnter={disableEnter}
        playerState={playerState}
        isVideoLoading={isVideoLoading}
        togglePlay={togglePlay}
        handleRefresh={handleRefresh}
        handleVideoBackward={handleVideoBackward}
        handleVideoSpeed={handleVideoSpeed}
        isTranscriptListOpen={isTranscriptListOpen}
        setIsTranscriptListOpen={setIsTranscriptListOpen}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        transcripts={transcripts}
        setResult={setResult}
        videoDuration={videoDuration}
        handleVideoProgress={handleVideoProgress}
        toggleMute={toggleMute}
      />

      <TranscriptList 
        isOpen={isTranscriptListOpen}
        isPlaying={playerState?.isPlaying}
        close={() => setIsTranscriptListOpen(false)}
        transcripts={transcriptArr}
        currentTime={playerState?.currentTime}
        setCurrentTime={setCurrentTime} 
        curISO={curISO} 
        updateSpeakersLabel={updateSpeakersLabel}
        videoDuration={videoDuration}
      />

      <Modal
        isOpen={isTranscriptModalOpen}
        isHeader={true}
        isFooter={false}
        closeModal={() => setIsTranscriptModalOpen(false)}
        isDraggable={true}
        initialLeft={5}
        initialTop={10}
        isEnabledOutsideClick={false}
        className="bg-gray-800 opacity-90"
        handleExitFullScreen={() => setIsFullScreen(false)}
      >
        <div id="transcript" className="text-white z-6000 bg-gray-800 w-500 h-auto p-2 rounded-b-lg">
          {playerState.transcript?.speaker}
          {playerState.transcript?.message}
        </div>
      </Modal>
    </div>
  )
}

export default AbleVideo;
