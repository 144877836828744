import React, { useState, useEffect, useRef } from "react";
import { timeFormat } from "../utils";
import { ReactComponent as EditIcon } from "../icons/pencil.svg";
import CountdownTimeModal from "../features/countdownTimeModal";

const CountDownTimer = ({ endTime, setTime, onClick, zIndex, selected, editable, isTimerModal, setIsTimerModal, ...props }) => {
	const [timerMessage, setTimerMessage] = useState('');

	const timeout = useRef();
	
	useEffect(() => {
		if (props.playStatus && endTime > new Date().getTime()) {
			timeout.current = setTimeout(() => {
				setTime(Math.max(0, Math.round((endTime - new Date().getTime()) / 1000)), props?.playStatus);
				// setCurrentTime(Math.max(0, Math.round((endTime - new Date().getTime()) / 1000)));
				if (endTime <= new Date().getTime()) {
					clearTimeout(timeout.current);
				}
			}, 1000);
		} else if (!props?.playStatus) {
			clearTimeout(timeout.current);
		} else if (endTime === 0) {
			// setCurrentTime(0);
		}
	}, [props.playStatus, endTime, setTime, props?.currentTime]);

	const handleSetTime = (t) => {
		setTime(t);
		setTimerMessage('');
	};

	const style = {
    position: "absolute",
    zIndex: selected || isTimerModal ? 40 : zIndex,
		background: props.backgroundColor,
  };
  
	const timerStyle = {
		fontFamily: props.fontFamily,
		color: props.textColor,
		fontSize: `${props.fontSize}px`,
		fontWeight: props.fontWeight,
		fontStyle: props.fontStyle,
		textDecoration: props.textDecoration,
		textAlign: props.textAlign,
	}

	return (
		<div
			className={`text-white p-2 rounded-md flex relative w-full h-full items-center`}
			style={style}
			onClick={onClick}
		>
			<CountdownTimeModal
				isOpen={isTimerModal}
				onClose={() => {
					setIsTimerModal(false);
					props.setTextActive(null);
				}}
				setCountdownTime={handleSetTime}
				message={timerMessage}
			/>
			<div className="w-full" style={timerStyle}>
				{timeFormat(Math.max(0, props?.currentTime))}
			</div>
		</div>
	)
};

export default CountDownTimer;
