import React, { useEffect, useContext } from "react";
import Broadcaster from "../features/broadcaster.js";
import { AudioChannelProvider } from "../helpers/contexts/audioChannelContext.js";
import { ChatProvider } from "../helpers/contexts/chatContext.js";
import ConferenceCallProvider from "../helpers/contexts/conferenceCallContext.js";
import { LocalTracksProvider } from "../helpers/contexts/localTracksContext.js";
import { TranscriptionProvider } from "../helpers/contexts/transcriptionContext.js";
import { AuthContext } from "../helpers/contexts/userSessionContext.js";
import { signInAnonymously } from "@firebase/auth";
import { LayerEditorProvider } from "../helpers/contexts/layerEditorContext.js";
import { FirebaseContext } from "../helpers/contexts/firebaseContext.js";
import { useParams } from "react-router-dom";

/**
 * Conference is the chief component responsible for rendering the Conference view. There
 * are two primary end-users for this view. Viewers, as in anonymous users, and Producers,
 * the people running the conference.
 */
const Producer = ({
  roomType="broadcast"
}) => {
  const { auth } = useContext(AuthContext);
  const { user } = useContext(FirebaseContext);
  useEffect(() => {
    if (!user && auth) {
      signInAnonymously(auth).then((data) => {
        // console.log('beam user', {user})
      })
    } else {
      // console.log('host user', {user})
    }
  }, [user, auth]);
  
  const { conferenceId } = useParams();

  return (
    <LayerEditorProvider>
      <AudioChannelProvider>
        <ConferenceCallProvider>
          <LocalTracksProvider>
            <TranscriptionProvider roomId={conferenceId}>
              <ChatProvider>
                <Broadcaster roomType={roomType} />
              </ChatProvider>
            </TranscriptionProvider>
          </LocalTracksProvider>
        </ConferenceCallProvider>
      </AudioChannelProvider>
    </LayerEditorProvider>
  );
};

export default Producer;
