import React, { useContext, useState, useMemo } from "react";
import { nanoid } from "nanoid";
import {ReactComponent as MovieIcon} from "../../icons/movie.svg";
import {ReactComponent as CloseIcon} from "../../icons/x-circle.svg";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { ReactComponent as EditIcon } from "../../icons/pencil.svg";
import { ReactComponent as ChevronDownIcon } from "../../icons/chevron-down.svg";
import DropDownMenu from "../dropDownMenu";
import Button from "../button";
import SaveScreenModal from "../../features/saveSceneModal";
import { FirebaseContext } from "../../helpers/contexts/firebaseContext";
import { useLayerEditorContext } from "../../helpers/hooks/useLayerEditorContext";
import { useCollectionQuery } from "../../helpers/hooks/useFirebaseCollection";
import { useDocumentQuery } from "../../helpers/hooks/useFirebaseDocument";
import InputField from "../forms/inputField";
import StreamBackgroundModal from "../../features/streamBackgroundModal";
import { AuthContext } from "../../helpers/contexts/userSessionContext";
import { deleteFile, firebaseUpload } from "../../helpers/firebaseUpload";
import DeleteSceneModal from "../../features/deleteSceneModal";
import SaveLayoutModal from "./saveLayoutModal";
import LoadLayoutModal from "./loadLayoutModal";
import { getFirestore, collection, doc, setDoc, updateDoc, getDocs, getDoc, deleteDoc } from "@firebase/firestore";
import { useConferenceCall } from "../../helpers/hooks/useConferenceCall";
import { ReactComponent as ArrowRightIcon } from "../../icons/arrows.svg";
import DynamicCanvasSetting from "./dynamicCanvasSetting";
import SpeakerViewSetting from "./speakerViewSetting";

const Scenes = ({conferenceId}) => {
	const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);

  const confRef = useMemo(() => doc(collection(db, "conferences"), conferenceId ?? "defaultConference"), [db, conferenceId]);
  const scenesRef = useMemo(() => collection(confRef, "scenes"), [confRef]);
	const [isEditOpen, setIsEditOpen] = useState(false);
  const [sceneModalOpen, setSceneModalOpen] = useState(false);
  const [currentScene, setCurrentScene] = useState();
	const [nextId, setNextId] = useState(-1);
  const [openAlertCurrentChange, setOpenAlertCurrentChange] = useState(false);
	const [isRenameModal, setIsRenameModal] = useState(false);
  const [bgModalOpen, setBgModalOpen] = useState(false);
  const [deletingImages, setDeletingImages] = useState([]);
  const [currentBackground ,setCurrentBackground] = useState();
  const [saveLayoutModal, setSaveLayoutModal] = useState(false);
  const [loadLayoutModal, setLoadLayoutModal] = useState(false);
  const [layouts, setLayouts] = useState([]);
	const [
    confirmDeleteSceneModalOpen,
    setConfirmDeleteSceneModalOpen,
  ] = useState(false);
	const [scenes, scenesLoading] = useCollectionQuery(scenesRef, {
    idField: "id",
  });
	const [conference, confLoading] = useDocumentQuery(confRef, {
    idField: "id",
  });
	const activeScene = useMemo(() => scenes?.find(({ id }) => id === conference?.activeScene), [scenes, conference]);
  const editingScene = useMemo(() => scenes?.find(({ id }) => id === 'editing_scene'), [scenes]);

  const { user, hostStatus } = useContext(AuthContext);
	const [editable, setEditable] = useState(() => {
    if (hostStatus) {
      if (user)
        return hostStatus[user?.uid];
      else return false;
    } return false;
  });

  const [isDynamicSetting, setIsDynamicSetting] = useState(false);
  const [isSpeakerSetting, setIsSpeakerSetting] = useState(false);
  const [isAutoSpeaker, setIsAutoSpeaker] = useState(true);
  const { curManualSpeaker, setCurManualSpeaker } = useLayerEditorContext();

	const { currentState, setEdited, } = useLayerEditorContext();

	const { participants, localParticipant } = useConferenceCall({});

  const setAutoDynamic = (value) => {
    updateDoc(confRef, {
      isAutoDynamic: !!value
    })
  }
	
	const setActiveScene = (id) => {
    updateDoc(confRef, {
      activeScene: id,
    });
  };

	const createNewScene = async ({ switchOnCreate = false, name = "" }) => {
    const id = nanoid();

    await setDoc(doc(scenesRef, id), {
      name,
      layers: [],
      topZIndex: 1,
    });

    for (let participant of [...participants, localParticipant]) {
      await setDoc(doc(collection(doc(scenesRef, id), "layers"), participant.identity ?? 'default'), {
        id: participant.identity,
        type: "stream",
        x: 0,
        y: 0,
        z: 0,
        height: 250,
        width: 444,
        zone: "participant",
      });
    }
    
    if (switchOnCreate) {
      setActiveScene(id);
    }

    return id;
  };
	
	const submitNewScene = ({ name }) => {
    createNewScene({ name, switchOnCreate: true });
    setSceneModalOpen(false);
  };

  const saveLayout = async ({ name }) => {
    const snapshots = await getDocs(collection(doc(scenesRef, activeScene?.id  ?? 'default'), "layers"));
    let temp = [];
    snapshots.forEach(doc => {
      temp.push(doc.data());
    });
    const id = nanoid();
    const _sceneData = await getDoc(doc(scenesRef, activeScene?.id ?? 'default'));
    const background = _sceneData?.data()?.background;
    setDoc(doc(collection(confRef, "savedLayouts"), id), {
      id,
      name,
      background: background ?? "",
      data: temp
    });
    setSaveLayoutModal(false);
  };

  const loadLayout = async ({id}) => {
    const _layout = layouts.find((item) => id === item.id);
    const snapshots = await getDocs(collection(doc(scenesRef, activeScene?.id ?? 'default'), "layers"));
    let temp = [];
    snapshots.forEach(doc => {
      temp.push(doc.data());
    });
    if (_layout.background) {
      await updateDoc(doc(scenesRef, activeScene?.id ?? 'default'), {
        background: _layout.background
      })
    } else {
      await updateDoc(doc(scenesRef, activeScene?.id ?? 'default'), {
        background: { color: 'white'}
      });
    }
    for (let _data of temp) {
      await deleteDoc(doc(collection(doc(scenesRef, activeScene?.id ?? 'default'), "layers"), _data.id));
    }
    for (let _data of _layout.data) {
      setDoc(doc(collection(doc(scenesRef, activeScene?.id ?? 'default'), "layers"), _data.id), {
        ..._data
      });
    }
    setLoadLayoutModal(false);
  };

  const handleLoadLayout = async (e) => {
    const _layouts = await getDocs(collection(confRef, "savedLayouts"));
    let temp = [];
    _layouts.forEach(doc => {
      temp.push(doc.data());
    });
    setLayouts(temp);
    setLoadLayoutModal(true);
  };

	const updateBackground = async ({ color, image }) => {
    let url;

    if (image) {
      const file = image[0];
      try {
        if (activeScene?.background?.image) {
          if (editable && currentState) {
            let temp = JSON.parse(JSON.stringify(deletingImages));
            temp.push(editingScene?.background?.image);
            setDeletingImages(temp);
          } else {
            await deleteFile(firebaseApp)({ name: activeScene?.background?.image });
          }
        }
      } catch (err) {
        // console.log(err);
      }
      url = await firebaseUpload(firebaseApp)({
        name: file.name,
        file,
      });
    }
    setCurrentBackground({
      background: {
        color,
        image: url ?? "",
      },
    });
    updateDoc(doc(scenesRef, activeScene?.id ?? 'default'), {
      background: {
        color: color ? color : '',
        image: url ?? "",
      },
    });
    setEdited(true);
    setBgModalOpen(false);
  };
	
	const handleSceneDelete = () => {
    
      deleteDoc(doc(scenesRef, activeScene?.id ?? 'default'));
      if (scenes.length > 1) {
        let newScene = scenes.filter((scene) => scene.id !== activeScene?.id)[0];
        // setCurrentScene(newScene);
        setActiveScene(newScene.id);
      } else {
        setDoc(doc(scenesRef, "editing_scene"), {
          name: "EditingScene",
          layers: [],
          topZIndex: 1,
        });
    
        participants.forEach((participant) => {
          setDoc(doc(collection(doc(scenesRef, "editing_scene"), "layers"), participant.identity ?? 'default'), {
            id: participant.identity,
            type: "stream",
            x: 0,
            y: 0,
            z: 0,
            height: 250,
            width: 444,
            zone: "participant",
          });
        });
        setActiveScene("editing_scene");
      }
    setConfirmDeleteSceneModalOpen(false);
  };
	
	const RenameModal = ({isOpen, onClose}) => {
		const [name, setName] = useState(activeScene?.name);

		const handleChangeName = (e) => {
			setName(e.target.value);
		};

		const handleConfirmName = () => {
			if (name && name.length > 0) {
        updateDoc(doc(scenesRef, activeScene?.id ?? 'default'), { name });
      }
			onClose();
		};

		if (isOpen) {
			return (
				<div className="absolute right-0 top-20 z-20 custom-bg-2 p-3 rounded-md shadow-md">
					<InputField
						fullWidth
						label="Scene Name: "
						value={name}
            labelStyle="text-gray-100"
						onChange={handleChangeName}
						placeholder="RTMP server url"
					/>
					<div className="w-full pt-2">
						<Button className="float-right" onClick={handleConfirmName}>OK</Button>
					</div>
					<CloseIcon className="w-5 h-5 absolute right-0 top-0 cursor-pointer hover:text-red-400" onClick={() => onClose()} />
				</div>
			)
		} else {
			return (<></>)
		}
	};

	return (
    <div className="relative flex items-center mx-4">
      <SaveScreenModal
        isOpen={sceneModalOpen}
        onClose={() => setSceneModalOpen(false)}
        onSubmit={submitNewScene}
      />
      <SaveLayoutModal
        isOpen={saveLayoutModal}
        onClose={() => setSaveLayoutModal(false)}
        onSubmit={saveLayout}
      />
      <LoadLayoutModal
        isOpen={loadLayoutModal}
        onClose={() => setLoadLayoutModal(false)}
        layouts={layouts}
        onSubmit={loadLayout}
      />
      <RenameModal isOpen={isRenameModal} onClose={() => setIsRenameModal(false)} />
      <StreamBackgroundModal
        isOpen={bgModalOpen}
        onClose={() => setBgModalOpen(false)}
        onSubmit={updateBackground}
        initialColor={conference?.background?.color}
      />
      <DeleteSceneModal
        isOpen={confirmDeleteSceneModalOpen}
        onClose={() => setConfirmDeleteSceneModalOpen(false)}
        onSubmit={handleSceneDelete}
        name={activeScene?.name ?? "Name Missing"}
      />
      {/* <MovieIcon
        className="w-5 h-5 cursor-pointer hover:text-yellow-400"
      /> */}
      <DropDownMenu
        className="flex custom-bg-1 border-1 border-gray-500 pl-8 mt-1 text-gray-100 items-center"
        childCls="custom-bg-2 shadow-lg rounded"
        icon="screen"
        text={
          activeScene ? activeScene.name : "None"
        }
      >
        <div
          className="flex flex-col"
          onMouseLeave={(e) => {
            setIsDynamicSetting(false)
            setIsSpeakerSetting(false)
          }}
        >
          <DropDownMenu.MenuItem
            className="px-2 py-1 text-gray-100 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              // setIsDynamicSetting(!isDynamicSetting);
            }}
            onMouseEnter={(e) => {
              setIsDynamicSetting(true)
              setIsSpeakerSetting(false)
            }}
          >
            <span
              className="flex flex-col rounded hover:border hover:border-gray-500"
            >
              <span className="flex items-center justify-between">
                Dynamic Canvas
                <ArrowRightIcon 
                  className="text-gray-100 w-3 h-3 -rotate-90 mt-1"
                  onClick={(e) => {}}
                />
              </span>
              {activeScene?.dynamicId > 0 && 
                <span className="text-xs text-gray-500 italic">
                  {activeScene?.dynamicId} Speaker{activeScene?.dynamicId > 1 ? 's' : ''}
                </span>
              }
              <DynamicCanvasSetting
                isShow={isDynamicSetting} 
                scenes={scenes?.filter(scene => scene.dynamicId > 0)}
                activeScene={activeScene?.id}
                setActiveScene={setActiveScene}
                isAutoDynamic={conference?.isAutoDynamic}
                setAutoDynamic={setAutoDynamic}
              />
            </span>
          </DropDownMenu.MenuItem>
          <div
            className="mx-2 text-white rounded cursor-pointer hover:border hover:border-gray-500 flex justify-between items-center"
            onClick={() => {
              setActiveScene(scenes?.find(scene => scene.isSingleView === true)?.id);
            }}
            onMouseEnter={(e) => {
              setIsDynamicSetting(false)
              setIsSpeakerSetting(true)
            }}
          >
            Speaker View
            <ArrowRightIcon 
              className="text-gray-100 w-3 h-3 -rotate-90 mt-1"
              onClick={(e) => {}}
            />
            {/* <div className="text-green-500">
              {activeScene?.isSingleView === true ? 'active': ''}
            </div> */}
            <SpeakerViewSetting
              isShow={isSpeakerSetting}
              isAutoSpeaker={isAutoSpeaker}
              setIsAutoSpeaker={setIsAutoSpeaker}
              participants={[localParticipant, ...participants]}
              topAxis={activeScene?.dynamicId > 0 ? 12 : 8}
              curManualSpeaker={curManualSpeaker}
              setCurManualSpeaker={setCurManualSpeaker}
            />
          </div>
          <div
            className="flex flex-col"
            onMouseEnter={(e) => {
              setIsDynamicSetting(false);
              setIsSpeakerSetting(false);
            }}
          >
            {scenes?.filter(scene => (!!!scene.dynamicId || scene.dynamicId < 1) && scene.isSingleView !== true && scene.id !== 'editing_scene')
              ?.map((scene) => (
                <div key={scene.id}>
                  <DropDownMenu.MenuItem
                    keycode={scene.id}
                    className="px-2 py-1 text-gray-100 cursor-pointer flex justify-between"
                    onClick={() => setActiveScene(scene.id)}
                  >
                    {scene.name} 
                    <div className="text-green-500">
                      {scene.id === activeScene?.id ? 'active': ''}
                    </div>
                  </DropDownMenu.MenuItem>
                </div>
              )
            )}
          </div>
        </div>
      </DropDownMenu>
      
      <button
        className="flex custom-bg-1 border border-1 border-gray-500 text-gray-100 ml-2 items-center hover:custom-bg-1 focus:ring-2 focus:ring-yellow-500 text-sm rounded p-2 pr-4 h-8 -mb-1"
        onClick={() => setSceneModalOpen(true)}
      >
        <PlusIcon className="h-4 w-4" /> Add Scene
      </button>
      
      <button
        className="flex custom-bg-1 border border-1 border-gray-500 text-gray-100 ml-2 items-center hover:custom-bg-1 focus:ring-2 focus:ring-yellow-500 text-sm rounded p-2 h-8 -mb-1"
        onClick={() => setIsEditOpen(!isEditOpen)}
      >
        <EditIcon className="h-4 w-4" /> Edit Scene
        <ChevronDownIcon className="h-4 w-4 hover:text-gray-600 ml-px" />
      </button>
      {isEditOpen && <div className="flex gap-2 custom-bg-2 pt-3 pb-3 items-center rounded-lg absolute top-10 right-0 pl-5 pr-5 shadow-md">
        <div className="flex flex-col">
          <button className="mt-2 custom-bg-2 hover:custom-bg-2 border-none text-gray-100" onClick={() => setIsRenameModal(!isRenameModal)}>Rename Scene</button>
          <button className="mt-2 custom-bg-2 hover:custom-bg-2 border-none text-gray-100" onClick={() => setBgModalOpen(true)}>Edit Background</button>
          <button className="mt-2 custom-bg-2 hover:custom-bg-2 border-none text-gray-100" onClick={() => setSaveLayoutModal(!saveLayoutModal)}>Save as Layout</ button>
          <button className="mt-2 custom-bg-2 hover:custom-bg-2 border-none text-gray-100" onClick={handleLoadLayout}>Load Layout</button>
          <button className="mt-2 custom-bg-2 hover:custom-bg-2 border-none text-gray-100" onClick={() => setConfirmDeleteSceneModalOpen(true)}>Delete Scene</button>
        </div>
        <CloseIcon className="w-5 h-5 absolute right-0 top-0 cursor-pointer custom-bg-3 rounded-xl hover:text-red-400" onClick={() => setIsEditOpen(false)} />
      </div>}
    </div>
	)
};

export default Scenes;
