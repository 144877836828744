import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import {
  Accordion,
  Box,
  Button,
  Flex,
  Input,
  Label,
  Loading,
  Text,
} from "@100mslive/react-ui";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { 
  setError, 
  setLoading, 
  setChannelId, 
  setAccessToken,
  setBroadcastId,
  setLiveStreamId,
  setMonitorStream,
  setIsLive,
  setIsTestingBC,
} from '../../../helpers/redux/reducers/youtubeRtmpReducer';

const FormLabel = ({ id, children }) => {
  return (
    <Label
      htmlFor={id}
      css={{ color: "$textHighEmp", my: "$4", fontSize: "$sm" }}
    >
      {children}
    </Label>
  );
};

const Asterik = () => {
  return (
    <Text variant="sm" as="span" css={{ color: "$error", mx: "$2" }}>
      *
    </Text>
  );
};

const YouTubeOAuth = ({
  rtmpStreams,
	setRTMPStreams,
	updateStream,
}) => {
  const redirect_uri = window.location.href;

  // const baseInfoUrl = 'http://localhost:5000/youtube_video_info';
  const baseInfoUrl = 'https://videosplitor.voodoocast.io/youtube_video_info';

  const [isConfirming, setIsConfirming] = React.useState(false);

  const [rtmpStreamId, setRtmpStreamId] = React.useState();

  const dispatch = useDispatch();

  const { 
    loading,
    error,
    accessToken,
    liveStreamId
  } = useSelector(state => state.youtubeRtmp);

  const [loadingStep, setLoadingStep] = React.useState('oauth');
  const [bcName, setBCName] = React.useState('');

  const getChannelId = async (_accessToken) => {
    const response = await axios.get(`${baseInfoUrl}/get_broadcast_channels?access_token=${_accessToken}`);

    console.log({ channelData: response?.data })

    if (response?.data?.success) {
      dispatch(setChannelId(response?.data?.id));
    } else {
      throw new Error('No Channel Exists', {err: response?.data?.error});
    }
    return response.data;
  };

  const getLiveStreamId = async (_accessToken) => {
    const listLiveStreamsResponse = await axios.get(`${baseInfoUrl}/get_live_stream_rtmp?access_token=${_accessToken}`);

    let liveStreamInstance = listLiveStreamsResponse?.data?.data?.items?.filter(item => item?.status?.streamStatus === 'ready')?.[0];

    if (!liveStreamInstance) {
      console.log('stream does not exists');
      const newLiveStreamResponse = await axios.get(`${baseInfoUrl}/get_live_stream_rtmp?access_token=${_accessToken}`);
      liveStreamInstance = newLiveStreamResponse?.data?.data;
    }

    if (liveStreamInstance) {

      console.log({liveStreamInstance})

      dispatch(setLiveStreamId(liveStreamInstance?.id))
      dispatch(setIsLive(false))

      const rtmpKey = liveStreamInstance?.cdn?.ingestionInfo?.streamName;
      const rtmpUrl = liveStreamInstance?.cdn?.ingestionInfo?.ingestionAddress;

      setRTMPStreams(streams => updateStream({
        streams,
        id: rtmpStreamId,
        key: 'streamKey',
        value: rtmpKey
      }));
      setRTMPStreams(streams => updateStream({
        streams,
        id: rtmpStreamId,
        key: 'rtmpURL',
        value: rtmpUrl
      }));
      setRTMPStreams(streams => updateStream({
        streams,
        id: rtmpStreamId,
        key: 'isYoutube',
        value: true
      }));
      setRTMPStreams(streams => updateStream({
        streams,
        id: rtmpStreamId,
        key: 'youtubeLiveStreamId',
        value: liveStreamInstance?.id
      }));
      setRTMPStreams(streams => updateStream({
        streams,
        id: rtmpStreamId,
        key: 'youtubeStartTimestamp',
        value: Date.now(),
      }));
    } else {
      throw new Error('Cannot Create Live Stream to Your Youtube');
    }

    return liveStreamInstance;
  };

  const getBroadcastId = async (_accessToken) => {
    const response = await axios.get(`${baseInfoUrl}/new_broadcast?access_token=${_accessToken}&broadcast_name=${bcName}`)

    console.log({broadcastResponse: response});

    if (response?.data?.success) {
      dispatch(setBroadcastId(response?.data?.data?.id))
      setRTMPStreams(streams => updateStream({
        streams,
        id: rtmpStreamId,
        key: 'youtubeBroadcastId',
        value: response?.data?.data?.id
      }));

      setRTMPStreams(streams => updateStream({
        streams,
        id: rtmpStreamId,
        key: 'name',
        value: bcName,
      }));
    } else {
      throw new Error('Error When Creating Broadcast in YouTube', {err: response?.data?.error})
    }

    return response.data;
  };

  const bindBroadcast = async (_accessToken, _broadcastId, _liveStreamId) => {
    const response = await axios.get(`${baseInfoUrl}/bind_broadcast?access_token=${_accessToken}&broadcast_id=${_broadcastId}&stream_id=${_liveStreamId}`)

    console.log({bindResponse: response})
    if (response?.data?.success) {
      dispatch(setMonitorStream(response?.data?.data?.contentDetails?.monitorStream?.embedHtml));
    } else {
      throw new Error('Error When Binding Broadcast to LiveStream', {err: response?.data?.error});
    }

    return response.data;
  };

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      // Handle the response after successful sign-in
      const _accessToken = response.access_token;
      dispatch(setAccessToken(_accessToken));

      try {
        const channelResponse = await getChannelId(_accessToken);

        const streamResponse = await getLiveStreamId(_accessToken);

        setLoadingStep('creating_broadcast')

      } catch (err) {
        console.log(err)
        dispatch(setError(err?.message));
        dispatch(setLoading(false));
      }
    },
    onError: response => {
      dispatch(setError('Something Error'))
      dispatch(setLoading(false))
    },
    scope: 'profile email https://www.googleapis.com/auth/youtube.force-ssl',
    ux_mode: 'redirect',
    redirect_uri: redirect_uri
  });

  const handleCreateNewBC = async e => {
    dispatch(setError(''));
    const newStreams = rtmpStreams?.filter(stream => !stream.isYoutube) ?? [];

    if (newStreams.length === 3) {
      dispatch(setError("Live Stream Number exceeded the maxium of 3"));
      return;
    }
    
    const newId = Date.now();

    setRtmpStreamId(newId);
    setRTMPStreams(streams => [
      ...newStreams,
      {
        name: "Stream",
        id: newId,
        rtmpURL: "",
        streamKey: "",
        isYoutube: true,
      },
    ]);

    login()
  }

  const handleConfirmBC = async (e) => {
    if (!bcName) return;

    setIsConfirming(true);

    try {
      const broadcastResponse = await getBroadcastId(accessToken);

      const broadcastId = broadcastResponse?.data?.id;

      const bindResponse = await bindBroadcast(accessToken, broadcastId, liveStreamId);
    } catch (err) {
      console.log(err)
      dispatch(setError(err?.message));
    }
    dispatch(setLoading(false));
    setLoadingStep('oauth');
    setIsConfirming(false);
  };

  return (
    <div className="">
      <Button 
        css={{ mx: '$10', my: '$1', h: '$10' }}
        disabled={loading}
        onClick={handleCreateNewBC}
      >
        Stream To YouTube
      </Button>
      {error && 
        <div className="flex justify-between mx-6">
          <span className="text-red-300 mb-2">{error}</span>
          <a href="https://www.youtube.com/" target="_blank">YouTube</a>
        </div>
      }
      {loadingStep === 'creating_broadcast' &&
        <Box
          className='border border-gray-700 rounded m-2 p-2'
        >
          <FormLabel id="rtmpName">
            RTMP NAME
            <Asterik />
          </FormLabel>
          <Input
            placeholder="Enter RTMP Name"
            id="name"
            name="name"
            value={bcName}
            onChange={e => {
              setBCName(e.target.value)
            }}
            required
          />
          <div className="flex justify-end">
            <Button
              className="mt-2"
              disabled={isConfirming}
              onClick={handleConfirmBC}
            >Confirm</Button>
          </div>
        </Box>
      }
    </div>
  );
};

export default YouTubeOAuth;
