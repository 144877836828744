import React from "react";
import { 
  Button,
  Input,
  Flex,
  Tooltip,
} from "@100mslive/react-ui";
import { ReactComponent as ChevronDownIcon } from "../../../icons/chevron-down.svg";
import {CrossIcon} from "@100mslive/react-icons"
import Modal from "../../../components/modal";
import defaultInteractionType from "./defaultInteractionType.json";
import filterKeys from "./filterKeys.json";

const TargetModal = ({
  isTargetModal,
  setIsTargetModal,
  handleSetFilter,
  curFilterMatch={},
}) => {
  const [filterMatch, setFilterMatch] = React.useState({ key: 'all', name: 'All'});
  React.useEffect(() => {
    if (curFilterMatch?.key) {
      setFilterMatch(curFilterMatch)
    }
  }, [curFilterMatch])
  
  return (
    <Modal
      isOpen={isTargetModal}
      closeModal={() => setIsTargetModal(false)}
      className="w-full left-0"
      isHeader={false}
      isFooter={false}
    >
      <div className="custom-bg-1 w-full p-2 rounded-t-md flex items-center">
        <h2 className="font-medium text-xl text-gray-100">Filter Target</h2>
      </div>
      <div 
        className="p-2"
      >
        <div>
          <label>Filter By</label>
          <select
            className="bg-black text-white h-7 px-2 ml-2"
            style={{ borderRadius: '15px' }}
            value={filterMatch?.key ?? ''}
            onChange={(e) => setFilterMatch(prev => {
              return {
                ...prev,
                key: e.currentTarget.value,
                name: filterKeys?.find(t => t.key === e.currentTarget.value)?.name
              }
            })}
          >
            {filterKeys.map((filterKey, idx) => (
              <option key={idx} value={filterKey.key}>
                {filterKey.name}
              </option>
            ))}
          </select>
        </div>
        <Input 
          css={{ 
            minHeight: '10px',
            h: '$11',
            borderRadius: '$3',
            bg: 'white',
            color: 'black',
            my: '$3'
          }}
          disabled={filterMatch?.key === 'all'}
          value={filterMatch?.value ?? ''}
          onChange={(e) => setFilterMatch(prev => {
            return {
              ...prev,
              value: e.target.value
            }
          })}
        />
        <Flex
          justify="around"
          css={{
            mt: '$5',
            px: '$3'
          }}
        >
          <Button
            onClick={async () => {
              await handleSetFilter(filterMatch);
              setFilterMatch({ key: 'all', name: 'All'})
              setIsTargetModal(false);
            }}
            css={{ h: '$11' }}
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              setIsTargetModal(false);
            }}
            variant="standard"
            css={{ h: '$11' }}
          >
            Cancel
          </Button>
        </Flex>
      </div>
    </Modal>
  )
}

const InteractionType = ({
  curType={},
  setCurType=()=>{}  
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="flex flex-col">
      <label>Interaction Type</label>
      <div className="relative ml-1">
        <Tooltip side="bottom" title="required">
          <Flex
            justify="between"
            align="center"
            css={{
              borderRadius: "$3",
              bg: '#3e4857',
              color: '$white',
              minWidth: '200px',
              maxWidth: '500px',
              overflow: 'hidden',
              px: '$5',
              h: '$11',
              cursor: 'pointer'
            }}
            onClick={() => setIsOpen(true)}
          >
            {curType?.name && curType?.parentName &&
              <span>{curType?.parentName} / {curType?.name}</span>
            }
            {!curType?.name &&
              <span>Interaction Type</span>
            }
            <ChevronDownIcon className="w-4 h-4" />
          </Flex>
        </Tooltip>
        {isOpen &&  <div
          className="fixed left-0 top-0 w-full h-full z-10"
          onClick={() => setIsOpen(false)}
        ></div>}
        {isOpen && 
          <div className="absolute top-6 border rounded z-50 w-full bg-gray-600 text-white py-1">
            <label className="pl-1 text-green-300">Broadcast Window</label>
            {defaultInteractionType?.broadcastWindow?.map((value, idx) => (
              <div
                key={idx}
                className={`flex justify-between cursor-pointer z-50 mx-1 px-2 hover:bg-gray-200 
                  ${idx === defaultInteractionType?.broadcastWindow?.length - 1 ? '' : 'border-b border-gray-300'}`}
                onClick={() => {
                  setCurType({ ...value, parentType: 'broadcastWindow', parentName: 'Broadcast Window'})
                  setIsOpen(false)
                }}
              >
                <span>{value?.name}</span>
              </div>
            ))}
            <label className="pl-1 text-green-300">Chat Window</label>
            {defaultInteractionType?.chatWindow?.map((value, idx) => (
              <div
                key={idx}
                className={`flex justify-between cursor-pointer z-50 mx-1 px-2 hover:bg-gray-200 
                  ${idx === defaultInteractionType?.chatWindow?.length - 1 ? '' : 'border-b border-gray-300'}`}
                onClick={() => {
                  setCurType({ ...value, parentType: 'chatWindow', parentName: 'Chat Window'})
                  setIsOpen(false)
                }}
              >
                <span>{value?.name}</span>
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  )
}

const InteractionContent = ({
  curType={},
  curContent={},
  setCurContent=()=>{}
}) => {
  const [isAddOption, setIsAddOption] = React.useState(false);
  const [addingOption, setAddingOption] = React.useState('');

  return (
    <div className="flex flex-col">
      <label>Interaction Quote</label>
      <Input
        // className="border border-gray-500 rounded p-2 mx-2 text-white max-w-500"
        style={{ background: '#3e4857' }}
        value={curContent?.body ?? ''}
        css={{
          bg: '#3e4857',
          h: '$11',
          minHeight: '10px',
          borderRadius: '$3',
          color: '$white',
          ml: '$2',
          mr: '$2',
          width: 'calc(100% - 5px)'
        }}
        onChange={(e) => {
          setCurContent({ ...curContent, body: e.target.value })
        }}
      />
      <div className="mx-2 ">
        <div className="flex flex-col">
          {(curContent?.options ?? []).map((option, idx) => (
            <div
              key={idx}
              className="flex items-center justify-between mx-4 border-b border-blue-300"
            >
              <div className="flex items-center text-blue-500">
                <div className="w-2 h-2 mt-1 rounded-lg bg-blue-700 mr-2"></div>
                {option?.option}
              </div>
              <CrossIcon
                className="text-red-500 w-5 h-5 cursor-pointer hover:text-red-300"
                onClick={() => {
                  let newOptions = [];
                  curContent?.options.forEach(_option => {
                    if (_option.id !== option.id) newOptions.push(_option)
                  })
                  setCurContent({...curContent, options: newOptions})
                }}
              />
            </div>
          ))}
        </div>
        {curType?.parentType === 'chatWindow' && 
          <span
            className="cursor-pointer underline text-decoration-blue-500 text-blue-500 hover:text-blue-300"
            onClick={() => setIsAddOption(true)}
          >
            Add Option +
          </span>
        }
        {isAddOption &&
          <div className="flex flex-col border-2 border-gray-300 rounded p-2 max-w-500">
            <Input 
              value={addingOption}
              onChange={(e) => setAddingOption(e.target.value)}
              css={{
                bg: '#3e4857',
                h: '$7',
                borderRadius: '$3',
                color: '$white',
                ml: '$2',
                mr: '$2',
                width: 'calc(100% - 5px)'
              }}
            />
            <div className="flex justify-around mt-2">
              <Button
                variant="primary"
                css={{ h: '$12' }}
                onClick={() => { 
                  setCurContent({
                    ...curContent, 
                    options: [...(curContent?.options ?? []), { id: (curContent?.options ?? []).length, option: addingOption}]
                  })
                  setAddingOption('')
                  setIsAddOption(false)
                }}
              >Add</Button>
              <Button
                variant="standard"
                css={{ h: '$12' }}
                onClick={()=> {
                  setAddingOption('')
                  setIsAddOption(false)
                }}
              >Cancel</Button>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

const CreateInteraction = ({
  updatingInteraction,
  updateInteraction=async()=>{},
}) => {
  const [newInteraction, setNewInteraction] = React.useState({});
  const [isTargetModal, setIsTargetModal] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [errorMsg, setError] = React.useState('');

  React.useEffect(() => {
    if (updatingInteraction) {
      setNewInteraction(updatingInteraction)
    }
  }, [updatingInteraction])

  return (
    <div className="flex flex-col w-full  mt-5">
      {errorMsg && 
        <div className="text-red-500">
          {errorMsg}
        </div>
      }
      <div className="flex">
        <div className="flex flex-col mr-3">
          <label>Interaction Name</label>
          <Input
            placeholder="Interaction Name"
            css={{
              bg: '#3e4857',
              h: '$11',
              minHeight: '10px',
              borderRadius: '$3',
              color: '$white',
              ml: '$2',
              mr: '$2',
              width: 'calc(100% - 5px)'
            }}
            value={newInteraction?.name ?? ''}
            onChange={(e) => {
              setError('');
              setNewInteraction(prev => {
                return {
                  ...prev,
                  name: e.target.value
                }
              })
            }}
          />
        </div>
        <InteractionType
          curType={newInteraction?.type ?? {}}
          setCurType={(type) => {
            setNewInteraction(prev => {
              return {
                ...prev,
                type
              }
            })
            setError('');
          }}
        />
        <div className="ml-3">
          <label>Interaction Target</label>
          <Button
            variant="standard"
            css={{
              h: "$11",
              minHeight: '10px',
              minWidth: '100px',
              mx: '$2',
              borderRadius: '$3',
              p: '0',
              px: '$4'
            }}
            onClick={() => {
              setIsTargetModal(true);
              setError('');
            }}
          >
            {
              newInteraction?.filterMatch?.key === 'all'
              ? "All"
              : (newInteraction?.filterMatch?.key
                ? `${newInteraction?.filterMatch?.key} = ${newInteraction?.filterMatch?.value}`
                : 'Target')
            }
          </Button>
          <TargetModal 
            isTargetModal={isTargetModal}
            setIsTargetModal={setIsTargetModal}
            curFilterMatch={newInteraction?.filterMatch ?? {}}
            handleSetFilter={(filterMatch) => setNewInteraction(prev => {
              return {
                ...prev,
                filterMatch
              }
            })}
          />
        </div>
      </div>
      
      <InteractionContent
        curType={newInteraction?.type ?? {}}
        curContent={newInteraction?.content ?? {}}
        setCurContent={(content) => {
          setNewInteraction(prev => {
            return {
              ...prev,
              content
            }
          })
          setError('');
        }}
      />
      <div className='flex justify-center mt-3'>
        <Button
          css={{
            h: '$11',
            w: '$32',
            borderRadius: '$3'
          }}
          disabled={isLoading}
          onClick={async (e) => {
            if (!newInteraction?.name) {
              setError('Missing Interaction Name.');
              return;
            }
            if (!newInteraction?.type?.name) {
              setError('Misssing Interaction Type.');
              return;
            }
            if (!newInteraction?.content?.body) {
              setError('Missing Interaction Quote.');
              return;
            }
            if (!newInteraction?.filterMatch?.key /*|| !newInteraction?.filterMatch?.value*/) {
              setError('Missing Interaction Target.');
              return;
            }
            setLoading(true);
            updateInteraction(newInteraction);
            setNewInteraction({})
            setLoading(false);
          }}
        >Enter</Button>
      </div>
    </div>
  )
};

export default CreateInteraction;
