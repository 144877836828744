export const waitingRoomStyle = (isWaitingRoom, isBeamUser = false, isSingleView = false) => (isWaitingRoom && !isBeamUser) ?
  {
    height: `${isSingleView ? 125 : 160}px`,
    transform: 'translate(0px, 0px)',
    transition: 'transform 0.5s'
  } : (!isWaitingRoom && !isBeamUser) ? {
    height: `${isSingleView ? 125 : 160}px`,
    transform: 'translate(0px, calc(100% - 40px))',
    transition: 'transform 0.5s'
  } : {
    height: `${isSingleView ? 125 : 160}px`,
    transform: 'translate(0px, 100%)',
    transition: 'transform 0.5s'
  };
