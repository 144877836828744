import styled from "@emotion/styled";
import React, { useContext, useState, useMemo } from "react";
import { ReactComponent as ChevronDownIcon } from "../../icons/chevron-down.svg";
import { ReactComponent as LogoutIcon } from "../../icons/logout.svg";
import { PencilIcon } from "@100mslive/react-icons"
import Avatar from "../avatar";
import { getAuth, signOut } from "firebase/auth";
import ProfileModal from "../../features/profileModal";
import { firebaseUpload } from "../../helpers/firebaseUpload";
import { FirebaseContext } from '../../helpers/contexts/firebaseContext';
import { getFirestore, collection, doc, updateDoc } from "@firebase/firestore";

const AvatarMenu = ({ user, auth }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isProfileModal, setIsProfileModal] = useState(false);
  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);

  const toggleOpen = () => {
    setIsOpened(!isOpened);
  };

  const handleLogout = async () => {
    const _auth = getAuth();
    // await signOut(_auth);
    signOut(_auth).then(() => {
      window.location.href = '/';
    }).catch((error) => {
      // console.log('An error happened.');
    });
  };

  const handleEditProfile = async (data) => {
    let url = '';
    if (typeof(data?.photoUrl) === 'object') {
      url = await firebaseUpload(firebaseApp)({
        name: data?.photoUrl.name,
        file: data?.photoUrl,
      });
    }
    let updateData;
    if (url) {
      updateData = {
        photoUrl: url,
        firstName: data?.firstName,
        lastName: data?.lastName,
        title: data?.title,
        company: data?.company,
        fieldOne: data?.fieldOne,
        fieldTwo: data?.fieldTwo,
        fieldThree: data?.fieldThree
      }
    } else {
      updateData = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        title: data?.title,
        company: data?.company,
        fieldOne: data?.fieldOne,
        fieldTwo: data?.fieldTwo,
        fieldThree: data?.fieldThree
      }
    }
    await updateDoc(doc(collection(db, "profiles"), user?.uid ?? 'default'), {
      ...updateData
    })
  }

  if (!user) return null;

  return (
    <div className="relative">
      <button
        onClick={toggleOpen}
        className={`flex items-center custom-bg-3 p-2 rounded-t-lg ${isOpened ? "" : "rounded-b-lg"} hover:bg-gray-400`}
      >
        <Avatar imgUrl={user?.photoUrl} />
        <div className="flex items-center ">
          {/* <span className="text-sm font-bold text-gray-700 w-full">
            {_user?.displayName ? _user?.displayName : (_user?.firstName && _user?.lastName) ? 
              `${_user?.firstName} ${_user?.lastName}` : _user?.email}
          </span> */}
          <ChevronDownIcon className="h-5 w-5 mt-px text-gray-200" />
        </div>
      </button>
      <ProfileModal
        isOpen={isProfileModal}
        onClose={() => setIsProfileModal(false)}
        onSubmit={handleEditProfile}
        user={user}
      />
      {isOpened && (
        <div
          className="fixed w-full h-full top-0 left-0"
          onClick={() => toggleOpen()}
        >
          <div className="absolute custom-bg-3 shadow-lg rounded-b-lg rounded-l-lg top-12 right-2 p-2">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsProfileModal(true)
              }}
              className="font-bold hover:bg-gray-50 w-full p-2 flex items-center gap-3 text-gray-300 rounded"
            >
              <PencilIcon
                className="w-4 h-4"
              /> Profile
            </button>
            <button
              className="font-bold hover:bg-gray-50 w-full p-2 flex items-center gap-3 text-gray-300 rounded"
              onClick={(e) => {
                e.stopPropagation();
                handleLogout();
              }}
            >
              <LogoutIcon className="w-4 h-4" /> Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvatarMenu;
