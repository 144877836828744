import { useRef, useState, useEffect } from "react";
import { Button, Flex, Input, Tooltip } from "@100mslive/react-ui";
import LinkForm from "./linkForm";
import { ReactComponent as PaintIcon } from "../../../icons/paint-icon.svg";
import SaveTemplateForm from "./saveTemplateForm";
import LoadTemplateForm from "./loadTemplateForm";
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css'; //Example style, you can use another

const CommunicationBody = ({
  comBody,
  setComBody=()=>{},
  curCommunicate,
  communicates=[],
  templates,
  saveTemplate,
  removeTemplate,
}) => {
  const textRef = useRef();
  const fontColorRef = useRef();
  const [isLinkForm, setIsLinkForm] = useState(false);
  const [isSaveForm, setIsSaveForm] = useState(false);
  const [isLoadForm, setIsLoadForm] = useState(false);
  const [fontColor, setFontColor] = useState('black');
  const [fontSize, setFontSize] = useState(3);
  const stash = useRef();
  const [isText, setIsText] = useState(true);

  const addLink = (link) => {
    document.execCommand('createLink', false, link?.url, 'hyperlink')
  };

  useEffect(() => {
    if (curCommunicate) {
      if (textRef.current?.childNodes?.length > 0) {
        if (!stash.current) {
          stash.current = textRef.current?.innerHTML?.toString();
        }
        for (let node of textRef.current?.childNodes) {
          node?.remove();
        }
      }
      const newRef = communicates?.find(com => com.id === curCommunicate)?.body;
      setComBody(newRef);
      textRef.current.innerHTML = newRef;
    } else if (stash.current) {
      textRef.current.innerHTML = stash.current;
      setComBody(stash.current);
      stash.current = null;
    } else {
      // textRef.current.innerHTML = "";
    }
  }, [curCommunicate, communicates, setComBody]);

  useEffect(() => {
    if (!comBody) {
      if (textRef.current?.childNodes?.length > 0) {
        for (let node of textRef.current?.childNodes) {
          node?.remove();
        }
      }
    }
  }, [comBody])

  return (
    <>
      <Flex direction="column" className="bg-gray-300 py-1 w-full h-16 border border-gray-500 rounded-t mt-3">
        <Flex justify="between" className="w-full px-2">
          <Flex>
            <Flex>
              <Button
                variant="standard"
                className="h-6 mr-1"
                css={{ px: '$1', borderRadius: '$0' }}
                onMouseDown={(e) => {
                  e.preventDefault();
                  document.execCommand('insertText', false, "{firstName}");
                }}
              >
                {"{firstName}"}
              </Button>
              <Button
                variant="standard"
                css={{ px: '$1', borderRadius: '$0' }}
                className="h-6 mr-1"
                onMouseDown={(e) => {
                  e.preventDefault();
                  document.execCommand('insertText', false, "{lastName}");
                }}
              >
                {"{lastName}"}
              </Button>
              <Button
                variant="standard"
                css={{ px: '$1', borderRadius: '$0' }}
                className="h-6 mr-1"
                onMouseDown={(e) => {
                  e.preventDefault();
                  document.execCommand('insertText', false, "{meta1}");
                }}
              >
                {"{meta1}"}
              </Button>
              <Button
                variant="standard"
                css={{ px: '$1', borderRadius: '$0' }}
                className="h-6 mr-1"
                onMouseDown={(e) => {
                  e.preventDefault();
                  document.execCommand('insertText', false, "{meta2}");
                }}
              >
                {"{meta2}"}
              </Button>
              <Button
                variant="standard"
                className="h-6 mr-3"
                css={{ px: '$1', borderRadius: '$0' }}
                onMouseDown={(e) => {
                  e.preventDefault();
                  document.execCommand('insertText', false, "{meta3}");
                }}
              >
                {"{meta3}"}
              </Button> 
            </Flex>
            <Flex className="relative">
              {/* <Button
                variant="standard"
                className="h-6 mr-1"
                css={{ px: '$1', borderRadius: '$0' }}
              >
                Add Paragraph
              </Button> */}
              <Button
                variant="standard"
                className="h-6"
                css={{ px: '$1', borderRadius: '$0' }}
                disabled={!isText}
                onClick={(e) => {
                  e.preventDefault();
                  setIsLinkForm(true)
                }}
              >
                Add Link
              </Button>
              <LinkForm 
                isOpen={isLinkForm} 
                onConfirm={addLink}
                onClose={() => {
                  setIsLinkForm(false);
                  textRef.current.focus();
                }} 
              />
            </Flex>
          </Flex>
          <Button
            variant="standard"
            css={{ 
              w: '140px', h: '25px', px: '$0', py: '$0', borderRadius: '$0', mr: '$0',
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              setIsText(previous => !previous)
            }}
          >
            {isText &&
              <>
                <span className="text-blue-300">TEXT&nbsp;</span> / {`<HTML>`}
              </>
            }
            {!isText &&
              <>
                TEXT / <span className="text-blue-300">&nbsp;{`<HTML>`}</span>
              </>
            }
          </Button>
        </Flex>
        <Flex className="mt-1 px-2 justify-between">
          <Flex justify="between" align="center" css={{mb: '$2'}}>
            <Button
              variant="standard"
              css={{ 
                w: '25px', h: '25px', px: '$0', py: '$0', borderRadius: '$0', mr: '$1',
              }}
              onMouseDown={(e) => {
                e.preventDefault();
                document.execCommand('bold', false);
              }}
            >B</Button>
            <Button
              variant="standard"
              css={{ 
                w: '25px', h: '25px', px: '$0', py: '$0', borderRadius: '$0', mr: '$1', fontStyle: 'italic'
              }}
              onMouseDown={(e) => {
                e.preventDefault();
                document.execCommand('italic', false);
              }}
            >I</Button>
            <Button
              variant="standard"
              css={{ 
                w: '25px', h: '25px', px: '$0', py: '$0', borderRadius: '$0', mr: '$1', 
                textDecoration: 'underline', textDecorationThickness: '2px', color: fontColor
              }}
              onMouseDown={(e) => {
                e.preventDefault();
                document.execCommand('foreColor', false, fontColor);
              }}
            >A</Button>
            <Flex className="relative">
              <Button
                className="w-6 h-6 cursor-pointer border-2 border-white underline"
                css={{
                  bg: fontColor,
                  borderRadius: '$0',
                  px: '$1',
                  py: '$1'
                }}
                onClick={() => fontColorRef.current?.click()}
              >
                <PaintIcon className="w-5 h-5" />
              </Button>
              <Input 
                ref={fontColorRef}
                type="color"
                css={{
                  w: '25px', h: '25px', minHeight: '20px', py: '$0', px: '$0', borderRadius: '$0',
                  right: '$0', top: '$0', bg: 'white', border: 'none', visibility: 'hidden'
                }}
                onChange={(e) => setFontColor(e.target.value)}
              />
            </Flex>
            <Button
              variant="standard"
              css={{ 
                w: '70px', h: '25px', px: '$0', py: '$0', borderRadius: '$0', mr: '$1',
              }}
              onMouseDown={(e) => {
                e.preventDefault();
                document.execCommand('fontSize', false, Math.min(7, fontSize + 1));
                setFontSize(Math.min(7, fontSize + 1));
              }}
            >Font +</Button>
            <Button
              variant="standard"
              css={{ 
                w: '70px', h: '25px', px: '$0', py: '$0', borderRadius: '$0', mr: '$1',
              }}
              onMouseDown={(e) => {
                e.preventDefault();
                document.execCommand('fontSize', false, Math.max(1, fontSize - 1));
                setFontSize(Math.max(1, fontSize - 1));
              }}
            >Font -</Button>
          </Flex>
        
          <Flex>
            <div className="relative">
              <Tooltip title="Save as Template">
                <span
                  className="underline decoration-solid cursor-pointer mr-2"
                  onClick={() => setIsSaveForm(true)}
                >
                  Save
                </span>
              </Tooltip>
              <SaveTemplateForm
                isOpen={isSaveForm}
                onClose={() => setIsSaveForm(false)}
                onConfirm={async (name) => {
                  await saveTemplate(name, comBody);
                }}
              />
            </div>
            <div className="relative">
              <Tooltip title="Load from Template">
                <span
                  className="underline decoration-solid cursor-pointer"
                  onClick={() => setIsLoadForm(true)}
                >
                  Load
                </span>
              </Tooltip>
              <LoadTemplateForm
                isOpen={isLoadForm}
                onClose={() => setIsLoadForm(false)}
                templates={templates}
                onConfirmLoad={(id) => {
                  setComBody(templates?.find(temp => temp.id === id)?.template)
                  textRef.current.innerHTML = templates?.find(temp => temp.id === id)?.template
                }}
                onConfirmRemove={(id) => {
                  removeTemplate(id);
                }}
              />
            </div>
          </Flex>
        </Flex>
      </Flex>
      <div
        id="textarea"
        ref={textRef}
        contentEditable="true" 
        className={
          `communicate-body w-full h-full p-2 overflow-y-scroll border-l border-r border-b border-gray-500 rounded-b mb-1 
          ${isText ? "" : "hidden"}`
        }
        onInput={(e) => {
          setComBody(textRef.current.innerHTML.toString());
        }}
        onSelect={(e) => {
          setFontSize(3);
        }}
      ></div>
      {!isText &&
        <Editor
          value={comBody ?? ''}
          onValueChange={code => {
            setComBody(code)
            textRef.current.innerHTML = code;
          }}
          highlight={code => highlight(code, languages.js)}
          padding={10}
          className="communicate-body w-full h-full p-2 overflow-y-scroll border-l border-r border-b border-gray-500 rounded-b mb-1 text-black"
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 15,
            width: '100%',
            height: '1000px',
            overflowY: 'scroll'
          }}
        />
      }
    </>
  )
};

export default CommunicationBody;
