import { useState, useEffect, useCallback } from "react";
import { useLocalStorage } from "react-use";
import { onSnapshot, updateDoc, getDoc, setDoc } from "@firebase/firestore";

export const UserPreferencesKeys = {
  PREVIEW: "preview",
  NOTIFICATIONS: "notifications",
  UI_SETTINGS: "uiSettings",
  RTMP_URLS: "rtmpUrls",
};

export const defaultPreviewPreference = {
  name: "",
  isAudioMuted: false,
  isVideoMuted: false,
};

export const useUserPreferences = (key, defaultPreference) => {
  const [localStorageValue, setStorageValue] = useLocalStorage(
    key,
    defaultPreference
  );
  const [preference, setPreference] = useState(
    localStorageValue || defaultPreference
  );
  const changePreference = value => {
    setPreference(value);
    setStorageValue(value);
  };
  return [preference, changePreference];
};

export const useUserPreferencesOnDB = (docRef, defaultPreference) => {
  const [value, setValue] = useState()
  
  const checkExists = useCallback(async () => {
    const snapshot = await getDoc(docRef);
    return snapshot.exists();
  }, [docRef]);

  useEffect(() => {
    checkExists().then(exists => {
      if (!exists) {
        setDoc(docRef, {
          id: new Date().getTime()
        });
      }
    })

    onSnapshot(docRef, (doc) => {
      const preValue = value ? JSON.stringify(value) : value;
      const curValue = doc.data() ? JSON.stringify(doc.data()) : doc.data();
      if (preValue !== curValue) {
        setValue(doc.data());
      }
    })
  }, [checkExists, docRef, value])

  const [preference, setPreference] = useState(
    value ? (value?.data) : defaultPreference
  );

  useEffect(() => {
    if (value?.data) {
      setPreference(value?.data);
    }
  }, [value])

  const changePreference = _value => {
    setPreference(_value);
    try {
      updateDoc(docRef, { data: _value });
    } catch (err) {
      // console.log(err)
    }
  };

  return [preference, changePreference];
};
