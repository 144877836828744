import React, { useContext, useMemo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../helpers/contexts/userSessionContext";
import { FirebaseContext } from "../helpers/contexts/firebaseContext";
import { getDatabase, ref as rRef, update } from "@firebase/database";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
import { 
	useHMSActions,
} from "@100mslive/react-sdk";
import Video from "../components/rtc/video";
import EntryQueueMinimized from "./entryQueueMinimized";
import {
  MicOnIcon,
  MicOffIcon
} from "@100mslive/react-icons"

const AttendeesRoomMinimized = ({
  roomType,
  sessions,
  localParticipant,
  participants,
  streamParticipants,
  height,
  userIsLive = false,
  producer = true,
  isBeamUser = false,
}) => {
  const [isLocalHover, setIsLocalHover] = useState(false);
  const [curHovered, setCurHovered] = useState();
  const hmsActions = useHMSActions();
  const { user } = useContext(AuthContext);
  const { conferenceId: confId } = useParams();
  const { firebaseApp } = useContext(FirebaseContext);
  const rdb = useMemo(() => getDatabase(firebaseApp), [firebaseApp]);

  const mySession = sessions?.[user?.uid];
  const { currentState, setEdited } = useLayerEditorContext();

  const toggleLive = (uid) => {

    const ref = rRef(rdb, `${confId}/status/${uid}`);
    if (currentState) {
      update(ref, {
        editingLive: true,
      });
      setEdited(true);
    } else {
      update(ref, {
        live: true,
      });
    }

  }

  const goLive = (uid) => {
    toggleLive(uid);
  };

  const offLive = (uid) => {
    const ref = rRef(rdb, `${confId}/status/${uid}`);
    if (currentState) {
      update(ref, {
        editingLive: false,
      });
      setEdited(true);
    } else {
      update(ref, {
        live: false,
      });
    }
  };

  const mute = (participant, currentMute) => {
    if (participant.identity !== localParticipant.identity) return;
    
    update(rRef(rdb, `${confId}/status/${participant.identity}`), {
      muted: !currentMute,
    });
  };

  useEffect(() => {
    hmsActions.setLocalAudioEnabled(!mySession?.muted);
  }, [mySession?.muted, hmsActions]);

  return (
    <div className="flex h-full flex-start items-center" style={{width: `calc(100% - ${isBeamUser ? 200 : 500}px)`}}>
      {!isBeamUser &&
        <div
          className="rounded bg-gray-500 mb-1 flex items-center px-1 cursor-default border border-1 border-solid border-gray-900 relative justify-between w-48 mr-1"
          onMouseEnter={() => {
            setIsLocalHover(true);
          }}
          onMouseLeave={() => {
            setIsLocalHover(false);
          }}
        >
          <span className="w-24 whitespace-nowrap overflow-hidden">
            {localParticipant?.name?.split('*_*')[1] ?? 'You'}
          </span>
          {roomType !== 'viewing' && 
            <button
              className="rounded p-1 bg-gray-700 text-white ml-2 my-1 text-xs"
              onClick={() => {
                if (userIsLive)
                  offLive(localParticipant?.identity)
                else 
                  goLive(localParticipant?.identity)
              }}
            >
              {userIsLive ? "Off Live" : "Go Live"}
            </button>
          }
          {roomType === 'viewing' && producer &&
            <>
              {sessions[localParticipant.identity]?.muted && 
                <MicOffIcon
                  className="text-white"
                  onClick={() => {
                    mute(localParticipant, true);
                  }}
                />
              }
              {!sessions[localParticipant.identity]?.muted && 
                <MicOnIcon
                  className="text-white"
                  onClick={() => {
                    mute(localParticipant, false);
                  }}
                />
              }
            </>
          }
          {isLocalHover && roomType !== 'viewing' &&
            <Video
              // width={180}
              height={101.25}
              peer={localParticipant}
              className="h-full object-cover absolute left-0 w-full rounded"
              style={{zIndex: 10000, bottom: '36px'}}
            />
          }
        </div>
      }
      {participants.map((participant, idx) => (
        <div
          key={idx}
          className="rounded bg-gray-500 mb-1 flex items-center px-1 cursor-default border border-1 border-solid border-gray-900 relative justify-between w-48 mr-1"
          onMouseEnter={() => {
            setCurHovered(participant.identity);
          }}
          onMouseLeave={() => {
            setCurHovered(null);
          }}
        >
          <span className="w-24 whitespace-nowrap overflow-hidden">
            {participant?.name?.split('*_*')[1]}
          </span>
          {roomType !== 'viewing' && <button
            className="rounded p-1 bg-gray-700 text-white ml-2 my-1 text-xs"
            onClick={() => {
              goLive(participant?.identity)
            }}
          >
            {isBeamUser ? "Ready to Live" : "Go Live"}
          </button>}
          {participant.identity === curHovered && roomType !== 'viewing' &&
            <Video
              height={101.25}
              peer={participant}
              className="h-full object-cover absolute left-0 w-full rounded"
              style={{zIndex: 10000, bottom: '36px'}}
            />
          }
        </div>
      ))}
      {streamParticipants.map((participant, idx) => (
        <div
          key={idx}
          className="rounded bg-gray-500 mb-1 flex items-center px-1 cursor-default border border-1 border-solid border-gray-900 relative justify-between w-48 mr-1"
          onMouseEnter={() => {
            setCurHovered(participant.identity);
          }}
          onMouseLeave={() => {
            setCurHovered(null);
          }}
        >
          <span className="w-24 whitespace-nowrap overflow-hidden">
            {participant?.name?.split('*_*')[1]}
          </span>
          <button
            className="rounded p-1 bg-gray-700 text-white ml-2 my-1 text-xs"
            onClick={() => {
              offLive(participant?.identity)
            }}
          >
            {isBeamUser ? "Live" : "Off Live"}
          </button>
          {participant.identity === curHovered &&
            <Video
              // width={180}
              height={101.25}
              peer={participant}
              className="h-full object-cover absolute left-0 w-full rounded"
              style={{zIndex: 10000, bottom: '36px'}}
            />
          }
        </div>
      ))}
      <EntryQueueMinimized roomType />
    </div>
  );
};

export default AttendeesRoomMinimized;
