import React, { useState, useRef } from "react";
import { DraggableCore } from "react-draggable";
import Draggable from "./draggable";
import { ReactComponent as CloseIcon } from "../icons/x-circle.svg";
import { ReactComponent as ExitFullScreenIcon } from "../icons/exit_fullscreen.svg";

const TranscriptModal = ({
	isOpen,
	closeModal = () => {}, 
	isHeader = true,
	isFooter = true,
	title = '',
	isEnabledOutsideClick = true,
	initialLeft = 40,
	initialTop = 30,
	isDraggable = true,
	handleExitFullScreen = () => {},
	className,
	children
}) => {
	const nodeRef = useRef();
	const ref = useRef();
	const handleClose = (e) => {
		e.stopPropagation();
		closeModal();
	}
	return (
			<>
				{isOpen ? (
					<>
						<div
							className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-8000 outline-none focus:outline-none"
						>
							<div 
								id="modal_outside"
								ref={ref}
								className="fixed w-full h-full my-6 left-0 top-0"
								onClick={isEnabledOutsideClick ? handleClose : null}
							>
								{/*content*/}
								<Draggable
									bounds={"parent"}
									onStart={() => {}}
									onStop={() => {}}
									onDrag={() => {}}
									parentRef={ref}
									position={{x: initialLeft, y: initialTop}}
									disabled={!isDraggable}
									nodeRef={nodeRef}
									isVisible={true}
								>
									<div
										className={`cursor-move border-0 rounded-lg shadow-lg relative flex flex-col min-w-400 w-full 
											outline-none focus:outline-none ${className}`}
										onClick={(e) => e.stopPropagation()}
									>
										{/*header*/}
										{isHeader && <div className="flex items-start cursor-move text-black justify-between rounded-t">
											<h3 className="text-3xl font-semibold">
												{title}
											</h3>
											<button
												className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
												onClick={handleClose}
											>
												<CloseIcon className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none cursor-pointer hover:text-red-400" />
											</button>
										</div>}
										{/*body*/}
										<div className="relative flex-auto min-w-min min-h-min">
											{children}
										</div>
										{/*footer*/}
										{isFooter && <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
											<button
												className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
												type="button"
												onClick={handleClose}
											>
												Close
											</button>
											<button
												className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
												type="button"
												onClick={handleClose}
											>
												Save
											</button>
										</div>}

										{isOpen && <ExitFullScreenIcon 
											className="w-8 h-8 z-5000 fixed right-3 top-3 text-white cursor-pointer"
											onClick={() => {
												handleExitFullScreen();
											}}
										/>}
									</div>
								</Draggable>
							</div>
						</div>
						<div id="close" className="opacity-25 fixed inset-0 z-40 bg-black" onClick={handleClose}></div>
					</>
				) : null}
			</>
		);
}

export default TranscriptModal;
