import { 
  useRef,
  useEffect
} from "react";
import { 
  Flex,
  Label,
} from "@100mslive/react-ui";

import {ArrowRightIcon} from "@100mslive/react-icons";

const SortBy = ({
  playlist,
  setPlaylist,
  sortBy,
  setSortBy,
}) => {
  const keyRef = useRef();

  const sortByKey = (key='name', direction=1) => (a, b) => {
    if (a?.[key] > b?.[key])
      return direction;
    if (a?.[key] < b?.[key])
      return -1 * direction;
    return 0;
  };

  useEffect(() => {
    if (sortBy?.key && (sortBy?.key !== keyRef.current?.key || sortBy?.direction !== keyRef.current?.direction)) {
      let temp = JSON.parse(JSON.stringify(playlist ?? []));
      temp = temp.sort(sortByKey(sortBy?.key, sortBy?.direction ?? 1));
      setPlaylist(temp);
      keyRef.current = JSON.parse(JSON.stringify(sortBy ?? {}))
    }
  }, [sortBy, playlist, setPlaylist]);

  const checkActive = (key, direction) => {
    if (sortBy?.key === key && sortBy?.direction === direction)
      return true;
    return false;
  };

  return (
    <Flex
      direction="column"
      css={{
        px: "$2",
        mt: "$2"
      }}
    >
      {/* <Label>
        Sort By
      </Label> */}
      <Flex
        justify="between"
      >
        <Flex>
          <span className="text-sm text-white">Name: </span>
          <button 
            onClick={() => setSortBy({key: 'name', direction: 1})}
            className={
              `rounded px-0 text-center ${checkActive('name', 1) ? 'bg-yellow-500' : 'bg-blue-400'}
               hover:bg-yellow-500 text-white w-4 h-4 flex justify-center ml-1`
            }
          >
            <ArrowRightIcon className="w-4 h-4 -rotate-90" />
          </button>
          <button 
            onClick={() => setSortBy({key: 'name', direction: -1})}
            className={
              `rounded px-0 text-center ${checkActive('name', -1) ? 'bg-yellow-500' : 'bg-blue-400'}
               hover:bg-yellow-500 text-white w-4 h-4 flex justify-center ml-1`
            }
          >
            <ArrowRightIcon className="w-4 h-4 rotate-90" />
          </button>
        </Flex>
        <Flex css={{ml: "$1"}}>
          <span className="text-sm text-white">Duration: </span>
          <button 
            onClick={() => setSortBy({key: 'duration', direction: 1})}
            className={
              `rounded px-0 text-center ${checkActive('duration', 1) ? 'bg-yellow-500' : 'bg-blue-400'}
               hover:bg-yellow-500 text-white w-4 h-4 flex justify-center ml-1`
            }
          >
            <ArrowRightIcon className="w-4 h-4 -rotate-90" />
          </button>
          <button 
            onClick={() => setSortBy({key: 'duration', direction: -1})}
            className={
              `rounded px-0 text-center ${checkActive('duration', -1) ? 'bg-yellow-500' : 'bg-blue-400'}
               hover:bg-yellow-500 text-white w-4 h-4 flex justify-center ml-1`
            }
          >
            <ArrowRightIcon className="w-4 h-4 rotate-90" />
          </button>
        </Flex>
        <Flex css={{ml: "$1"}}>
          <span className="text-sm text-white">Date:</span>
          <button 
            onClick={() => setSortBy({key: 'created', direction: 1})}
            className={
              `rounded px-0 text-center ${checkActive('created', 1) ? 'bg-yellow-500' : 'bg-blue-400'}
               hover:bg-yellow-500 text-white w-4 h-4 flex justify-center ml-1`
            }
          >
            <ArrowRightIcon className="w-4 h-4 -rotate-90" />
          </button>
          <button 
            onClick={() => setSortBy({key: 'created', direction: -1})}
            className={
              `rounded px-0 text-center ${checkActive('created', -1) ? 'bg-yellow-500' : 'bg-blue-400'}
               hover:bg-yellow-500 text-white w-4 h-4 flex justify-center ml-1`
            }
          >
            <ArrowRightIcon className="w-4 h-4 rotate-90" />
          </button>
        </Flex>
      </Flex>
    </Flex>
  )
};

export default SortBy;
