import {
	selectIsConnectedToRoom,
	useHMSActions,
	useHMSStore
} from "@100mslive/react-sdk";
import React from "react";
import Logo from "../image/site.png";

function Header() {
	const isConnected = useHMSStore(selectIsConnectedToRoom);
	const hmsActions = useHMSActions();

	const handleLeave = (e) => {
		hmsActions.leave();
		window.location.href = "/";
	}

	return (
		<header>
			<img
				className="logo"
				src={Logo}
				alt="logo"
				style={{width: '50px', height: '50px'}}
			/>
			{isConnected && (
				<button
					id="leave-btn"
					className="btn-danger"
					onClick={handleLeave}
				>
					Leave Room
				</button>
			)}
		</header>
	);
}

export default Header;
