import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const RecordingNumLabel = ({num=0, isActive=false}) => {
  return (
    <>
      {num > 0 && 
        <span 
          style={{fontSize: '10px'}}
          className={`absolute right-0 top-6 italic font-bold w-full text-center ${isActive ? "text-green-500" : "text-red-500"}`}
        >
          {num} REC{num > 1 ? 's' : ''}
        </span>
      }
    </>
  )
}
const RecordingCalendar = ({ 
  isRecLoading,
  recordingDate, 
  setRecordingDate, 
  recordings 
}) => {
  const getNumber = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const _date = `${year}${month < 10 ? '0'+month : month}${day < 10 ? '0'+day : day}`;
    const curRecsG = recordings?.filter(recording => recording.recordingDate === _date) ?? [];
    let curRecs = [];
    curRecsG?.forEach(group => {
      curRecs = [...curRecs, ...group?.recordingArr]
    })
    return curRecs?.length ?? 0;
  }

  const checkActive = (date) => {
    const today = new Date();
    return (recordingDate?.getFullYear() === date.getFullYear()
      && recordingDate?.getMonth() === date.getMonth()
      && recordingDate?.getDate() === date.getDate()) ||
      (today?.getFullYear() === date.getFullYear()
      && today?.getMonth() === date.getMonth()
      && today?.getDate() === date.getDate())
  }

  const formatDate = (date, type) => {
    return 'Mon'
  }

  return (
    <div className="z-50 rounded flex justify-center bg-gray-800 relative">
      <Calendar
        style={{background: 'none'}}
        className="p-3 w-300 bg-none bg-transparent border-none"
        onChange={setRecordingDate}
        value={recordingDate} 
        // nextLabel={<span className="text-gray-300">{">"}</span>}
        // next2Label={<span className="text-gray-300">{">>"}</span>}
        // prevLabel={<span className="text-gray-300">{"<"}</span>}
        // prev2Label={<span className="text-gray-300">{"<<"}</span>}
        // navigationLabel={({ date, label, locale, view }) => <span className="text-gray-300">{`Current view: ${view}, date: ${date.toLocaleDateString()}`}</span>}
        tileClassName={({ activeStartDate, date, view }) => view === 'month' && checkActive(date) ? 'relative text-green-500' : 'relative text-white'}
        tileContent={({ activeStartDate, date, view }) => 
          view === 'month' ? 
            <RecordingNumLabel num={getNumber(date)} isActive={checkActive(date)}/>
            : null
        }
      />
      {isRecLoading && !!!recordings?.length && 
        <div className="absolute w-full h-full left-0 top-0 flex justify-center items-center bg-gray-100 opacity-30">
          <div className="w-12 h-12">
            <div className="uploading-spinner"></div>
          </div>
        </div>
      }
    </div>
  );
}

export default RecordingCalendar;
