import React, { createContext, useContext, useMemo, useCallback, useEffect, useState } from "react";
import { FirebaseContext } from "./firebaseContext";
import { useParams } from "react-router-dom";
import {
  selectRemotePeers,
  useHMSStore,
} from "@100mslive/react-sdk";
import { getFirestore, collection, doc, getDoc, setDoc, onSnapshot, query, orderBy, limit } from "firebase/firestore";
import { nanoid } from "nanoid";
import { useConferenceCall } from "../hooks/useConferenceCall";

export const ChatContext = createContext({});

export const ChatProvider = ({ children }) => {
  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);

  const { localParticipant } = useConferenceCall();

  const { role: __role = 'respondent', conferenceId: roomId } = useParams();

  const { name: _username, identity: userid=100000 } 
    = useMemo(() => localParticipant ?? {}, [localParticipant]);

  const username = useMemo(() => _username?.indexOf('*_*') >=0 ? _username?.split('*_*')[1] : _username, [_username]);

  const messageRef = useMemo(() => collection(doc(db, 'chat', roomId ?? "defaultConference"), 'messages'), [db, roomId]);

  const peers = useHMSStore(selectRemotePeers);

  const _role = useMemo(() => __role.replace('producer', 'broadcaster'), [__role]);

  const [chatOptions, setChatOptions] = useState({
    role: "",
    peerId: "",
    selection: "Everyone",
  });

  const [messages, setMessages] = useState([]);

  const convertDateToUTC = (date) => { 
    let _date = date ? date : new Date();
    return new Date(_date.getUTCFullYear(), _date.getUTCMonth(), _date.getUTCDate(), _date.getUTCHours(), _date.getUTCMinutes(), _date.getUTCSeconds(), _date.getUTCMilliseconds()); 
  }

  const saveChat = useCallback(({ role: recipientRole, peerId, message }) => {
    const recipientId = peers?.find(peer => peer.id === peerId)?.name?.split('*_*')[0];
    const recipientName = peers?.find(peer => peer.id === peerId)?.name?.split('*_*')[1];
    const time = new Date().toLocaleString('en-US', {
      timeZone: 'America/New_York',
    })
    const utcTime = convertDateToUTC();
    const newId = nanoid();
    // const senderId = username.match(/\d+_/)?.[0]?.slice(0, -1);
    const senderId = userid;
    const senderName = username.replace(senderId + '_', '');
    setDoc(doc(messageRef, newId), {
      id: newId, senderId, senderName, senderRole: _role, 
      recipientId: recipientId ?? '', recipientName: recipientName ?? '', recipientRole: recipientRole ?? '',
      message, time, timeInt: utcTime.getTime(), messageRead: [senderId], 
    })
  }, [peers, username, _role, userid, messageRef]);

  useEffect(() => {
    if (!messageRef) return;
    
    const unsub = onSnapshot(query(messageRef, orderBy('timeInt', 'desc'), limit(1000)), (snapshots) => {
      let _messages = [];
      snapshots.forEach(snapshot => {
        _messages = [snapshot.data(), ..._messages];
      })
      setMessages(_messages);
    });

    return () => {
      unsub();
    }    
  }, [messageRef]);

  const filteredMessages = useMemo(() => {
    let _messages = JSON.parse(JSON.stringify(messages));
    const messageRole = _role.replace('um_', '').replace('administrator', 'broadcaster');
    
    if (chatOptions.selection === 'Everyone') {
      return _messages.filter(message => (!message.recipientId && !message.recipientRole) || 
        message.recipientId === userid || message.recipientRole === messageRole || message.senderId === userid);
    } else if (chatOptions.role) {
      return _messages.filter(message => message.recipientRole === chatOptions.role);
    } else if (chatOptions.peerId) {
      return _messages.filter(message => (message.recipientId === chatOptions.peerId && message.senderId === userid) || 
        (message.senderId === chatOptions.peerId && message.recipientId === userid));
    }
  }, [chatOptions, userid, messages, _role]);

  return (
    <ChatContext.Provider
      value={{
        username,
        role: _role,
        userid,
        chatOptions,
        setChatOptions,
        saveChat,
        filteredMessages
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}
