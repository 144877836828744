import React, { useEffect, useMemo, useRef, useState, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { install } from "resize-observer";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
import Button from "../components/button";
import Scene from './scene';
import UploadingStatusModal from "./uploadingStatusModal";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { ReactComponent as PencilIcon } from "../icons/pencil.svg";
import { ReactComponent as LockIcon } from "../icons/lock-closed.svg";
import { ReactComponent as UnLockIcon } from "../icons/lock-open.svg";
import { ReactComponent as DesktopMobileIcon } from "../icons/desktop-mobile.svg";

import { useOutsideClick } from "../helpers/hooks/useOutsideClick";
import { FirebaseContext } from "../helpers/contexts/firebaseContext";
import { getFirestore, collection, doc, getDocs, updateDoc, setDoc, onSnapshot } from "firebase/firestore";
import {
  useHMSStore,
  selectPeerSharingVideoPlaylist
} from "@100mslive/react-sdk";
import { VideoPlayer } from "../components/Playlist/VideoPlayer";
import { usePlaylist } from "../components/hooks/usePlaylist";
import { compareObject } from "../utils/compareObject";
import HumanResource from "./humanResource";
import { useAppNotification } from "../helpers/hooks/useAppNotification";
import { InteractionContext } from "../helpers/contexts/interactionContext";
import Interaction from "../components/Interaction/interaction";

// TODO
// - Ratio resize?
install();

const StreamEditor = ({
  room,
  parentWidth: width,
  parentHeight: height,
  activeScene,
  scenes,
  participants = [],
  renderLocalParticipant = false,
  color,
  backgroundImage,
  mySession,
  sessions,
  uploadRecord,
  streamer=false,
  countdown,
  onOpenCountdown,
  isGuest=false,
  isStream=false,
  isHost=true,
  stageHolderRef,
  isBeamUser = false,
  isWaitingRoom = false,
  updateConference = () => {},
  currentViewType = 'desktop',
  activeSpeaker,
}) => {
  const ref = useRef();
  const desktopRatio = 1920 / 1080;
  const mobileRatio = 1080 / 1920;
  const ratio = useMemo(() => currentViewType === 'desktop' ? desktopRatio : mobileRatio,
    [currentViewType, desktopRatio, mobileRatio]);

  const { 
    activeLayer, setActiveLayer, setSemiActiveLayer, semiActiveLayer, currentState, setEdited, 
    sceneLocked, setSceneLock, activeZone, setActiveZone, setCurDevice, componentLayers, readComponentLayers, setComponentLayers,
  } = useLayerEditorContext();

  useEffect(() => {
    if (!isHost) {
      setActiveZone('overlay')
    }
  }, [isHost, setActiveZone])

  const [screenLocked, setLock] = useState(sceneLocked);
  const activeSceneDoc = scenes?.filter(_scene => _scene.id === activeScene)[0];
  const _backgroundImage = backgroundImage || activeSceneDoc?.background?.image;
  const _backgroundColor = color || activeSceneDoc?.background?.color;

  const delta = isBeamUser ? 0 : ((isWaitingRoom && !isBeamUser) ? 150 : 50);
  const desktopViewerWidth = width < (height - delta) * desktopRatio ? width : (height - delta) * desktopRatio;
  const desktopViewerHeight = (height - delta) < width / desktopRatio ? (height - delta) : width / desktopRatio;
  // const viewerWidth = width < (height - delta) * ratio ? width : (height - delta) * ratio;
  // const viewerHeight = (height - delta) < width / ratio ? (height - delta) : width / ratio;
  const viewerHeight = (isBeamUser && currentViewType === 'mobile') ? height: desktopViewerHeight;
  const viewerWidth = width < (desktopViewerHeight ) * ratio ? width : (desktopViewerHeight ) * ratio;
  const beamViewerWidth = width < (viewerHeight ) * ratio ? width : (viewerHeight ) * ratio;

  useAppNotification();

  const {
    isRequestOpen,
    setIsRequestOpen,
    interactionRequest,
    interact,
    deny
  } = useContext(InteractionContext);

  const style = useMemo(() => ({
    background: _backgroundColor ? _backgroundColor : 'white',
    backgroundImage: _backgroundImage ? `url(${_backgroundImage})` : 'none',
    width: isBeamUser ? beamViewerWidth : viewerWidth,
    height: viewerHeight,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }), [viewerWidth, viewerHeight, _backgroundColor, _backgroundImage, isBeamUser, beamViewerWidth]);

  const scene = useMemo(
    () => scenes?.find((scene) => scene.id === activeScene),
    [activeScene, scenes]
  );

  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);
  const { conferenceId } = useParams();
  const collectionRef = collection(doc(collection(db, "conferences"), conferenceId ?? "defaultConference"), "scenes");
  const layersRef = collection(doc(collectionRef, scene?.id ?? 'xx'), 'layers');
  const [layers, layersLoading] = useCollectionQuery(layersRef, {
		idField: "id",
	});

  useEffect(() => {
    // readComponentLayers();
    let temp = componentLayers.map(componentLayer => {
      if (componentLayer.sceneId !== activeScene) {
        return componentLayer;
      } else {
        return {
          sceneId: activeScene,
          components: [...(layers ?? [])]
        }
      }
    });

    if (activeScene && !componentLayers?.some(cLayer => cLayer.sceneId === activeScene)) {
      temp = [...temp, {
        sceneId: activeScene,
        components: [...(layers ?? [])]
      }]
    }
    
    if (!compareObject(componentLayers, temp)) {
      setComponentLayers(temp);
    }
  }, [layers, activeScene, componentLayers, setComponentLayers])

  const zoneLayers = layers?.filter(_ => _?.zone === activeZone);
  const [isUploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const peerSharingPlaylist = useHMSStore(selectPeerSharingVideoPlaylist);

  useOutsideClick(ref, () => {
    setSemiActiveLayer(null);
  });

  const [isShowOverlay, setIsShowOverlay] = useState((activeZone === 'overlay' || isBeamUser));

  useEffect(() => {
    setLock(sceneLocked);
  }, [sceneLocked]);
  
  useEffect(() => {
    localStorage.setItem('loadedImage', '{"count":0}');
  }, [currentViewType])

  if (!scene) {
    return null;
  }

  const handleFocus = (e) => {
    if (currentState) setEdited(true);
    if (e.target.id === 'onstage') {
      setActiveLayer(null);
    }
  };

  const compare = (a, b) => {
    if (a.width * a.height > b.width * b.height) return 1;
    if (a.width * a.height < b.width * b.height) return -1;
    if (a.z > b.z) return 1;
    if (a.z < b.z) return -1;
    return 0;
  };

  const handleMouseMove = (e) => {
    if (screenLocked) return;
    const w = Number(ref.current.style.width.replace('px', ''));
    const h = Number(ref.current.style.height.replace('px', ''));
    const pl = stageHolderRef?.current?.offsetLeft;
    const pt = stageHolderRef?.current?.offsetTop;
    const x = (e.clientX - ref.current.offsetLeft - pl) * 1920 / w;
    const y = (e.clientY - ref.current.offsetTop - pt - 58) * 1080 / h;

    let _layers = zoneLayers?.filter(_layer => 
      _layer.x / 100 * 1920 < x
      && _layer.y / 100 * 1080 < y + 10
      && _layer.x / 100 * 1920 + _layer.width + 10 >= x 
      && _layer.y / 100 * 1080 + _layer.height >= y
      && document.getElementById(_layer.id)
      && activeZone === _layer.zone
    );
    _layers = _layers?.sort(compare);
    if (!_layers || _layers.length === 0) {
      setSemiActiveLayer(null);
    } else {
      setSemiActiveLayer(_layers[0].id);
    }
  }

  const handleToggleDevice = (e) => {
    if (ratio > 1) {
      setCurDevice('mobile');
      updateConference({ currentViewType: 'mobile' });
    } else {
      setCurDevice('desktop');
      updateConference({ currentViewType: 'desktop' });
    }
  }

  const isHResource = () => {
    if (activeZone === 'interaction' || activeZone === 'director' || activeZone === 'communicate')
      return true;

    return false;
  }

  return (
    <>
      {!isStream && 
        <>
          <div 
            className="flex justify-between items-end relative" 
            style={{width: desktopViewerWidth}}
          >
              
            {!isHost && <h2 className="text-black font-extrabold text-2xl leading-none ml-px mb-3">
              Conference
            </h2>}
            
            {(isHost && !isBeamUser) &&
              <Button 
                className="absolute top-8 -left-8 w-8 px-1 custom-bg-3 border-none text-white
                  hover:text-yellow-500 hover:custom-bg-3 focus:ring-transparent"
                onClick={handleToggleDevice}
              >
                <DesktopMobileIcon className="" />
              </Button>
            }
            
            {(isHost && !isBeamUser) && 
              <div className="flex items-center">
                <div className="flex">
                  <Button
                    className={`rounded-t-md ${activeZone === 'background'
                    // ? 'bg-gradient-to-t from-white via-gray-500 to-gray-700' 
                    ? 'custom-bg-3'
                    : 'custom-bg-1'} 
                      text-gray-100  hover:text-blue-600 border-none mr-2
                      focus:ring-transparent lg:w-28 md:w-16`}
                    onClick={() => {
                      setActiveZone('background');
                      setIsShowOverlay(false);
                      setActiveLayer(null);
                    }}
                  >Background</Button>
                  <Button
                    className={`rounded-t-md ${activeZone === 'participant'
                    // ? 'bg-gradient-to-t from-white via-gray-500 to-gray-700' 
                    ? 'custom-bg-3'
                    : 'custom-bg-1'} 
                      text-gray-100 hover:text-blue-600 border-none mr-2
                      focus:ring-transparent w-28`}
                    onClick={() => {
                      setActiveZone('participant');
                      setIsShowOverlay(false);
                      setActiveLayer(null);
                    }}
                  >Participants</Button>
                  <Button
                    className={`rounded-t-md ${activeZone === 'foreground'
                    // ? 'bg-gradient-to-t from-white via-gray-500 to-gray-700' 
                    ? 'custom-bg-3'
                    : 'custom-bg-1'} 
                      text-gray-100 hover:text-blue-600 border-none mr-2
                      focus:ring-transparent w-28`}
                    onClick={() => {
                      setActiveZone('foreground');
                      setIsShowOverlay(false);
                      setActiveLayer(null);
                    }}
                  >Foreground</Button>
                  <Button
                    className={`rounded-t-md ${activeZone === 'overlay'
                    // ? 'bg-gradient-to-t from-white via-gray-500 to-gray-700' 
                    ? 'custom-bg-3'
                    : 'custom-bg-1'} 
                      text-gray-100 hover:text-blue-600 border-none mr-2
                      focus:ring-transparent w-28`}
                    onClick={() => {
                      setActiveZone('overlay');
                      setIsShowOverlay(true);
                      setActiveLayer(null);
                    }}
                  >Overlay</Button>
                </div>
                <Button
                  className="border-none rounded-md ml-2 h-6 text-gray-100 custom-bg-2 hover:text-blue-600"
                  onClick={() => {
                    setIsShowOverlay(!isShowOverlay)
                  }}
                >{isShowOverlay? 'Hide Overlay' : 'Show Overlay'}</Button>
                <div className="ml-10 flex">
                  <Button 
                    className={`${activeZone === 'interaction' 
                      ? 'bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700' 
                      : 'bg-gradient-to-r from-gray-500 via-gray-600 to-gray-700'}
                      hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 
                      font-medium rounded-l text-sm px-5 py-2.5 text-center text-white h-7 mt-1 w-28`}
                    onClick={() => {
                      setActiveZone('interaction');
                      setIsShowOverlay(true);
                      setActiveLayer(null);
                    }}
                  >Interaction</Button>
                  <Button 
                    className={`${activeZone === 'director' 
                      ? 'bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700' 
                      : 'bg-gradient-to-r from-gray-500 via-gray-600 to-gray-700'}
                      hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 
                      font-medium rounded-sm text-sm px-5 py-2.5 text-center text-white h-7 mt-1 w-28`}
                    onClick={() => {
                      setActiveZone('director');
                      setIsShowOverlay(true);
                      setActiveLayer(null);
                    }}
                  >Director</Button>
                  <Button 
                    className={`${activeZone === 'communicate' 
                      ? 'bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700' 
                      : 'bg-gradient-to-r from-gray-500 via-gray-600 to-gray-700'}
                      hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 
                      font-medium rounded-r text-sm px-5 py-2.5 text-center text-white h-7 mt-1 w-28`}
                    onClick={() => {
                      setActiveZone('communicate');
                      setIsShowOverlay(true);
                      setActiveLayer(null);
                    }}
                  >Communicate</Button>
                </div>
              </div>
            }
            
          </div>
          {(isHost && !isBeamUser) &&
            <div className="flex absolute top-0 right-2">
              <div className="flex items-center text-xl font-bold text-gray-100">
                {countdown}
                <PencilIcon
                  onClick={onOpenCountdown}
                  className="ml-1 h-4 w-4 text-gray-400 hover:text-gray-600"
                />
              </div>
              <Button
                className="flex ml-2 text-lg text-gray-100 rounded border border-gray-500 custom-bg-2 my-1"
                onClick={() => {
                  setSceneLock(!sceneLocked);
                  setActiveLayer(null);
                }}
              >
                {sceneLocked && 
                  <LockIcon
                    className="text-gray-400 cursor-pointer w-6 h-6"
                  />
                }
                {!sceneLocked && 
                  <UnLockIcon
                    className="text-gray-400 cursor-pointer w-6 h-6"
                  />
                }
                Screen {sceneLocked ? 'Unlock' : 'Lock'}
              </Button>
            </div>
          }
        </>
      }
      <div 
        className="bg-gray-500 flex justify-center items-center" 
        style={{
          width: isBeamUser ? beamViewerWidth : desktopViewerWidth,
          height: isBeamUser ? viewerHeight : desktopViewerHeight,
        }}
      >
        <div
          id="onstage"
          ref={ref}
          style={!isNaN(width) && !isNaN(height) && style ? style : {}}
          className="rounded-sm shadow relative overflow-hidden w-full h-full"
          onClick={handleFocus}
          onMouseMove={handleMouseMove}
          onMouseLeave={() => setSemiActiveLayer(null)}
        >
          <Interaction
            isOpen={isRequestOpen}
            onClose={() => setIsRequestOpen(false)}
            interaction={interactionRequest}
            interact={interact}
            deny={deny}
            parentWidth={isBeamUser ? beamViewerWidth : viewerWidth}
            parentHeight={viewerHeight}
          />
          {!_backgroundImage && 
            <div 
              onClick={(e) => {
                setActiveLayer(null);
              }}
              className="shape-bg-3"
            ></div>
          }
          <UploadingStatusModal
            isOpen={isUploading}
            caption="Don't leave this page until uploading finished"
            progress={progress}
            onClose={() => setUploading(false)}
          />
          <Scene
            {...scene}
            participants={participants}
            localParticipant={room && room.localParticipant}
            renderLocalParticipant={renderLocalParticipant}
            viewWidth={isBeamUser ? beamViewerWidth : viewerWidth}
            viewHeight={viewerHeight}
            parentRef={ref}
            mySession={mySession}
            sessions={sessions}
            activeScene={activeScene}
            activeLayer={activeLayer}
            setActiveLayer={setActiveLayer}
            semiActiveLayer={semiActiveLayer}
            currentState={currentState}
            screenLocked={screenLocked}
            streamer={streamer}
            isHost={isHost}
            activeZone={activeZone}
            isStream={isStream}
            isShowOverlay={isShowOverlay || !isHost}
            currentViewType={currentViewType}
            componentLayers={componentLayers}
            activeSpeaker={activeSpeaker}
            isSingleView={activeSceneDoc?.isSingleView}
          />
          {/* {isHResource() && */}
            <HumanResource activeZone={activeZone} />
          {/* } */}
          {peerSharingPlaylist &&
            <div
              className="w-full h-full absolute left-0 top-0 z-2000"
            >
              <VideoPlayer
                peerId={peerSharingPlaylist?.id} 
                parentWidth={viewerWidth}
                parentHeight={viewerHeight}
              />
            </div>
          }
          {/* <ErrorBoundary></ErrorBoundary> */}
        </div>
      </div>
    </>
  );
};

export default StreamEditor;

