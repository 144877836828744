import React, { useRef, useState } from "react";
import { useOutsideClick } from "../helpers/hooks/useOutsideClick";
import { ReactComponent as ChevronDownIcon } from "../icons/chevron-down.svg";
import { ReactComponent as SettingIcon } from "../icons/setting.svg";
import { ReactComponent as DesktopIcon } from "../icons/desktop.svg";
import { ReactComponent as PlusIcon } from "../icons/plus.svg";
import { ReactComponent as EditIcon } from "../icons/pencil.svg";

export const MenuItem = ({ children, ...props }) => {
  return (
    <a
      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
      role="menuitem"
      key={props?.keycode}
      id={props?.keycode}
      {...props}
    >
      {children}
    </a>
  );
}

const DropDownMenu = ({ children, icon, defaultOpen = false, text = "", childCls="", className, position = 'absolute', childClickable = true }) => {
  const ref = useRef();
  const [open, setOpen] = useState(defaultOpen);

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  return (
    <div className="relative" ref={ref}>
      <div>
        <button
          className={`${
            className ? className : ""
          } inline-flex justify-center rounded-md border border-gray-300 shadow-sm 
          ${className?.indexOf('px-') >=0 ? "" : "px-4"} py-2 
          ${className?.indexOf('bg-') >=0 ? "" : "bg-white"} font-medium focus:outline-none focus:ring-2 focus:ring-offset-gray-100 focus:ring-yellow-500 whitespace-nowrap items-center h-8`}
          onClick={() => setOpen(!open)}
        >
          {icon === 'setting' ? <SettingIcon style={{marginLeft: '-15px'}} /> : ''}
          {icon === 'screen' ? <DesktopIcon className="h-3 w-3 mr-1 -mb-1" style={{marginLeft: '-15px'}} /> : ''}
          {icon === 'plus' ? <PlusIcon className="h-4 w-4 mr-1" style={{marginLeft: '-15px'}} /> : ''}
          {icon === 'edit' ? <EditIcon className="h-4 w-4 mr-1" style={{marginLeft: '-15px'}} /> : ''}
          {text}
          <ChevronDownIcon className="h-4 w-4 hover:text-gray-600 ml-px" />
        </button>
      </div>

      {open && (
        <div
          className={`origin-top-right ${position} left-0 mt-2 w-56 z-20 ${childCls}`}
          onClick={() => setOpen(!childClickable)}
        >
          <div
            className="py-1 flex flex-col"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

DropDownMenu.MenuItem = MenuItem;

export default DropDownMenu;
