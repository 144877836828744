import PropTypes from "prop-types";
import React from "react";
import cx from "clsx";

const defaultProps = {
	className: "react-tabs__tab-list"
};

const propTypes = process.env.NODE_ENV !==  "production" ? {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), 
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object])
} : {};

const TabList = props => {
	const{
		children,
		className,
		...attributes
	} = {
		...defaultProps,
		...props
	};

	return (
		<ul
			className={className}
			role="tablist"
		>
			{children}
		</ul>
	)
};

TabList.tabsRole = "TabList";

TabList.propTypes = process.env.NODE_ENV !==  "production" ? propTypes : {};

export { TabList };
