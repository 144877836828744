import {
  useMemo,
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { analyzeRecordings } from "./analyzeRecordings";
import { loadContentsFromS3 } from "./loadContentsFromS3";
import { timeFormatV2 } from "../../utils/utils";
import { 
  PlayCircleIcon,
  PauseCircleIcon,
  RefreshIcon,
} from "@100mslive/react-icons";
import Modal from "../../components/modal";
import AbleVideo from "./ableVideo";
import LoadingSpinner from "../../components/loadingSpinner";
import { usePlaylist } from "../../components/hooks/usePlaylist";
import RangeBar from "../../components/RangeBar";
import {
  HMSPlaylistType,
  useHMSStore,
  selectVideoPlaylist,
  selectPeerSharingVideoPlaylist
} from "@100mslive/react-sdk";
import { FirebaseContext } from "../../helpers/contexts/firebaseContext";
import { getFirestore, collection } from "@firebase/firestore";
import { AuthContext } from "../../helpers/contexts/userSessionContext";
import { useCollectionQuery } from "../../helpers/hooks/useFirebaseCollection";
import { analyzeHlsRecording } from "./analyzeHlsRecording";
import { timeFormat } from '../../utils/timeFormat';

const UploadingSpinner = () => {
  return (
    <div className="w-5 h-5">
      <div className="uploading-spinner"></div>
    </div>
  )
}

const PlayerControl = ({
  curISO,
  isVideoLoading
}) => {
  const { active, actions } = usePlaylist(HMSPlaylistType.video);
  const videoProgress = useHMSStore(selectVideoPlaylist.progress);
  const videoList = useHMSStore(selectVideoPlaylist.list);

  const handleTogglePlay = (e) => {
    if (active?.playing) {
      actions?.pause();
    } else {
      let id = active?.id;
      if (!id) {
        id = videoList?.filter(video => video.url === curISO?.url)?.[0]?.id;
      }
      actions?.play(id);
    }
  }

  const handleSeekTo = (e) => {
    const duration = active?.duration || 100;
    actions.seekTo(duration ? duration * e.target.value / 100 : e.target.value);
  };

  return (
    <div className="flex items-center w-full">
      <button
        className="bg-none text-white"
        onClick={handleTogglePlay}
      >
        {!active?.playing && !isVideoLoading && 
          <PlayCircleIcon />
        }
        {isVideoLoading && 
          <div className="w-5 h-5">
            <div className="uploading-spinner"></div>
          </div>
        }
        {active?.playing && !isVideoLoading &&
          <PauseCircleIcon />
        }
      </button>
      <RangeBar
        getNumber={handleSeekTo}
        width="100%"
        backgroundColor="gray"
        fillColor="blue"
        fillSecondaryColor="blue"
        headColor="white"
        headShadow="white"
        headShadowSize={6}
        progress={videoProgress}
        mousePosition={(position, width) => {timeFormat(active?.duration / width * position * 1000)}}
      />
    </div>
  )
};

export const RecordingListOnEditor = () => {
  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);

  const [_conferences, conferencesLoading] = useCollectionQuery(
    collection(db, "conferences"),
    {
      idField: "id",
    }
  );

  const { user, loading: userLoading } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const conferences = _conferences?.filter(conference => conference.userId === user?.uid);
  const isListLoaded = useRef(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const { active, actions } = usePlaylist(HMSPlaylistType.video);
  const peerSharingPlaylist = useHMSStore(selectPeerSharingVideoPlaylist);

  const [curISO, setCurISO] = useState()
  const [recordings, setRecordings] = useState(-1);
  const credential = useMemo(() => ({
    accessKey: process.env.REACT_APP_AWS_KEY,
    bucket: "cloud-conference-1",
    dir: `beam`,
    // dir: `beam/63504a9ef5e7fd9874ffce85/20221025/`,
    region: "us-east-2",
    secretKey: process.env.REACT_APP_AWS_SECRET
  }), []);

  const compareList = (a, b) => {
    if (a.recordingDate < b.recordingDate)
      return 1;
    if (a.recordingDate > b.recordingDate)
      return -1;
    return 0;
  };

  const compareSubList = (a, b) => {
    if (a.recordingTime < b.recordingTime)
      return 1;
    if (a.recordingTime > b.recordingTime)
      return -1;
    return 0;
  };

  const handleLoadS3 = useCallback(async (e) => {
    if (!isListLoaded.current) {
      setLoading(true);
      if (!conferences) return;
      let contents = await loadContentsFromS3(credential, conferences);
      isListLoaded.current = true;
      const analyzed = analyzeRecordings(credential, contents, conferences);
      contents = await loadContentsFromS3(credential, conferences, 'hls');
      const analyzedHls = analyzeHlsRecording(credential, contents, conferences);
      let result = [], i = 0, j = 0;
      while (i < analyzed?.length || j < analyzedHls?.length) {
        if (analyzed?.[i]?.recordingDate < analyzedHls?.[j]?.recordingDate || j >= analyzedHls?.length || !analyzedHls?.length) {
          result.push(analyzed?.[i]);
          i++;
        } else if (analyzed?.[i]?.recordingDate > analyzedHls?.[j]?.recordingDate || i >= analyzed?.length || !analyzed?.length) {
          result.push(analyzedHls?.[j]);
          j++;
        } else if (analyzed?.[i]?.recordingDate === analyzedHls?.[j]?.recordingDate) {
          result.push({
            recordingDate: analyzed?.[i]?.recordingDate,
            recordingArr: [...analyzed?.[i]?.recordingArr, ...analyzedHls?.[j]?.recordingArr].sort(compareSubList)
          })
          i++; j++;
        }
      }
      result = result.sort(compareList);
      setRecordings(result);
      setLoading(false);
    }
  }, [credential, conferences]);

  useEffect(() => {
    handleLoadS3()
  }, [handleLoadS3]);

  useEffect(() => {
    if (curISO?.url) {
      let id = new Date().getTime();
      let list = [{
        type: HMSPlaylistType.video,
        id,
        name: id,
        url: curISO.url,
      }];
      actions.setList(list);
      actions.play(id);
      setIsVideoLoading(curISO?.id);
    }
  }, [curISO, actions]);

  useEffect(() => {
    if (peerSharingPlaylist && active) {
      setIsVideoLoading();
    }
  }, [peerSharingPlaylist, active]);

  const dateFormat = (date) => {
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    return `${year}/${month}/${day}`;
  }

  const filterSpeakerName = (url) => {
    return url?.split('/')?.[6]?.split('-')?.[0];
  }

  return (
    <div className="mb-2">
      <table
        className="w-full text-gray-100 "
      >
        <tbody className="border-t px-2">
          {Array.isArray(recordings) && recordings?.map((recording, idx) => (
            recording.recordingArr.map((item, _idx) => (
              <tr
                key={idx + '-' + _idx}
                className="border-b border-gray-500"
              >
                <table>
                  <tr>
                    <td>DateTime:</td>
                    <td className="align-top pt-1">
                      {dateFormat(recording?.recordingDate)} {timeFormatV2(item.recordingTime)}
                    </td>
                  </tr>
                  {item.recordingType === 'hls' && 
                    <tr>
                      <td>Recording Type:</td>
                      <td className="align-top pt-1">
                        HLS
                      </td>
                    </tr>
                  }
                  <tr>
                    <td>Room:</td>
                    <td className="align-top pt-1">
                      {item.roomName}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="recording-videos align-top pt-1 w-300 max-w-300 overflow-x-scroll overflow-hidden">
                      <div className="flex flex-wrap">
                        <div className="flex flex-col w-full">
                          <div
                            className="relative bg-gray-700 flex rounded p-1 items-center cursor-pointer justify-between w-32 text-sm mr-1 mt-1"
                            onClick={() => {
                              if (item.roomVideo) {
                                setCurISO({
                                  id: `${idx}-${_idx}`,
                                  url: item.roomVideo,
                                  transcript: item.transcript
                                })
                              } else {
                                alert('Missing Room Video');
                              }
                            }}
                          >
                            {isVideoLoading === `${idx}-${_idx}` &&
                              <div className="absolute w-32 h-full left-0 top-0 flex items-center justify-center bg-gray-800 opacity-80">
                                <UploadingSpinner />
                              </div>
                            }
                            Room Video
                            {(curISO?.id !== `${idx}-${_idx}` || !active?.playing) &&
                              <PlayCircleIcon
                                className={`ml-2 w-6 h-6`}
                              /> 
                            }
                            {curISO?.id === `${idx}-${_idx}` && active?.playing &&
                              <RefreshIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  actions.seekTo(0);
                                }}
                                className={`ml-2 w-6 h-6`}
                              />
                            }
                          </div>
                          {curISO?.id === `${idx}-${_idx}` &&
                            <PlayerControl 
                              curISO={curISO}
                              isVideoLoading={isVideoLoading}
                            />
                          }
                        </div>
                        {item.isoArr.map((iso, __idx) => (
                          <div className="flex flex-col">
                            <div
                              key={`${idx}-${_idx}-${__idx}`}
                              className="relative bg-gray-600 flex rounded p-1 items-center cursor-pointer justify-between mt-1 mr-1 w-32 text-sm"
                              onClick={() => {
                                setCurISO({
                                  id: `${idx}-${_idx}-${__idx}`,
                                  url: iso,
                                  transcript: item.transcript
                                })
                              }}
                            >
                              {isVideoLoading === `${idx}-${_idx}-${__idx}` &&
                                <div className="absolute w-32 h-full left-0 top-0 flex items-center justify-center bg-gray-800 opacity-80">
                                  <UploadingSpinner />
                                </div>
                              }
                              <span className="w-32 whitespace-nowrap overflow-hidden">
                                {filterSpeakerName(iso)}
                              </span>
                              {(curISO?.id !== `${idx}-${_idx}-${__idx}` || !active?.playing) &&
                                <PlayCircleIcon
                                  className={`ml-2 w-6 h-6`}
                                />
                              }
                              {curISO?.id === `${idx}-${_idx}-${__idx}` && active?.playing &&
                                <RefreshIcon
                                  className={`ml-2 w-6 h-6`}
                                />
                              }
                            </div>
                            {/* {curISO?.id === `${idx}-${_idx}-${__idx}` &&
                              <PlayerControl
                                isVideoLoading={isVideoLoading}
                                curISO={curISO}
                              />
                            } */}
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                </table>
              </tr>
            ))
          ))}
        </tbody>
      </table>
      {loading &&
        <div className="w-full h-400 flex items-center justify-center">
          <LoadingSpinner />
        </div>
      }
      {Array.isArray(recordings) && recordings?.length === 0 && 
        <div className="">
          No Recordings
        </div>
      }
    </div>
  )
};
