export const defaultDynamicCoordinates = ({ numOfSpeakers }) => {
  if (numOfSpeakers <= 0) return [];
  if (numOfSpeakers > 12) return defaultDynamicCoordinates({ numOfSpeakers: 12 });
  
  const xGap = 2;
  const yGap = 1.5;
  const xPadding = 3.5;
  const yPadding = 2.5;
  const rows = [0, 1, 1, 2, 2, 2, 2, 3 ,3, 3, 3, 3, 3];
  const cols = [0, 1, 2, 2, 2, 3 ,3 ,3 ,3 ,3, 4, 4, 4];
  const emptyRows = [0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 1, 1, 1];
  const emptyCols = [0, 0, 0, 1, 0, 1, 0, 2, 1, 0, 2, 1, 0];
  const cellWidth = (100 - 2 * xPadding - (cols[numOfSpeakers] - 1) * xGap) / cols[numOfSpeakers];
  const cellHeight = (100 - 2 * yPadding - (rows[numOfSpeakers] - 1) * yGap) / rows[numOfSpeakers];
  
  let coordinates = [];

  for (let i = 0; i < numOfSpeakers; i++) {
    let curRow = Math.floor(i / cols[numOfSpeakers]);
    let curCol = Math.floor(i % cols[numOfSpeakers]);
    if (curRow === rows[numOfSpeakers] - 1) {
      coordinates.push({
        x: xPadding + cellWidth * curCol + xGap * curCol + (cellWidth + xGap) * emptyCols[numOfSpeakers] / 2,
        y: yPadding + cellWidth * curRow + yGap * curRow + (cellWidth + yGap) * emptyRows[numOfSpeakers] / 2,
        width: cellWidth / 100 * 1920,
        height: Math.min(cellHeight, cellWidth) / 100 * 1080,
      })
    } else {
      coordinates.push({
        x: xPadding + cellWidth * curCol + xGap * curCol,
        y: yPadding + cellWidth * curRow + yGap * curRow + (cellWidth + yGap) * emptyRows[numOfSpeakers] / 2,
        width: cellWidth / 100 * 1920,
        height: Math.min(cellHeight, cellWidth) / 100 * 1080,
      })
    }
  }

  return coordinates;
};
