import React, { useState, useContext, useCallback } from 'react';
import { useEffect } from "react";
import { FirebaseContext } from '../contexts/firebaseContext';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, doc, getDoc, setDoc, onSnapshot } from "firebase/firestore";
import { useLoading } from "./useLoading";

export const useFirebaseAuthState = () => {
  const { firebaseApp } = useContext(FirebaseContext);
  const db = getFirestore(firebaseApp);
  const auth = getAuth();

  const { error, loading, value, setValue, setLoading, setError} = useLoading();
  
  const authStateListner = useCallback((user) => {
    if (user && !user?.isAnonymous) {
      onSnapshot(doc(db, "profiles", user?.uid ?? "defaultLayer"), (doc) => {
        const userData = doc.data();
        if (userData) {
          const preValue = value ? JSON.stringify(value) : value;
          const curValue = JSON.stringify({
            uid: user?.uid,
            isAnonymous: false,
            ...JSON.parse(JSON.stringify(userData))
          });

          if (preValue !== curValue) {
            setValue({
              uid: user?.uid,
              isAnonymous: false,
              ...JSON.parse(JSON.stringify(userData))
            });
            setLoading(false);
          }
        } else {
          if (loading !== false) {
            const preValue = value ? JSON.stringify(value) : value;
            const curValue = JSON.stringify(user);
            if (preValue !== curValue) {
              setLoading(false);
              setValue(user)
            }
          }
        }
      });
    } else if (user && user?.isAnonymous) {
      if (loading !== false) {
        const preValue = value ? JSON.stringify(value) : value;
        const curValue = JSON.stringify(user);
        if (preValue !== curValue) {
          setLoading(false);
          setValue(user)
        }
      }
    } else {
      if ((value !== null && value !== undefined) || loading) {
        setValue(null)
      }
      if (loading)
      setLoading(false);
    }
    
  }, [db, setValue, value, loading, setLoading])

  // useEffect(() => {
    onAuthStateChanged(auth, authStateListner)
  // }, [authStateListner, auth])

  return [value, loading, error, auth];
};
