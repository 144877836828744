
import AWS from 'aws-sdk';

const listFromS3 = (s3Client, bucket, directories=[]) => 
  Promise.all(directories.map(directory => new Promise((resolve, reject) => {
    const params = {
      Bucket: bucket,
      Delimiter: '/',
      Prefix: directory
    }
    s3Client.listObjectsV2(params, (err, data) => {
      if (err) {
        reject(err)
      }
      resolve({
        prefix: directory,
        directories: data?.CommonPrefixes,
        contents: data?.Contents
      })
    })
  })))

export const loadContentsFromS3 = async (credential, conferences, root = 'beam') => {
  if (!credential || !credential?.region) return [];

  AWS.config.update({ region: credential.region });

  const s3Client = new AWS.S3({
    accessKeyId: credential.accessKey,
    secretAccessKey: credential.secretKey,
    region: credential.region
  });

  let list = [];

  list = await listFromS3(s3Client, credential?.bucket, [`${root}/`]);
  let directories = list?.[0]?.directories?.filter(item => 
    conferences?.some(conference => item.Prefix.indexOf(conference?.id) >= 0)
  )?.map(directory => directory?.Prefix);

  if (!directories || directories?.length === 0) return [];

  list = await listFromS3(s3Client, credential?.bucket, directories);
  directories = [];
  if (!list || list?.length === 0) return [];
  list.forEach(item => {
    directories = [...directories, ...(item.directories?.map(directory => directory.Prefix) || [])];
  })

  list = await listFromS3(s3Client, credential?.bucket, directories);

  if (root === 'beam') {
    return list?.map(item => ({
      directory: item.prefix,
      contents: item.contents,
    }))
  }

  directories = [];
  if (!list || list?.length === 0) return [];
  list.forEach(item => {
    directories = [...directories, ...(item.directories?.map(directory => directory.Prefix) || [])];
  })

  list = await listFromS3(s3Client, credential?.bucket, directories);

  if (!list || list?.length === 0) return [];
  directories = [];
  list.forEach(item => {
    directories = [...directories, ...(item.directories?.filter(directory => directory.Prefix?.indexOf('file-recording') >=0).map(directory => directory.Prefix) || [])];
  })
  list = await listFromS3(s3Client, credential?.bucket, directories);
  
  return list?.map(item => ({
    directory: item.prefix,
    contents: item.contents,
  }))
};
