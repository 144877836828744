export const analyzeHlsRecording = (credential, contents = [], conferences = []) => {
  let result = [];
  let urlPrefix = `https://${credential.bucket}.s3.${credential.region}.amazonaws.com/`;

  contents.forEach(content => {
    const roomId = content?.directory?.split('/')?.[1];
    const roomName = conferences.find(conference => conference.id === roomId)?.name;
    const recordingDate = content?.directory?.split('/')?.[2];
    const recordingTime = content?.directory?.split('/')?.[3];
    let temp = JSON.parse(JSON.stringify(result));
    result = temp.map(item => {
      if (item.recordingDate === recordingDate) {
        return {
          ...item,
          recordingArr: [
            ...item.recordingArr,
            {
              roomId,
              roomName,
              roomVideo: `${urlPrefix}${content.contents?.[0]?.Key}`,
              recordingTime,
              recordingType: 'hls',
              isoArr: [],
              transcript: ''
            }
          ]
        }
      } else {
        return item;
      }
    })

    if (!result.some(item => item.recordingDate === recordingDate)) {
      result.push({
        recordingDate,
        recordingArr: [{
          roomId,
          roomName,
          roomVideo: `${urlPrefix}${content.contents?.[0]?.Key}`,
          recordingTime,
          recordingType: 'hls',
          isoArr: [],
          transcript: ''
        }]
      })
    }
  })
  return result;
};
