import React, { 
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import { 
	useVideo,
  useHMSStore,
	selectScreenShareByPeerID
} from "@100mslive/react-sdk";
// TODO:
// - Improve loader css stability (sometimes creates too large of an element)

const VideoByTrack = ({
  isRemote,
  width,
  height,
  className,
  deviceId,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  navigator.getUserMedia = navigator.getUserMedia ||
                         navigator.webkitGetUserMedia ||
                         navigator.mozGetUserMedia;
  const constraints = useMemo(() => ({
    audio: false,
    video: { deviceId }
  }), [deviceId]);


  const videoRef = useRef();

  useEffect(() => {
    setLoading(true);
    navigator?.getUserMedia(constraints, (stream) => {
      if (videoRef?.current) {
        const ele = videoRef.current;
        ele.srcObject = stream;
      }
      setLoading(false)
    }, (err) => {
      setLoading(false)
    });
  }, [constraints, videoRef])



  const style = useMemo(() =>({
    width: `${width}px`,
    height: `${height - 5}px`,
    objectFit: "cover",
    background: "black",
    zIndex: props?.style?.zIndex,
    bottom: props?.style?.bottom ?? '0'
  }), [width, height, props]);

  return (
    <>
      <div className={`${loading ? "block" : "hidden"} loader`} />
      <video
        id={`v_${deviceId}`}
        ref={videoRef}
        style={style}
        autoPlay
        muted
        playsInline
        className={`${className ? className : ""} ${
          !loading ? "block w-full h-full" : "hidden"
        }`}
      />
    </>
  );
};

export default VideoByTrack;
