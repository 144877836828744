import React, { useContext } from "react";
import { nanoid } from "nanoid";
import { getFirestore, collection, doc, getDoc, setDoc, onSnapshot } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const uploadRecord = async (firebaseApp, conferenceId, recordUrl, fileName, setProgress) => {
  const metadata = {
    contentType: "video/mp4"
  };

  const db = getFirestore(firebaseApp);
  const storage = getStorage(firebaseApp);

  const fileRef = ref(storage, `${new Date().getTime()}-${fileName}`);

  const _promise = new Promise((resolve, reject) => {

    const uploadTask = uploadBytesResumable(fileRef, recordUrl, metadata);

    uploadTask.on('state_changed', 
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(percentage);

        // console.log('Upload is ' + percentage + '% done');
        switch (snapshot.state) {
          case 'paused':
            // console.log('Upload is paused');
            break;
          case 'running':
            // console.log('Upload is running');
            break;
          default:
            break;
        }
      }, 
      (error) => {
        reject(error);
      }, 
      async function () {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        setProgress(0);
        resolve(url);
      }
    );
  });

  let res;
  await _promise.then(data => res = data);

  const url = res;
  
  const recordingRef = collection(doc(collection(db, "conferences"), conferenceId ?? "defaultConference"), 'recordings');
  const id = nanoid();
  setDoc(doc(recordingRef, id), {
    id,
    name: fileName,
    url,
    createdAt: new Date().getTime(),
    description: ''
  });
};
