import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject, listAll } from "firebase/storage";

export const uploadFile = (storage, file, name) =>
  new Promise((resolve, reject) => {
    const storageRef = ref(storage, `${name}`);
    listAll(ref(storage)).then(res => {
      const isExist = Array.from(res.items).some(item => item.name === name);
      if (isExist) {
        getDownloadURL(Array.from(res.items).find(item => item.name === name)).then(url => {
          resolve(url)
        }).catch(reject)
      } else {
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed', 
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                // console.log('Upload is paused');
                break;
              case 'running':
                // console.log('Upload is running');
                break;
              default:
                break;
            }
          }, 
          (error) => {
            reject(error);
          }, 
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      }
    })
  });

export const deleteFile = (firebase) => ({ name }) => {
  const storage = getStorage(firebase);
  const storageRef = ref(storage, `${name.split('/')[7].split('?')[0]}`);
  return new Promise((resolve, reject) => {
    deleteObject(storageRef).then(resolve, reject);
  })
};

export const firebaseUpload = (firebase) => ({ name, file }) => {
  const storage = getStorage(firebase);
  // const storageRef = ref(storage, `${new Date().getTime()}-${name}`);
  return uploadFile(storage, file, name);
};
