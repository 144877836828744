import React from 'react';
import { useEffect, useCallback } from "react";
import { useLoading } from "./useLoading";
import { onSnapshot } from "firebase/firestore";
import { isEqual } from "../../utils/utils"

export const useDocumentQuery = (query, options) => {
  const idField = options?.idField;

  const { error, loading, setLoading, setError, setValue, value } = useLoading();

  const listener = useCallback((snap) => {
    const curVal = snap?.exists() ? {
      ...snap.data(),
      ...(idField ? { [idField]: snap.id } : null),
    } : undefined;

    if (!isEqual(value, curVal)) {
      setValue(curVal);
    }
    setLoading(false)
  }, [value, setValue, idField, setLoading]);

  useEffect(() => {
    onSnapshot(query, listener, setError);
    setLoading(false);
  }, [query, listener, setLoading, setError]);

  return [value, loading, error];
};
