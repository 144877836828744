import React from 'react';
import { createContext, useState, useEffect, useRef, useCallback, useContext } from "react";
import S3 from 'react-aws-s3';
import AWS from 'aws-sdk'
import { TranscriptionContext } from './transcriptionContext';
import { timeFormat } from '../../utils/timeFormat';
import { useParams } from "react-router-dom";

const convertTZ = (date, tzString) => {
	return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
}

export const ISOContext = createContext({});

export const ISOProvider = ({ children, users = [] }) => {
	const { transcript, startedTime, setIsLocalTranscriptEnabled } = useContext(TranscriptionContext);
	const [isISORecording, setISORecording] = useState(false);
	const [isUploading, setUploading] = useState(false);
	const today = convertTZ(new Date(), "Asia/Jakarta");
	const year = today.getFullYear();
	const month = today.getMonth() + 1;
	const date = today.getDate();
	const { conferenceId: roomId } = useParams();
	const [s3credential, setCredential] = useState({
		accessKey: process.env.REACT_APP_AWS_KEY,
		bucket: "cloud-conference-1",
		dir: `beam/${roomId}/${year}${month < 10 ? '0'+month : month}${date < 10 ? '0'+date : date}`,
		region: "us-east-2",
		secretKey: process.env.REACT_APP_AWS_SECRET
	});

	const streams = useRef([]);
	const ReactS3Client = useRef();

	const initializeISO = useCallback(() => {
		window.Buffer = window.Buffer || require('buffer').Buffer;
		const config = {
			bucketName: s3credential.bucket,
			dirName: s3credential.dir, /* optional */
			region: s3credential.region,
			accessKeyId: s3credential.accessKey,
			secretAccessKey: s3credential.secretKey,
			// s3Url: 'https:/your-custom-s3-url.com/', /* optional */
		}

		// ReactS3Client.current = new S3(config);
		AWS.config.update({ region: config.region });

		ReactS3Client.current = new AWS.S3({
			accessKeyId: config.accessKeyId,
			secretAccessKey: config.secretAccessKey,
			region: config.region
		});

		let tempStream = [];
		users.forEach(user => {
			if (user.name.split('*_*')[1] !== 'beam') {
				const videoEle = document.querySelector(`video#v_${user.id}`);
				const _stream = videoEle?.captureStream(25);
				tempStream.push({
					id: user.id,
					name: user.name.split('*_*')[1],
					stream: _stream,
					recorder: null,
					chunks: [],
				});
			}
		})
		streams.current = tempStream;
	}, [s3credential, users]);

	const transcriptRef = useRef();
	const startedTimeRef = useRef();

	useEffect(() => {
		transcriptRef.current = JSON.parse(JSON.stringify(transcript ?? []));
	}, [transcript])

	useEffect(() => {
		startedTimeRef.current = startedTime;
	}, [startedTime]);

	const startISORecording = () => {
		initializeISO();
		streams.current.forEach(stream => {
			if (stream.stream) {
				const peers = window.__hms.sdk.getPeers();
				const audioTrack = peers.filter(p => p.peerId === stream.id)[0].audioTrack.nativeTrack;
				const videoTrack = stream.stream.getVideoTracks();
				const _recorder = new MediaRecorder(new MediaStream(videoTrack.length ? [audioTrack, videoTrack[0]] : [audioTrack]));
				stream.recorder = _recorder;
				_recorder.id = stream.id;
				try {
					_recorder.start(1000);
					
					_recorder.ondataavailable = function(e) {
						if (e.data.size > 0) {
							streams.current?.filter(pt => pt.id === e.target.id)[0].chunks.push(e.data);
						}
					}
				} catch (err) {
					// console.log(err);
				}
			}
		});
	}

	const stopISORecording = useCallback(async (_startedTime, _startedDate) => {
		if (!ReactS3Client.current) return;
		let recordedTime = _startedTime ? _startedTime : startedTime;
		let locationDir = s3credential.dir?.split('/');
		if (_startedDate) {
			locationDir[2] = _startedDate;
		}

		setUploading(true);
		const uploadPromises = Array.from(streams.current).map(stream => {
			try {
				stream.recorder.ondataavailable = null;
				stream.recorder.stop();
			} catch (err) {
				// console.log(err);
			}

			if (!stream.chunks || stream.chunks.length === 0) return Promise.resolve();

			var blob = new Blob(stream.chunks, {
				type: "video/mp4"
			});
			
			const fileName = `${stream.name}-${recordedTime}.mp4`;

			const config = {
				bucketName: s3credential.bucket,
				dirName: locationDir?.join('/'), /* optional */
				region: s3credential.region,
				accessKeyId: s3credential.accessKey,
				secretAccessKey: s3credential.secretKey,
			}
	
			const blobUploadS3 = new S3(config);

			return new Promise((resolve, reject) => {
				blobUploadS3.uploadFile(blob, fileName)
					.then(data => {
						resolve(data);
					})
					.catch(err => {
						// console.error(err);
						reject(err);
					})
			});
		});

		if (transcriptRef.current?.length > 0) {
			const s3Bucket = s3credential.bucket; 
			const objectName = `${locationDir?.join('/')}/transcript-${recordedTime}.json`; 
			// const deltaT = Math.floor(((new Date().getTime()) - (new Date(transcript[transcript.length - 1].created))) / 3600000) * 3600000;
			const deltaT = Math.round(((new Date().getTime()) - (new Date(transcriptRef.current[transcriptRef.current.length - 1].created).getTime())) / 3600000) * 3600000;
			
			const _transcript = transcriptRef.current.map(t => ({
				...t,
				created: Math.floor(new Date(t.created).getTime()) - Math.floor(startedTimeRef.current) + deltaT
			}));
			
			const objectData = JSON.stringify(_transcript);
			const objectType = 'application/json'; 
			
			const params = {
				Bucket: s3Bucket,
				Key: objectName,
				Body: objectData,
				ContentType: objectType,
			};
			const result = await ReactS3Client.current.putObject(params).promise();

			let transcriptTxt = '';
			for (let i = 0; i < _transcript.length; i ++) {
				let startTime = timeFormat(_transcript[i].created);
				let endTime = timeFormat(i < _transcript.length - 1 ? _transcript[i + 1].created : Math.floor(_transcript[i].created) + 5000);
				transcriptTxt = `${transcriptTxt}\r\n`;
				transcriptTxt = `${transcriptTxt}${i + 1}\r\n`;
				transcriptTxt = `${transcriptTxt}${startTime} --> ${endTime}\r\n`;
				transcriptTxt = `${transcriptTxt}${_transcript[i].speaker}\r\n`;
				transcriptTxt = `${transcriptTxt}${_transcript[i].message}\r\n\r\n`;
			}

			const blobSrt = new Blob([transcriptTxt], {type:"text/plain"}); 
			const blobVtt = new Blob([`WEBVTT\r\n\r\n${transcriptTxt}`], {type:"text/plain"}); 
			const fileNameSrt = `transcript-${recordedTime}.srt`;
			const fileNameVtt = `transcript-${recordedTime}.vtt`;

			const config = {
				bucketName: s3credential.bucket,
				dirName: s3credential.dir, /* optional */
				region: s3credential.region,
				accessKeyId: s3credential.accessKey,
				secretAccessKey: s3credential.secretKey,
				// s3Url: 'https:/your-custom-s3-url.com/', /* optional */
			}
	
			const blobUploadS3 = new S3(config);
			const uploadPromise = (_blob, _fileName) => new Promise((resolve, reject) => {
				blobUploadS3.uploadFile(_blob, _fileName)
					.then(data => {
						resolve(data);
					})
					.catch(err => {
						// console.error(err);
						reject(err);
					})
			});
			await uploadPromise(blobSrt, fileNameSrt).then(() => {
			}).catch(
				err => console.log(err)
			);
			await uploadPromise(blobVtt, fileNameVtt).then(() => {
				// console.log('vtt uploaded');
			}).catch(err => console.log(err));
			// clearTranscripts();
			setIsLocalTranscriptEnabled(false);
		}

		Promise.all(uploadPromises)
			.then(() => {
				setUploading(false);
				streams.current = [];
			})
			.catch(err => {
				setUploading(false);
			})
	}, [s3credential, startedTime, setIsLocalTranscriptEnabled]);
	
  return (
    <ISOContext.Provider
      value={{
        isISORecording,
				setISORecording,
				startISORecording,
				stopISORecording,
				s3credential,
				setCredential,
				initializeISO,
				isUploading,
				setUploading
      }}
    >
      {children}
    </ISOContext.Provider>
  );
}