import React, { useState, useEffect } from "react";
import Button from "../components/button";
import Modal from "../components/modal";

const UploadMediaModal = ({ isOpen, onClose, onSubmit }) => {
  const [ uploading, setUploading ] = useState(false);
  const [file, setFile] = useState();
  const [family, setFamily] = useState('');
	const [format, setFormat] = useState('');
	const [error, setError] = useState('');
  
  useEffect(() => {
    return () => {
        setUploading(false);
    }
  }, []);

  const handleOnClose = (e) => {
    setUploading(false);
    onClose();
  };

  const handleUpload = async (e) => {
		if (file === null || file === undefined) {
			setError('font file required');
			return;
		}
		if (family === '') {
			setError('font family required');
			return;
		}
		
		setError('');
		setUploading(true);
    await onSubmit({font: file, family, format});
    setFile();
    setFamily('');
		setFormat('');
    setUploading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={handleOnClose}
      className="w-1/4"
      isHeader={false}
      isFooter={false}
    >
      <div className="custom-bg-1 w-full p-2 rounded-t-md flex items-center">
        <h2 className="font-medium text-xl text-gray-100">Upload New Font</h2>
      </div>
      <div className="p-4 custom-bg-2 text-gray-100 rounded-b">
        {error !== '' && (
					<div className="text-red-500">
						{error}
					</div>
				)}
        <input id="font" name="font" type="file" onChange={(e) => setFile(e.target.files)}/>
				<div className="w-full flex items-center mt-2">
	        <label>Font Family:</label>
					<input
						className="rounded h-8 ml-2"
						type="text"
						value={family}
						onChange={(e) => setFamily(e.target.value)}
						required
					/>
				</div>
				<div className="w-full flex items-center mt-2">
					<label>Font Format:</label>
					<input
						className="rounded h-8 ml-2"
						type="text"
						value={format}
						onChange={(e) => setFormat(e.target.value)}
					/>
				</div>
        <div className="flex flex-row-reverse mt-4">
          <Button
            className="rounded text-gray-900"
            onClick={handleUpload}
            type="submit"
            disable={!uploading}
          >
            {!uploading && 'Submit'}
            {uploading && 'Uploading'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadMediaModal;
