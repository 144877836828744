import { useState } from "react";
import {
  Tooltip,
  Flex,
} from "@100mslive/react-ui";
import { ReactComponent as ChevronDownIcon } from "../../../icons/chevron-down.svg";

const DropDown = ({
  dropdownType,
  currentValue,
  setCurrentValue,
  values = [],
  placeHolder,
  disabled=false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="relative ml-1">
      <Tooltip side="bottom" title="required">
        <Flex
          justify="between"
          align="center"
          css={{
            borderRadius: "$3",
            bg: 'black',
            color: '$white',
            minWidth: '200px',
            maxWidth: '300px',
            overflow: 'hidden',
            px: '$5',
            h: '$11',
            cursor: 'pointer'
          }}
          onClick={() => setIsOpen(true && !disabled)}
        >
          {currentValue &&
            <span>{currentValue?.name}</span>
          }
          {!currentValue && placeHolder && 
            <span>{placeHolder}</span>
          }
          <ChevronDownIcon className="w-4 h-4" />
        </Flex>
      </Tooltip>
      {isOpen && !disabled && <div
        className="fixed left-0 top-0 w-full h-full z-10"
        onClick={() => setIsOpen(false)}
      ></div>}
      {isOpen && !disabled && 
        <div className="absolute top-6 border rounded z-50 w-full bg-gray-600 text-white py-1">
          {values?.map((value, idx) => (
            <div
              key={idx}
              className={`flex justify-between cursor-pointer z-50 mx-1 px-2 hover:bg-gray-200 
                ${idx === values?.length - 1 ? '' : 'border-b border-gray-300'}`}
              onClick={() => {
                setCurrentValue(value)
                setIsOpen(false)
              }}
            >
              <span>{value?.name}</span>
              {dropdownType === 'targeting' &&
                <span className="text-sm italic text-gray-300">
                  {value?.targetType}
                </span>
              }
            </div>
          ))}
        </div>
      }
    </div>
  )
};

export default DropDown;
