import React, { useState, useRef } from "react";
import './style.css'
// import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import RangeSlider from "./RangeSlider/rangeSlider";
import { deTimeFormat, timeFormat } from '../../utils/timeFormat';
import { useSelector, useDispatch } from 'react-redux';
import { 
  setDownloadKey, 
  setIsDownloading,
  setIsIntentDownload,
  setDownloadTimeRange
} from '../../helpers/redux/reducers/videoSplitReducer';

const RangeBar = (props) => {
  const [value, setValue] = useState(props.progress);
  const inputRef = useRef();
  const [toolTip, setToolTip] = useState({
    position: 0,
    text: ''
  });
  const isIntentDownload = useSelector((state) => state.videoSplit.isIntentDownload);
  const downloadTimeRange = useSelector(state => state.videoSplit.downloadTimeRange);
  const dispatch = useDispatch();
  const playerState = props.playerState;
  const videoDuration = props.videoDuration;
  const preRange = React.useRef([0, 100]);

  const handleSelectDownloadRange = (values) => {
    let playPoint;
    if (preRange.current[0] !== values[0]) {
      playPoint = values[0];
    } else {
      playPoint = values[1];
    }
    preRange.current = values;
    
    let startTime = timeFormat(values[0] / 100 * videoDuration);
    startTime = startTime.split(' ').join('');
    let duration = (values[1] - values[0]) / 100 * videoDuration;
    dispatch(setDownloadTimeRange({startTime, duration}));

    handleNo({
      target:{
        value: playPoint
      }
    })
  }

  let handleNo = function handleNo(event) {
    // props.getNumber(event.target.value);
    props.getNumber(event);
  };

  const handleMouseMove = (e) => {
    const rect = inputRef.current.getBoundingClientRect();
    // console.log(e.pageX - rect.x, rect.width);
    if (props?.mousePosition) {
      setToolTip({
        position: e.pageX - rect.x,
        text: props.mousePosition(e.pageX - rect.x, rect.width)
      });
    }
  }

  const handleMouseLeave = (e) => {
    setToolTip({
      position: 0,
      text: ''
    });
  }

  return (
    <div className="relative w-full">
      {isIntentDownload && 
        <RangeSlider 
          onInput={handleSelectDownloadRange}
          maxValue={100}
          defaultValue={[0, 100]}
          value={[
            deTimeFormat(downloadTimeRange?.startTime) / (isNaN(videoDuration) ? 100 : videoDuration) * 100, 
            (deTimeFormat(downloadTimeRange?.startTime) + downloadTimeRange?.duration) / (isNaN(videoDuration) ? 100 : videoDuration) * 100 
          ]}
          onChange={values => {
            handleSelectDownloadRange(values)
          }}
          step={1}
          videoDuration={props.videoDuration}
          label="range: "
        />
      }
      {!isIntentDownload && <input 
        ref={inputRef}
        style = {{
          '--head-color': props.headColor,
          '--changeColorPosition': props.progress + "%",
          '--background': props.backgroundColor,
          '--fillColor': props.fillColor,
          '--fillSecondry': props.fillSecondaryColor === undefined ? props.fillColor : props.fillSecondaryColor,
          '--width': props.width,
          '--headShadowColor': props.headShadow,
          '--headShadowSize': props.headShadowSize === 0 ? '' : props.headShadowSize + "px",
          'WebkitAppearance': props.direct === 'vertical' ? 'slider-vertical' : 'revert',
          'height': props.direct === 'vertical' ? '150px' : '1px',
          'paddingBottom': '0px',
          'padding': '0px',
          'cursor': 'ew-resize'
        }}
        className={props.direct === 'vertical' ? 'vertical' : 'horizontal'}
        type={"range"}
        multiple
        value={isNaN(props.progress) ? 0 : props.progress}
        defaultValue={0}
        min={0}
        max={100}
        onChange={handleNo}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      />}
      {toolTip?.text !== '' && (
        <>
          <div
            className="bg-none text-white absolute -top-5 text-xs w-24"
            style={{
              left: `${toolTip?.position - 48}px`
            }}
          >
            {toolTip?.text}
          </div>
          <div
            className="border border-1 border-black h-full absolute bottom-0"
            style={{
              left: `${toolTip?.position}px`
            }}
          ></div>
        </>
      )}
    </div>
  )
};

export default RangeBar;
