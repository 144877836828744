import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../helpers/contexts/userSessionContext";
import { LocalTracksContext } from "../helpers/contexts/localTracksContext";
import { FirebaseContext } from '../helpers/contexts/firebaseContext';
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { useAudioChannelContext } from "../helpers/hooks/useAudioChannelContext";
import TextLayer from './textLayer';
import ImageLayer from './imageLayer';
import VideoLayer from './videoLayer';
import StreamLayer from './streamLayer';
import LocalStreamLayer from './localStreamLayer';
import TimerLayer from './timerLayer';
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
import { getFirestore, collection, doc, getDoc, setDoc, updateDoc, deleteDoc, onSnapshot } from "firebase/firestore";
import { getDatabase, ref as rRef, set, update, onValue } from "firebase/database";

const Loader = () => <div className="loader">&nbsp;</div>

const Scene = ({
	id,
	participants,
	localParticipant,
	renderLocalParticipant,
	viewWidth,
	viewHeight,
	mySession,
	sessions,
	activeScene,
	activeLayer,
	setActiveLayer,
	semiActiveLayer,
	currentState,
	screenLocked,
	streamer,
	isHost=true,
	isStream=false,
	activeZone,
	isShowOverlay=true,
	parentRef,
	currentViewType = 'desktop',
	componentLayers=[],
	...props
}) => {
	const [loadedImageCount, setLoadedImageCount] = useState(0);

	const { user, hostStatus } = useContext(AuthContext);
	const { conferenceId } = useParams();
	const { currentAudioChannel } = useAudioChannelContext();

	const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);
  const rdb = useMemo(() => getDatabase(firebaseApp), [firebaseApp]);

	const [editable, setEditable] = useState(() => {
		if (hostStatus) {
			if (user)
				return !screenLocked && hostStatus[user?.uid];
			else return false;
		} return false;
	});
	const { isBeingReposition, setIsBeingReposition, isImageLayersLoading, setIsImageLayersLoading, curManualSpeaker } = useLayerEditorContext();

	useEffect(() => {
		setEditable(() => {
			if (hostStatus) {
				if (user)
					return !screenLocked && hostStatus[user?.uid];
				else return false;
			} return false;
		});
	}, [screenLocked, hostStatus, user, conferenceId]);

	useEffect(() => {
		localStorage.setItem('loadedImage', '{"count":0}');
	}, []);

	const onSelectClick = (layer) => {
		if (layer.zone !== activeZone) return;
		if (layer.id === activeLayer) {
			// setActiveLayer(undefined);
		} else {
			setActiveLayer && setActiveLayer(layer.id);
		}
	};

	const isSelected = (id) => id === activeLayer;

	const participantMap = new Map(
		participants.map((part) => [part.identity, part])
	);

	const goOffstage = (uid) => {
		const ref = rRef(rdb, `/${conferenceId}/status/${uid}`);
		
		if (currentState) {
			update(ref, {
				editingLive: false,
			});
		} else {
			update(ref, {
				live: false,
			});
		}
	};

	const editingRef = collection(doc(collection(doc(collection(db, "conferences"), conferenceId ?? "defaultConference"), "scenes"), 'editing_scene'), 'layers');
	const activeRef = collection(doc(collection(doc(collection(db, "conferences"), conferenceId ?? "defaultConference"), "scenes"), id ?? "defaultScene"), "layers");
	
	const [ref, setRef] = useState((editable && currentState) ? editingRef : activeRef);

	const [_layers, loading] = useCollectionQuery((editable && currentState) ? editingRef : activeRef, { idField: "id" });
	const layers = useMemo(() => _layers?.filter(layer => layer.currentViewType === currentViewType || layer.currentViewType === undefined), 
		[currentViewType, _layers]);

	const scenesRef = collection(doc(collection(db, "conferences"), conferenceId ?? "defaultConference"), "scenes");
	// const customFontsRef = scenesRef.doc(editable && currentState ? 'editing_scene' : id).collection("fonts");
	const customFontsRef = collection(db, 'fonts');
	const [_customFonts, customFontsLoading] = useCollectionQuery(customFontsRef, {
		idField: "id",
	});
	// const customFonts = _customFonts?.filter(font => font.userId === user?.uid);
	const customFonts = _customFonts;

	const hoveredLayer = layers?.filter(_=> _.id === semiActiveLayer && activeZone === _.zone)?.[0];

	// const hoveredLayer = useMemo(() => componentLayers
	// 	?.filter(sceneLayers => sceneLayers.sceneId === activeScene)?.components
	// 	?.filter(component => component.id === semiActiveLayer && component.zone === activeZone
	// ), [componentLayers, semiActiveLayer, activeScene, activeZone]);
	
	useEffect(() => {
		setRef(collection(doc(collection(doc(collection(db, "conferences"), conferenceId ?? "defaultConference"), "scenes"), 
			(editable && currentState) ? 'editing_scene' : id ?? "defaultScene"), "layers"));
	}, [editable, currentState, conferenceId, db, id]);

	useEffect(() => {
		console.log('here might be the bug', {activeScene});
		updateDoc(doc(collection(db, "conferences"), conferenceId ?? 'default'), {
			activeScene: activeScene ?? 'defaultScene'
		})
	}, [editable, currentState, activeScene, conferenceId, db]);

	const currentSceneLayers = componentLayers?.find(sceneLayers => sceneLayers.sceneId === activeScene)?.components;
	const imageLayerCount = currentSceneLayers?.filter(layer => layer.type === 'image')?.length;

	useEffect(() => {
		const current = JSON.parse(localStorage.getItem('loadedImage') ?? '{"count":0}');

    if (imageLayerCount === 0 || (imageLayerCount && imageLayerCount <= current?.count)) {
			setIsImageLayersLoading(false);
		} else {
			// setIsImageLayersLoading(true);
		}
	}, [imageLayerCount, loadedImageCount, setIsImageLayersLoading])

	const mute = (participant, currentMute) => {
		if (!editable && participant?.identity !== localParticipant?.identity) return;
		const isYouInPrivate = currentAudioChannel.members ? (currentAudioChannel.members.some(m => m.id === user?.uid)) : false;
		const isPInPrivate = currentAudioChannel.members ? (currentAudioChannel.members.some(m => m.id === participant.identity)) : false;
		if (isPInPrivate && !isYouInPrivate) return;
		update(rRef(rdb, `/${conferenceId}/status/${participant.identity}`), {
			muted: !currentMute,
		});
	};

	const handleDrag = (id) => (_, { x, y }) => {
		if (isBeingReposition !== id) setIsBeingReposition(id);
		try {
			if ((id === localParticipant?.identity || participants?.some(participant => participant.identity === id)) && currentViewType === 'mobile') {
				updateDoc(doc(ref, id), {
					mobile_x: x,
					mobile_y: y,
				});
			} else {
				updateDoc(doc(ref, id), {
					x,
					y,
				});
			}
		} catch (err) {
			// console.log(err);
		}
	};

	const findLayer = (id) => {
		return currentSceneLayers?.find(component => component.id === id);
	};

	const changeRatio = (id, ratio) => {
		const targetLayer = findLayer(id);
		if (currentViewType === 'desktop') {
			updateDoc(doc(ref, id), {
				ratio,
				width: targetLayer?.height,
				height: targetLayer?.width
			})
		} else {
			updateDoc(doc(ref, id), { 
				mobile_ratio: ratio,
				mobile_width: targetLayer?.mobile_height,
				mobile_height: targetLayer?.mobile_width
			})
		}
	}

	const handleOnStop = () => {
		setIsBeingReposition(null);
	};

	const handleResize = ({ id, height, width }) => {
		if (isBeingReposition !== id) setIsBeingReposition(id);
		if ((id === localParticipant?.identity || participants?.some(participant => participant.identity === id)) && currentViewType === 'mobile') {
			updateDoc(doc(ref, id), {
				mobile_height: height,
				mobile_width: width,
			});
		} else {
			updateDoc(doc(ref, id), {
				height,
				width,
			});
		}
	};

	const handleBringForward = ({id, z}) => {
		if ((id === localParticipant?.identity || participants?.some(participant => participant.identity === id)) && currentViewType === 'mobile') {
			updateDoc(doc(ref, id), {
				mobile_z: z < 30 ? z + 1 : z
			});
		} else {
			updateDoc(doc(ref, id), {
				z: z < 30 ? z + 1 : z
			});
		}
	};

	const handleBringBack = ({id, z}) => {
		if ((id === localParticipant?.identity || participants?.some(participant => participant.identity === id)) && currentViewType === 'mobile') {
			updateDoc(doc(ref, id), {
				mobile_z: z > 0 ? z - 1 : z
			});
		} else {
			updateDoc(doc(ref, id), {
				z: z > 0 ? z - 1 : z
			});
		}
	};

	const handleTextEdit = (id, text) => {
		updateDoc(doc(ref, id), {
			text,
		});
	};

	const setTime = (id, time, status = 1) => {
		updateDoc(doc(ref, id), {
			currentTime: time,
			// playStatus: status,
		})
	}

	const handleRemove = (id) => {
		deleteDoc(doc(ref, id));
	}

	const handlePlay = (id, event) => {
		updateDoc(doc(ref, id), {
			playStatus: 1,
			currentTime: event.target.currentTime,
		});
	}

	const handlePause = (id, event) => {
		updateDoc(doc(ref, id), {
			playStatus: 0,
			currentTime: event.target.currentTime,
		});
	}

	const handleVolumeChange = (id, event) => {
		updateDoc(doc(ref, id), {
			muted: event.target.muted,
			volume: event.target.volume,
		});
	}

	const zoneNumber = (str) => {
		if (str === 'background')
			return 0;
		else if (str === 'participant')
			return 1;
		else if (str === 'foreground')
			return 2;
		else if (str === 'overlay')
			return 3;
		
		return -1;
	};

	const isVisibleOnZone = (layer) => {
		if (layer.zone === 'overlay')
			return isShowOverlay || isStream;

		if ((layer.zone && zoneNumber(layer.zone) <= zoneNumber(activeZone)) || !isHost || isStream) {
			return true;
		}

		return false;
	};

	if (loading) {
		return (<Loader />);
	}

	if (!currentSceneLayers || !Array.isArray(currentSceneLayers)) {
		let doNothing = true;
		// console.log('here entered')
		// return (
		// 	<></>
		// )
	}

	return (
		<>
			{
			// imageLayerCount && loadedImageCount < imageLayerCount / 2 &&
			isImageLayersLoading && 
				<div className="flex items-center justify-center bg-gray-500 absolute left-0 top-0 w-full h-full z-2000">
					<Loader />
				</div>
			}
			{componentLayers?.map(componentsOfScene => {
				return componentsOfScene?.components?.map(component => {
					if (component?.type === 'image') {
						return (
							<ImageLayer
								key={component.id}
								{...component}
								editable={editable && activeZone === component.zone}
								onDrag={handleDrag}
								onResize={handleResize}
								width={component.width}
								height={component.height}
								selected={isSelected(component.id)}
								hovered={semiActiveLayer === component.id && !screenLocked && editable}
								hoveredLayer={hoveredLayer}
								onClick={() => editable && onSelectClick(component)}
								viewWidth={viewWidth}
								viewHeight={viewHeight}
								x={component.x}
								y={component.y}
								isCustomRatio={component.isCustomRatio}
								handleBringForward={handleBringForward}
								handleBringBack={handleBringBack}
								isBeingReposition={isBeingReposition}
								setIsBeingReposition={setIsBeingReposition}
								zoneIndex={zoneNumber(component?.zone)}
								zone={component?.zone}
								parentRef={parentRef}
								isVisible={
									isVisibleOnZone(component) && componentsOfScene?.sceneId === id && 
									(component?.currentViewType === currentViewType || component?.currentViewType === undefined)
								}
								loadedImageCount={loadedImageCount}
								setLoadedImageCount={setLoadedImageCount}
							/>
						)
					}
					
					if (component?.type === 'text') {
						return (
							<TextLayer
								key={component.id}
								{...component}
								editable={editable && activeZone === component.zone}
								onDrag={handleDrag}
								onResize={handleResize}
								selected={isSelected(component.id)}
								hovered={semiActiveLayer === component.id && !screenLocked && editable}
								hoveredLayer={hoveredLayer}
								width={component.width}
								height={component.height}
								onClick={() => editable && onSelectClick(component)}
								onTextEdit={(text) => handleTextEdit(component.id, text)}
								viewWidth={viewWidth}
								viewHeight={viewHeight}
								x={component.x}
								y={component.y}
								customFonts={customFonts}
								onRemove={handleRemove}handleBringForward={handleBringForward}
								handleBringBack={handleBringBack}
								isBeingReposition={isBeingReposition}
								setIsBeingReposition={setIsBeingReposition}
								zoneIndex={zoneNumber(component?.zone)}
								zone={component?.zone}
								parentRef={parentRef}
								isVisible={isVisibleOnZone(component) && componentsOfScene?.sceneId === id}
							/>
						);
					}
					
					if (component?.type === 'timer') {
						return (
							<TimerLayer
								{...component}
								editable={editable && activeZone === component.zone}
								onDrag={handleDrag}
								onStop={handleOnStop}
								key={component.id}
								onRemove={handleRemove}
								onResize={handleResize}
								width={component.width}
								height={component.height}
								selected={isSelected(component.id)}
								hovered={semiActiveLayer === component.id && !screenLocked && editable}
								hoveredLayer={hoveredLayer}
								onClick={() => editable && onSelectClick(component)}
								viewWidth={viewWidth}
								viewHeight={viewHeight}
								x={component.x}
								y={component.y}
								handleBringForward={handleBringForward}
								handleBringBack={handleBringBack}
								setTime={(time, playStatus) => editable && setTime(component.id, time, playStatus)}
								endTime={component.endTime}
								customFonts={customFonts}
								isBeingReposition={isBeingReposition}
								setIsBeingReposition={setIsBeingReposition}
								zoneIndex={zoneNumber(component?.zone)}
								zone={component?.zone}
								parentRef={parentRef}
								isVisible={isVisibleOnZone(component) && componentsOfScene?.sceneId === id}
							/>
						);
					}
					
					if (component.type === "stream" && participantMap.has(component.id)) {
						const participant = participantMap.get(component.id);
						if (!props?.isSingleView || (participant?.peerId === props?.activeSpeaker && !!!curManualSpeaker)
							|| (participant?.identity === curManualSpeaker)						
						) {
							return (
								<StreamLayer
									key={component?.id}
									{...component}
									onDrag={handleDrag}
									editable={editable && activeZone === component.zone}
									participant={participant}
									onRemove={() => goOffstage(participant.identity)}
									onResize={handleResize}
									selected={isSelected(component.id)}
									hovered={semiActiveLayer === component.id && !screenLocked && editable}
									hoveredLayer={hoveredLayer}
									onClick={() => editable && onSelectClick(component)}
									width={currentViewType === 'desktop' ? component.width : component.mobile_width}
									height={currentViewType === 'desktop' ? component.height : component.mobile_height}
									viewWidth={viewWidth}
									viewHeight={viewHeight}
									x={currentViewType === 'desktop' ? component.x : component.mobile_x}
									y={currentViewType === 'desktop' ? component.y : component.mobile_y}
									z={currentViewType === 'desktop' ? component.z : component.mobile_z}
									mute={mute}
									sessions={sessions}
									handleBringForward={handleBringForward}
									handleBringBack={handleBringBack}
									isBeingReposition={isBeingReposition}
									setIsBeingReposition={setIsBeingReposition}
									zoneIndex={zoneNumber(component?.zone)}
									zone={component?.zone}
									parentRef={parentRef}
									isVisible={participantMap.has(component.id) && isVisibleOnZone(component) && componentsOfScene?.sceneId === id}
									currentViewType={currentViewType}
									changeRatio={changeRatio}
									ratio={component?.ratio}
									mobileRatio={component?.mobile_ratio}
									isLive={true}
									isAutoGen={props.dynamicId > 0}
									activeScene={activeScene}
									currentScene={id}
									activeSpeaker={props?.activeSpeaker}
									isSingleView={props?.isSingleView}
								/>
							);
						}
					}
	
					if (renderLocalParticipant) {
						if (component.type === "stream" && component.id === user?.uid /*&& isVisibleOnZone(layer)*/) {
							if (!props?.isSingleView || (localParticipant?.peerId === props?.activeSpeaker && !!!curManualSpeaker)
								|| (localParticipant?.identity === curManualSpeaker) 
								|| (props?.isSingleView && !!!curManualSpeaker && !!!props?.activeSpeaker)
							) {
								return (
									<LocalStreamLayer
										key={component?.id}
										{...component}
										onDrag={handleDrag}
										editable={editable && activeZone === component.zone}
										localParticipant={localParticipant}
										onRemove={() => goOffstage(user?.uid)}
										onResize={handleResize}
										selected={isSelected(component.id)}
										hovered={semiActiveLayer === component.id && !screenLocked && editable}
										hoveredLayer={hoveredLayer}
										onClick={() => editable && onSelectClick(component)}
										width={currentViewType === 'desktop' ? component.width : component.mobile_width}
										height={currentViewType === 'desktop' ? component.height : component.mobile_height}
										x={currentViewType === 'desktop' ? component.x : component.mobile_x}
										y={currentViewType === 'desktop' ? component.y : component.mobile_y}
										z={currentViewType === 'desktop' ? component.z : component.mobile_z}
										viewWidth={viewWidth}
										viewHeight={viewHeight}
										mute={mute}
										mySession={mySession}
										sessions={sessions}
										handleBringForward={handleBringForward}
										handleBringBack={handleBringBack}
										isBeingReposition={isBeingReposition}
										setIsBeingReposition={setIsBeingReposition}
										zoneIndex={zoneNumber(component?.zone)}
										zone={component?.zone}
										parentRef={parentRef}
										isVisible={component.id === user?.uid && isVisibleOnZone(component) && componentsOfScene?.sceneId === id}
										currentViewType={currentViewType}
										changeRatio={changeRatio}
										ratio={component?.ratio}
										mobileRatio={component?.mobile_ratio}
										isLive={true}
										isAutoGen={props.dynamicId > 0}
										activeScene={activeScene}
										currentScene={id}
										activeSpeaker={props?.activeSpeaker}
										isSingleView={props?.isSingleView}
									/>
								);
							}
						}
					}
					
					return (<div key={`xyz-${Math.floor(Math.random() * 100000)}`}></div>)
				})
			})}
		</>
	);
};

export default Scene;
