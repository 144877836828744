import { useRef, useState } from "react";
import { Button, Flex, Input } from "@100mslive/react-ui";

const SaveTemplateForm = ({
  isOpen,
  onConfirm = async ()=>{},
  onClose=()=>{},
}) => {
  const [name, setName] = useState('');

  const handleSave = async (e) => {
    if (name) {
      await onConfirm(name);
      onClose();
      setName('');
    }
  };

  if (isOpen) {
    return (
      <>
        <div className="fixed w-full h-full left-0 top-0" onClick={() => onClose()}></div>
        <Flex 
          direction="column"
          className="absolute w-64 right-0 top-6 bg-gray-300 border border-gray-500 rounded px-2 pb-2 z-50"
        >
          <span className="mb-2">Save as Template</span>
          <Flex justify="between" align="center" css={{mb: '$2'}}>
            <span>Name</span>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              css={{w: '180px', h: '20px', bg: 'white', color: 'black', zIndex: 50}}
            />
          </Flex>
          
          <Flex justify="between">
            <Button
              variant="standard"
              onClick={() => onClose()}
              css={{ fontSize: '15px', px: '$1', py: '$1', h: '$10', w: '$50'}}
            >Cancel</Button>
            <Button
              css={{ fontSize: '15px', px: '$1', py: '$1', h: '$10', w: '70px'}}
              onClick={handleSave}
            >Save</Button>
          </Flex>
        </Flex>
      </>
    )
  } else {
    return (<></>)
  }
};

export default SaveTemplateForm;
