import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../components/button";
import Modal from "../components/modal";

const CountdownTimeModal = ({ isOpen, onClose, onSubmit, setCountdownTime, message }) => {
  const [ hour, setHour ] = useState(0);
  const [ minute, setMinute ] = useState(0);
  const [ second, setSecond ] = useState(0);

  const onHour = (event) => {
    setHour(event.target.value);
  };

  const onMinute = (event) => {
    setMinute(event.target.value);
  };

  const onSecond = (event) => {
    setSecond(event.target.value);
  };

  const onHandleCountdown = (event) => {
    setCountdownTime(Number(hour) * 3600 + Number(minute) * 60 + Number(second));
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={onClose}
      className="w-1/3"
      isHeader={false}
      isFooter={false}
    >
      <div className="custom-bg-1 w-full p-2 rounded-t-md border-b border-gray-200 flex items-center">
        <h2 className="font-medium text-xl text-gray-100">Countdown Time</h2>
      </div>
      {message.length === 0 && 
        <div className="p-4 flex items-center custom-bg-2 text-white">
          {/* <InputField id="name" label="Name" ref={register} required /> */}
          <input
            className="rounded m-2 text-black"
            value={hour}
            onChange={onHour}
            min={0}
            max={24}
            type="number"
          />:
          <input
            className="rounded m-2 text-black"
            value={minute}
            onChange={onMinute}
            min={0}
            max={59}
            type="number"
          />:
          <input
            className="text-black rounded m-2 "
            value={second}
            onChange={onSecond}
            min={0}
            max={59}
            type="number"
          />
          <div className="flex flex-row-reverse">
            <Button
              onClick={onHandleCountdown}
              type="submit"
              className="rounded w-24"
            >
              Set
            </Button>
          </div>
        </div>
      }
      {message &&
        <div className="custom-bg-2 flex justify-center items-center w-400 h-300 text-white">
          <p style={{ fontSize: '25px' }}>{message}</p>
        </div>
      }
    </Modal>
  );
};

export default CountdownTimeModal;
