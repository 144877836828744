import React from "react";
import { FirebaseContext } from "./firebaseContext";
import { AuthContext } from './userSessionContext';
import { getFirestore, collection, doc, updateDoc, getDoc, setDoc, onSnapshot, query, orderBy, limit } from "firebase/firestore";
import { getDatabase, ref, set, get, onValue, remove } from "@firebase/database";
import { useCollectionQuery } from "../hooks/useFirebaseCollection";
import { ToastBatcher } from "../../components/Toast/ToastBatcher";
import { StarIcon } from "@100mslive/react-icons";
import { ReactComponent as StarFillIcon } from "../../icons/SimpleStar.svg";
import ReactDOMServer from 'react-dom/server';
import { useParams } from "react-router-dom";
import { useDocumentQuery } from "../hooks/useFirebaseDocument";
import { ThumbsUpIcon, ThumbsDownIcon } from "@100mslive/react-icons";
import { nanoid } from "nanoid";
import { Button } from "@100mslive/react-ui";

export const InteractionContext = React.createContext({});

const ChatAction = React.forwardRef((_, ref) => {
  const { setIsOpenInteractionTab } = React.useContext(InteractionContext);

  return (
    <Button
      outlined
      as="div"
      variant="standard"
      css={{ w: "max-content" }}
      onClick={() => {
        setIsOpenInteractionTab(false);
        setTimeout(() => setIsOpenInteractionTab(true))
      }}
      ref={ref}
    >
      Open Interaction Tab
    </Button>
  );
});

export const InteractionProvider = ({children}) => {
  const { user, firebaseApp } = React.useContext(FirebaseContext);
  const { hostStatus } = React.useContext(AuthContext);
  const [isRequestOpen, setIsRequestOpen] = React.useState(false);
  const rdb = getDatabase(firebaseApp);
  const db = getFirestore(firebaseApp);
  const [userProfile, userProfileLoading] = useDocumentQuery(doc(collection(db, 'profiles'), user?.uid ?? 'defaultUser'));
  const collectionRef = collection(db, 'interactions');
  const historyRef = collection(db, 'interactionHistory');
  const interactionRef = React.useMemo(() => ref(rdb, `/interactions/${user?.uid ?? 'defaultId'}`), [user?.uid, rdb]);
  const [interactions, interactionsLoading] = useCollectionQuery(query(collectionRef, orderBy('createdAt', 'asc')));
  const [interactionHistory, historyLoading] = useCollectionQuery(query(historyRef, orderBy('triggeredAt', 'asc'), limit(1000)));

  const [isOpenInteractionTab, setIsOpenInteractionTab] = React.useState(false);
  const { conferenceId, role } = useParams();

  const lastTriggered = React.useRef(0);

  const [interactionRequest, setInteractionRequest] = React.useState();

  const testInteraction = (interaction) => {
    setInteractionRequest({
      ...interaction,
      isTest: true
    });
    setIsRequestOpen(true);
  };

  const triggerInteraction = async (interaction) => {
    const triggeredAt = new Date().getTime();
    await updateDoc(doc(collectionRef, interaction?.id), {
      triggeredAt
    })
    const id = nanoid();
    await setDoc(doc(historyRef, id), {
      ...interaction,
      id,
      interactionId: interaction?.id,
      triggeredAt,
      responded: [],
      denied: [],
      roomId: conferenceId
    })
  };

  const interact = async (interaction) => {
    const snapshot = await get(ref(rdb, `/interactions/${interaction?.userId}/`));
    const data = snapshot.val();
    await set(ref(rdb, `/interactions/${interaction?.userId}/`), {
      responded: [...(data?.responded ?? []), interaction]
    })
    if (!interaction?.isTest) {
      const snapshotdata = await getDoc(doc(historyRef, interaction?.id));
      const history = snapshotdata.data();
      await updateDoc(doc(historyRef, interaction?.id), {
        responded: [...(history?.responded ?? []), {
          ...interaction,
          respondedAt: new Date().getTime(),
        }]
      })
    }
    setInteractionRequest();
  };

  const deny = async (interaction) => {
    const snapshot = await get(ref(rdb, `/interactions/${interaction?.userId}/`));
    const data = snapshot.val();
    await set(ref(rdb, `/interactions/${interaction?.userId}/`), {
      denied: [...(data?.responded ?? []), interaction]
    })
    if (!interaction?.isTest) {
      const snapshotdata = await getDoc(doc(historyRef, interaction?.id));
      const history = snapshotdata.data();
      await updateDoc(doc(historyRef, interaction?.id), {
        denied: [...(history?.denied ?? []), {
          ...interaction,
          deniedAt: new Date().getTime(),
        }]
      })
    }
    setInteractionRequest();
  };

  const receiveInteraction = React.useCallback((snapshot) => {
    const { responded, denied, triggered } = (snapshot.val() ?? {});
    (responded ?? []).forEach(interaction => {
      if (interaction?.type?.parentType === 'broadcastWindow') {
        ToastBatcher.showToast({
          type: 'App_Notification',
          notification: {
            title: 
              `<div className="flex flex-col justify-center items-center">
                <p style="color: blue;">${interaction?.interactedUser?.displayName}&nbsp;responded</p>
                <p>${interaction?.content?.body}</p>
                <div style="display: flex;">
                  ${interaction?.rate < 2 ? ReactDOMServer.renderToString(<ThumbsDownIcon className="w-8 h-8 mr-1 text-yellow-500 cursor-pointer"/>) : ''}
                  ${[...Array(5)].map((star, idx) => 
                    `<div key={idx}>
                      ${idx < interaction?.rate 
                        ? ReactDOMServer.renderToString(<StarFillIcon className="w-8 h-8 mr-1 text-yellow-500 cursor-pointer" />)
                        : ReactDOMServer.renderToString(<StarIcon className="w-8 h-8 mr-1 text-yellow-500 cursor-pointer" />)
                      }
                    </div>`
                  ).join('')}
                  ${interaction?.rate === 5 ? ReactDOMServer.renderToString(<ThumbsUpIcon className="w-8 h-8 mr-1 text-yellow-500 cursor-pointer"/>) : ''}
                </div>
              </div>`
          },
          duration: 100000
        });
      } else {
        ToastBatcher.showToast({
          type: 'App_Notification',
          notification: {
            title: 
              `<div className="flex flex-col justify-center items-center">
                <p style="color: blue;">${interaction?.interactedUser?.displayName}&nbsp;responded</p>
                <p>${interaction?.content?.body}</p>
              </div>`,
            action: <ChatAction />
          },
          duration: 100000
        });
      }
    })
    try {
      (denied ?? []).forEach(interaction => {
        ToastBatcher.showToast({
          type: 'App_Notification',
          notification: {
            title: 
              `<div className="flex flex-col justify-center items-center">
                <p style="color: blue;">${interaction?.deniedUser?.displayName}&nbsp;denied</p>
                <p>${interaction?.content?.body}</p>
              </div>`
          },
          duration: 100000
        });
      })
    } catch (err) {
      // console.log(err);
    }
    remove(ref(rdb, `/interactions/${user?.uid}/`));
    // remove(ref(rdb, `/interactions/${user?.uid}/denied/`));
  }, [rdb, user]);

  const checkIfTarget = React.useCallback((interaction = {}) => {
    const _userProfile = {
      ...(userProfile ?? {}),
      meta1: userProfile?.fieldOne ?? '',
      meta2: userProfile?.fieldTwo ?? '',
      meta3: userProfile?.fieldThree ?? ''
    }

    return interaction?.roomId === conferenceId 
      && (!user?.isAnonymous || (role !== 'producer' && role))
      && interaction?.userId !== user?.uid
      && ((interaction?.targets?.length ? interaction?.targets : [])?.some(target => 
          user?.displayName?.toLowerCase()?.indexOf(target?.firstName?.toLowerCase()) >= 0
          || user?.displayName?.toLowerCase()?.indexOf(target?.lastName?.toLowerCase()) >= 0
          || (user?.email && user?.email?.toLowerCase()?.indexOf(target?.email?.toLowerCase()) >= 0)
        )
        || ((interaction?.filterMatch?.key === 'firstName' || interaction?.filterMatch?.key === 'lastName') &&
          (user?.displayName ?? '').toLowerCase().indexOf(interaction?.filterMatch?.value?.toLowerCase() ?? 'all') >= 0)
        || (_userProfile?.[interaction?.filterMatch?.key] ?? '').toLowerCase().indexOf(interaction?.filterMatch?.value?.toLowerCase() ?? 'all') >= 0
        || interaction?.filterMatch?.key === 'all'
      )
  }, [userProfile, conferenceId, user, role]);

  const checkInteraction = (interaction) => {
    if (hostStatus[user?.uid]) {
      return interaction?.roomId === conferenceId && interaction?.userId === user?.uid;
    } else {
      return checkIfTarget(interaction)
    }
  }

  React.useEffect(() => {
    return onValue(interactionRef, receiveInteraction);
  }, [interactionRef, receiveInteraction]);

  React.useEffect(() => {
    const curTime = new Date().getTime();
    (interactionHistory ?? []).forEach(interaction => {
      if (interaction?.triggeredAt > lastTriggered.current && curTime - interaction?.triggeredAt < 20000 && checkIfTarget(interaction)) {
        lastTriggered.current = interaction?.triggeredAt;
        setInteractionRequest(interaction);
        setIsRequestOpen(true);
      }
    });
  }, [interactionHistory, checkIfTarget]);

  React.useEffect(() => {
    const curTime = new Date().getTime();
    (interactions ?? []).forEach(interaction => {
      if (interaction?.scheduledAt > curTime && checkIfTarget(interaction)) {
        setTimeout(() => {
          const id = nanoid();
          setDoc(doc(historyRef, id), {
            ...interaction,
            id,
            interactionId: interaction?.id,
            triggeredAt: interaction?.scheduledAt
          })
        }, [interaction?.scheduledAt - curTime])
      }
    });
  }, [interactions, checkIfTarget, historyRef]);

  return (
    <InteractionContext.Provider
      value={{
        interactions,
        interactionHistory,
        interactionRequest,
        testInteraction,
        triggerInteraction,
        interact,
        deny,
        isOpenInteractionTab,
        setIsOpenInteractionTab,
        isRequestOpen,
        setIsRequestOpen,
        checkIfTarget,
        checkInteraction
      }}
    >
      {children}
    </InteractionContext.Provider>
  )
};
