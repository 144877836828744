import React, { useContext, useState } from "react";
import CopyField from "../../features/copyField";
import {ReactComponent as ChainIcon} from "../../icons/chain.svg";
import {ReactComponent as CloseIcon} from "../../icons/x-circle.svg";
import { useParams } from "react-router-dom";

const InviteLink = () => {
	const [isOpen, setIsOpen] = useState(false);
	const { conferenceId } = useParams();
	const recordingUrl = `https://100ms.voodoocast.io/hls-view/${conferenceId}/hls-viewer/`;
	const embedCode = `<iframe src="${recordingUrl}" style="position: relative; z-index: 10000; left: 0; top: 0; width: 100%; height: 100%; min-height: 700px;"></iframe>`;

	return (
		<div className="relative mr-2">
			<ChainIcon
				className="w-4 h-4 cursor-pointer text-gray-100 hover:text-yellow-400"
				onClick={() => setIsOpen(!isOpen)}
			/>
			{isOpen && 
				<div
					onClick={(e) => {
						e.stopPropagation();
						setIsOpen(false);
					}}
					className="fixed w-full h-full left-0 top-0"
				>
					<div
						onClick={(e) => {
							e.stopPropagation();
						}}
						className="flex flex-col absolute top-8 left-0 custom-bg-3 rounded-lg shadow-md"
					>
						<div className="flex gap-2 p-2 items-center pr-5 border-b border-dotted mx-2">
							<p className="font-medium w-28 text-white">Invite:</p>
							<CopyField value={window.location.href.replace("/producer", "/respondent")} />
							<CloseIcon className="w-5 h-5 absolute right-0 top-0 cursor-pointer hover:text-red-400" onClick={() => setIsOpen(false)} />
						</div>
						<div className="flex gap-2 p-2 items-center pr-5 border-b border-dotted mx-2">
							<p className="font-medium w-28 text-white">View:</p>
							<CopyField value={recordingUrl} />
						</div>
						<div className="flex gap-2 p-2 items-center pr-5 mx-2">
							<p className="font-medium w-28 text-white">Embeding Code:</p>
							<CopyField value={embedCode} />
						</div>
					</div>
				</div>
			}
		</div>
	)
};

export default InviteLink;
