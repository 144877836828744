import React, { useContext, useMemo, useRef, useState, useEffect } from "react";
import { AuthContext } from "../../helpers/contexts/userSessionContext";
import { FirebaseContext } from "../../helpers/contexts/firebaseContext";
import { useDocumentQuery } from "../../helpers/hooks/useFirebaseDocument";
import View from "./view";
import Edit from "./edit";
import Control from "./control";
import { getFirestore, collection, doc, updateDoc } from "@firebase/firestore";

const Teleprompter = ({
	isHost,
	conferenceId,
	isTeleprompter,
	setIsTeleprompter,
	parentRef,
	isBeamUser = false
}) => {
	const [currentTab, setCurrentTab] = useState('view');
	const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);
	const docRef = useMemo(() => doc(collection(db, "conferences"), conferenceId ?? "defaultConference"), [db, conferenceId]);
	const editTextRef = useRef();
  
  const [conference, loading] = useDocumentQuery(docRef);

  const { user } = useContext(AuthContext);
	useEffect(() => {
		if (user?.isAnonymous) {
			setCurrentTab('view');
		}
	}, [isHost, user]);

	useEffect(() => {
		if (conference?.teleprompter?.status) {
			setIsTeleprompter(conference?.teleprompter?.status?.isOpen);
		}
	}, [conference?.teleprompter, setIsTeleprompter]);

	const handleSave = (e) => {
		updateDoc(docRef, {
			teleprompter: {
				...conference?.teleprompter,
				text: editTextRef.current.value,
			}
		});
	};

	const handleCancel = (e) => {
		editTextRef.current.value = conference?.teleprompter?.text;
	};

	const settleCurrentParagraph = (idx = 0) => {
		updateDoc(docRef, {
			teleprompter: {
				...conference?.teleprompter,
				currentParagraph: idx,
			}
		});
	};

	const updateInterval = (interval) => {
		updateDoc(docRef, {
			teleprompter: {
				...conference?.teleprompter,
				interval
			}
		});
	};

	const updateStatus = (status) => {
		updateDoc(docRef, {
			teleprompter: {
				...conference?.teleprompter,
				status,
			}
		});
	};

  return (
		<>
			<div
				className="h-full"
			>
				{(isHost && !isBeamUser) && <div className="flex" style={{ transform: 'translateY(2px)' }}>
					<div
						onClick={() => setCurrentTab('view')}
						className={`cursor-pointer rounded-t-md pt-1 w-16 h-8 text-lg text-white text-center border border-green-400 mr-1 ml-2 text-sm flex items-center justify-center
						${currentTab === 'view' ? 'bg-green-400 z-20 border-b border-white' : 'custom-bg-2 z-10'}`}
					>
						View
					</div>
					<div
						onClick={() => setCurrentTab('edit')}
						className={`${currentTab === 'edit' ? 'bg-green-400 z-20' : 'custom-bg-2 z-10'} 
							cursor-pointer rounded-t-md pt-1 w-16 h-8 text-lg text-center border border-green-400 mr-1 text-white text-sm flex items-center justify-center`}
						style={currentTab === 'edit' ? { borderBottom: 'white', borderBottomLeftRadius: '2px', borderBottomRightRadius: '2px' } : {}}
					>
						Edit
					</div>
					<div
						onClick={() => setCurrentTab('control')}
						className={`${currentTab === 'control' ? 'bg-green-400 z-20' : 'custom-bg-2 z-10'}
						cursor-pointer rounded-t-md pt-1 w-16 h-8 text-lg text-center border border-green-400 text-white text-sm flex items-center justify-center`}
						style={currentTab === 'control' ? { borderBottom: 'white', borderBottomLeftRadius: '2px', borderBottomRightRadius: '2px' } : {}}
					>
						Control
					</div>
				</div>}
				<div
					className={`p-4 w-full bg-green-400 ${isHost?"border border-gray-600":"mt-2"} rounded `}
					style={isHost ? { height: 'calc(100% - 40px)'} : { height: 'calc(100% - 30px)' }}
				>
					{currentTab === 'view' &&
						<View
							isHost={isHost}
							isBeamUser={isBeamUser}
							teleprompter={conference?.teleprompter}
							updateStatus={updateStatus}
							updateInterval={updateInterval}
							isOpen={isTeleprompter}
						/>
					}
					{currentTab === 'edit' && 
						<Edit
							isOpen={isTeleprompter}
							teleprompter={conference?.teleprompter} editTextRef={editTextRef} handleSave={handleSave} handleCancel={handleCancel}
						/>
					}
					{currentTab === 'control' &&
						<Control
							isOpen={isTeleprompter}
							isHost={isHost}
							teleprompter={conference?.teleprompter}
							settleCurrentParagraph={settleCurrentParagraph}
							updateStatus={updateStatus}
							updateInterval={updateInterval}
						/>
					}
				</div>
			
			</div>
		</>
	)
};

export default Teleprompter;
