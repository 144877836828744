import { useState, useEffect, useRef } from "react";

const useVideoPlayer = (videoElement, transcriptUrl) => {
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    currentTime: 0,
    progress: 0,
    speed: 1,
    isMuted: false,
    transcript: ''
  });

  const [transcriptArr, setTranscriptArr] = useState([]);

  const reset = () => {
    setPlayerState({
      isPlaying: false,
      progress: 0,
      speed: 1,
      isMuted: false,
      transcript: ''
    });
  }

  const togglePlay = () => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying,
    });
  };

  const playVideo = () => {
    setPlayerState({
      ...playerState,
      isPlaying: true
    })
  }

  useEffect(() => {
    playerState.isPlaying
      ? videoElement.current.play()
      : videoElement.current.pause();
  }, [playerState.isPlaying, videoElement]);

  const handleOnTimeUpdate = () => {
    const progress = (videoElement.current.currentTime / videoElement.current.duration) * 100;
    let idx = 0;
    let flag = false;
    for (idx = 0; idx < transcriptArr?.length; idx++) {
      if (transcriptArr[idx].created - 1000 > videoElement.current.currentTime * 1000) {
        flag = true;
        break;
      }
    }
    if (flag && idx === 0) flag = false;
    idx = Math.max(idx - 1, 0);
    setPlayerState({
      ...playerState,
      currentTime: videoElement.current.currentTime,
      progress,
      transcript: transcriptArr ? 
        {
          speaker: !flag ? '' : transcriptArr[idx].speaker,
          message: !flag ? '' : transcriptArr[idx].message,
        } : null
    });
  };

  const handleVideoProgress = (event) => {
    const manualChange = Number(event.target.value);
    try {
      videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange;
    } catch (err) {
      console.log(err)
    }
    setPlayerState({
      ...playerState,
      currentTime: videoElement.current.currentTime,
      progress: manualChange,
    });
  };

  const handleVideoBackward = (numOfSecs) => {
    let currentTime = Math.max(videoElement.current.currentTime - numOfSecs, 0);
    let progress = videoElement.current.duration ? (currentTime / videoElement.current.duration) * 100 : 0;
    videoElement.current.currentTime = currentTime;
    setPlayerState({
      ...playerState,
      currentTime,
      progress,
    });
  };

  const setCurrentTime = (time) => {
    videoElement.current.currentTime = time;
    setPlayerState({
      ...playerState,
      currentTime: videoElement.current.currentTime,
      progress: time / videoElement.current?.duration * 100 ?? 0
    })
  }

  const handleVideoSpeed = (event) => {
    const speed = Number(event.target.value);
    videoElement.current.playbackRate = speed;
    setPlayerState({
      ...playerState,
      speed,
    });
  };

  const toggleMute = () => {
    setPlayerState({
      ...playerState,
      isMuted: !playerState.isMuted,
    });
  };

  const handleRefresh = () => {
    setPlayerState({
      ...playerState,
      isPlaying: true,
      progress: 0,
    });
    videoElement.current.currentTime = 0;
    videoElement.current.play();
  }

  useEffect(() => {
    playerState.isMuted
      ? (videoElement.current.muted = true)
      : (videoElement.current.muted = false);
  }, [playerState.isMuted, videoElement]);

  return {
    playerState,
    transcripts: transcriptArr,
    playVideo,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoBackward,
    handleVideoSpeed,
    toggleMute,
    handleRefresh,
    reset,
    setTranscriptArr,
    setCurrentTime,
  };
};

export default useVideoPlayer;
