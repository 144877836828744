import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { FirebaseContext } from "../contexts/firebaseContext";
import { AuthContext } from '../contexts/userSessionContext';
import { getFirestore, collection, doc, getDoc, setDoc, onSnapshot, query, orderBy, limit } from "firebase/firestore";
import { useCollectionQuery } from "../hooks/useFirebaseCollection";
import { ToastBatcher } from "../../components/Toast/ToastBatcher";

export const useAppNotification = () => {
  const { firebaseApp } = useContext(FirebaseContext);
  const { user, auth } = useContext(AuthContext);
  const db = getFirestore(firebaseApp)
  const communicatesRef = collection(db, 'communicates');
  const [communicates, communicatesLoading] = useCollectionQuery(communicatesRef);

  const [firstNotification, setFirstNotification] = useState({});
  const eventsRef = useRef([]);

  const replaceUserMeta = (content='', _user) => {
    return content.replaceAll('{firstName}', _user?.firstName ?? "")
      .replaceAll('{lastName}', _user?.lastName ?? "")
      .replaceAll('{meta1}', _user?.meta1 ?? "")
      .replaceAll('{meta2}', _user?.meta2 ?? "")
      .replaceAll('{meta3}', _user?.meta3 ?? "");
  }

  const putNotifications = useCallback(() => {
    for (let t of eventsRef.current) {
      clearTimeout(t)
    }
    eventsRef.current = [];
    (Array.isArray(communicates) ? communicates : [])?.forEach(communicate => {
      if (communicate?.type?.type === 'app_notification') {
        const scheduledTime = new Date(communicate?.scheduledAt).getTime();
        const currentTime = new Date().getTime();
        if (scheduledTime - currentTime > 0) {
          if (communicate?.target?.targetType === 'user') {
            if (user?.displayName === `${communicate?.target?.firstName} ${communicate?.target?.lastName}`
              || user?.email === communicate?.target?.email
              || (user?.firstName === communicate?.target?.firstName && user?.lastName === communicate?.target?.lastName)
            ) {
              const timeout = setTimeout(() => {
                ToastBatcher.showToast({
                  type: 'App_Notification',
                  notification: {
                    title: replaceUserMeta(communicate?.body, communicate?.target)
                  },
                  duration: 10000
                })
              }, scheduledTime - currentTime)
              eventsRef.current.push(timeout);
              return;
            }
          } else if (communicate?.target?.targetType === 'group') {
            for (let _user of communicate?.target?.users) {
              if (user?.displayName === `${_user?.firstName} ${_user?.lastName}`
                || user?.email === _user?.email
                || (user?.firstName === _user?.firstName && user?.lastName === _user?.lastName)
              ) {
                const timeout = setTimeout(() => {
                  ToastBatcher.showToast({
                    type: 'App_Notification',
                    notification: {
                      title: replaceUserMeta(communicate?.body, _user)
                    },
                    duration: 10000
                  })
                }, scheduledTime - currentTime)
                eventsRef.current.push(timeout);
                break;
              }
            }
          } else {
            for (let _user of communicate?.target?.batching) {
              if (user?.displayName === `${_user?.firstName} ${_user?.lastName}`
                || user?.email === _user?.email
                || (user?.firstName === _user?.firstName && user?.lastName === _user?.lastName)
              ) {
                const timeout = setTimeout(() => {
                  ToastBatcher.showToast({
                    type: 'App_Notification',
                    notification: {
                      title: replaceUserMeta(communicate?.body, _user)
                    },
                    duration: 10000
                  })
                }, scheduledTime - currentTime)
                eventsRef.current.push(timeout);
                break;
              }
            }
          }
        }
      }
    })
  }, [communicates, user]);

  useEffect(() => {
    putNotifications();
  }, [communicates, putNotifications])

  return {}
};

