import { Resizable } from "re-resizable";
import React, { useEffect, useMemo, useState } from "react";
import { install } from "resize-observer";
import LocalVideoFeed from "./localVideoFeed";
import Draggable from './draggable';
import Button from "../components/button";
import { ReactComponent as SortAscendingIcon } from "../icons/sort-ascending.svg";
import { ReactComponent as SortDescendingIcon } from "../icons/sort-descending.svg";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
// TODO
// - Ratio resize?
install();

const LocalStreamLayer = ({
  localParticipant,
  onDrag,
  id,
  x = 0,
  y = 0,
  z = 0,
  onRemove,
  onResize,
  layer,
  height: givenHeight,
  width: givenWidth,
  selected,
  hovered,
  onClick,
  editable,
  viewWidth,
  viewHeight,
  mute,
  mySession,
  sessions,
  handleBringForward,
  handleBringBack,
  isBeingReposition,
  setIsBeingReposition,
  zoneIndex,
  hoveredLayer,
  parentRef,
  zone,
  isVisible,
  ratio: baseRatio,
  mobileRatio: baseMobileRatio,
  changeRatio,
  currentViewType = 'desktop',
  isLive,
  isAutoGen = false,
  activeScene,
  currentScene,
  activeSpeaker,
  ...props
}) => {
  const [parentLocked, setParentLocked] = useState(false);
  const { activeSublayer, setActiveSublayer, semiActiveSublayer, setSemiActiveSublayer } = useLayerEditorContext();

  const initialRatio = currentViewType === 'desktop' ? 1920 / 1080 : 1080 / 1920;

  const ratio = (currentViewType === 'desktop' ? baseRatio : baseMobileRatio) ?? initialRatio;

  useEffect(() => {
    if (!selected) {
      setActiveSublayer();
      setSemiActiveSublayer();
    }
  }, [selected, setSemiActiveSublayer, setActiveSublayer]);

  const scaleDimensions = ({ viewWidth, viewHeight, width, height }) => {
    const heightPercentage = viewHeight / 1080;
    const computedHeight = heightPercentage * (height > 0 ? height : 500);
    const computedWidth = computedHeight * ratio;

    return {
      width: computedWidth,
      height: computedHeight,
    };
  };

  const deScaleDimensions = ({ viewWidth, viewHeight, width, height }) => {
    const heightPercentage = viewHeight / 1080;
    const computedHeight = height / heightPercentage;
    const computedWidth = computedHeight * ratio;

    return {
      width: computedWidth,
      height: computedHeight,
    };
  };

  const { width, height } = scaleDimensions({
    viewWidth,
    viewHeight,
    width: givenWidth,
    height: givenHeight,
  });

  const isInHoveredLayer = () => {
    const hoveredX = currentViewType === 'desktop' ? hoveredLayer?.x : hoveredLayer?.mobile_x;
    const hoveredY = currentViewType === 'desktop' ? hoveredLayer?.y : hoveredLayer?.mobile_y;
    const hoveredZ = currentViewType === 'desktop' ? hoveredLayer?.z : hoveredLayer?.mobile_z;
    const hoveredWidth = currentViewType === 'desktop' ? hoveredLayer?.width : hoveredLayer?.mobile_width;
    const hoveredHeight = currentViewType === 'desktop' ? hoveredLayer?.height : hoveredLayer?.mobile_height;

		if (z > hoveredZ && hoveredLayer?.zone === zone) {
		  if (hoveredX < x && hoveredX + hoveredWidth > x
			&& hoveredY < y && hoveredY + hoveredHeight > y) {
			  return true;
			}
		  if (hoveredX < x + givenWidth && hoveredX + hoveredWidth > x + givenWidth
			&& hoveredY < y && hoveredY + hoveredHeight > y) {
			  return true;
			}
		  if (hoveredX < x && hoveredX + hoveredWidth > x
			&& hoveredY < y + givenHeight && hoveredY + hoveredHeight > y + givenHeight) {
			  return true;
			}
		  if (hoveredX < x + givenWidth && hoveredX + hoveredWidth > x + givenWidth
			&& hoveredY < y + givenHeight && hoveredY + hoveredHeight > y + givenHeight) {
			  return true;
			}
		}
		return false;
  };

  const baseZ = isInHoveredLayer() ? 1500 : 0;

  return (
    <Draggable
      id={id}
      onDrag={onDrag(id)}
      x={x}
      y={y}
      layer={layer}
      position={{ x, y }}
      disabled={!editable || isAutoGen || parentLocked || props?.isSingleView}
      viewHeight={viewHeight}
      viewWidth={viewWidth}
      setIsBeingReposition={setIsBeingReposition}
      parentRef={parentRef}
      isVisible={isVisible}
    >
      <Resizable
        style={{
          position: "absolute",
          zIndex: isBeingReposition === id || (mySession?.live && mySession?.isOverloaded && localParticipant?.peerId === activeSpeaker)
            ? 2500 : hovered 
              ? 2000 : selected 
                ? 1500 + baseZ :  isInHoveredLayer() ? 2000 + z : 250 * zoneIndex + z,
        }}
        bounds="parent"
        handleClasses="handle"
        handleWrapperClass="re-handle"
        className="inline-block absolute"
        lockAspectRatio
        enable={{
          top: false,
          right: true && editable && !isAutoGen,
          bottom: true && editable && !isAutoGen,
          bottomLeft: false,
          bottomRight: true && editable && !isAutoGen,
          topRight: false,
          topLeft: false,
        }}
        size={{ width, height }}
        onResizeStart={(e) => {
          e.stopPropagation();
          setIsBeingReposition(id);
        }}
        onClick={(e) => {
          onClick(e);
          setActiveSublayer();
          setSemiActiveSublayer();
        }}
        onResize={(e, direction, ref, d) => {}}
        onResizeStop={(e, direction, ref, d) => {
          const deScale = deScaleDimensions({
            viewWidth,
            viewHeight,
            width: width + d.width,
            height: height + d.height,
          });
          onResize({
            id,
            width: deScale.width,
            height: deScale.height,
          });
          setIsBeingReposition(null);
        }}
      >
        <LocalVideoFeed
          localParticipant={localParticipant}
          onAction={onRemove}
          width={width}
          height={height}
          z={z}
          onActionText="Leave"
          showAction={editable}
          className="w-full h-full cursor-move"
          muted={sessions[localParticipant?.identity]?.muted}
          ratio={ratio}
          setRatio={(value) => changeRatio(id, value)}
          onMute={() =>
            mute(localParticipant, sessions[localParticipant.identity]?.muted)
          }
          session={mySession}
          isLive={isLive}
          activeScene={activeScene}
          currentScene={currentScene}
          currentViewType={currentViewType}
          isVisible={isVisible}
          activeLayer={activeSublayer}
          setActiveLayer={setActiveSublayer}
          semiActiveLayer={semiActiveSublayer}
          setSemiActiveLayer={setSemiActiveSublayer}
          parentSelect={onClick}
          parentLocked={parentLocked}
          setParentLocked={!isAutoGen && setParentLocked}
          activeSpeaker={activeSpeaker}
        />
        {selected && <div className="absolute flex flex-col top-0 right-0 w-16 h-8 z-1500">
          <div>
            <Button
              className="w-8"
              onClick={(e) => {
                e.stopPropagation();
                handleBringForward({id, z});
              }}
            >
              <SortAscendingIcon className="w-4 h-4" />
              {/* Bring Forward */}
            </Button>
            <Button
              className="w-8"
              onClick={(e) => {
                e.stopPropagation();
                handleBringBack({id, z});
              }}
            >
              <SortDescendingIcon className="w-4 h-4" />
              {/* Send Back */}
            </Button>
          </div>
          <span className="text-red-500 font-bold">level: {z}</span>
        </div>}
      </Resizable>
    </Draggable>
  );
};

export default LocalStreamLayer;
