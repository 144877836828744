import React, { useEffect, useState, useRef } from "react";
import Button from '../button';
import { ReactComponent as DoubleArrow } from "../../icons/double-arrow.svg";
import { ReactComponent as Rotate } from "../../icons/rotate.svg";
import { ReactComponent as Pause } from "../../icons/pause.svg";

const DEFAULT_INTERVAL = 300;
const dy = 2;

const Control = ({
	isHost,
	teleprompter,
	updateStatus,
	settleCurrentParagraph,
	updateInterval
}) => {
	const scrollRef = useRef();
	const scrollTimer = useRef();
	const scrollAnimation = useRef();
	const autoscrollRef = useRef();
	const manualScrollRef = useRef();

	useEffect(() => {
		const _animation = document.getAnimations();
		if (_animation && _animation.length > 0) {
			_animation[0].cancel();
		}
	}, []);

	useEffect(() => {
		if (teleprompter?.status) {
			if (teleprompter.status.autoScroll) {
				// scrollRef.current.scrollTop = (scrollRef.current.scrollHeight - scrollRef.current.offsetHeight) * teleprompter.status.currentScroll;
				scrollTeleprompter();
			} else if (!isHost) {
				clearInterval(scrollTimer.current);
				scrollRef.current.scrollTop = (scrollRef.current.scrollHeight - scrollRef.current.offsetHeight) * teleprompter.status.currentScroll;
			}
		}
	}, [teleprompter]);

	useEffect(() => {
		scrollRef.current.scrollTop = -(autoscrollRef.current.getBoundingClientRect().top - scrollRef.current.getBoundingClientRect().top);
		if (scrollAnimation.current) {
			scrollAnimation.current.cancel();
			scrollAnimation.current = autoscrollRef.current.animate([{
				transform: `translateY(-${scrollRef.current.scrollHeight - scrollRef.current.offsetHeight}px)`,
			}], {
				duration: teleprompter.status.scrollInterval * 1000,
				iterations: Infinity,
			});
		}
	}, [teleprompter?.status?.scrollInterval]);

	const scrollTeleprompter = async () => {
		if (scrollTimer.current) {
			clearInterval(scrollTimer.current);
		}
		if (teleprompter?.status?.autoScroll && !teleprompter?.status?.pause) {
			
			scrollTimer.current = setInterval(() => {
				if (!autoscrollRef.current) {
					clearInterval(scrollTimer.current);
					return;
				}
				
				if (autoscrollRef.current.getBoundingClientRect().top < 70 - (scrollRef.current.scrollHeight - scrollRef.current.offsetHeight)) {
					scrollAnimation.current.cancel();
					scrollRef.current.scrollTop = 0;
					autoscrollRef.current.style.top = 0;
					scrollAnimation.current = autoscrollRef.current.animate([{
						transform: `translateY(-${scrollRef.current.scrollHeight - scrollRef.current.offsetHeight}px)`,
					}], {
						duration: teleprompter.status.scrollInterval * 1000,
						iterations: Infinity,
					});
				}
			}, teleprompter.status.scrollInterval);
		}

		if (scrollAnimation.current) {
			if (teleprompter?.status?.pause)
				scrollAnimation.current.pause();
			else scrollAnimation.current.play();
			return;
		}
		if (!teleprompter?.status?.pause) {
			scrollAnimation.current = autoscrollRef.current.animate([{
				transform: `translateY(-${scrollRef.current.scrollHeight - scrollRef.current.offsetHeight}px)`,
			}], {
				duration: teleprompter.status.scrollInterval * 1000,
				iterations: Infinity,
			});
		}
	}

	const handleOnScroll = (e) => {
		if (teleprompter.status.autoScroll === 0 && isHost) {
			updateStatus({
				...teleprompter.status,
				currentScroll: scrollRef.current.scrollTop / (scrollRef.current.scrollHeight - scrollRef.current.offsetHeight)
			});
		}
	};

	const handleAutoScroll = (e) => {
		clearInterval(scrollTimer.current);
		if (teleprompter?.status?.autoScroll) {
			// if (teleprompter?.status?.pause)
			// 	scrollAnimation.current.play();
			// else
			// 	scrollAnimation.current.pause();
			updateStatus({
				...teleprompter?.status,
				pause: !teleprompter?.status?.pause,
			})
		} else {
			
			// scrollAnimation.current.play();
			autoscrollRef.current.style.top = `-${scrollRef.current.scrollTop}`;
			updateStatus({
				...teleprompter.status,
				autoScroll: 1,
				pause: false,
				isSingleParagraph: 0,
				scrollInterval: DEFAULT_INTERVAL,
				timestamp: new Date().getTime(),
			})
		}
		
	};

	const handleSlower = async (e) => {
		
		await updateStatus({
			...teleprompter.status,
			scrollInterval: teleprompter.status.scrollInterval + 30,
		});
		scrollTeleprompter();
	};

	const handleFaster = async (e) => {
		await updateStatus({
			...teleprompter.status,
			scrollInterval: Math.max(teleprompter.status.scrollInterval - 30, 10),
		});
		scrollTeleprompter();
	};

	const handleSingleParagraph = (e) => {
		clearInterval(scrollTimer.current);
		updateStatus({
			...teleprompter.status,
			autoScroll: 0,
			currentParagraph: 0,
		});
	};

	const handleSettleParagraph = (index) => {
		if (!teleprompter?.status?.autoScroll) {
			updateStatus({
				...teleprompter.status,
				isSingleParagraph: 1,
				currentParagraph: index,
			});
		}
	};

  return (
		<div className="relative h-full flex flex-col">
			<div
				id="view_container"
				ref={scrollRef}
				onScroll={handleOnScroll}
				className={`${(isHost && !teleprompter?.status?.autoScroll) ?"overflow-y-scroll":"overflow-hidden"} 
					 pt-5 pl-2 pr-2 pb-2 h-full text-lg relative ${isHost?"border-b border-gray-500":""}`}
			>
				<div
					ref={manualScrollRef}
					className={`${teleprompter?.status?.autoScroll ? "hidden" : "block"}`}
				>
					{teleprompter?.text?.split('\n').map((_text, idx) => _text && (
						<p
							key={idx}
							className={`p-1 mt-2 bg-none rounded z-10  cursor-pointer hover:bg-yellow-300 
								${(!teleprompter?.status?.isSingleParagraph || teleprompter?.status?.currentParagraph === idx) ? "block": "hidden"}`}
							onClick={() => handleSettleParagraph(idx)}
						>
							{_text}
						</p>
					))}
					{isHost && <p className="h-32"></p>}
				</div>
				<div
					id="autoscroll"
					ref={autoscrollRef}
					className={`absolute bg-none z-20 top-0 ${isHost ? "pr-6" : "pr-2"} ${teleprompter?.status?.autoScroll ? "block" : "hidden"}`}>
					{teleprompter?.text?.split('\n').map((_text, idx) => _text && (
						<p
							key={idx}
							className={`p-1 mt-2 bg-none rounded w-full
								${(!teleprompter?.status?.isSingleParagraph || teleprompter?.status?.currentParagraph === idx) ? "block": "hidden"}`}
						>
							{_text}
						</p>
					))}
					{isHost && <p className="h-32"></p>}
				</div>
				{/* {teleprompter?.text?.split('\n').map((_text, idx) => _text && (
					<p
						key={idx}
						className={`p-1 mt-2 bg-gray-100 rounded cursor-pointer hover:bg-yellow-300 
						 ${teleprompter?.status?.isSingleParagraph && teleprompter?.status?.currentParagraph === idx ? 'bg-yellow-500':''}`}
						onClick={() => handleSettleParagraph(idx)}
					>
						{_text}
					</p>
				))}
				{isHost && <p className="h-32"></p>} */}
			</div>
			{isHost && <div className="relative flex flex-col items-center justify-center bottom-0 w-full z-30">
				<div className="flex flex-row justify-around w-full mt-1 overflow-x-hidden">
					<Button
						onClick={handleSlower}
						className="h-6 bg-green-400 border-none items-center justify-center text-black hover:text-blue-500"
					>
						<DoubleArrow className="w-3 h-3 mr-1" style={{ transform: 'rotate(270deg'}} />
						Slower
					</Button>
					<Button
						onClick={handleAutoScroll}
						className={`h-6 bg-green-400 border-none flex items-center justify-center break-words items-center text-black hover:text-blue-500
							${teleprompter?.status?.autoScroll?"border-yellow-500":""}`}
					>
						{teleprompter?.status?.autoScroll && !teleprompter?.status?.pause ? <><Pause className="mr-1 w-3 h-3" />Pause</>
						: <><Rotate className="mr-1" width={16} height={16} />Auto Scroll</>}
					</Button>
					<Button
						onClick={handleFaster}
						className="h-6 bg-green-400 border-none items-center justify-center text-black hover:text-blue-500"
					>
						Faster
						<DoubleArrow className="w-3 h-3 ml-1" style={{ transform: 'rotate(90deg'}} />
					</Button>
				</div>
				<Button
					onClick={handleSingleParagraph}
					className={`w-18 h-6 bg-green-400 border-none -mb-3 text-black hover:text-blue-500 ${teleprompter?.status?.isSingleParagraph?"border-yellow-500":""}`}
				>
					<span>Single Paragraph</span>
				</Button>
			</div>}
		</div>
	)
};

export default Control;
