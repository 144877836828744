import { Flex, Button } from "@100mslive/react-ui";
import PersonsList from "./personsList";
import UploadPersons from "./uploadPersons";
import { ReactComponent as ChevronDownIcon } from "../../../icons/chevron-down.svg";

const Users = ({
  persons,
  loading,
  addPerson,
  updatePerson,
  removePerson,
  selectedAddingUsers,
  setSelectedAddingUsers,
  addToGroup,
  confirmMove,
  removeFromGroup,
  openConfigSave,
}) => {
  return (
    <Flex 
      direction="row"
      css={{
        position: 'relative',
        w: 'calc(50% + 50px)',
        h: '100%'
      }}
    >
      <Flex
        direction="column"
        css={{
          w: 'calc(100% - 130px)'
        }}
      >
        <PersonsList 
          persons={persons}
          updatePerson={updatePerson}
          removePerson={removePerson}
          selectedAddingUsers={selectedAddingUsers}
          setSelectedAddingUsers={setSelectedAddingUsers}
        />
        <UploadPersons addPerson={addPerson} />
      </Flex>
      <Flex
        direction="column"
        justify="center"
        css={{
          h: '66%',
          ml: '$7'
        }}
      >
        <Button
          css={{
            // bg: '#6b7280',
            border: 'none',
            borderRadius: '$0',
            w: '100px',
            h: '25px',
            mb: '$2',
            fontSize: '$sm'
          }}
          onClick={() => {
            addToGroup();
          }}
        >
          Add
          <ChevronDownIcon className="w-5 h-5 -rotate-90 -ml-1" />
        </Button>
        <Button
          css={{
            // bg: '#6b7280',
            border: 'none',
            borderRadius: '$0',
            w: '100px',
            h: '25px',
            mb: '$15',
            fontSize: '$sm'
          }}
          onClick={() => removeFromGroup()}
        >
          <ChevronDownIcon className="w-5 h-5 rotate-90 -mr-1" />
          Remove
        </Button>
        <Button
          css={{
            // bg: '#6b7280',
            border: 'none',
            borderRadius: '$0',
            w: '100px',
            h: '25px',
            mb: '$2',
            fontSize: '$sm'
          }}
          onClick={() => confirmMove()}
        >
          Move Users
        </Button>
        <Button
          css={{
            // bg: '#6b7280',
            border: 'none',
            borderRadius: '$0',
            w: '100px',
            h: '50px',
            fontSize: '$sm'
          }}
          onClick={() => openConfigSave()}
        >
          <Flex direction="column">
            <span>Save</span> <span>Configuration</span>
          </Flex>
        </Button>
      </Flex>
    </Flex>
  )
};

export default Users;
