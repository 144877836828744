import React, { useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import Button from "../components/button";
import { FirebaseContext } from "../helpers/contexts/firebaseContext";
import { getFirestore, collection, query, where, updateDoc,
  doc, getDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore";
// import { useFirebase } from "../helpers/hooks/useFirebase";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { ReactComponent as UserAddIcon } from "../icons/user-add.svg";

const AddAllGuests = () => {
  const { conferenceId } = useParams();
  // const firebase = useFirebase();
  // const firestore = useMemo(() => firebase.firestore(), [firebase]);


  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);

  const queueRef = query(collection(doc(collection(db, "conferences"), conferenceId ?? "defaultConference"), "participants"),
    where("canJoin", "==", false),
    where("deny", "==", false)
  );

  const [data, loading] = useCollectionQuery(queueRef, { idField: "id" });

  const handleAcceptAll = () => {
    if (!loading && data?.length) {
      data.forEach((participant) => {
        updateDoc(collection(doc(collection(db, "conferences"), conferenceId ?? 'default'), "participants", participant.id ?? 'default'), {
          canJoin: true,
        });
      });
    }
  };

  return (
    <button
      className="flex items-center mb-1 text-gray-100 text-sm"
      disabled={loading || !data || !data?.length}
      onClick={handleAcceptAll}
    >
      <UserAddIcon className="h-4 w-4 mr-2 text-gray-700" /> Add All Guests
    </button>
  );
};

export default AddAllGuests;
