
import React, { useState, useEffect } from "react";
import Button from "../../components/button";
import {
  CrossIcon,
  PlayCircleIcon,
  PauseCircleIcon,
  TrashIcon,
} from "@100mslive/react-icons";
import {
  selectVideoPlaylist,
  useHMSStore
} from "@100mslive/react-sdk";
import RangeBar from "../../components/RangeBar";
import { timeFormat } from '../../utils/timeFormat';

export const VideoList = ({
  medias,
  curTab,
  handlePlayMedia,
  handlePauseMedia,
  handleAddToScene,
  deleting,
  handleDelete,
  updateTitle,
  activeVideo,
  handleSeekTo,
}) => {
  const videoProgress = useHMSStore(selectVideoPlaylist.progress);
  const [videoStarting, setVideoStarting] = useState(false);

  useEffect(() => {
    setVideoStarting(false);
  }, [activeVideo, activeVideo?.playing]);

  const handleTogglePlay = (e) => {
    const mediaId = e.currentTarget?.id ?? e.target.id;
    if (activeVideo?.id === mediaId?.split('a76')[1] && activeVideo?.playing) {
      handlePauseMedia(e);
    } else {
      handlePlayMedia(e);
      setVideoStarting(mediaId?.split('a76')[1]);
    }
  };

  return (
    <>
      {medias && medias.filter(media => media.type === curTab)?.map((media, idx) => (
        <div
          key={idx}
          style={{margin: '5px'}}
          className={`relative rounded-lg border-2 border-purple-500`}
        >
          <video
            id={'a76' + media.id}
            src={media.url}
            className="video-thumb rounded-lg bg-black w-full h-200"
            muted
            autoPlay={false}
            loop
            onMouseOver={(e) => {
              e.target.play()
            }}
            onMouseLeave={(e) => {
              e.target.pause()
              e.target.currentTime = 3;
            }}
          />
          <input
            id={'a76' + media.id}
            type='text'
            value={media.title}
            onChange={(e) => {updateTitle(e.target.id.split('a76')[1], e.target.value)}}
            style={{position: 'absolute', left: 0, top: 0, background: 'black', width: '100%', height: '20px', color: 'white', border: 'none'}}
          />
          <label id={'a76' + media.id} style={{position: 'absolute', right: 5, top: 3, background: 'black', height: '16px', color: 'white', fontSize: '12px', padding: 0}}>
            {media && media.duration > 0 ? `${Math.round(media.duration)} secs`: 'loading'}
          </label>
          <div className="absolute bottom-0 w-full h-6 rounded-b-lg bg-purple-800 flex justify-between">
            <div className="flex items-center">
              <button
                id={'a76' + media.id}
                className="bg-none text-white"
                onClick={handleTogglePlay}
              >
                {(activeVideo?.id !== media.id || !activeVideo?.playing) && videoStarting !== media.id && 
                  <PlayCircleIcon id={'a76' + media.id} />
                }
                {(activeVideo?.id !== media.id || !activeVideo?.playing) && videoStarting === media.id && 
                  <div className="w-5 h-5">
                    <div className="uploading-spinner"></div>
                  </div>
                }
                {activeVideo?.id === media.id && activeVideo?.playing && 
                  <PauseCircleIcon id={'a76' + media.id} />
                }
              </button>
              {activeVideo?.id === media.id &&
                <div className="flex w-full items-center">
                  <RangeBar
                    getNumber={handleSeekTo}
                    width="100%"
                    backgroundColor="gray"
                    fillColor="blue"
                    fillSecondaryColor="blue"
                    headColor="white"
                    headShadow="white"
                    headShadowSize={6}
                    progress={videoProgress}
                    mousePosition={(position, width) => {timeFormat(activeVideo?.duration / width * position * 1000)}}
                  />
                </div>
              }
            </div>
            <div className="flex flex-end">
              <button
                id={'a76' + media.id}
                className="bg-none text-white"
                onClick={handleAddToScene}
              >
                <CrossIcon className="rotate-45" id={'a76' + media.id} />
              </button>
              <button
                id={'a76' + media.id}
                className="bg-none text-white"
                onClick={handleDelete}
              >
                {deleting === media.id && 
                  <div className="w-5 h-5">
                    <div className="uploading-spinner"></div>
                  </div>
                }
                {deleting !== media.id && <TrashIcon id={'a76' + media.id} />}
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  )
};

export default VideoList;
