import {
  useState,
  useContext,
  useEffect
} from "react";
import { FirebaseContext } from "../../../helpers/contexts/firebaseContext";
import { getFirestore, doc, collection, setDoc, updateDoc, deleteDoc } from "@firebase/firestore";
import Users from "./users";
import Rooms from "./rooms";
import { useCollectionQuery } from "../../../helpers/hooks/useFirebaseCollection";
import { Flex } from "@100mslive/react-ui";
import { nanoid } from "nanoid";
import SaveConfigModal from "./saveConfigModal";
import { roomCreate, roomToggleEnable } from "../../../features/services/roomService";

const Director = ({
  isShow=false,
}) => {
  const { user, firebaseApp } = useContext(FirebaseContext);
  const db = getFirestore(firebaseApp);
  const docRef = doc(collection(db, 'profiles'), user?.uid ?? 'defaultID');
  const [persons, personsLoading] = useCollectionQuery(collection(docRef, 'persons'));
  const [groups, groupsLoading] = useCollectionQuery(collection(docRef, 'groups'));
  const [configs, configsLoading] = useCollectionQuery(collection(docRef, 'configs'));
  const [copiedPersons, setCopiedPersons] = useState([]);
  const [copiedGroups, setCopiedGroups] = useState([]);
  const [selectedAddingUsers, setSelectedAddingUsers] = useState([]);
  const [selectedRemoveUsers, setSelectedRemoveUsers] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const [isSaveConfigModal, setIsSaveConfigModal] = useState(false);

  useEffect(() => {
    let _persons = persons?.filter(person => !copiedGroups?.some(group => group?.users?.some(user => user.id === person.id)));
    setCopiedPersons(_persons);
  }, [persons, copiedGroups]);

  useEffect(() => {
    setCopiedGroups(previous => {
      let temp = previous?.filter(preGroup => groups?.some(group => group.id === preGroup.id)) ?? [];
      let adding = groups?.filter(group => !temp?.some(preGroup => preGroup.id === group.id)) ?? [];
      temp = [...temp, ...adding];
      return temp;
    });
  }, [groups]);

  const addPerson = async (person) => {
    const id = nanoid();
    await setDoc(doc(collection(docRef, 'persons'), id), {
      id,
      firstName: person?.firstName ?? '',
      lastName: person?.lastName ?? '',
      email: person?.email ?? '',
      phoneNumber: person?.phoneNumber ?? '',
      meta1: person?.meta1 ?? '',
      meta2: person?.meta2 ?? '',
      meta3: person?.meta3 ?? ''
    })
  }

  const updatePerson = (person) => {
    if (person?.id) {
      updateDoc(doc(collection(docRef, 'persons'), person?.id), {
        id: person?.id,
        firstName: person?.firstName ?? '',
        lastName: person?.lastName ?? '',
        email: person?.email ?? '',
        phoneNumber: person?.phoneNumber ?? '',
        meta1: person?.meta1 ?? '',
        meta2: person?.meta2 ?? '',
        meta3: person?.meta3 ?? ''
      })
    }
  }

  const removePerson = (id) => {
    deleteDoc(doc(collection(docRef, 'persons'), id))
  }

  const addGroup = async (group) => {
    await roomCreate({
      name: group?.name, 
      roomType: group?.type, 
      firebaseApp, 
      userId: user?.uid
    });
  }

  const updateGroup = (group) => {
    if (group?.id) {
      updateDoc(doc(collection(docRef, 'groups'), group?.id), {
        id: group?.id,
        name: group?.name ?? '',
        type: group?.type ?? '',
        users: group?.users ?? []
      })
    }
  }

  const removeGroup = async (id) => {
    deleteDoc(doc(collection(docRef, 'groups'), id));
    await roomToggleEnable(id, false);
    await deleteDoc(doc(collection(db, "conferences"), id));
  }

  const addToGroup = async () => {
    if (selectedGroup) {
      setCopiedGroups(previous => {
        return previous.map(group => {
          if (group.id !== selectedGroup)
            return group;
          return {
            ...group,
            users: [...group.users, ...selectedAddingUsers]
          }
        })
      })
      setCopiedPersons(previous => previous?.filter(person => !selectedAddingUsers?.some(user => user.id === person.id)));
      setSelectedAddingUsers([]);
    }
  }

  const removeFromGroup = () => {
    setCopiedGroups(previous => {
      return previous?.map(group => {
        return {
          ...group,
          users: group?.users?.filter(user => !selectedRemoveUsers?.some(rUser => rUser.id === user.id))
        }
      })
    })
    setCopiedPersons(previous => {
      return [...previous, ...selectedRemoveUsers];
    })
    setSelectedRemoveUsers([]);
  }

  const confirmMove = () => {
    copiedGroups?.forEach(group => {
      updateDoc(doc(collection(docRef, 'groups'), group.id), {
        users: group.users
      })
    })
  }

  const handleSaveConfig = async (configName) => {
    const id = nanoid();
    await setDoc(doc(collection(docRef, 'configs'), id), {
      id,
      name: configName,
      data: copiedGroups,
    })
  }

  const loadConfig = (id) => {
    if (!id) return;
    setCopiedGroups(configs?.find(config => config.id === (id ?? configs?.[0]?.id))?.data)
  }

  const removeConfig = (id) => {
    deleteDoc(doc(collection(docRef, 'configs'), id ?? configs?.[0]?.id));
  }

  const handleRefreshGroups = () => {
    setCopiedGroups(groups)
  }

  return (
    <div
      className={`${isShow ? "flex" : "hidden"} absolute bg-white left-0 top-0 px-4 py-2 w-full h-full`}
    >
      <Users
        persons={copiedPersons}
        loading={personsLoading}
        addPerson={addPerson}
        updatePerson={updatePerson}
        removePerson={removePerson}
        selectedAddingUsers={selectedAddingUsers}
        setSelectedAddingUsers={setSelectedAddingUsers}
        addToGroup={addToGroup}
        confirmMove={confirmMove}
        removeFromGroup={removeFromGroup}
        openConfigSave={() => setIsSaveConfigModal(true)}
      />
      <Rooms
        groups={copiedGroups}
        setCopiedGroups={setCopiedGroups}
        loading={groupsLoading}
        addGroup={addGroup}
        updateGroup={updateGroup}
        removeGroup={removeGroup}
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
        selectedRemoveUsers={selectedRemoveUsers}
        setSelectedRemoveUsers={setSelectedRemoveUsers}
        configs={configs}
        loadConfig={loadConfig}
        removeConfig={removeConfig}
        handleRefreshGroups={handleRefreshGroups}
      />
      <SaveConfigModal      
        isSaveConfigModal={isSaveConfigModal}
        setIsSaveConfigModal={setIsSaveConfigModal}
        handleSaveConfig={handleSaveConfig}
      />
    </div>
  )
};

export default Director;
