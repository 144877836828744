import React from 'react';
import Participant from "../components/rtc/participant";
import VideoFeed from "../components/rtc/videoFeed";
import VideoContainer from "../components/videoContainer";
import SubLayers from "./subLayers";

const ParticipantVideoFeed = ({
  session,
  participant,
  onAction,
  width,
  height,
  z,
  onActionText = "Go Live",
  onClose = () => {},
  onMute = () => {},
  className,
  disableAudio = false,
  showAction = false,
  ratio,
  setRatio,
  isLive,
  ...props
}) => {
  return (
    <VideoFeed
      onAction={onAction}
      onActionText={onActionText}
      className={className}
      showAction={showAction}
      isOverloaded={session?.live && session?.isOverloaded && !isLive}
      {...props}
    >
      <VideoContainer
        onClose={onClose}
        onMute={onMute}
        key={participant.sid}
        identity={participant.identity}
        session={session}
        guestName={session?.name}
        muted={session?.muted}
        showAdminCommands={showAction}
        width={width}
        height={height}
        ratio={ratio}
        setRatio={setRatio}
        isLive={isLive}
        activeSpeaker={props?.activeSpeaker}
        peerId={participant?.peerId}
      >
        <Participant
          width={width}
          height={height}
          z={z}
          participant={participant}
          disableAudio={disableAudio}
        />

        {props?.activeScene && <SubLayers 
          id={participant?.identity}
          activeScene={props?.activeScene}
          editable={showAction}
          currentViewType={props?.currentViewType}
          viewWidth={width}
          viewHeight={height}
          isVisible={props?.isVisible}
          activeLayer={props?.activeLayer}
          setActiveLayer={props?.setActiveLayer}
          semiActiveLayer={props?.semiActiveLayer}
          setSemiActiveLayer={props?.setSemiActiveLayer}
          parentSelect={props?.parentSelect}
          participant={participant}
        />}
      </VideoContainer>
    </VideoFeed>
  );
};

export default ParticipantVideoFeed;
