import { useState } from "react";

function JoinForm() {
  const [inputValues, setInputValues] = useState({
    name: ""
  });

  const handleInputChange = (e) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  const joinConference = (e) => {
    e.preventDefault();
    window.location.href = `/${inputValues.name}`
  };

  return (
    <form>
      <h2>Join Room</h2>
      <div className="input-container">
        <input
          required
          value={inputValues.name}
          onChange={handleInputChange}
          id="name"
          type="text"
          name="name"
          placeholder="Your name"
        />
      </div>
      <button 
				className="btn-primary"
        onClick={joinConference}
			>
				Join Room
			</button>
    </form>
  );
}

export default JoinForm;