import React from "react";
import axios from "axios";
import {
	Button, Tooltip
} from "@100mslive/react-ui";

import {
	useDispatch, useSelector
} from "react-redux";

import {
	InformationIcon
} from "@100mslive/react-icons"

import { 
  setError, 
  setLoading, 
  setChannelId, 
  setAccessToken,
  setBroadcastId,
  setIsTestingBC,
} from '../../../helpers/redux/reducers/youtubeRtmpReducer';
import { ReactComponent as CloseIcon } from "../../../icons/x-circle.svg";
import LoadingSpinner from "../../loadingSpinner";

const ToolTipText = () => {
	return (
		<div>
			Navigate to your YouTube.
		</div>
	)
}

const TestBCFlow = () => {

  	// const baseInfoUrl = 'http://localhost:5000/youtube_video_info';
	const baseInfoUrl = 'https://videosplitor.voodoocast.io/youtube_video_info';

	const [broadcasts, setBroadcasts] = React.useState();

	const {
		isTestingBC,
		accessToken,
		error,
		channelId,
		broadcastId,
		monitorStream,
	} = useSelector(state => state.youtubeRtmp);

	const [isInitiating, setIsInitiating] = React.useState(true);

	const [isConfirming, setIsConfirming] = React.useState(false);

	const [isNowLive, setIsNowLive] = React.useState(false);

	const dispatch = useDispatch();

	const waitSomeDelay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

	React.useEffect(() => {
		const test = async () => {
			const transitionTestResponse = await axios.get(`${baseInfoUrl}/transition_broadcast?access_token=${accessToken}&broadcast_id=${broadcastId}&status=testing`);
			console.log({transitionTestResponse})
			if (!transitionTestResponse?.data?.success) {
				dispatch(setIsTestingBC(new Date().getTime()))
			} else {
				await waitSomeDelay(2000)
				setIsConfirming(Date.now());
				setIsInitiating(false)
			}
		}
		if (isTestingBC && monitorStream) {
			test();
		}
	}, [isTestingBC, monitorStream])

	React.useEffect(() => {
		const confirm = async () => {
			if (isConfirming) {
				const transitionLiveResponse = await axios.get(`${baseInfoUrl}/transition_broadcast?access_token=${accessToken}&broadcast_id=${broadcastId}&status=live`);
				console.log({transitionLiveResponse})
				if (transitionLiveResponse?.data?.success) {
					setIsConfirming(false);
					setIsNowLive(true);
				} else {
					await waitSomeDelay(2000);
					setIsConfirming(Date.now());
				}
			}
		}
		confirm();
	}, [isConfirming])

	const handleTest = async (e) => {
		const transitionTestResponse = await axios.get(`${baseInfoUrl}/transition_broadcast?access_token=${accessToken}&broadcast_id=${broadcastId}&status=testing`);
		console.log({transitionTestResponse})
	}

	const handleGoLive = async (e) => {
		const transitionLiveResponse = await axios.get(`${baseInfoUrl}/transition_broadcast?access_token=${accessToken}&broadcast_id=${broadcastId}&status=live`);
      	console.log({transitionLiveResponse})

		dispatch(setIsTestingBC(false))
	}

	const handleClose = e => {
		dispatch(setIsTestingBC(false))
		setIsNowLive(false);
	}

	return (
		<>
			{isTestingBC &&
				<div 
					className="fixed bg-black text-white rounded-lg shadow p-3 right-0 top-20 z-5000 min-w-400"
					id="testing_bc"
				>
					<button
						className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
						onClick={handleClose}
					>
						<CloseIcon className="text-blue-300  h-6 w-6 text-2xl block outline-none focus:outline-none cursor-pointer hover:text-red-400" />
					</button>
					{error?.message &&
						<>
							<p>
								{error.message}
							</p>
						</>
					}

					{/* <div className="flex">
						<Button
							onClick={handleTest}
						>
							Test
						</Button>

						<Button
							onClick={handleGoLive}
						> Go Live </Button>
					</div> */}
					
					{(isInitiating || isConfirming) && 
						<div>
							<p>
								Preparing live stream to your youtube. This will take a few seconds...
							</p>
							<LoadingSpinner />
						</div>
					}
					{/* {!isInitiating && <div dangerouslySetInnerHTML={{ __html: monitorStream }} />} */}

					{isNowLive && <p>
						You can watch the live broadcast using this link. 
						<a href={`https://www.youtube.com/watch?v=${broadcastId}`} target="_blank">
							{`https://www.youtube.com/watch?v=${broadcastId}`}
						</a>
					</p>}
				</div>
			}
		</>
	)
};

export default TestBCFlow;
