import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "../components/button";
import Modal from "../components/modal";
import InputField from "../components/forms/inputField";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import roomTypes from "./humanResource/roomTypes.json";

const CreateConferenceModal = ({
  isOpen,
  onClose,
  onSubmit,
  title = "Create Conference",
  name = "",
  rtmpUrl = "",
  scheduledDate,
}) => {
  const [error, setErr] = useState('');
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      name: '',
      roomType: roomTypes?.[3]?.type,
    },
  });

  const [loading, setLoading] = useState(false);

  const _onSubmit = async (data) => {
    setLoading(true);
    try {
      await onSubmit(data);
    } catch (err) {
      setErr('Something Error!');
      setTimeout(() => {
        setErr('');
      }, 3000)
    }
    setLoading(false);
  }

  return (
    <Modal isOpen={isOpen} closeModal={onClose} className="w-1/4" isHeader={false} isFooter={false} isEnabledOutsideClick={!loading}>
      <div className="custom-bg-1 w-full p-2 rounded-t-md flex items-center">
        <h2 className="font-medium text-xl text-gray-100">{title}</h2>
      </div>
      <div className="p-4">
        <form
          // onSubmit={handleSubmit(onSubmit)}
          onSubmit={handleSubmit(_onSubmit)}
          className="flex flex-col gap-2 p-4"
        >
          {error && <span className="text-red-500">{error}</span>}
          <InputField
            id="name"
            label="Name"
            className="w-full"
            labelStyle="text-gray-900"
            {...register('name', { required: true })}
            required
          />
          <span>Room Type</span>
          <select
            className="w-28 h-8 rounded-lg bg-gray-900 text-white"
            defaultValue={roomTypes?.[3]?.type}
            {...register('roomType', { required: true })}
            // onChange={(e) => {
            //   setAddingRoom(previous => {
            //     return {
            //       ...previous,
            //       type: e.target.value,
            //     }
            //   })
            // }}
          >
            {roomTypes?.map((roomType, idx) => (
              <option key={idx} value={roomType?.type}>{roomType?.label}</option>
            ))}
          </select>

          <div className="flex flex-row-reverse mt-4">
            <Button
              className="rounded bg-gray-500 text-gray-100 w-24"
              type="submit"
              disabled={loading}
            >
              {!loading && 'Submit'}
              {loading &&
                <div className="w-5 h-5">
                  <div className="uploading-spinner"></div>
                </div>
              }
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateConferenceModal;
