import React, { useEffect, useMemo } from "react";
import { ReactComponent as XCircleIcon } from "../../icons/x-circle.svg";
import Select from "../select1";
import Video from "./video";
import Modal from "../modal";

import { 
  useDevices,
  DeviceType,
  useHMSActions
} from "@100mslive/react-sdk";
import { useConferenceCall } from "../../helpers/hooks/useConferenceCall";

const DeviceSelection = ({ 
  isOpen,
  message = "",
  onClose = () => {},
  setMessage = () => {}
}) => {
  const { localParticipant } = useConferenceCall();
  const { allDevices, selectedDeviceIDs, updateDevice } = useDevices();
  const { videoInput: _videoInput, audioInput, audioOutput } = useMemo(() => allDevices, [allDevices]);

  const videoInput = useMemo(() => ([
    ...(_videoInput ?? []), {
      deviceId: 'none',
      label: 'None',
      kind: DeviceType.videoInput
    }
  ]), [_videoInput])

  const hmsActions = useHMSActions();

  useEffect(() => {
    const cachedData = window.localStorage.getItem('hms-device-selection');
    let cachedId = '';
    try {
      cachedId = JSON.parse(cachedData);
    } catch (err) {
      // console.log(err);
    }

    if (!selectedDeviceIDs?.videoInput && selectedDeviceIDs?.videoInput !== 'none') {
      updateDevice({
        deviceId: cachedId?.videoInput ?? _videoInput?.[0]?.deviceId,
        deviceType: DeviceType.videoInput
      })
    }
  }, [_videoInput, updateDevice, selectedDeviceIDs?.videoInput])

  return (
    <Modal
      isOpen={isOpen}
      closeModal={onClose}
      isHeader={false}
      isFooter={false}
    >
      <div className="flex flex-col">
        <div className="absolute top-0 left-0 z-40 h-full w-full custom-bg-2 rounded-lg opacity-50 shadow-lg">
          &nbsp;
        </div>
        <div
          className="rounded-lg w-full custom-bg-2 flex flex-col gap-3 p-4 relative z-3000"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="bg-white absolute rounded-lg border border-gray-200 w-5 h-5 flex justify-center items-center -top-2 -right-2 shadow cursor-pointer text-gray-700 hover:text-gray-500"
            onClick={() => onClose && onClose()}
          >
            <XCircleIcon className="w-5 h-5" />
          </div>
          
            <>
              {message && <div className="text-red mt-2 -mb-10 flex items-center">
                <p className="text-red-700 text-center">{message}</p>
              </div>}
              <div className="flex justify-center max-w-500">
                <Video
                  peer={localParticipant}
                  muted
                  autoPlay
                  className="rounded-md shadow-md"
                />
              </div>
              <div>
                <Select
                  title="Camera"
                  titleClass="text-white"
                  value={selectedDeviceIDs.videoInput}
                  list={videoInput}
                  onChange={async (e) => {
                    await updateDevice({
                      deviceId: e.target.value,
                      deviceType: DeviceType.videoInput
                    })
                    try {
                      if (e.target.value !== 'none')
                        await hmsActions.setLocalVideoEnabled(true);
                    } catch (err) {
                      // console.log(err);
                    }
                  }}
                />
              </div>
              <div>
                <Select
                  title="Microphone"
                  titleClass="text-white"
                  value={selectedDeviceIDs.audioInput}
                  list={audioInput}
                  onChange={(e) =>
                    updateDevice({
                      deviceId: e.target.value,
                      deviceType: DeviceType.audioInput
                    })
                  }
                />
              </div>
              <div>
                <Select
                  title="Speaker"
                  titleClass="text-white"
                  value={selectedDeviceIDs.audioOutput}
                  list={audioOutput}
                  onChange={(e) =>
                    updateDevice({
                      deviceId: e.target.value,
                      deviceType: DeviceType.audioOutput
                    })
                  }
                />
              </div>
            </>
        </div>
      </div>
    </Modal>
  );
};

export default DeviceSelection;
