import React from 'react';
import { createContext, useState, useEffect, useRef, useContext, useMemo, useCallback } from "react";
import setPrototypeOf from "setprototypeof";
import { getFirestore, collection, doc, getDoc, updateDoc, getDocs, deleteDoc, setDoc } from "firebase/firestore";
import { AuthContext } from './userSessionContext';
import { signInAnonymously } from '@firebase/auth';
import { FirebaseContext } from "./firebaseContext";
import { useParams } from 'react-router-dom';
import { compareObject } from "../../utils/compareObject";
import { useCollectionQuery } from '../hooks/useFirebaseCollection';
import { useDocumentQuery } from '../hooks/useFirebaseDocument';

export const LayerEditorContext = createContext({});

export const LayerEditorProvider = ({ children }) => {
  const [isTeleprompter, setIsTeleprompter] = useState();
  const [isBeingReposition, setIsBeingReposition] = useState();
  const [activeLayer, setActiveLayer] = useState();
  const [semiActiveLayer, setSemiActiveLayer] = useState();
  const [activeSublayer, setActiveSublayer] = useState();
  const [semiActiveSublayer, setSemiActiveSublayer] = useState();
  const [textActive, setTextActive] = useState();
  const [scenes, setScenes] = useState();
  const [activeScene, setActiveScene] = useState();
  const editingScene = { id: 'editing_scene', name: 'Editing Scene', layers: [] };
  const [edited, setEdited] = useState(false);
  // const [RTMPUrl, setRTMPUrl] = useState("rtmps://rtmp-global.cloud.vimeo.com:443/live/");
  // const [RTMPKey, setRTMPKey] = useState('7c393fcf-09b3-4d3e-9ba0-93abbc9ba2c4');
  const [RTMPUrl, setRTMPUrl] = useState("");
  const [RTMPKey, setRTMPKey] = useState('');
  // const mediaRecorder = useRef();
  const [mediaRecorder, setMediaRecorder] = useState();
  const [isStart, setStart] = useState();
  const backgroundImg = useRef();
  const drawId = useRef();
  const [sceneLocked, setSceneLock] = useState(false);
  const [recording, setRecording] = useState(null);
  const [localRecording, setLocalRecording] = useState(false);
  const isEditing = useRef();

  const [curDevice, setCurDevice] = useState('desktop');

	const [isISOSetting, setIsISOSetting] = useState();
	const [isoDestination, setISODestination] = useState();
  const [dropboxAccessToken, setDropboxAccessToken] = useState('');
  const [googleAccessToken, setGoogleAccessToken] = useState('');

  const [isImageLayersLoading, setIsImageLayersLoading] = useState(true);

  const [curManualSpeaker, setCurManualSpeaker] = useState();

  const { auth } = useContext(AuthContext);
  const { user } = useContext(FirebaseContext);
  
  useEffect(() => {
    if (!user && auth) {
      setActiveZone('overlay')
      signInAnonymously(auth).then((data) => {
        // console.log('beam user', {user})
      })
    } else {
      // console.log('host user', {user})
    }
  }, [user, auth]);

  const [activeZone, setActiveZone] = useState(user?.isAnonymous ? 'overlay' : 'foreground');
  const { conferenceId } = useParams();

  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);
  const collectionRef = collection(doc(collection(db, "conferences"), conferenceId ?? "defaultConference"), "scenes");

  const [componentLayers, setComponentLayers] = useState([]);

  const readComponentLayers = useCallback(async () => {
    let temp = [];
    for(let _scene of scenes ?? []) {
      const snapshots = await getDocs(collection(doc(collectionRef, _scene?.id), 'layers'));
      let components = [];
      snapshots.forEach(snapshot => {
        // if (snapshot.data()?.type === 'image') {
          components.push(snapshot.data())
        // }
      })
      temp.push({
        sceneId: _scene?.id,
        components: components,
      })
    }

    // if (!compareObject(componentLayers, temp)) {
    if (componentLayers?.length === 0) {
      setComponentLayers(temp);
    }
  }, [collectionRef, scenes, componentLayers]);

  const { role } = useParams();

  useEffect(() => {
    if (role !== 'hls-viewer')
      readComponentLayers();
  }, [readComponentLayers, role])

  const loadEditingScene = async (collectionRef, id) => {
    const layers = collection(doc(collectionRef, id), 'layers');
    const snap = await getDoc(doc(collectionRef, id));
    if (snap.exists()) {
      updateDoc(doc(collectionRef, 'editing_scene'), {
        background: {...snap.data().background},
        topZIndex: {...snap.data().topZIndex}
      });
    }
    setEdited(false);
    
    const preSnaps = await getDocs(collection(doc(collectionRef, 'editing_scene'), 'layers'));
    const curSnaps = await getDocs(layers);
    
    preSnaps.forEach((snap) => {
      deleteDoc(doc(collection(doc(collectionRef, 'editing_scene'), 'layers'), snap.id));
    });
    curSnaps.forEach((snap) => {
      setDoc(doc(collection(doc(collectionRef, 'editing_scene'), 'layers'), snap.id), {
        ...snap.data()
      })
    })

    return editingScene;
  };

  useEffect(() => {
    const isLocalRecording = window.localStorage.getItem('localRecording');
    const isBroadcasting = window.localStorage.getItem('broadcast');
    setLocalRecording(isLocalRecording === '1');
    setRecording(isBroadcasting === '1');

    const _isISOSetting = window.localStorage.getItem('isISOSetting');
    const _dropboxAccessToken = window.localStorage.getItem('dropboxAccessToken');
    const _isoDestination = window.localStorage.getItem('isoDestination');
    const _googleAccessToken = window.localStorage.getItem('googleAccessToken');
    const _rtmpUrl = window.localStorage.getItem('RTMPUrl');
    const _rtmpKey = window.localStorage.getItem('RTMPKey');

    setIsISOSetting(_isISOSetting === 'true' || _isISOSetting === true);
    setDropboxAccessToken(_dropboxAccessToken);
    setGoogleAccessToken(_googleAccessToken);
    setISODestination({
      kind: _isoDestination
    })
    setRTMPKey(_rtmpKey);
    setRTMPUrl(_rtmpUrl);
  }, []);

  useEffect(() => {
    window.localStorage.setItem('isISOSetting', isISOSetting);
  }, [isISOSetting]);

  useEffect(() => {
    window.localStorage.setItem('isoDestination', isoDestination?.kind);
  }, [isoDestination]);

  useEffect(() => {
    window.localStorage.setItem('dropboxAccessToken', dropboxAccessToken);
  }, [dropboxAccessToken]);

  useEffect(() => {
    window.localStorage.setItem('googleAccessToken', googleAccessToken);
  }, [googleAccessToken]);

  useEffect(() => {
    window.localStorage.setItem('RTMPUrl', RTMPUrl);
    window.localStorage.setItem('RTMPKey', RTMPKey);
    window.localStorage.setItem('rtmp', RTMPUrl + RTMPKey);
  }, [RTMPUrl, RTMPKey]);

  return (
    <LayerEditorContext.Provider
      value={{
        activeLayer,
        setActiveLayer,
        semiActiveLayer,
        setSemiActiveLayer,
        scenes,
        setScenes,
        activeScene,
        setActiveScene,
        editingScene,
        loadEditingScene,
        edited,
        setEdited,
        socket: null,
        RTMPUrl,
        setRTMPUrl,
        RTMPKey,
        setRTMPKey,
        mediaRecorder,
        setMediaRecorder,
        sceneLocked,
        setSceneLock,
        isEditing,
        recording,
        setRecording,
        localRecording,
        setLocalRecording,
        backgroundImg,
        drawId,
        textActive,
        setTextActive,
        isTeleprompter,
        setIsTeleprompter,
        isBeingReposition,
        setIsBeingReposition,
        isISOSetting,
        setIsISOSetting,
        dropboxAccessToken,
        setDropboxAccessToken,
        isoDestination,
        setISODestination,
        googleAccessToken,
        setGoogleAccessToken,
        activeZone,
        setActiveZone,
        curDevice,
        setCurDevice,
        isImageLayersLoading,
        setIsImageLayersLoading,
        componentLayers,
        setComponentLayers,
        readComponentLayers,
        activeSublayer,
        setActiveSublayer,
        semiActiveSublayer,
        setSemiActiveSublayer,
        curManualSpeaker,
        setCurManualSpeaker,
        // activeSubLayers
      }}
    >
      {children}
    </LayerEditorContext.Provider>
  );
};
