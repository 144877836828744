import { Fragment, useCallback, useState, useEffect } from "react";
import {
  selectAppData,
  useHMSActions,
  useHMSStore,
  useRecordingStreaming,
} from "@100mslive/react-sdk";
import { EndStreamIcon, GoLiveIcon, InfoIcon } from "@100mslive/react-icons";
import { Box, Button, Flex, Text, Loading, Tooltip } from "@100mslive/react-ui";
import {
  Container,
  ContentBody,
  ContentHeader,
  ErrorText,
  RecordStream,
} from "./Common";
import { useSetAppDataByKey } from "../AppData/useUISettings";
import { getDefaultMeetingUrl } from "../../common/utils";
import { APP_DATA } from "../../common/constants";
import { useParams } from "react-router-dom";
import { copyToClipboard } from "../../features/services/copyToClipboard";

export const HLSStreaming = ({ onBack }) => {
  const { isHLSRunning } = useRecordingStreaming();
  const { conferenceId } = useParams();
  const recordingUrl = `https://100ms.voodoocast.io/hls-view/${conferenceId}/hls-viewer/`;
  const embedCode = 
    `<iframe
      src="${recordingUrl}"
      style="position: relative; z-index: 10000; left: 0; top: 0; width: 100%; height: 100%; min-height: 700px;"
    ></iframe>`;

  const embedCodeTooltip = <span className="flex flex-col z-5000">
    <span>{`<iframe`}</span>
    <span className="ml-4">{`src="${recordingUrl}"`}</span>
    <span className="ml-4">{`style="position: relative; z-index: 10000; left: 0; top: 0; width: 100%; height: 100%; min-height: 700px;"`}</span>
    <span>{`></iframe>`}</span>
  </span>
    
  const handleCopyUrl = (e) => {
    copyToClipboard(recordingUrl);
  };

  const handleCopyCode = (e) => {
    copyToClipboard(embedCode);
  };

  return (
    <Container>
      <ContentHeader title="Start Streaming" content="HLS" onBack={onBack} />
      <ContentBody title="HLS Streaming" Icon={GoLiveIcon}>
        Stream directly from the browser using any device with multiple hosts
        and real-time messaging, all within this platform.
        The Stream Will Broadcast Here: 
        <a
          className="break-all text-blue-500 ml-1"
          href={recordingUrl}
          target="_blank"
          rel="noreferrer"
        >
          {recordingUrl}
        </a>
        <Button
          css={{
            px: '2px',
            py: '2px',
            fontSize: '12px',
            ml: '5px',
          }}
          onClick={handleCopyUrl}
        >
          Click to Copy URL
        </Button>
        <Tooltip
          title={embedCodeTooltip}
          outlined={true}
          side="top"
        >
          <Button
            css={{
              px: '2px',
              py: '2px',
              fontSize: '12px',
              ml: '5px',
              mt: '5px'
            }}
            onClick={handleCopyCode}
          >
            Click to Copy Embeding Code
          </Button>
        </Tooltip>
      </ContentBody>
      {isHLSRunning ? <EndHLS /> : <StartHLS />}
    </Container>
  );
};

const StartHLS = () => {
  const [record, setRecord] = useState(false);
  const [error, setError] = useState(false);
  const hmsActions = useHMSActions();
  // const recordingUrl = useHMSStore(selectAppData(APP_DATA.recordingUrl));
  const { conferenceId } = useParams();
  const recordingUrl = `https://100ms.voodoocast.io/conference/${conferenceId}/producer/`;
  const [isHLSStarted, setHLSStarted] = useSetAppDataByKey(APP_DATA.hlsStarted);
  const startHLS = useCallback(
    async variants => {
      try {
        if (isHLSStarted) {
          return;
        }
        setHLSStarted(true);
        setError("");
        await hmsActions.startHLSStreaming({
          variants: [{ meetingURL: recordingUrl || getDefaultMeetingUrl() }],
          recording: record
            ? { hlsVod: false, singleFilePerLayer: true }
            : undefined,
        });
      } catch (error) {
        setHLSStarted(false);
        setError(error.message);
      }
    },
    [hmsActions, record, isHLSStarted, setHLSStarted, recordingUrl]
  );

  return (
    <Fragment>
      <RecordStream record={record} setRecord={setRecord} />
      <Box css={{ p: "$4 $10" }}>
        <ErrorText error={error} />
        <Button
          css={{ w: "100%", r: "$0" }}
          icon
          onClick={startHLS}
          disabled={isHLSStarted}
        >
          {isHLSStarted ? (
            <Loading size={24} color="currentColor" />
          ) : (
            <GoLiveIcon />
          )}
          {isHLSStarted ? "Starting Stream..." : "Go Live"}
        </Button>
      </Box>
      <Flex align="center" css={{ p: "$4 $10" }}>
        <Text>
          <InfoIcon width={16} height={16} />
        </Text>
        <Text variant="tiny" color="$textMedEmp" css={{ mx: "$8" }}>
          You cannot start recording once the stream starts, you will have to
          stop the stream to enable recording.
        </Text>
      </Flex>
    </Fragment>
  );
};

const EndHLS = () => {
  const hmsActions = useHMSActions();
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState("");
  const { isHLSRunning } = useRecordingStreaming();

  useEffect(() => {
    if (inProgress && !isHLSRunning) {
      setInProgress(false);
    }
  }, [inProgress, isHLSRunning]);

  return (
    <Box css={{ p: "$4 $10" }}>
      <ErrorText error={error} />
      <Button
        variant="danger"
        css={{ w: "100%", r: "$0", my: "$8" }}
        icon
        loading={inProgress}
        disabled={inProgress}
        onClick={async () => {
          try {
            setInProgress(true);
            await hmsActions.stopHLSStreaming();
          } catch (error) {
            setError(error.message);
            setInProgress(false);
          }
        }}
      >
        <EndStreamIcon />
        End Stream
      </Button>
    </Box>
  );
};
