import { Checkbox, Input, Flex, Label, Button } from "@100mslive/react-ui";
import { useState, useRef, useEffect } from "react";
import { CheckIcon, UploadIcon } from "@100mslive/react-icons";
import UploadingSpinner from "../../components/uploadingSpinner";
import { ReactComponent as CloseIcon } from "../../icons/x-circle.svg";
import HourGlass from "../../components/hourGlass";

const UploadForm = ({
  isOpen,
  extension="",
  handleClose,
  onSubmit,
  uploadToS3,
  progress,
}) => {
  const [isTranscribe, setIsTranscribe] = useState(false);
  const fileInputRef = useRef();
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isUploadingToS3, setIsUploadingToS3] = useState(false);

  useEffect(() => {
    if (!uploading) {
      setIsTranscribe(false)
    }
  }, [extension, uploading])
  
  const handleBrowse = (e) => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleUpload = async (e) => {
    if (uploading) return;
    setUploading(true);
    await onSubmit({ img: files });
    if (isTranscribe) {
      setIsUploadingToS3(true);
      const recordedTime = new Date().getTime();
      await uploadToS3(files, recordedTime);

      setIsUploadingToS3(false);
    }
    setFiles();
    setUploading(false);
  };

  return (
    <div className={`relative flex flex-col border border-1 rounded p-2 mt-2 mx-2 ${isOpen ? "" : "hidden"}`}>
      <button
        className="absolute right-0 top-0 p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
        onClick={() => {
          if (!uploading) 
            handleClose();
        }}
      >
        <CloseIcon className="text-gray-300  h-6 w-6 text-2xl block outline-none focus:outline-none cursor-pointer hover:text-red-400" />
      </button>
      <Button
        onClick={handleBrowse}
        css={{
          w: "$28",
          h: '$12'
        }}
      >
        Browse Files
      </Button>
      <Input 
        ref={fileInputRef} 
        type="file"
        multiple={true}
        onChange={handleFileChange}
        css={{display: 'none'}}
        accept={extension?extension:''}
      />
      <Label
        css={{
          lineBreak: "anywhere"
        }}
      >
        <span className="text-yellow-500 mr-1">{files?.[0]?.name}</span>
        {files?.length === 2 &&
          <span>and 1 file more.</span>
        }
        {files?.length > 2 &&
          <span>and {files?.length - 1} files more.</span>
        }
      </Label>
      {extension !== 'image/*' && 
        <Flex align="center" css={{ w: "100%", my: "$2" }}>
          <Checkbox.Root
            checked={isTranscribe}
            onCheckedChange={value => setIsTranscribe(value)}
            id="fileupload"
          >
            <Checkbox.Indicator>
              <CheckIcon width={16} height={16} />
            </Checkbox.Indicator>
          </Checkbox.Root>
          <Label
            htmlFor="fileupload"
            css={{ cursor: "pointer", ml: "$2" }}
          >
            Transcribe
          </Label>
        </Flex>
      }
      {uploading && !isUploadingToS3 &&
        <div style={{width: '100%', height: '10px', padding: '1px', border: 'solid thin gray', borderRadius: '2px', marginBottom: '2px'}}>
          <div style={{width: `${progress}%`, height: '7px', background: 'blue', borderRadius: '2px'}}>
          </div>
        </div>
      }
      {isUploadingToS3 && 
        <div className="text-white flex flex-col items-center justify-center">
          Files are not ready yet. Uploading To Recording List with transcripts will take a few minutes.<br />
          <HourGlass />
        </div>
      }
      {/* <HourGlass /> */}
      <div>
        <Button
          onClick={handleUpload}
          css={{
            bg: '#4a5061',
            w: "$28",
            h: '$12',
            float: 'right'
          }}
          disabled={uploading}
        >
          {!uploading && <UploadIcon className="w-5 h-5" />}
          {uploading && <UploadingSpinner className="w-5 h-5 mr-2" />}
          Upload
        </Button>
      </div>
    </div>
  )
};

export default UploadForm;
