import React from 'react';
import { useMemo, useReducer, useState, useEffect } from "react";
// import { useAsyncMemo } from "use-async-memo"

export const ERROR_TYPE = "error";
export const RESET_TYPE = "reset";
export const VALUE_TYPE = "value";
export const LOADING_TYPE = "loading";

export const handleErrorType = (state, action) => ({
  ...state,
  error: action.error,
  loading: false,
  value: undefined,
});

export const handleValueType = (state, action) => ({
  ...state,
  error: undefined,
  loading: false,
  value: action.value,
});

export const handelLoadingType = (state, action) => ({
  ...state,
  loading: action.loading,
})

export const handleResetType = (_, action) => getDefaultState(action.value);

export const getDefaultState = (value) => ({
  loading: value === undefined || value === null,
  value,
});

const HANDLERS = {
  error: handleErrorType,
  reset: handleResetType,
  value: handleValueType,
  loading: handelLoadingType,
};

export const reducer = (state, action) => {
  const handler = HANDLERS[action.type];

  if (handler) {
    return handler(state, action);
  }

  return state;
};

export const useLoading = (getDefaultValue) => {
  const value = useMemo(() => { 
    return getDefaultValue?.();
  }, [getDefaultValue]);

  const [state, setState] = useState(value);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const reset = () => {
    setState(value);
  };

  return {
    error: error,
    loading: loading,
    value: state,
    reset,
    setError,
    setValue: setState,
    setLoading,
  };
};
