import { Resizable } from "re-resizable";
import React, { useRef, useState, useEffect } from "react";
import { install } from "resize-observer";
import { ReactComponent as XCircleIcon } from "../icons/x-circle.svg";
import Draggable from './draggable';
import Button from "../components/button";
import { ReactComponent as SortAscendingIcon } from "../icons/sort-ascending.svg";
import { ReactComponent as SortDescendingIcon } from "../icons/sort-descending.svg";
import CountDownTimer from "../components/countDownTimer";
import { createGlobalStyle } from 'styled-components';
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
// TODO
// - Ratio resize?
install();

const GlobalStyle = createGlobalStyle`
  body {
    @font-face {
			font-family: '${props => props.family}';
			src: ${props => props.url};
    };
  }
`

const ratio = 1920 / 1080;

const scaleDimensions = ({ viewWidth, viewHeight, width, height, isCustomRatio }) => {
  const widthPercentage = viewWidth / 1920;
  const heightPercentage = viewHeight / 1080;
  // const computedWidth = widthPercentage * (width > 0 ? width : 500);
  // const computedHeight = heightPercentage * (height > 0 ? height : 500);
  const computedHeight = heightPercentage * (height > 0 ? height : 500);
  const computedWidth = widthPercentage * (width > 0 ? width : 500);

  return {
    width: computedWidth,
    height: computedHeight,
  };
};

const deScaleDimensions = ({ viewWidth, viewHeight, width, height }) => {
	const widthPercentage = viewWidth / 1920;
	const heightPercentage = viewHeight / 1080;
  
	// const computedWidth = width / widthPercentage;
	// const computedHeight = height / heightPercentage;
	const computedHeight = height / heightPercentage;
	const computedWidth = computedHeight * ratio;
  
	return {
	  width: computedWidth,
	  height: computedHeight,
	};
  };

const TimerLayer = ({
	onDrag,
	onStop,
	src,
	id,
	x = 0,
	y = 0,
	z = 0,
	height: givenHeight,
	width: givenWidth,
	onClick,
	onResize,
	onRemove,
	onPlay,
	onPause,
	volume,
	// playStatus,
	muted,
	endTime,
	onVolumeChange,
	selected,
	hovered,
	editable,
	viewWidth,
	viewHeight,
	isCustomRatio,
	handleBringForward,
	handleBringBack,
	setTime,
	isBeingReposition,
	setIsBeingReposition,
	zoneIndex,
	hoveredLayer,
	zone,
	parentRef,
	isVisible,
	...props
}) => {
	const ref = useRef();
  const { textActive, setTextActive } = useLayerEditorContext();
	const [isTimerModal, setIsTimerModal] = useState(false);
	const { width, height } = scaleDimensions({
		viewWidth,
		viewHeight,
		width: givenWidth,
		height: givenHeight,
		isCustomRatio
	});

	const isInHoveredLayer = () => {
		if (z > hoveredLayer?.z && hoveredLayer?.zone === zone) {
		  if (hoveredLayer?.x < x && hoveredLayer?.x + hoveredLayer?.width > x
			&& hoveredLayer?.y < y && hoveredLayer?.y + hoveredLayer?.height > y) {
			  return true;
			}
		  if (hoveredLayer?.x < x + givenWidth && hoveredLayer?.x + hoveredLayer?.width > x + givenWidth
			&& hoveredLayer?.y < y && hoveredLayer?.y + hoveredLayer?.height > y) {
			  return true;
			}
		  if (hoveredLayer?.x < x && hoveredLayer?.x + hoveredLayer?.width > x
			&& hoveredLayer?.y < y + givenHeight && hoveredLayer?.y + hoveredLayer?.height > y + givenHeight) {
			  return true;
			}
		  if (hoveredLayer?.x < x + givenWidth && hoveredLayer?.x + hoveredLayer?.width > x + givenWidth
			&& hoveredLayer?.y < y + givenHeight && hoveredLayer?.y + hoveredLayer?.height > y + givenHeight) {
			  return true;
			}
		}
		return false;
	};

	const baseZ = isInHoveredLayer() ? 1500 : 0;
	const style = {
		position: "absolute",
		zIndex: isTimerModal ? 3000 : isBeingReposition === id ? 2500 : textActive === id ? 2300 : hovered ? 2000 : selected ? 1500 + baseZ :  isInHoveredLayer() ? 2000 + z : 250 * zoneIndex + z,
		width: '150px',
		height: '20px'
	};
	
	return (
	<>
		{props.customFonts && props.customFonts.map((font, index) => (
			<GlobalStyle key={`global_font_${index}`} family={font.family} url={`url(${font.url})`} format={font.format?font.format:'ttf'} />
		))}
		<Draggable
			id={id}
			onDrag={onDrag(id)}
			onStop={onStop}
			x={x}
			y={y}
			disabled={!editable}
			viewHeight={viewHeight}
			viewWidth={viewWidth}
			isTimer={true}
			style={style}
			// onMouseDown={() => console.log(id, 'down')}
			// onMouseUp={() => console.log(id, 'up')}
			setIsBeingReposition={setIsBeingReposition}
			parentRef={parentRef}
      isVisible={isVisible}
		>
			<>
			<Resizable
				style={{
					position: "absolute",
					zIndex: isTimerModal ? 3000 : isBeingReposition === id ? 2500 : textActive === id ? 2300 :  hovered ? 2000 : selected ? 1500 + baseZ :  isInHoveredLayer() ? 2000 + z : 250 * zoneIndex + z,
				}}
				ref={ref}
				bounds="parent"
				handleClasses="handle"
				handleWrapperClass="re-handle"
				className={`inline-block absolute ${
					(hovered || isBeingReposition === id) ? "border-2 border-yellow-500" : selected ? "border-2 border-red-500" : ""
				}`}
				enable={{
					top: false,
					right: true && editable,
					bottom: true && editable,
					bottomLeft: false,
					bottomRight: true && editable,
					topRight: false,
					topLeft: false,
				}}
				size={{ width, height }}
				// lockAspectRatio={!isCustomRatio}
				onResizeStart={(e) => {
					e.stopPropagation();
					setIsBeingReposition(id);
				}}
				onResizeStop={(e, direction, ref, d) => {
					const deScale = deScaleDimensions({
						viewWidth,
						viewHeight,
						width: width + d.width,
						height: height + d.height,
					});
					const countedW =(width + d.width) / viewWidth * 1920;
					const countedH = (height + d.height) / viewHeight * 1080;
					onResize({
						id,
						width: countedW,
						height: countedH 
					});
					setIsBeingReposition(null);
				}}
				onClick={(e) => {
					e.stopPropagation();
					onClick(e);
				}}
			>
				{editable && selected && <button
					className="absolute -top-3 -right-3 bg-white rounded-full w-5 h-5 text-red-500 hover:text-red-400"
					onClick={() => onRemove(id)}
				>
					<XCircleIcon className="w-5 h-5" />
				</button>}
				<div
					className="absolute w-full h-full cursor-move"
				>
					<div className="absolute w-full h-full">
						
						<CountDownTimer
							editable={editable}
							endTime={endTime}
							zIndex={z}
							setTime={setTime}
							onClick={onClick}
							selected={selected}
							width={givenWidth}
							height={givenHeight}
							{...props}
							textActive={textActive}
							setTextActive={setTextActive}
							id={id}
							isTimerModal={isTimerModal}
							setIsTimerModal={setIsTimerModal}
						/>
					</div>
				</div>
				{selected && <div className="absolute flex flex-col top-0 left-0 w-16 h-8" style={{zIndex: isTimerModal ? 3000 : isBeingReposition === id ? 2500 : textActive === id ? 2300 : hovered ? 2000 : selected ? 1500 + baseZ :  isInHoveredLayer() ? 2000 + z : 250 * zoneIndex + z}}>
					<div>
						<Button
							className="w-8"
							onClick={(e) => {
							e.stopPropagation();
							handleBringForward({id, z});
							}}
						>
							<SortAscendingIcon className="w-4 h-4" />
							{/* Bring Forward */}
						</Button>
						<Button
							className="w-8"
							onClick={(e) => {
							e.stopPropagation();
							handleBringBack({id, z});
							}}
						>
							<SortDescendingIcon className="w-4 h-4" />
							{/* Send Back */}
						</Button>
					</div>
					<span className="text-red-500 font-bold">level: {z}</span>
				</div>}
			</Resizable>
			</>
		</Draggable>
	</>
	);
};
  
export default TimerLayer;

