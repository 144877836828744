import React from "react";

const InputField = React.forwardRef(
  ({ id, type = "text", required = false, label, labelStyle="text-gray-700", ...props }, ref) => {
    return (
      <div>
        {label && <label htmlFor={id} className={`block text-sm font-medium ${labelStyle}`}>
          {label}
        </label>}
        <div className={`${props.className?.indexOf('mt-') < 0 ? "mt-1" : ""}` }>
          <input
            {...props}
            ref={ref}
            id={id}
            name={id}
            type={type}
            required={required}
            className={`appearance-none block border border-gray-300 rounded-md shadow-sm placeholder-gray-400
              focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm ${props.className}
              ${props.className?.indexOf('w-') < 0 ? "w-full" : ""} ${props.className?.indexOf('h-') < 0 ? "h-8" : ""} 
              ${props.className?.indexOf('px-') < 0 ? "px-3" : ""}`}
          />
        </div>
      </div>
    );
  }
);

export default InputField;
