import { useState, useEffect, useContext } from "react";
import { CrossIcon, PencilIcon } from "@100mslive/react-icons";
import { Flex } from "@100mslive/react-ui";
import { ReactComponent as AscendingIcon } from "../../../icons/asc-sort.svg";
import { ReactComponent as DescendingIcon } from "../../../icons/desc-sort.svg";
import { ReactComponent as EyeIcon } from "../../../icons/eye-icon.svg";
import { FirebaseContext } from "../../../helpers/contexts/firebaseContext";
import { getFirestore, doc, collection, setDoc, updateDoc, deleteDoc } from "@firebase/firestore";

const SortSVG = ({
  curKey,
  sortObj
}) => {
  if (curKey && curKey === sortObj?.sortKey) {
    if (sortObj?.direction) {
      return (
        <AscendingIcon className="w-4 h-4 ml-1" />
      )
    } else {
      return (
        <DescendingIcon className="w-4 h-4 ml-1" />
      )
    }
  } else {
    return (<></>)
  }
};

const SortTBHeader = ({
  label,
  sortKey,
  sortObj,
  setSortObj,
  width
}) => {
  return (
    <th
      className="bg-gray-700 px-1 cursor-pointer text-white border-r border-gray-500"
      style={{ width: width }}
      onClick={() => {
        setSortObj({
          sortKey: sortKey,
          direction: !!!sortObj?.direction
        })
      }}
    >
      <div className="flex items-center justify-between">
        {label} <SortSVG curKey={sortKey} sortObj={sortObj} />
      </div>
    </th>
  )
};

const CommunicationList = ({
  height,
  communicates = [],
  curCommunicate,
  setCurCommunicate,
  removeCommunicate = async () => {},
}) => {
  const headerWidth = [5, 20, 10, 25, 17, 17, 6];
  const [sortObj, setSortObj] = useState({});
  const [isRemoving, setIsRemoving] = useState({});
  
  const [sortedCommunicates, setSortedCommunicates] = useState(communicates ?? []);

  const compareBy = (sorting) => {
    return (a, b) => {
      if (sorting?.sortKey === 'type') {
        if (a?.type?.name > b?.type?.name) {
          return sorting?.direction ? 1 : -1;
        }
        if (a?.type?.name < b?.type?.name) {
          return sorting?.direction ? -1 : 1;
        }
      } else if (sorting?.sortKey === 'target') {
        if (a?.target?.name > b?.target?.name) {
          return sorting?.direction ? 1 : -1;
        }
        if (a?.target?.name < b?.target?.name) {
          return sorting?.direction ? -1 : 1;
        }
      } else if (sorting?.sortKey === 'timing') {
        if (new Date(a?.scheduledAt).getTime() > new Date(b?.scheduledAt).getTime()) {
          return sorting?.direction ? 1 : -1;
        }
        if (new Date(a?.scheduledAt).getTime() < new Date(b?.scheduledAt).getTime()) {
          return sorting?.direction ? -1 : 1;
        }
      } else {
        if (a?.[sorting?.sortKey] > b?.[sorting?.sortKey]) {
          return sorting?.direction ? 1 : -1;
        }
        if (a?.[sorting?.sortKey] < b?.[sorting?.sortKey]) {
          return sorting?.direction ? -1 : 1;
        }
      }
      return 0;
    }
  }

  useEffect(() => {
    let temp = JSON.parse(JSON.stringify(communicates ?? []));
    if (sortObj?.sortKey) {
      setSortedCommunicates(temp?.sort(compareBy(sortObj)))
    } else {
      setSortedCommunicates(temp);
    }
  }, [sortObj, communicates]);

  const { user, firebaseApp } = useContext(FirebaseContext);
  const db = getFirestore(firebaseApp);
  const [statusCheck, setStatusCheck] = useState(false)
  
  useEffect(() => {
    (communicates ?? []).forEach(communicate => {
      if (new Date(communicate?.scheduledAt).getTime() < new Date().getTime() && communicate?.status === 'scheduled') {
        updateDoc(doc(collection(db, 'communicates'), communicate?.id), {
          status: 'processed'
        })
      }
    })
  }, [communicates, db, statusCheck])

  useEffect(() => {
    setTimeout(() => {
      setStatusCheck(!statusCheck)
    }, 10000)
  }, [statusCheck])

  return (
    <div className={`flex flex-col w-full border-2 border-gray-500 rounded ${height}`}>
      <table className="w-full">
        <thead className="">
          <tr>
            <th className="bg-gray-700 text-white text-left pl-2 border-r border-gray-500" style={{width: `${headerWidth[0]}%`}}>No</th>
            <SortTBHeader label="Name" sortKey="name" sortObj={sortObj} setSortObj={setSortObj} width={`${headerWidth[1]}%`} />
            <SortTBHeader label="Type" sortKey="type" sortObj={sortObj} setSortObj={setSortObj} width={`${headerWidth[2]}%`} />
            <SortTBHeader label="Timing" sortKey="timing" sortObj={sortObj} setSortObj={setSortObj} width={`${headerWidth[3]}%`} />
            <SortTBHeader label="Target" sortKey="target" sortObj={sortObj} setSortObj={setSortObj} width={`${headerWidth[4]}%`} />
            <SortTBHeader label="Status" sortKey="status" sortObj={sortObj} setSortObj={setSortObj} width={`calc(${headerWidth[5]}% - 5px)`} />
            <th className="bg-gray-700 text-white text-center" style={{width: `${headerWidth[6]}%`}}></th>
            <th className="bg-gray-700 text-white text-center" style={{width: '10px'}}></th>
          </tr>
        </thead>
      </table>
      <div className="w-full overflow-y-scroll communicate-list">
        <table className="w-full">
          <tbody>
            {(sortedCommunicates ?? [])?.map((communicate, idx) => (
              <tr
                key={communicate?.id}
                className={`border-b ${communicate?.id === curCommunicate ? 'bg-blue-300' : ''}`}
                // onClick={() => setCurCommunicate(communicate?.id)}
              >
                <td className="h-8 text-left pl-2" style={{width: `${headerWidth[0]}%`}}>{idx + 1}</td>
                <td className="h-8 text-left pl-2" style={{width: `${headerWidth[1]}%`}}>{communicate?.name}</td>
                <td className="h-8 text-left pl-2" style={{width: `${headerWidth[2]}%`}}>{communicate?.type?.name}</td>
                <td className="h-8 text-left pl-2" style={{width: `${headerWidth[3]}%`}}>{communicate?.scheduledAt?.replace('T', '  ')}</td>
                <td className="h-8 text-left pl-2" style={{width: `${headerWidth[4]}%`}}>{communicate?.target?.name}</td>
                <td className="h-8 text-left pl-2" style={{width: `${headerWidth[5]}%`}}>{communicate?.status}</td>
                <td style={{width: `${headerWidth[6]}%`}}>
                  <Flex justify="around">
                    {communicate?.status !== 'processed' && 
                      <PencilIcon
                        className="w-5 h-5 text-blue-500 hover:text-blue-700 cursor-pointer"
                        onClick={() => {
                          // if (communicate?.status === 'scheduled') {
                            setCurCommunicate(communicate?.id)
                          // }
                        }}
                      />
                    }
                    {communicate?.status === 'processed' && 
                      <EyeIcon
                        className="w-5 h-5 text-blue-500 hover:text-blue-700 cursor-pointer"
                        onClick={() => {
                          // if (communicate?.status === 'scheduled') {
                            setCurCommunicate(communicate?.id)
                          // }
                        }}
                      />
                    }
                    {!isRemoving?.[`re_${communicate?.id}`] &&
                      <CrossIcon
                        className="w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer"
                        onClick={() => {
                          setIsRemoving(previous => {
                            return {
                              ...previous,
                              [`re_${communicate?.id}`]: !previous?.[`re_${communicate?.id}`]
                            }
                          })
                          removeCommunicate(communicate?.id)
                        }}
                      />
                    }
                    {isRemoving?.[`re_${communicate?.id}`] &&
                      <div className="w-5 h-5"><div className="uploading-spinner"></div></div>
                    }
                  </Flex>
                </td>
                {/* <td className="" style={{width: '10px'}}></td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
};

export default CommunicationList;
