import React from 'react';
import { useEffect, useState, useMemo, createContext, useContext, useCallback } from "react";
import { FirebaseContext } from './firebaseContext';
import { nanoid } from "nanoid";
import { getFirestore, collection, doc, updateDoc, getDoc, getDocs, setDoc, deleteDoc } from '@firebase/firestore';
import { getDatabase, ref as rRef, set, get, update, onValue } from '@firebase/database';
import { useParams } from "react-router-dom";

export const AudioChannelContext = createContext({});

export const AudioChannelProvider = ({ children }) => {
  const [audioChannels, setAudioChannels] = useState([]);
  const { firebaseApp } = useContext(FirebaseContext);
	const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);
	const [currentAudioChannel, _setCurrentAudioChannel] = useState('On Stage');

  const { conferenceId: currentConference } = useParams();

	const rdb = useMemo(() => getDatabase(firebaseApp), [firebaseApp]);
  
	const ref = useMemo(() => 
		currentConference ? rRef(rdb, `/${currentConference}/currentAudioChannel`) : null
	, [currentConference, rdb]);

  const confRef = useMemo(() => 
		currentConference ? doc(collection(db, "conferences"), currentConference ?? "defaultConference"): null
	, [db, currentConference]);

	const audioChannelRef = useMemo(() =>
		confRef ? collection(confRef, "audioChannels"):null
	, [confRef]);

	const updateChannel = useCallback(async () => {
		const snapshots = await getDocs(audioChannelRef);
		if (snapshots.length === 0) {
			let id = nanoid();
			await setDoc(doc(audioChannelRef, id), {
				id: id,
				name: 'broadcast',
				members: [],
			})
			update(rRef(rdb, `/${currentConference}/currentAudioChannel`), {
				id: id,
				name: 'broadcast',
				members: [],
			})
		} else {
			let temp = [];
			snapshots.forEach(snapshot => {
				temp.push(snapshot.data());
			})
			update(rRef(rdb, `${currentConference}/currentAudioChannel`), {
				...temp.filter(channel => channel.name === 'broadcast')[0]
			})
		}
	}, [audioChannelRef, currentConference, rdb]);

  useEffect(() => {
		if (currentConference && confRef && audioChannelRef) {
			if (ref) {
				onValue(ref, async (snapshot) => {
					if (snapshot.val()) {
						_setCurrentAudioChannel({ ...snapshot.val() });
						const snapshots = await getDocs(audioChannelRef);
						
						let temp = [];
						snapshots.forEach(doc => {
							temp.push(doc.data());
						});
						setAudioChannels(JSON.parse(JSON.stringify(temp)));
					}
				});
			}

			updateChannel();
		}

    return () => {
      setAudioChannels([]);
    };
  }, [currentConference, confRef, audioChannelRef, updateChannel, ref]);

	const addAudioChannel = (name, members) => {
		let id = nanoid();
		setDoc(doc(audioChannelRef, id), { id, name, members });
		update(rRef(rdb, `/${currentConference}/currentAudioChannel`), { id, name, members });
	};

	const deleteAudioChannel = (id) => {
		update(rRef(rdb, `/${currentConference}/currentAudioChannel`), { ...audioChannels.filter(channel => channel.name === 'broadcast')[0] });
		try {
			deleteDoc(doc(audioChannelRef, id));
		} catch (err) {
			// console.log(err);
		}
	};

	const addMember = (id, members) => {
		let temp = JSON.parse(JSON.stringify(currentAudioChannel.members));
		for (let member of members) {
			if (!temp.some(t => t.id === member.id)) temp.push(member);
		}
		updateDoc(doc(audioChannelRef, id), { members: temp });
		update(rRef(rdb, `/${currentConference}/currentAudioChannel`), {...currentAudioChannel, members: temp});
	};

	const deleteMember = (id, members) => {
		let temp = JSON.parse(JSON.stringify(currentAudioChannel.members));
		for (let member of members) {
			temp = temp.filter(t => t.id !== member.id);
		}
		updateDoc(doc(audioChannelRef, id), { members: temp });
		update(rRef(rdb, `/${currentConference}/currentAudioChannel`), {...currentAudioChannel, members: temp});
	};

	const setCurrentAudioChannel = (channel) => {
		update(rRef(rdb, `/${currentConference}/currentAudioChannel`), {...channel});
		_setCurrentAudioChannel(channel);
	};

  return (
    <AudioChannelContext.Provider
      value={{
				currentAudioChannel,
				setCurrentAudioChannel,
				audioChannels,
				addAudioChannel,
				deleteAudioChannel,
				addMember,
				deleteMember
			}}
		>
			{children}
		</AudioChannelContext.Provider>
	);
};
