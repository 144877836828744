import { createSlice } from '@reduxjs/toolkit'

export const videoSplitSlice = createSlice({
  name: 'videoSplit',
  initialState: {
    downloadKey: 0,
    isDownloading: false,
    isIntentDownload: false,
    downloadTimeRange: {
      startTiem: '00:00:00',
      duration: 100,
    },
    sliderValue: [0, 100],
    progress: 0,
  },
  reducers: {
    setDownloadKey: (state, action) => {
      state.downloadKey = action.payload
    },
    setIsDownloading: (state, action) => {
      state.isDownloading = action.payload
    },
    setIsIntentDownload: (state, action) => {
      state.isIntentDownload = action.payload
    },
    setDownloadTimeRange: (state, action) => {
      state.downloadTimeRange = action.payload
    },
    setSliderValue: (state, action) => {
      state.sliderValue = action.payload
    },
    setProgress: (state, action) => {
      state.progress = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { 
  setDownloadKey,
  setIsDownloading,
  setIsIntentDownload,
  setDownloadTimeRange,
  setSliderValue,
  setProgress,
} = videoSplitSlice.actions

export default videoSplitSlice.reducer
