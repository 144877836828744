import React, { useContext, useMemo, useRef, useState, useEffect } from "react";
import useResizeObserver from "use-resize-observer";
import Helmet from "react-helmet";
import Layout from "../components/layout/index.js";
import LayerEditorControls from "../features/layerEditorControls";
import AttendeesRoom from "../features/attendeesRoom";
import { Chat } from "../components/ChatFB/Chat";
import { AuthContext } from "../helpers/contexts/userSessionContext";
import { useConferenceCall } from "../helpers/hooks/useConferenceCall";
import { FirebaseContext } from "../helpers/contexts/firebaseContext";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { useDocumentQuery } from "../helpers/hooks/useFirebaseDocument";
import StreamEditor from "./streamEditor";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
import { useHistory } from "react-router-dom";
import HeaderQuickControl from "../components/headerQuickControl/index.js";
import Button from "../components/button";
import { ReactComponent as ChatIcon } from "../icons/chat.svg";
import { ReactComponent as InteractionIcon } from "../icons/interaction.svg";
import Teleprompter from "../components/teleprompter/index";
import { ReactComponent as ArrowDrag } from "../icons/chevron-down.svg";
import { ReactComponent as TeleIcon} from "../icons/teleprompter-1.svg";
import { getFirestore, collection, doc, updateDoc } from "@firebase/firestore";
import { getDatabase, ref as rRef, onValue } from "@firebase/database";
import { btnStyle, teleprompterStyle, canvasStyle } from "../components/teleprompterStyle.js";
import { Notifications } from "../components/Notifications/Notifications.jsx";
import { ToastContainer } from "../components/Toast/ToastContainer";
import { hmsStore } from "../hms";
import {
  selectDominantSpeaker,
} from '@100mslive/hms-video-store';
import ChatInteractions from "../components/Interaction/chatInteractions";
import { InteractionContext } from "../helpers/contexts/interactionContext.js";
import AttendeesRoomMinimized from "./attendeesRoomMinimized.js";
import { Tooltip } from "@100mslive/react-ui";
import { ClosedCaptionIcon } from "@100mslive/react-icons";
import RealtimeTranscriptModal from "../components/transcriptModalBC";
import { TranscriptionContext } from "../helpers/contexts/transcriptionContext.js";
import { useCanJoinRoom } from "../helpers/hooks/requests/useCanJoinRoom";

const StreamEditorHolder = ({
  roomType,
  room,
  conference,
  user,
  scenes,
  localParticipant,
  streamParticipants,
  mySession,
  sessions,
  currentState,
  isHost,
  stageHolderRef
}) => {
  const ref = useRef();
  const { width, height } = useResizeObserver({ ref });
  const [activeSpeaker, setActiveSpeaker] = useState();

  const onActiveSpeaker = (peer, prevPeer) => {
    if (peer?.name) {
      setActiveSpeaker(peer.id);
    }
    if (prevPeer?.name) {
      setActiveSpeaker(false);
    }
  }

  hmsStore.subscribe(onActiveSpeaker, selectDominantSpeaker);

  return (
    <div
      ref={ref}
      className="rounded-sm flex-grow mb-2 flex flex-col justify-start items-center"
      style={{ width: '100%', height: `calc(100vh - ${roomType === 'viewing' ? 140 : 250}px)` }}
    >
      <StreamEditor
        room={room}
        parentWidth={width}
        parentHeight={height}
        key={conference?.activeScene}
        scenes={scenes}
        activeScene={conference?.activeScene}
        participants={streamParticipants}
        // renderLocalParticipant={currentState ? (mySession?.editingLive) : (mySession?.live)}
        renderLocalParticipant={mySession?.live}
        // color={conference?.background?.color}
        // backgroundImage={conference?.background?.image}
        color={conference?.activeScene?.background?.color}
        backgroundImage={conference?.activeScene?.background?.image}
        mySession={mySession}
        sessions={sessions}
        isGuest={true}
        isHost={isHost}
        stageHolderRef={stageHolderRef}
        currentViewType={conference?.currentViewType || 'desktop'}
        activeSpeaker={activeSpeaker}
      />
    </div>
  )
}

const ParticipantView = ({ userSessions, conferenceId, roomType }) => {
  const { user, hostStatus, initHost } = useContext(AuthContext);
  const { firebaseApp } = useContext(FirebaseContext);
  const db = useMemo(() => getFirestore(firebaseApp), [firebaseApp]);
  const [isWaitingRoom, setIsWaitingRoom] = useState(true);
  const [isChat, setIsChat] = useState(true);
  const { isOpenInteractionTab } = useContext(InteractionContext);

  const { 
    isConnecting,
    isShowTranscript,
    setIsShowTranscript,
    setIsLocalTranscriptEnabled
  } = useContext(TranscriptionContext);

  useEffect(() => {
    if (roomType === 'viewing') {
      setIsWaitingRoom(false);
    }
  }, [roomType])

  useEffect(() => {
    if (isOpenInteractionTab) {
      setIsChat(false);
    }
  }, [isOpenInteractionTab]);

  const attendeeRef = useRef();
  const teleprompterRef = useRef();
  const stageHolderRef = useRef();

	const mySession = userSessions?.[user?.uid];

  const docRef = doc(collection(db, "conferences"), conferenceId ?? "defaultConference");

  const [conference, loading] = useDocumentQuery(docRef);

  const collectionRef = collection(docRef, "scenes");

  const [scenes, scenesLoading] = useCollectionQuery(collectionRef, {
    idField: "id",
  });

  const activeScene = scenes?.find(scene => scene.id === conference?.activeScene);

  const { currentState, sceneLocked, setSceneLock, setActiveLayer, isTeleprompter, setIsTeleprompter, setScenes } = useLayerEditorContext();
  
  useEffect(() => {
    setScenes(scenes);
  }, [scenes, setScenes]);

  const { room, waitingRoomParticipants, streamParticipants, localParticipant, isJoined } = useConferenceCall({
    conferenceId,
    user,
    sessions: userSessions,
    currentState
  });

  useEffect(() => {
    if (conferenceId) initHost(conferenceId);
  }, [conferenceId, initHost]);

  const roomName = conference?.name ?? "Conference";

  if (loading || scenesLoading) {
    return <div className="loader">&nbsp;</div>;
  }

  return (
    <Layout
      titleBarContent={
        <div className="flex items-center gap-3">
          <h1 className="text-xl font-extrabold text-gray-800 tracking-loose">
            {roomName}
          </h1>
          <HeaderQuickControl 
            isHost={hostStatus[user?.uid]}
            conferenceId={conferenceId}
          />
        </div>
      }
    >
      <div className="z-5000">
        <ToastContainer />
        <Notifications />
      </div>
      <RealtimeTranscriptModal
        isOpen={isShowTranscript}
        isConnecting={isConnecting}
        closeModal={() => {
          setIsShowTranscript(false);
          setTimeout(() => setIsLocalTranscriptEnabled(false));
        }}
        initialLeft={50}
        initialTop={50}
        className="bg-gray-800 opacity-90"
      />
      
      <Helmet>
        <title>VoodooCast - {roomName}</title>
      </Helmet>
      <div className="h-full w-full flex">
        <div
          ref={teleprompterRef}
          className="w-2/12  pl-3 pr-3 relative"
            style={teleprompterStyle(isTeleprompter, roomType === 'viewing' ? false : true)}
        >
          {hostStatus[user?.uid] &&
            <button
              className={` h-10 absolute right-0 rounded p-2 py-2 ${isTeleprompter ? "top-9 bg-green-400 border-none": "top-0 w-12 bg-green-500"} `}
              style={btnStyle(isTeleprompter)}
              onClick={() => {
                updateDoc(docRef, {
                  teleprompter: {
                    ...conference?.teleprompter,
                    status: {
                      isOpen: !isTeleprompter
                    },
                  }
                });
                setIsTeleprompter(!isTeleprompter);
              }}
            >
              {!isTeleprompter && <TeleIcon className="w-10 h-10"/> }
              {isTeleprompter && <ArrowDrag className="w-6 h-6" style={{	transform: 'rotate(90deg)' }} /> }
            </button>
          }

          <Teleprompter
            isHost={hostStatus[user?.uid]}
            conferenceId={conferenceId}
            isTeleprompter={isTeleprompter}
            setIsTeleprompter={setIsTeleprompter}
            parentRef={teleprompterRef}
          />
        </div>

        <div 
          ref={stageHolderRef} 
          className=" p-5 flex flex-col gap-2 absolute  custom-bg-2 custom-shadow " 
          style={canvasStyle(isTeleprompter)}
        >
          
          <StreamEditorHolder
            roomType={roomType}
            room={room}
            conference={conference}
            user={user}
            scenes={scenes?.filter((scene) => scene.id !== 'editing_scene')}
            localParticipant={localParticipant}
            streamParticipants={streamParticipants}
            mySession={mySession}
            sessions={userSessions}
            currentState={currentState}
            isHost={hostStatus[user?.uid]}
            stageHolderRef={stageHolderRef}
          />
        </div>

        {!hostStatus[user?.uid] &&
        <>
          
          <div
            className="bg-cPurple shadow-lg flex flex-col px-2 pt-2 pb-4 absolute right-0 bottom-0 top-12"
            style={{
              width: '350px',
              height: `calc(100vh - ${(activeScene?.isSingleView ? 170 : 210) - (isWaitingRoom ? 0 : 100)}px)`
            }}
          >
            <div className="flex mt-2">
              <div className="w-2 border-b-2 border-gray-500"></div>
              <button
                onClick={() => setIsChat(true)}
                className={`flex py-1 px-2 border-gray-500
                  rounded-t ${isChat 
                    ? "text-white border-r-2 border-t-2 border-l-2" 
                    : "border-b-2 border-t border-l"}`}
              >
                <ChatIcon className="w-6 h-6" /> Chats
              </button>
              <button
                onClick={() => setIsChat(false)}
                className={`flex py-1 px-2 border-gray-500
                  rounded-t ${!isChat 
                    ? "text-white border-l-2 border-t-2 border-r-2" 
                    : "border-b-2 border-t border-r"}`}
              >
                <InteractionIcon className="w-6 h-6" /> Interactions
              </button>
              <div className="w-full border-b-2 border-gray-500"></div>
            </div>
            {isChat && <Chat />}
            {!isChat && <ChatInteractions />}
          </div>
        </>
        }
        {hostStatus[user?.uid] &&
          <div
            className="absolute right-0"
            style={{
              width: '350px',
              height: `calc(100vh - ${(isWaitingRoom ? 205 : 90) - (activeScene?.isSingleView ? 30 : 0)}px)`
            }}
          >
            <LayerEditorControls conference={conference} conferenceId={conferenceId} docRef={docRef} />
          </div>
        }
      </div>
      <div
        className={`custom-bg-3 shadow-lg rounded-sm flex ${roomType === 'viewing' ? 'z-10000' : 'z-3000 flex-col'} 
          overflow-initial p-1 absolute bottom-0 left-0 w-5/6 border-none`}
        style={{width: '100vw', height: roomType === 'viewing' ? '50px' : !activeScene?.isSingleView ? '160px': '130px'}}
      >
        {roomType !== 'viewing' && 
          <>
            {!activeScene?.isSingleView && <div className="flex justify-between">
              <h3 className="text-gray-300 font-extrabold text-xl leading-none ml-px py-1">
                Waiting Room
              </h3>
              <Tooltip
                title="Transcription"
                align="center"
                side="top"
              >
                <button
                  className="bg-green-500 hover:bg-green-400 text-gray-700 rounded px-2 mr-1"
                  onClick={() => {
                    const flag = !isShowTranscript;
                    setIsShowTranscript(flag);
                    setTimeout(() => setIsLocalTranscriptEnabled(flag));
                  }}
                >
                  <ClosedCaptionIcon className="w-7 h-7 text-white" />
                </button>
              </Tooltip>
            </div>}
            <div 
              ref={attendeeRef} 
              className="flex flex-wrap h-9/12 custom-bg-2 rounded scrollbar-1" 
              style={{ height: '85%' }}
            >
              <AttendeesRoom
                roomType={roomType}
                localParticipant={room && room.localParticipant}
                height={attendeeRef.current?.offsetHeight}
                sessions={userSessions}
                participants={waitingRoomParticipants}
                streamParticipants={streamParticipants}
                userIsLive={mySession?.live}
                activeScene={activeScene}
                currentViewType={conference?.currentViewType}
              />
            </div>
          </>
        }
        {roomType === 'viewing' && 
          <AttendeesRoomMinimized 
            roomType={roomType}
            localParticipant={room && room.localParticipant}
            producer={!user?.isAnonymous}
            sessions={userSessions}
            participants={waitingRoomParticipants}
            streamParticipants={streamParticipants}
            userIsLive={mySession?.live}
            isBeamUser={false}
          />
        }
      </div>
    </Layout>
  );
};

export default ParticipantView;
