import React from "react";
import "../styles/spinner.css";

export default function UploadingSpinner({ className="w-10 h-10", ...props }) {
  return (
    <div className={`${className} spinner-container`} style={{...(props?.style ?? {})}}>
      <div className="uploading-spinner">
      </div>
    </div>
  );
}