import React, { useContext, useRef, useState, useMemo } from "react";
import Video from "../components/rtc/video";
import VideoFeed from "../components/rtc/videoFeed";
import VideoContainer from "../components/videoContainer";
import { AuthContext } from "../helpers/contexts/userSessionContext";
import { 
	useHMSActions,
  useHMSStore,
  selectIsLocalScreenShared,
} from "@100mslive/react-sdk";
import SubLayers from "./subLayers";

const LocalVideoFeed = ({
  session,
  mainHost,
  localParticipant,
  onAction,
  onActionText = "Go Live",
  className,
  width,
  height,
  z,
  showAction = false,
  muted = false,
  onMute,
  ratio,
  setRatio,
  isLive,
  ...props
}) => {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const hmsActions = useHMSActions();

  const amIScreenSharing = useHMSStore(selectIsLocalScreenShared);

  const handleMuteToggle = (event) => {
    onMute();
  };

  const handleScreenToggle = async (event) => {
    setLoading(true);
    await hmsActions.setScreenShareEnabled(!amIScreenSharing);
    setLoading(false);
  };

  return (
    <VideoFeed
      onAction={onAction}
      onActionText={onActionText}
      className={className}
      key={user?.uid}
      showAction={showAction}
      isOverloaded={session?.live && session?.isOverloaded && !isLive}
      {...props}
    >
      <VideoContainer
        mainHost={mainHost}
        isLocal
        onMute={handleMuteToggle}
        muted={muted}
        shared={amIScreenSharing}
        onScreenShare={handleScreenToggle}
        showAdminCommands={showAction}
        className="h-full"
        // style={{ height: "100%" }}
        width={width}
        height={height}
        identity={localParticipant && localParticipant.identity}
        ratio={ratio}
        setRatio={setRatio}
        session={session}
        isLive={isLive}
        activeSpeaker={props?.activeSpeaker}
        peerId={localParticipant?.peerId}
      >
        <Video
          loading={loading}
          width={width ? width : height / 1080 * 1920}
          height={height}
          peer={localParticipant}
          className="h-full object-cover"
          style={{zIndex: z}}
          isLocal
          isSharing={amIScreenSharing}
          onClick={(e) => {
            e.stopPropagation();
            alert('hey');
          }}
        />
        {props?.activeScene && <SubLayers 
          id={localParticipant?.identity}
          activeScene={props?.activeScene}
          editable={showAction}
          currentViewType={props?.currentViewType}
          viewWidth={width}
          viewHeight={height}
          isVisible={props?.isVisible}
          activeLayer={props?.activeLayer}
          setActiveLayer={props?.setActiveLayer}
          semiActiveLayer={props?.semiActiveLayer}
          setSemiActiveLayer={props?.setSemiActiveLayer}
          parentSelect={props?.parentSelect}
          participant={localParticipant}
        />}
      </VideoContainer>
    </VideoFeed>
  );
};

export default LocalVideoFeed;
