import { useRef, useState } from "react";
import { Button, Flex, Input } from "@100mslive/react-ui";

const LinkForm = ({
  isOpen,
  onConfirm=()=>{},
  onClose=()=>{},
}) => {
  const [url, setUrl] = useState('');

  if (isOpen) {
    return (
      <Flex 
        direction="column"
        className="absolute w-64 right-0 top-10 bg-gray-300 border border-gray-500 rounded px-2 pb-2 z-50"
      >
        <span className="mb-2">Add Link to Text</span>
        <Flex justify="between" align="center" css={{mb: '$2'}}>
          <span>URL</span>
          <Input
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            css={{w: '200px', h: '20px', bg: 'white', color: 'black'}}
          />
        </Flex>
        
        <Flex justify="between">
          <Button
            variant="standard"
            onClick={() => onClose()}
            css={{ fontSize: '15px', px: '$1', py: '$1', h: '$10', w: '$50'}}
          >Cancel</Button>
          <Button
            css={{ fontSize: '15px', px: '$1', py: '$1', h: '$10', w: '70px'}}
            onClick={() => {
              if (url) {
                onClose();
                onConfirm({
                  url
                })
              }
            }}
          >Add</Button>
        </Flex>
      </Flex>
    )
  } else {
    return (<></>)
  }
};

export default LinkForm;
