import React from "react";

const useJustResponded = ({
  interaction={}
}) => {
  const [isJustResponded, setIsJustResponded] = React.useState({});

  React.useEffect(() => {
    const curTime = new Date().getTime();
    let flag = {}
    for (let item of interaction?.responded) {
      if (curTime - item?.respondedAt < 10000) {
        flag = { ...flag, [item?.id]: true }
        setTimeout(() => {
          setIsJustResponded(previous => {
            return {
              ...previous,
              [item?.id]: false
            }
          })
        }, item?.respondedAt + 5000 - curTime)
      } else {
        flag = { ...flag, [item?.id]: false }
      }
    }
    setIsJustResponded(flag);
  }, [interaction?.responded]);

  return {
    isJustResponded
  }
}

export default useJustResponded;
