import {
  useMemo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { analyzeRecordings } from "./analyzeRecordings";
import { loadContentsFromS3 } from "./loadContentsFromS3";
import { timeFormatV2 } from "../../utils/utils";
import { Button } from "@100mslive/react-ui";
import { PlayCircleIcon } from "@100mslive/react-icons";
import Modal from "../../components/ableVideoModal";
import AbleVideo from "./ableVideo";
import LoadingSpinner from "../../components/loadingSpinner";
import { analyzeHlsRecording } from "./analyzeHlsRecording";
import React from "react";
import { compareObject } from "../../utils/compareObject";

const RecordingList = ({ conferences }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [curISO, setCurISO] = useState()
  const [recordings, setRecordings] = useState([]);
  const credential = useMemo(() => ({
    accessKey: process.env.REACT_APP_AWS_KEY,
    bucket: "cloud-conference-1",
    dir: `beam`,
    // dir: `beam/63504a9ef5e7fd9874ffce85/20221025/`,
    region: "us-east-2",
    secretKey: process.env.REACT_APP_AWS_SECRET
  }), []);

  const handleLoadS3 = useCallback(async (e) => {
    // if (recordings === -1 || recordings?.length === 0) 
      setIsLoading(true);
    let contents = await loadContentsFromS3(credential, conferences);
    const analyzed = analyzeRecordings(credential, contents, conferences);
    contents = await loadContentsFromS3(credential, conferences, 'hls');
    const analyzedHls = analyzeHlsRecording(credential, contents, conferences);
    let result = [], i = 0, j = 0;
    while (i < analyzed?.length || j < analyzedHls?.length) {
      if (analyzed?.[i]?.recordingDate < analyzedHls?.[j]?.recordingDate || j >= analyzedHls?.length || !analyzedHls?.length) {
        result.push(analyzed?.[i]);
        i++;
      } else if (analyzed?.[i]?.recordingDate > analyzedHls?.[j]?.recordingDate || i >= analyzed?.length || !analyzed?.length) {
        result.push(analyzedHls?.[j]);
        j++;
      } else if (analyzed?.[i]?.recordingDate === analyzedHls?.[j]?.recordingDate) {
        result.push({
          recordingDate: analyzed?.[i]?.recordingDate,
          recordingArr: [...analyzed?.[i]?.recordingArr, ...analyzedHls?.[j]?.recordingArr]
        })
        i++; j++;
      }
    }
    result = result.sort((a, b) => {
      if (a.recordingDate < b.recordingDate)
        return 1;
      if (a.recordingDate > b.recordingDate)
        return -1;
      return 0;
    })
    // if (!compareObject(result, recordings)) {
      setRecordings(result);
    // }
    setIsLoading(false);
  }, [credential, conferences, 
    // recordings
  ]);

  useEffect(() => {
    handleLoadS3();
  }, [handleLoadS3]);

  const dateFormat = (date) => {
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    return `${year}/${month}/${day}`;
  }

  const filterSpeakerName = (url) => {
    return url?.split('/')?.[6]?.split('-')?.[0];
  }

  return (
    <div 
      style={{width: '100%', height: '100%'}}
      className=""
    >
      <AbleVideo 
        isLoading={isLoading}
        curISO={curISO} 
        recordings={recordings} 
      />
    </div>
  )
};

export default RecordingList;
