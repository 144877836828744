import { createSlice } from '@reduxjs/toolkit'

export const youtubeRtmpSlice = createSlice({
  name: 'youtubeRtmp',
  initialState: {
    loading: false,
    error: null,
    accessToken: '',
    channelId: '',
    broadcastId: '',
    liveStreamId: '',
    isTestingBC: false,
    isLive: false,
    monitorStream: '',
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload
    },
    setChannelId: (state, action) => {
      state.channelId = action.payload
    },
    setBroadcastId: (state, action) => {
      state.broadcastId = action.payload
    },
    setLiveStreamId: (state, action) => {
      state.liveStreamId = action.payload
    },
    setIsTestingBC: (state, action) => {
      state.isTestingBC = action.payload
    },
    setIsLive: (state, action) => {
      state.isLive = action.payload
    },
    setMonitorStream: (state, action) => {
      state.monitorStream = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { 
  setLoading,
  setError,
  setAccessToken,
  setChannelId,
  setBroadcastId,
  setIsTestingBC,
  setIsLive,
  setLiveStreamId,
  setMonitorStream,
} = youtubeRtmpSlice.actions

export default youtubeRtmpSlice.reducer
