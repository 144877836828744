
import { useState } from "react";
import { ReactComponent as XCircle } from "../icons/x-circle.svg";
import { ReactComponent as Microphone } from "../icons/microphone2.svg";
import { ReactComponent as Host } from "../icons/user.svg";
import { ReactComponent as DeviceRotate } from "../icons/device_rotate.svg";
import PeerVolumeController from "../components/volumeLevel";

const ParticipantControl = ({
	idx, editable, producer, offLive, participant, kick, mute, sessions, hostStatus, userLavel,
	toggleHost, confId, editName, setEditName, setCurrentName, customForamt, currentName, handleNameChange,
	ratio, setRatio, ...props
}) => {
	const [showTooltip, setShowTooltip] = useState(false);

	return (
		<div
			key={`${idx}-xyz`}
			onClick={() => {
				if (editable || producer) offLive(participant.identity)
			}}
			className="p-1 cursor-pointer relative" style={{width: '180px', height: '105.25px'}}
		>
			{(producer || editable) &&
				<button
					className="absolute top-1 right-1 text-yellow-400 hover:text-yellow-200 cursor-pointer z-30"
					onClick={(e) => {
						e.stopPropagation();
						setRatio(ratio < 1 ? 1920 / 1080 : 1080 / 1920)
					}}
				>
					<DeviceRotate className={`${ratio < 1 ? "rotate-90 -scale-y-100" : ""} w-8 h-8`} />
				</button>
			}
			{(producer || editable) && (
				<button
					onClick={(e) => {
						e.stopPropagation();
						kick(participant);
					}}
					className="absolute top-5 right-0 text-red-500 hover:text-red-400 cursor-pointer z-30"
				>
					<XCircle className="w-6 h-6 mr-2 mt-2" />
				</button>
			)}
			<div className="absolute right-1 bottom-1">
				<PeerVolumeController 
					peerID={props?.peerId}
					muted={sessions[participant.identity]?.muted}
					onMute={(e) => mute(participant, sessions[participant.identity]?.muted)}
					isShowMuteIcon={(producer || editable)}
				/>
			</div>
			{(producer || editable) && (
				<button
					// className="bg-white hover:bg-gray-100 rounded-md shadow-md py-1 px-3 z-40 font-medium text-gray-800"
					className={
						`absolute top-1 left-1 w-5 h-5
							${hostStatus[participant.identity]
								? "text-red-600 hover:text-red-400"
								: "text-white hover:text-gray-300"
							}
							cursor-pointer z-30`
						}
					onClick={(e) => {
						e.stopPropagation();
						toggleHost(confId, participant.identity)
					}}
					onMouseOver={() => setShowTooltip(true)}
					onMouseOut={() => setShowTooltip(false)}
				>
					<Host
						className="w-6 h-6 ml-1 mb-1"
					/>
					{showTooltip &&
						<div
							className={`
								absolute inline top-full w-20 text-left top-2 left-2 p-0
							`}
						>
							{userLavel && userLavel[participant.identity]}
						</div>
					}
				</button>
			)}
			<div className="h-full border-solid border-2 border-black rounded flex justify-center items-center bg-gray white">
				{editName !== participant.identity && <>
					<button
						className="rounded-md pl-2 pr-2 bg-gray-500 text-gray-100 hover:bg-blue-200"
						onClick={(e) => {
							e.stopPropagation();
							if (producer || editable) {
								setEditName(participant.identity);
								setCurrentName(customForamt(sessions[participant.identity]?.name));
							}
						}}
					>
						{customForamt(sessions[participant.identity]?.name)}
					</button>
				</>}
				{editName === participant.identity && <>
					<input
						className="w-32 customer-name ml-2 mr-2"
						value={currentName}
						onChange={(e) => setCurrentName(e.target.value)}
						onClick={(e) => e.stopPropagation()}
						onKeyPress={(e) => {
							if (e.charCode === 13) {
								if (e.target.value === '') return;
								handleNameChange(participant.identity, e.target.value);
								setEditName(-1);
							}
						}}
						onBlur={(e) => {
							setEditName(-1);
							setCurrentName(customForamt(sessions[participant.identity]?.name));
						}}
					/>
				</>}
			</div>
		</div>
	)
}

export default ParticipantControl;
