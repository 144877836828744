import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../helpers/contexts/userSessionContext";
import { useParams } from "react-router-dom";
import { LocalTracksContext } from "../helpers/contexts/localTracksContext";
import { ReactComponent as CogIcon } from "../icons/cog.svg";
import { ReactComponent as Microphone } from "../icons/microphone.svg";
import { ReactComponent as XCircle } from "../icons/x-circle.svg";
import { ReactComponent as ScreenShare } from "../icons/screen-share.svg";
import { ReactComponent as Host } from "../icons/user.svg";
import { ReactComponent as DeviceRotate } from "../icons/device_rotate.svg";
import PeerVolumeController from "./volumeLevel";

const VideoContainer = ({
  onClose,
  onMute,
  guestName = "",
  className = "",
  muted = false,
  shared = false,
  onScreenShare,
  hideControls = false,
  isLocal = false,
  selected = false,
  showAdminCommands = false,
  children,
  onClick,
  identity,
  width,
  height,
  ratio,
  isLive,
  setRatio,
  ...props
}) => {
  const { openDeviceSelection } = useContext(LocalTracksContext);
  const { user, hostStatus, hostLavel, toggleHost, initHost } = useContext(AuthContext);
  const { conferenceId } = useParams();
  const [isHost, setIsHost] = useState(hostStatus[user?.uid]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [userLavel, setUserLavel] = useState({});

  let style = {
    objectFit: "cover"
  };
  
  useEffect(() => {
    const _setUserLavel = async () => {
      hostStatus && setIsHost(hostStatus[user?.uid]);
      let lavels = await hostLavel(conferenceId);
      setUserLavel(lavels);
    };
    _setUserLavel();
  }, [hostStatus, conferenceId, user?.uid, hostLavel]);

  return (
    <>
      {identity && 
        <div
          id="video_container"
          className={`
            ${className} 
            ${props?.session?.live && props?.session?.isOverloaded && props?.peerId !== props?.activeSpeaker && isLive ? 'hidden' : ''} 
            ${props?.peerId === props?.activeSpeaker ? 'border border-2 border-yellow-500 rounded' : ''}
            h-full p-1 
          `}
          style={style}
        >
          <div
            onClick={onClick}
            className={`${
              selected ? "border-2 border-yellow-400" : ""
            } h-full rounded overflow-hidden shadow-lg relative bg-black`}
          >
            {/* {props?.session?.live && props?.session?.isOverloaded && !isLive &&
              <div className="absolute w-full h-full left-0 top-0 bg-yellow-400 opacity-50 z-30"></div>
            } */}
            {!hideControls && (
              <>
                {!isLive && (isLocal || hostStatus[user?.uid]) &&
                  <button
                    className="absolute top-0 right-0 text-yellow-400 hover:text-yellow-200 cursor-pointer z-30"
                    onClick={(e) => {
                      e.stopPropagation();
                      setRatio(ratio > 1 ? 1080 / 1920 : 1920 / 1080)
                    }}
                  >
                    <DeviceRotate className={`${ratio < 1 ? "rotate-90 -scale-y-100" : ""} w-8 h-8`} />
                  </button>
                }
                {!isLive && !isLocal && showAdminCommands && (
                  <button
                    onClick={onClose}
                    className={`absolute ${isLive ? 'top-6' : 'top-6'} right-0 text-red-500 hover:text-red-400 cursor-pointer z-30`}
                  >
                    <XCircle className="w-6 h-6 mr-1 mt-1" />
                  </button>
                )}
                {!isLive && !isLocal &&
                  <PeerVolumeController 
                    peerID={props?.peerId}
                    muted={muted}
                    onMute={onMute}
                    isShowMuteIcon={
                      !isLive && (isLocal || showAdminCommands)
                    }
                  />
                }
                {!isLive && isLocal &&
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onMute(e);
                    }}
                    className={`absolute bottom-0 right-0 w-7 h-7 ${
                      muted
                        ? "text-red-600 hover:text-red-400"
                        : "text-gray-300 hover:text-gray-400"
                    } cursor-pointer z-30`}
                  >
                    <Microphone className="w-6 h-6 mr-2 mb-2" />
                  </button>
                }
                {!isLive && isLocal && (
                  <button
                    onClick={onScreenShare}
                    className={`absolute bottom-0 left-0 ${
                      shared
                        ? "text-red-600 hover:text-red-400"
                        : "text-white hover:text-gray-300"
                    } cursor-pointer z-30`}
                  >
                    <ScreenShare className="w-6 h-6 ml-1 mb-1"/>
                  </button>
                )}
                {!isLive && <button
                  disabled={!isHost}
                  className={
                    `absolute
                      ${isLocal ? "right-1" : "left-0"}
                      ${isLive ? (isLocal ? "top-7" : 'top-1') : (isLocal ? 'top-7' : 'top-1')}
                      ${hostStatus[identity]
                        ? "text-red-600 hover:text-red-400"
                        : "text-white hover:text-gray-300"
                      }
                      cursor-pointer z-30`
                    }
                  onClick={() => toggleHost(conferenceId, identity)}
                  onMouseOver={() => setShowTooltip(true)}
                  onMouseOut={() => setShowTooltip(false)}
                >
                  <Host
                    className="w-6 h-6 ml-1 mb-1"
                  />
                  {showTooltip &&
                    <div
                      className={`
                        absolute inline top-full w-24 
                          ${isLocal
                            ? "right-0 float-right text-right"
                            : "left-0 float-left text-left"
                          }
                      `}
                    >
                      {userLavel && userLavel[identity]}
                    </div>
                  }
                </button>}
              </>
            )}
            <div className="absolute left-0 bottom-0 text-white font-bold z-10 pl-2 pb-1">
              {guestName}
            </div>
            {!isLive && isLocal && (
              <div className="absolute left-0 top-0 z-30">
                <button
                  onClick={() => openDeviceSelection()}
                  className="text-white hover:text-gray-300"
                >
                  <CogIcon className="w-6 h-6 ml-1 mt-1" />
                </button>
              </div>
            )}
            {children}
          </div>
        </div>
      }
    </>
  );
};

export default VideoContainer;
