import { Fragment, useState, useEffect, useContext, useRef, useCallback, useMemo } from "react";
import {
  selectAppData,
  selectIsConnectedToRoom,
  selectPermissions,
  useHMSActions,
  useHMSStore,
  useRecordingStreaming,
} from "@100mslive/react-sdk";
import { EndStreamIcon, RecordIcon } from "@100mslive/react-icons";
import {
  Box,
  Button,
  Flex,
  Loading,
  Popover,
  Text,
  Tooltip,
} from "@100mslive/react-ui";
import GoLiveButton from "../GoLiveButton";
import { AdditionalRoomState, getRecordingText } from "./AdditionalRoomState";
import { getResolution } from "../Streaming/RTMPStreaming";
import { ToastManager } from "../Toast/ToastManager";
import { ResolutionInput } from "../Streaming/ResolutionInput";
import { useSidepaneToggle } from "../AppData/useSidepane";
import { useSetAppDataByKey } from "../AppData/useUISettings";
import { getDefaultMeetingUrl } from "../../common/utils";
import {
  APP_DATA,
  RTMP_RECORD_DEFAULT_RESOLUTION,
  SIDE_PANE_OPTIONS,
} from "../../common/constants";
import { TranscriptionContext } from "../../helpers/contexts/transcriptionContext";
import { ISOContext } from "../../helpers/contexts/isoContext";
import { FirebaseContext } from '../../helpers/contexts/firebaseContext';
import { getDatabase, ref as rRef, set, onValue, update, onDisconnect } from "firebase/database";
import { useParams } from "react-router-dom"
import UploadingSpinner from "../uploadingSpinner";
import { copyToClipboard } from "../../features/services/copyToClipboard";

export const LiveStatus = () => {
  const { isHLSRunning, isRTMPRunning } = useRecordingStreaming();
  const { conferenceId } = useParams();
  const recordingUrl = `https://100ms.voodoocast.io/hls-view/${conferenceId}/hls-viewer/`;

  const handleCopyUrl = (e) => {
    copyToClipboard(recordingUrl);
  }

  if (!isHLSRunning && !isRTMPRunning) {
    return null;
  }
  return (
    <Flex align="center">
      <Box css={{ w: "$4", h: "$4", r: "$round", bg: "$error", mr: "$2" }} />
      <Text>
        Live
        <Text as="span" css={{ "@md": { display: "none" } }}>
          &nbsp;with {isHLSRunning ? "HLS" : "RTMP"}
        </Text>
      </Text>
      {isHLSRunning &&
        <Button
          css={{
            p: '0px',
            background: 'none',
            border: 'none',
            fontSize: '12px',
            textDecoration: 'underline',
            ml: '5px',
          }}
          onClick={handleCopyUrl}
        >
          Click to Copy URL
        </Button>
      }
    </Flex>
  );
};

export const RecordingStatus = () => {
  const {
    isBrowserRecordingOn,
    isServerRecordingOn,
    isHLSRecordingOn,
    isRecordingOn,
  } = useRecordingStreaming();
  const permissions = useHMSStore(selectPermissions);

  if (
    !isRecordingOn ||
    // if only browser recording is enabled, stop recording is shown
    // so no need to show this as it duplicates
    [
      permissions?.browserRecording,
      !isServerRecordingOn,
      !isHLSRecordingOn,
      isBrowserRecordingOn,
    ].every(value => !!value)
  ) {
    return null;
  }
  return (
    <Tooltip
      title={getRecordingText({
        isBrowserRecordingOn,
        isServerRecordingOn,
        isHLSRecordingOn,
      })}
    >
      <Box
        css={{
          color: "$error",
        }}
      >
        <RecordIcon width={24} height={24} />
      </Box>
    </Tooltip>
  );
};

const EndStream = () => {
  const { isStreamingOn } = useRecordingStreaming();
  const toggleStreaming = useSidepaneToggle(SIDE_PANE_OPTIONS.STREAMING);
  if (!isStreamingOn) {
    return null;
  }
  return (
    <Button
      variant="standard"
      outlined
      icon
      onClick={() => {
        toggleStreaming();
      }}
    >
      <EndStreamIcon />
      End Stream
    </Button>
  );
};

const StartRecording = () => {
  const permissions = useHMSStore(selectPermissions);
  const recordingUrl = useHMSStore(selectAppData(APP_DATA.recordingUrl));
  const [resolution, setResolution] = useState(RTMP_RECORD_DEFAULT_RESOLUTION);
  const [open, setOpen] = useState(false);
  const [selfStarted, setSelfStarted] = useState(false);
  const [recordingStarted, setRecordingState] = useSetAppDataByKey(
    APP_DATA.recordingStarted
  );
  
  const {
    isBrowserRecordingOn,
    isStreamingOn,
    isHLSRunning
  } = useRecordingStreaming();
  
  const hmsActions = useHMSActions();
  
  const {
    isLocalTranscriptEnabled,
    setIsLocalTranscriptEnabled,
    setTranscript,
    setStartedTime,
    isShowTranscript,
    setIsISO
  } = useContext(TranscriptionContext);
  
  const {
    setUploading,
    isISORecording,
    setISORecording,
    startISORecording,
    stopISORecording
  } = useContext(ISOContext);

  const { firebaseApp } = useContext(FirebaseContext);
  const rdb = useMemo(() => getDatabase(firebaseApp), [firebaseApp]);
  
  const { conferenceId: roomId } = useParams();
  const recordingStartedRef = rRef(rdb, `/recording/${roomId}/started`);
  const recordingStoppedRef = rRef(rdb, `/recording/${roomId}/stopped`);
  const recordingFailedRef = rRef(rdb, `/recording/${roomId}/failed`);
  const recordingSuccessRef = rRef(rdb, `/recording/${roomId}/success`);

  const startedTimeRef = useRef();
  const statusRef = useRef();

  const recordingStartedListener = useCallback((snapshot) => {
    const { status, time } = snapshot.val();
    if (status !== statusRef.current) {
      statusRef.current = status;
      if (status === 'beam.joined.success') {
        setTranscript([]);
        // setStartedTime(new Date().getTime());
        setStartedTime(new Date(time).getTime());
        setIsISO(true);
        setISORecording(true);
        startISORecording();
        update(recordingStartedRef, {
          status: ''
        });
      } 
    }
  }, [setISORecording, setIsISO, setStartedTime, setTranscript, 
    startISORecording, recordingStartedRef]);
  
  const recordingStoppedListener = useCallback((snapshot) => {
    const { status } = snapshot.val();
    if (status !== statusRef.current) {
      statusRef.current = status;
      if (status === 'beam.stopped.success') {
        setUploading(true);
        update(recordingStoppedRef, {
          status: ''
        });
        ToastManager.addToast({
          title: "ISO uploading started, Do Not leave meeting until it's finished",
          variant: "success",
        });
      }
    }
  }, [setUploading, recordingStoppedRef]); 
      
  const recordingSuccessListener = useCallback((snapshot) => {
    const { status, location } = snapshot.val();
    if (status !== statusRef.current) {
      statusRef.current = status;
      if (status === 'beam.recording.success') {
        let _startedTime = Math.floor(location?.split('/')[6].split('.')[0].split('-')[2]);
        let _startedDate = location?.split('/')[5];
        if (startedTimeRef.current !== _startedTime) {
          startedTimeRef.current = _startedTime;
          stopISORecording(startedTimeRef.current, _startedDate);
          setSelfStarted(false);
          update(recordingSuccessRef, {
            status: ''
          });
        }
      }
    }
  }, [stopISORecording, recordingSuccessRef]); 
      
  const recordingFailedListener = useCallback((snapshot) => {
    const { status } = snapshot.val();
    if (status !== statusRef.current) {
      statusRef.current = status;
      if (status === 'beam.failure') {
        ToastManager.addToast({
          title: "Recording already running",
          variant: "error",
        });
        stopISORecording();
        setSelfStarted(false);
        update(recordingFailedRef, {
          status: ''
        });
        alert('recording failed')
      }
    }
  }, [stopISORecording, recordingFailedRef]);

  useEffect(() => {
    if (selfStarted) {
      onValue(recordingStartedRef, recordingStartedListener);
    }
  }, [selfStarted, recordingStartedRef, recordingStartedListener]);

  useEffect(() => {
    if (selfStarted) {
      onValue(recordingStoppedRef, recordingStoppedListener);
    } else {
      update(recordingStoppedRef, {
        status: ''
      });
    }
  }, [selfStarted, recordingStoppedRef, recordingStoppedListener]);

  useEffect(() => {
    if (selfStarted) {
      onValue(recordingFailedRef, recordingFailedListener);
    }
  }, [selfStarted, recordingFailedRef, recordingFailedListener]);

  useEffect(() => {
    if (selfStarted) {
      onValue(recordingSuccessRef, recordingSuccessListener);
    }
  }, [selfStarted, recordingSuccessRef, recordingSuccessListener]);

  useEffect(() => {
    if (isBrowserRecordingOn && setRecordingState) {
      setTimeout(() => {
        setRecordingState(false);
      }, 200);
    }

    if (isBrowserRecordingOn && isLocalTranscriptEnabled && !isISORecording) {
      // setTranscript([]);
      // setStartedTime(new Date().getTime());
      // setIsISO(true);
      // setISORecording(true);
      // startISORecording();
    }
  }, [isBrowserRecordingOn, isLocalTranscriptEnabled, setRecordingState, setISORecording, 
    setIsISO, setStartedTime, setTranscript, startISORecording, isISORecording, stopISORecording]);

  useEffect(() => {
    if (!isBrowserRecordingOn) {
      // stopISORecording();
    }
  }, [isBrowserRecordingOn, stopISORecording]);

  if (!permissions?.browserRecording || isHLSRunning) {
    return null;
  }
  return (
    <>
      {/* <Button
        onClick={() => {
          startISORecording();
        }}
      >
        Start ISO Recording
      </Button>

      <Button
        onClick={() => {
          stopISORecording();
        }}
      >
        Stop ISO Recording
      </Button> */}
      {isBrowserRecordingOn &&
        <Popover.Root open={open} onOpenChange={setOpen}>
          <Popover.Trigger asChild>
            <Button css={{height: "30px"}} variant="danger" icon outlined onClick={() => setOpen(true)}>
              <RecordIcon />
              <Text
                as="span"
                css={{ "@md": { display: "none" }, color: "currentColor" }}
              >
                Stop Recording
              </Text>
            </Button>
          </Popover.Trigger>
          <Popover.Content align="end" sideOffset={8} css={{ w: "$64" }}>
            <Text variant="body" css={{ color: "$textMedEmp" }}>
              Are you sure you want to end the recording?
            </Text>
            <Button
              variant="danger"
              icon
              css={{ ml: "auto" }}
              onClick={async () => {
                // setIsLocalTranscriptEnabled(isShowTranscript);
                setISORecording(false);
                setIsISO(false);
                // await stopISORecording();
                try {
                  await hmsActions.stopRTMPAndRecording();
                } catch (error) {
                  ToastManager.addToast({
                    title: error.message,
                    variant: "error",
                  });
                }
                setOpen(false);
              }}
            >
              Stop
            </Button>
          </Popover.Content>
        </Popover.Root>
      }
      {!isBrowserRecordingOn &&
        <Popover.Root open={open} onOpenChange={setOpen}>
          <Popover.Trigger asChild>
            <Button
              variant="standard"
              icon
              css={{height: "30px"}}
              disabled={recordingStarted || isStreamingOn}
              onClick={() => setOpen(true)}
            >
              {recordingStarted ? (
                <Loading size={24} color="currentColor" />
              ) : (
                <RecordIcon />
              )}
              <Text
                as="span"
                css={{ "@md": { display: "none" }, color: "currentColor" }}
              >
                {recordingStarted ? "Starting" : "Start"} Recording
              </Text>
            </Button>
          </Popover.Trigger>
          <Popover.Content align="end" sideOffset={8} css={{ w: "$74" }}>
            <ResolutionInput
              css={{ flexDirection: "column", alignItems: "start" }}
              onResolutionChange={setResolution}
            />
            <Button
              variant="primary"
              icon
              css={{ ml: "auto" }}
              type="submit"
              disabled={recordingStarted || isStreamingOn}
              onClick={async () => {
                setIsLocalTranscriptEnabled(true);
                try {
                  setRecordingState(true);
                  setSelfStarted(true);
                  let meetingURL = (recordingUrl || getDefaultMeetingUrl()).replace('http://localhost:3000', 'https://100ms.voodoocast.io');
                  await hmsActions.startRTMPOrRecording({
                    meetingURL,
                    resolution: getResolution(resolution),
                    record: true,
                  });
                } catch (error) {
                  if (error.message.includes("stream alredy running")) {
                    ToastManager.addToast({
                      title: "Recording already running",
                      variant: "error",
                    });
                  } else {
                    ToastManager.addToast({
                      title: error.message,
                      variant: "error",
                    });
                  }
                  setSelfStarted(false);
                  setRecordingState(false);
                }
                setOpen(false);
                // setRecordingState(false);
              }}
            >
              Start
            </Button>
          </Popover.Content>
        </Popover.Root>
      }
    </>
  );
};

export const StreamActions = () => {
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const permissions = useHMSStore(selectPermissions);
  const { isUploading } = useContext(ISOContext);
  return (
    <Flex align="center" css={{ gap: "$4" }}>
      {isConnected && <StartRecording />}
      {isConnected && (permissions.hlsStreaming || permissions.rtmpStreaming) && (
        <Fragment>
          <GoLiveButton />
          <EndStream />
        </Fragment>
      )}
      
      <Flex align="center" css={{ gap: "$4", "@md": { display: "none" } }}>
        <LiveStatus />
        <RecordingStatus />
      </Flex>
      {isUploading && <>
        <UploadingSpinner className="w-6 h-6" />
        <span className="text-white">Uploading ISO</span>
      </>}
      {/* <AdditionalRoomState /> */}
    </Flex>
  );
};