import React from "react";
import {useSliderState} from 'react-stately';
import { timeFormat } from '../../../utils/timeFormat';
import { useSelector } from "react-redux";

import {mergeProps, useFocusRing, useNumberFormatter, useSlider, useSliderThumb, VisuallyHidden} from 'react-aria';

import Thumb from './thumb';

const SelectedRange = ({ state }) => {
	return (
		<div
			className="bg-blue-500 absolute"
			style={{
				left: `${state?.values?.[0]}%`,
				width: `${state?.values?.[1] - state?.values?.[0]}%`,
				height: '4px'
			}}
		>

		</div>
	)
};

const RangeSlider = (props) => {
	let trackRef = React.useRef(null);

	const sliderValue = useSelector(state => state.videoSplit.sliderValue);

	let numberFormatter = useNumberFormatter(props.formatOptions);
	let state = useSliderState({ ...props, numberFormatter });
	let {
		groupProps,
		trackProps,
		labelProps,
		outputProps
	} = useSlider(props, state, trackRef);

	const onInput = React.useRef(props?.onInput);

	React.useEffect(() => {
		// onInput.current(state?.values);
	}, [state?.values])

	// React.useEffect(() => {
	// 	console.log({sliderValue})
	// 	state.setThumbValue(0, sliderValue?.[0]);
	// 	state.setThumbValue(1, sliderValue?.[1]);
	// 	// state.values = JSON.parse(JSON.stringify(sliderValue))

	// 	console.log({state})
	// }, [sliderValue])

	return (
		<div {...groupProps} className={`slider ${state.orientation}`}>
			{props.label &&
				(
					<div className="label-container absolute top-2 text-xs text-white">
						<label {...labelProps}>{props.label}</label>
						<output {...outputProps}>
							{`${timeFormat(state.getThumbValueLabel(0) / 100 * props.videoDuration)} - ${
								timeFormat(state.getThumbValueLabel(1) / 100 * props.videoDuration)
							}`}
						</output>
					</div>
				)}
			<div
				{...trackProps}
				ref={trackRef}
				className={`track ${state.isDisabled ? 'disabled' : ''}`}
			>
				<SelectedRange state={state} />
				<Thumb index={0} state={state} trackRef={trackRef} videoDuration={props.videoDuration} />
				<Thumb index={1} state={state} trackRef={trackRef} videoDuration={props.videoDuration} />
			</div>
		</div>
	);
}

export default RangeSlider;
