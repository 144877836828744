import RecordingList from "./recordingList"

export const MainView = ({conferences}) => {
  return (
    <div className="overflow-y-visible main-recording-list">
      <RecordingList 
        conferences={conferences}
      />
    </div>
  )
};
