import React from 'react';
import { createContext } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";

export const SELECTED_VIDEO_INPUT_ID = "SELECTED_VIDEO_INPUT_ID";
export const SELECTED_AUDIO_INPUT_ID = "SELECTED_AUDIO_INPUT_ID";
export const SELECTED_AUDIO_OUTPUT_ID = "SElECTED_AUDIO_OUTPUT_ID";

export const MediaDeviceContext = createContext({
  videoDeviceId: "",
  audioInputDeviceId: "",
  audioOutputDeviceId: "",
});

const MediaDeviceProvider = ({ children }) => {
  const [videoDeviceId, setVideoDeviceId] = useLocalStorage(
    SELECTED_VIDEO_INPUT_ID,
    ""
  );

  const [audioInputDeviceId, setAudioInputDeviceId] = useLocalStorage(
    SELECTED_AUDIO_INPUT_ID,
    ""
  );

  const [audioOutputDeviceId, setAudioOutputDeviceId] = useLocalStorage(
    SELECTED_AUDIO_OUTPUT_ID,
    ""
  );

  const handleVideoDeviceChange = (id) => {
    // if (id !== videoDeviceId) {
      setVideoDeviceId(id);
    // }
  };

  const handleAudioInputDeviceChange = (id) => {
    if (id !== audioInputDeviceId) {
      setAudioInputDeviceId(id);
    }
  };

  const handleAudioOutputDeviceChange = (id) => {
    if (id !== audioOutputDeviceId) {
      setAudioOutputDeviceId(id);
    }
  };

  return (
    <MediaDeviceContext.Provider
      value={{
        videoDeviceId,
        audioInputDeviceId,
        audioOutputDeviceId,
        changeVideoDevice: handleVideoDeviceChange,
        changeAudioInputDevice: handleAudioInputDeviceChange,
        changeAudioOutputDevice: handleAudioOutputDeviceChange,
      }}
    >
      {children}
    </MediaDeviceContext.Provider>
  );
};

export default MediaDeviceProvider;
