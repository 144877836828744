import { Button } from "@100mslive/react-ui";
import React from "react";
import { ReactComponent as DropDownIcon } from "../../../icons/chevron-down.svg";
import useDismissed from "./useDismissed";
import useJustResponded from "./useJustResponded";

const EmbedInteraction = ({
  user,
  interaction,
  isShow,
  isAdmin,
  isResponded,
  isDenied,
  interact,
  deny
}) => {
  const [answer, setAnswer] = React.useState({});
  const [isShowResponse, setIsShowResponse] = React.useState(false);
  const { isDismissed } = useDismissed({ interaction, isResponded, isDenied });
  
  const { isJustResponded } = useJustResponded({ interaction });

  React.useEffect(() => {
    if (Object.keys(isJustResponded)?.some(item => isJustResponded?.[item])) {
      setIsShowResponse(true);
    }
  }, [isJustResponded]);

  React.useEffect(() => {
    if (isResponded) {
      setAnswer((interaction?.responded ?? []).find(_user => _user?.interactedUser?.userId === user?.uid)?.answer)
    }
  }, [isResponded, interaction, user])

  if (!isShow) {
    return (
      <></>
    )
  }
  return (
    <div className="flex flex-col">
      {isResponded && 
        <p className="text-sm italic">You interacted like below.</p>
      }
      {isDenied && 
        <p className="text-sm italic">You denied.</p>
      }
      {isDismissed && !isAdmin &&
        <p className="text-sm italic">You dismissed.</p>
      }
      {(interaction?.content?.options ?? []).map((option, idxx) => (
        <div key={idxx} className="flex flex-col justify-start">
          <div className="flex">
            {(isAdmin || isDismissed) && <span>{idxx + 1}.&nbsp;&nbsp;</span>}
            {option?.option}
          </div>
          {!isAdmin && !isDismissed && <input 
            value={answer?.[idxx]}
            onChange={(e) => setAnswer(previous => {
              return {
                ...(previous ?? {}),
                [idxx]: e.target.value
              }
            })}
            disabled={!!isAdmin || isResponded || isDenied}
            className="w-full h-5 mr-2 text-black"
          />}
        </div>
      ))}
      {isAdmin &&
        <>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => setIsShowResponse(!isShowResponse)}
          >
            <span className="text-blue-300 text-decoration-blue-300">
              {interaction?.responded?.length || interaction?.denied?.length 
                ? "Show Responses"
                : "No Response"
              }
            </span>
            {(!!interaction?.responded?.length || !!interaction?.denied?.length) && 
              <DropDownIcon className="text-blue-300 w-5 h-4 ml-2 mt-1" />
            }
          </div>
          {isShowResponse &&
            <>
              {(interaction?.responded ?? []).map((item, idxx) => (
                <div
                  key={idxx}
                  className="pl-2"
                >
                  <div className={`flex ${isJustResponded?.[item?.id] ? "animate-toggle": ""}`}>
                    <span>{item?.interactedUser?.displayName}</span>
                    &nbsp;&nbsp;
                    <span className="text-gray-500">responded</span>
                  </div>
                  {(interaction?.content?.options ?? []).map((option, idxxx) => (
                    <div key={idxxx} className="flex justify-start items-center">
                      <span>{idxxx + 1}.&nbsp;&nbsp;</span>
                      <input 
                        value={item.answer?.[idxxx]}
                        disabled={true}
                        className="h-5"
                      />
                    </div>
                  ))}
                </div>
              ))}
              {(interaction?.denied ?? []).map((item, idxx) => (
                <div
                  key={idxx}
                  className="pl-2"
                >
                  <div className="flex">
                    <span>{item?.deniedUser?.displayName}</span>
                    &nbsp;&nbsp;
                    <span className="text-gray-500">denied</span>
                  </div>
                </div>
              ))}
            </>
          }
        </>
      }
      {!isAdmin && !isResponded && !isDenied && !isDismissed && 
        <div className="flex justify-around my-2">
          <Button
            variant="primary"
            css={{ h: '$11' }}
            onClick={() => {
              interact({
                ...interaction,
                interactedUser: { userId: user?.uid, displayName: user?.displayName ?? '', email: user?.email ?? '' },
                answer
              })
            }}
          >interact</Button>
          <Button
            variant="standard"
            css={{ h: '$11' }}
            onClick={() => {
              deny({
                ...interaction,
                denied: true,
                deniedUser: { userId: user?.uid, displayName: user?.displayName ?? '', email: user?.email ?? '' },
              })
            }}
          >deny</Button>
        </div>
      }
    </div>
  )
}

export default EmbedInteraction;
