import Interaction from "./interaction";
import Director from "./director";
import Communicate from "./communicate";

const HumanResource = ({
  activeZone
}) => {
  return (
    <>
      <Interaction isShow={activeZone === 'interaction'} />
      <Director isShow={activeZone === 'director'} />
      <Communicate isShow={activeZone === 'communicate'} />
    </>
  )
}

export default HumanResource;
