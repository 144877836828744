import React from "react";
import { ReactComponent as FullScreenIcon } from "../../icons/fullscreen.svg";
import {ReactComponent as ExitFullScreenIcon } from "../../icons/exit_fullscreen.svg";
import LoadingSpinner from "../../components/loadingSpinner";
import { 
  PauseIcon,
  PlayIcon,
  RefreshIcon,
  BackwardIcon,
  ForwardSolidIcon,
  SpeakerIcon,
  ClosedCaptionIcon,
  VolumeOneIcon,
  ArrowLeftRightIcon,
} from "@100mslive/react-icons";
import { ReactComponent as AddCCIcon } from "../../icons/cc+.svg";
import { manualTranscript } from "./manualTranscript";
import { timeFormat } from '../../utils/timeFormat';
import RangeBar from "../../components/RangeBar";
import { ReactComponent as ArrowIcon } from "../../image/icon/chevron-down.svg"
import { ReactComponent as SpeakerOffIcon } from "../../image/icon/speaker-off.svg";
import { DownloadIcon } from "@100mslive/react-icons";
import axios from "axios";
import {
  onSnapshot,
  doc,
  collection,
  getFirestore
} from "@firebase/firestore";
import { FirebaseContext } from "../../helpers/contexts/firebaseContext";
import { useSelector, useDispatch } from 'react-redux';
import { 
  setDownloadKey, 
  setIsDownloading,
  setIsIntentDownload,
  setDownloadTimeRange,
  setProgress,
} from '../../helpers/redux/reducers/videoSplitReducer';
import { 
  CircularProgressbar,
  buildStyles 
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Video = ({
  videoRef,
  isFullScreen,
  curISO,
  handleOnTimeUpdate,
  handleVideoLoad,
  setIsVideoLoading,
  playVideo,
  toggleFullScreen,
  disableEnter,
  playerState,
  isVideoLoading,
  togglePlay,
  handleRefresh,
  handleVideoBackward,
  handleVideoSpeed,
  isTranscriptListOpen,
  setIsTranscriptListOpen,
  isLoading,
  setIsLoading,
  transcripts,
  setResult,
  videoDuration,
  handleVideoProgress,
  toggleMute,
}) => {
  // const [isIntentDownload, setIsIntentDownload] = React.useState(false);
  // const [downloadTimeRange, setDownloadTimeRange] = React.useState();
  const isIntentDownload = useSelector((state) => state.videoSplit.isIntentDownload);
  const downloadTimeRange = useSelector((state) => state.videoSplit.downloadTimeRange);
  const downloadKey = useSelector((state) => state.videoSplit.downloadKey);
  const isDownloading = useSelector((state) => state.videoSplit.isDownloading);
  const progress = useSelector(state => state.videoSplit.progress);
  const dispatch = useDispatch();

  const backendUrl = 'https://videosplitor.voodoocast.io';
  // const backendUrl = 'http://localhost:5000';

  const { firebaseApp } = React.useContext(FirebaseContext);
  const db = React.useMemo(() => getFirestore(firebaseApp), [firebaseApp]);
  const splitDocRef = React.useMemo(() => 
    doc(collection(doc(collection(db, 'conferences'), curISO?.roomId ?? 'defaultRoom'), 'splitDownload'), 'videoData')
    , [curISO?.roomId]
  );

  React.useEffect(() => {
    dispatch(setIsIntentDownload(false));
  }, [curISO?.url, dispatch]);

  const download = (dataurl, filename) => {
    return fetch(dataurl)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      dispatch(setIsDownloading(false));
    })
    .catch(console.error);
  }

  React.useEffect(() => {
    return onSnapshot(splitDocRef, async (doc) => {
      if (downloadKey) {
        const docData = doc.data();
        if (downloadKey === docData?.downloadKey && docData.success) {
          dispatch(setProgress(100))
          await download(`${backendUrl}/video_download/${docData?.filename}`, 'videoSplit.mp4');
          dispatch(setProgress(0))
        } else if (downloadKey === docData?.downloadKey && !docData.success && !docData.isProcessing) {
          dispatch(setIsDownloading(false));
          dispatch(setProgress(0))
        } else if (downloadKey === docData?.downloadKey && docData.isProcessing) {
          dispatch(setProgress(docData.progress))
        }
      }
    })
  }, [splitDocRef, downloadKey]);

  const toggleRangeSlider = async (e) => {
    dispatch(setIsIntentDownload(!isIntentDownload));
  }

  const downloadSplit = async (e) => {
    const _downloadKey = new Date().getTime();
    if (!curISO?.url || !curISO?.roomId || !downloadTimeRange.startTime || !downloadTimeRange.duration) {
      return;
    }
    dispatch(setIsDownloading(true));
    const response = await axios.post(`${backendUrl}/video_split`, {
      "video_url": curISO?.url ?? "",
      "startTime": downloadTimeRange.startTime.split(' ').join(''),
      "duration": Math.floor(downloadTimeRange.duration * 1000) / 1000,
      "roomId": curISO?.roomId ?? 'defaultConferece',
      "downloadKey": _downloadKey
    }).then(res => res);
    dispatch(setDownloadKey(_downloadKey));
  }

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="relative w-full h-full p-1 bg-gray-400">
        <div className="relative w-auto">
          <video
            ref={videoRef}
            className={`cursor-default w-full h-full bg-black rounded ${isFullScreen ? "fixed left-0 top-0 z-4000" : ""}`}
            src={curISO?.url}
            onTimeUpdate={(e) => {
              handleOnTimeUpdate();
              handleVideoLoad(e);
            }}
            style={{
              width: !isFullScreen ? '100%' : '100vw',
              height: !isFullScreen ? 'calc(100vh - 300px)' : '100vh',
              minHeight: '492px'
            }}
            onLoadStart={() => {
              if (curISO) setIsVideoLoading(true)
            }}
            onLoadedData={(e) => {
              handleVideoLoad(e);
              playVideo();
            }}
          >
            <source  type="video/mp4" />
            <track kind="captions" src={curISO?.transcriptVtt} srcLang="en" label="English" default />
          </video>
          {isFullScreen &&
            <ExitFullScreenIcon 
              className="w-8 h-8 z-5000 fixed right-3 top-3 text-white cursor-pointer"
              onClick={toggleFullScreen}
              onKeyDown={disableEnter}
            />
          }
          {!isFullScreen && 
            <div className="absolute bottom-0 text-white z-5000 bg-gray-800">
              {playerState.transcript?.speaker}
              {playerState.transcript?.message}
            </div>
          }
          {isVideoLoading && <div className="absolute w-full h-full top-0 left-0 flex justify-center items-center">
            <LoadingSpinner />
          </div>}
        </div>
      </div>
      <div className="flex justify-between p-1 bg-gray-700 rounded-br text-black">
        <div className="flex items-center">
          <button
            onClick={togglePlay}
            onKeyDown={disableEnter}
          >
            {!playerState.isPlaying && <PlayIcon className="w-10 h-10 cursor-pointer hover:border-2 hover:border-solid hover:border-yellow-300" />}
            {playerState.isPlaying && <PauseIcon className="w-10 h-10 cursor-pointer hover:border-2 hover:border-solid hover:border-yellow-300" />}
          </button>
          <button
            onClick={handleRefresh}
            onKeyDown={disableEnter}
          >
            <RefreshIcon className="w-10 h-10 cursor-pointer hover:border-2 hover:border-solid hover:border-yellow-300"/>
          </button>
          <button 
            onClick={() => handleVideoBackward(1)}
            onKeyDown={disableEnter}
          >
            <span className="w-6 h-6 cursor-pointer hover:border-2 hover:border-solid hover:border-yellow-300">{"<<"}</span>
          </button>
          {/* <BackwardIcon className="w-10 h-10 cursor-pointer hover:border-2 hover:border-solid hover:border-yellow-300"/>
          <ForwardSolidIcon className="w-10 h-10 cursor-pointer hover:border-2 hover:border-solid hover:border-yellow-300"/> */}
          <select
            className="w-10 h-10 bg-gray-700 appearance-none text-center cursor-pointer hover:border-2 hover:border-solid hover:border-yellow-300"
            value={playerState.speed}
            onChange={(e) => handleVideoSpeed(e)}
          >
            <option value="0.50">0.50x</option>
            <option value="1">1x</option>
            <option value="1.25">1.25x</option>
            <option value="2">2x</option>
          </select>
          <button 
            onClick={() => handleVideoBackward(-1)}
            onKeyDown={disableEnter}
          >
            <span className="w-6 h-6 cursor-pointer hover:border-2 hover:border-solid hover:border-yellow-300">{">>"}</span>
          </button>
          <ClosedCaptionIcon 
            className="w-10 h-10 cursor-pointer hover:border-2 hover:border-solid hover:border-yellow-300"
            onClick={() => setIsTranscriptListOpen(!isTranscriptListOpen)} 
          />
          {curISO?.isRoomVideo && <button
            className="w-10 h-10 cursor-pointer hover:border-2 hover:border-solid hover:border-yellow-300"
            disabled={isLoading || transcripts?.length > 0}
            onClick={(e) => {
              setIsLoading(true);
              manualTranscript({url: curISO?.url, setIsLoading, setResult})
            }}
            onKeyDown={disableEnter}
          >
            {!isLoading && <AddCCIcon />}
            {isLoading && <LoadingSpinner />}
          </button>}

          <button
            onClick={toggleFullScreen}
            onKeyDown={disableEnter}
            className="w-10 h-10 mr-2 cursor-pointer hover:border-2 hover:border-solid hover:border-yellow-300 flex items-center justify-center"
            disabled={!!!curISO?.url}
          >
            <FullScreenIcon 
              className="w-6 h-6"
            />
          </button>

          <div className="flex flex-col">
            <div className="flex">
              <span className="text-xs w-12 mr-2">
                Current:
              </span>
              <span className="text-xs w-32">
                {timeFormat(playerState?.currentTime ?? 0)}
              </span>
            </div>
            <div className="flex">
              <span className="text-xs w-12 mr-2">
                Duration:
              </span>
              <span className="text-xs w-32">
                {timeFormat(videoDuration)}
              </span>
            </div>
          </div>

          <button
            className="w-7 h-7 cursor-pointer hover:border-2 hover:border-solid hover:border-yellow-300 flex items-center justify-center"
            onClick={toggleRangeSlider}
          >
            <ArrowLeftRightIcon
              className="w-6 h-6"
            />
          </button>

          <button
            className={
              `w-7 h-7 mr-2 cursor-pointer 
              ${isIntentDownload ? "hover:border-2 hover:border-solid hover:border-yellow-300" : ""} 
              flex items-center justify-center`
            }
            onClick={downloadSplit}
            disabled={isDownloading || !isIntentDownload}
          >
            {!isDownloading && <DownloadIcon
              className="w-6 h-6"
            />}
            
            {isDownloading && progress === 0 &&
              <div 
                className="loading-spinner" 
                style={{
                  width: '20px',
                  height: '20px',
                  border: '4px solid #f3f3f3',
                  borderTop: '4px solid #383636',
                }}
              ></div>
            }
            {isDownloading && progress > 0 &&
              <CircularProgressbar 
                value={progress} 
                maxValue={100} 
                text={`${Math.floor(progress)}`} 
                strokeWidth={15}
                styles={buildStyles({
                  textColor: "white",
                  pathColor: "#f3f3f3",
                  trailColor: "#383636"
                })}
              />
            }
          </button>
        
        </div>
        <div className="flex w-full items-center">
          <RangeBar
            getNumber={handleVideoProgress}
            width="100%"
            backgroundColor="gray"
            fillColor="blue"
            fillSecondaryColor="blue"
            headColor="white"
            headShadow="white"
            headShadowSize={6}
            progress={playerState.progress}
            mousePosition={(position, width) => timeFormat(videoDuration / width * position * 1000)}
            isIntentDownload={isIntentDownload}
            setIsIntentDownload={() => dispatch(setIsIntentDownload)}
            downloadTimeRange={downloadTimeRange}
            setDownloadTimeRange={() => dispatch(setDownloadTimeRange)}
            playerState={playerState}
            videoDuration={videoDuration}
          />
          <div className="relative ml-2 pt-1">
            <button
                onClick={toggleMute}
            >
              {!playerState.isMuted && <SpeakerIcon 
                className="w-10 h-10 cursor-pointer hover:border-2 hover:border-solid hover:border-yellow-300"
              />}
              {playerState.isMuted && <SpeakerOffIcon 
                className="w-9 h-9 cursor-pointer hover:border-2 hover:border-solid hover:border-yellow-300 text-white"
              />}
            </button>
          </div>
        </div>
      </div>
      
      {!isTranscriptListOpen && 
        <div 
          className="absolute left-full top-0 h-8 py-1 px-0 bg-gray-400 cursor-pointer rounded-r"
          onClick={() => setIsTranscriptListOpen(true)}
        >
          <ArrowIcon className="w-6 h-6 -rotate-90 cursor-pointer text-gray-900" />
        </div>
      }
    </div>
  )
}

export default Video;
