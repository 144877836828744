import React, { useEffect } from 'react';
import axios from 'axios';
import {
  Button
} from "@100mslive/react-ui";

const VimeoOAuth = () => {
  const clientId = '13b36c35a93a2d72989f86fd36f287bbfc566459';
  const redirectUri = `https://d85b-38-75-137-97.ngrok-free.app/vimeo_oauth`;
  const scope = 'public private create upload edit';
  const vimeoAuthBackendUrl = 'http://localhost:5000/vimeo_access_token';

  const [accessToken, setAccessToken] = React.useState('');

  useEffect(() => {
    const handleOAuth = async () => {
      const url = `https://api.vimeo.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scope}`;
      
      // Redirect the user to the Vimeo OAuth authorization URL
      window.location.href = url;
    };

    const getAccessToken = async (code) => {
      try {
        const response = await axios.get(`${vimeoAuthBackendUrl}?code=${code}`);

        const { access_token } = response.data;
        setAccessToken(access_token);

        // Use the access token to make authenticated requests to the Vimeo API
        // Perform API calls or store the access token for future use
        console.log('Access Token:', access_token);
      } catch (error) {
        console.error('Error:', error.message);
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    
    if (!code) {
      handleOAuth();
    } else {
      getAccessToken(code);
    }
  }, []);

  const handleOneTimeLiveEvent = async () => {
    
  };

  return (
    <div>
      {accessToken &&
        <div>
          <Button onClick={handleOneTimeLiveEvent}>One Time Live</Button>
        </div>
      }
    </div>
  );
};

export default VimeoOAuth;